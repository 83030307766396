import ContentLoader from 'components/contentLoader/ContentLoader';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import OrdersItemsType from 'models/OrderItemsType';
import { Urls } from 'navigation/Urls';
import { TypeOfSaleConstants } from 'pages/shopping-cart/Constants';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { sortOrderItems, OfferingTypesWithMenus } from 'services/menus';
import {
  selectOrderItems, selectOrderFlow, getOrderItems, selectOrdersContent, selectIsPartnerFlow,
} from 'services/ordersSlice';
import { OrderFlow } from 'shared/constants';

const nextStepCobalt = Urls.Overview;

export const getFirstStepOfferingType = (
  offeringType: string,
  isSalesForce = false,
  isPunchlist = false,
  isPartnerFlow = false,
  isRebuild = false,
): string => {
  if (isSalesForce || isPunchlist || isPartnerFlow || isRebuild) {
    switch (offeringType) {
      case 'LandingPage': return Urls.GeneralQuestions;
      case 'ChatProduct': return Urls.GeneralQuestions;
      case 'ChatPlatinumProduct': return Urls.GeneralQuestions;
      case 'EcommerceMicrosite': return Urls.GeneralQuestions;
      case 'GoldSite': return Urls.GeneralQuestions;
      case 'Website': return Urls.GeneralQuestions;
      case 'CustomLandingPage': return Urls.LandingCustomDetails;
      case 'MasterAddon': return Urls.WebsiteUrl;
      case 'CustomWebsite': return Urls.CustomWebsiteDetails;
      case 'PremiumListing': return Urls.GeneralQuestions;
      case 'SEOStandard': return Urls.GeneralQuestions;
      case 'SEOCustom': return Urls.GeneralQuestions;
      case 'SEOBlogStandard': return Urls.GeneralQuestions;
      case 'SEOBlogEnhanced': return Urls.GeneralQuestions;
      case 'GenericProduct': return Urls.GeneralQuestions;
      case 'SMMStandard': return Urls.GeneralQuestions;
      case 'SMMCustom': return Urls.GeneralQuestions;
      case 'DMSNextCustomerCenterByDash': return Urls.GeneralQuestions;
      case 'DMSNextCustomerCenter': return Urls.GeneralQuestions;
      case 'YouTube': return Urls.GeneralQuestions;
      case 'PiQLandingPages': return Urls.GeneralQuestions;
      case 'TargetedDisplay': return Urls.GeneralQuestions;
      case 'TargetedDisplayLegacy': return Urls.GeneralQuestions;
      case 'XMO': return Urls.GeneralQuestions;
      case 'XMOUpgrade': return Urls.GeneralQuestions;
      case 'PromotionsMediaMix': return Urls.GeneralQuestions;
      default: return Urls.GeneralQuestions;
    }
  }
  switch (offeringType) {
    case 'LandingPage': return Urls.OrderType;
    case 'ChatProduct': return Urls.BusinessChat;
    case 'ChatPlatinumProduct': return Urls.PlatinumBusinessChat;
    case 'EcommerceMicrosite': return Urls.Domain;
    case 'GoldSite': return Urls.Domains;
    case 'Website': return Urls.Domains;
    case 'CustomLandingPage': return Urls.LandingCustomDetails;
    case 'MasterAddon': return Urls.WebsiteUrl;
    case 'CustomWebsite': return Urls.CustomWebsiteDetails;
    case 'SEOStandard': return Urls.SeoDetails;
    case 'SEOCustom': return Urls.SeoDetails;
    case 'SEOBlogStandard': return Urls.BlogSolutionStrategy;
    case 'SEOBlogEnhanced': return Urls.BlogSolutionStrategy;
    case 'GenericProduct': return Urls.GenericProductDetails;
    case 'SMMStandard': return Urls.SMMDetails;
    case 'SMMCustom': return Urls.SMMDetails;
    case 'DMSNextCustomerCenterByDash': return Urls.CCByDashDetails;
    case 'DMSNextCustomerCenter': return Urls.CustomerCenterStrategy;
    case 'YouTube': return Urls.YouTubeTargeting;
    case 'PiQLandingPages': return Urls.PiQWebsite;
    case 'TargetedDisplay': return Urls.TargetedDisplay;
    case 'TargetedDisplayLegacy': return Urls.TargetedDisplayLeagacyProxyProvisioning;
    case 'SearchEngineMarketing': return Urls.SemDetails;
    case 'XMO': return Urls.XmoDetails;
    case 'XMOUpgrade': return Urls.XmoDetails;
    case 'PromotionsMediaMix': return Urls.PmmDetails;
    default: return Urls.GenericProductDetails;
  }
};

export default function ProductMoveForward() {
  const { orderId, orderItemId } = useParams();
  const orderItems = useSelector(selectOrderItems);
  const dispatch = useDispatch();
  const navigate = useNavigationHandler();
  const orderFlow = useSelector(selectOrderFlow);
  const orderData = useSelector(selectOrdersContent);
  const isSalesForce = orderFlow === OrderFlow.SALES_FORCE;
  const isPunchlist = (orderFlow === OrderFlow.PUNCHLIST || orderData.orderFlow === OrderFlow.PUNCHLIST);
  const isPartnerFlow = useSelector(selectIsPartnerFlow);

  useEffect(() => {
    if (!orderItems.content || orderItems.content.length === 0) dispatch(getOrderItems(orderId));
  }, [orderItemId]);

  useEffect(() => {
    if (orderItems.content && orderItems.content.length > 0) {
      const orderItemsWithOffering = orderItems.content
        .filter(o => OfferingTypesWithMenus.includes(o.pdOfferingType || o.offeringType));

      const orderItemsIds = sortOrderItems(orderItemsWithOffering, isSalesForce, isPunchlist).map(o => o.id);

      const nextOrderItems = orderItemsIds
        .slice(orderItemsIds.indexOf(Number.parseInt(orderItemId, 10)) + 1, orderItemsIds.length);

      if (nextOrderItems?.length > 0) {
        const nextOrderItemId = nextOrderItems[0];
        const oItem = orderItems.content.filter(o => o.id === nextOrderItemId)[0] as OrdersItemsType;
        const oItemIsRebuild = oItem.saleType === TypeOfSaleConstants.REBUILD;
        const offeringType = oItem.pdOfferingType || oItem.offeringType;
        let url = getFirstStepOfferingType(offeringType, isSalesForce, isPunchlist, isPartnerFlow);
        if (oItemIsRebuild) {
          url = getFirstStepOfferingType(offeringType, isSalesForce, isPunchlist, isPartnerFlow, oItemIsRebuild);
        }
        navigate.to(url, `${nextOrderItemId}`);
      } else {
        navigate.to(nextStepCobalt, null, false);
      }
    } else {
      navigate.to(nextStepCobalt, null, false);
    }
  }, [orderItems]);

  return (
    <ContentLoader status="" message="" />
  );
}
