import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import AddIcon from "@mui/icons-material/Add";
import {
  IQButtonLink,
  IQFormInput,
  IQFormSelect, IQFormTextArea,
  IQTheme,
  ValidationProvider
} from "@gannettdigital/shared-react-components";
import {
  Checkbox,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  Typography
} from "@mui/material";
import {FormProvider, useForm} from "react-hook-form";
import schema from "pages/youtube/advertising/ad-group-options/AdGroupOptions.schema";
import GridItem from "components/grid/GridItem";
import {
  howCampaignShouldBeTargetedDropdownItems
} from "pages/youtube/advertising/ad-group-options/AdGroupOptionsService";
import AffinityAudienceFormDialog
  from "pages/youtube/advertising/ad-group-options/audience/AffinityAudienceFormDialog";
import InMarketAudienceFormDialog
  from "pages/youtube/advertising/ad-group-options/market-audience/InMarketAudienceFormDialog";
import LifeEventsFieldsFormDialog
  from "pages/youtube/advertising/ad-group-options/life-events/LifeEventsFieldsFormDialog";
import {v4 as uuidv4} from 'uuid';
import GenericCard from "components/generic-card/GenericCard";
import {useTranslation} from "react-i18next";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import GridContainer from "components/grid/GridContainer";
import CoNavigationConfirm from "components/navigation/CoNavigationConfirm";
import {updateOrAddDataBasedOnSourceId} from "pages/youtube/YouTubeService";
import {Box} from "@mui/system";

export default function AdGroupOptionsFormDialog({
                                                   open,
                                                   onOpen,
                                                   onClose,
                                                   updateAdGroupOptions,
                                                   selectedAdGroupOptions,
                                                   numberOfAdGroupsDropdownSelectedValue,
                                                   adGroupOptionsFieldsSize
                                                 }) {
  const {t} = useTranslation();
  const [howCampaignShouldBeTargetedSelectedValue, setHowCampaignShouldBeTargetedSelectedValue] = useState('');

  const handleHowCampaignShouldBeTargetedDropdownChange = (event) => {
    setHowCampaignShouldBeTargetedSelectedValue(event.target.value);
  };

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      adGroupName: '',
      pleaseProvideTheLinkToTheYouTubeVideo: '',
      additionalYouTubeVideoLink: '',
      advertiserWebsiteUrl: '',
      specificSubpageUrlBeingPromoted: '',
      howShouldTheCampaignBeTargeted: '',
      audiences: '',
      affinityAudiences: {
        affinityAudience: '',
        category: '',
        subCategory: '',
        specifics: '',
        details: '',
      },
      inMarketAudiences: {
        inMarketAudience: '',
        category: '',
        subCategory: '',
        specifics: '',
        details: '',
      },
      lifeEvents: {
        lifeEventsAudience: '',
        category: '',
      }
    },
  });

  const {
    handleSubmit,
    reset,
    formState: {
      isValid,
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
    },
    setValue,
  } = methods;

  /** Affinity Audiences **/
  const [affinityAudienceFormDialogOpen, setAffinityAudienceFormDialogOpen] = useState(false);
  const [affinityAudienceFields, setAffinityAudienceFields] = useState([]);
  const [selectedAffinityAudienceFields, setSelectedAffinityAudienceFields] = useState([]);

  const affinityAudiencesFieldsLabels = ['affinityAudience', 'category', 'subCategory'];
  const handleAffinityAudienceFormDialogOpen = () => {
    setAffinityAudienceFormDialogOpen(true);
  }
  const handleAffinityAudienceFormDialogClose = () => {
    setAffinityAudienceFormDialogOpen(false);
    setSelectedAffinityAudienceFields([])
  }

  const handleAffinityAudienceSelection = (selectedAffinityAudienceField) => {
    // Update setSelectedInMarketAudiences state
    setSelectedAffinityAudienceFields(selectedAffinityAudienceField);

    // Open the dialog
    handleAffinityAudienceFormDialogOpen();
  };

  // Function to update affinityAudienceFields
  const updateAffinityAudiences = (newData) => {
    setAffinityAudienceFields(prevData => {
      return updateOrAddDataBasedOnSourceId(prevData, newData);
    });
  };

  const onDeleteAffinityAudienceFields = async (affinityAudienceField: any) => {
    // Filter out the item with the specified sourceId
    const updatedAffinityAudience = affinityAudienceFields.filter(
        (affinityAudience) => affinityAudience.sourceId !== affinityAudienceField.sourceId
    );

    // Update the local state with the new affinityAudiences array
    setAffinityAudienceFields(updatedAffinityAudience);
  };

  /** In-Market Audiences **/
  const [inMarketAudienceFormDialogOpen, setInMarketAudienceFormDialogOpen] = useState(false);
  const [inMarketAudiencesFields, setInMarketAudiencesFields] = useState([]);
  const [selectedInMarketAudiencesFields, setSelectedInMarketAudiencesFields] = useState([]);

  const inMarketFieldsLabels = ['inMarketAudience', 'category', 'subCategory'];
  const handleInMarketAudienceFormDialogOpen = () => setInMarketAudienceFormDialogOpen(true);
  const handleInMarketAudienceFormDialogClose = () => {
    setInMarketAudienceFormDialogOpen(false);
    setSelectedInMarketAudiencesFields([]);
  }

  const handleInMarketAudiencesSelection = (selectedInMarketAudiencesField) => {
    // Update setSelectedInMarketAudiences state
    setSelectedInMarketAudiencesFields(selectedInMarketAudiencesField);

    // Open the dialog
    handleInMarketAudienceFormDialogOpen()
  };

  // Function to update inMarketAudiencesFields
  const updateInMarketAudiences = (newData) => {
    setInMarketAudiencesFields(prevData => {
      return updateOrAddDataBasedOnSourceId(prevData, newData);
    });
  };

  const onDeleteInMarketAudiencesFields = async (inMarketAudiencesField: any) => {
    // Filter out the item with the specified sourceId
    const updatedInMarketAudiences = inMarketAudiencesFields.filter(
        (inMarketAudience) => inMarketAudience.sourceId !== inMarketAudiencesField.sourceId
    );

    // Update the local state with the new inMarketAudiences array
    setInMarketAudiencesFields(updatedInMarketAudiences);
  };

  /** Life Events **/
  const [lifeEventsFieldsFormDialogOpen, setLifeEventsFieldsFormDialogOpen] = useState(false);
  const [lifeEventsFields, setLifeEventsFields] = useState([]);
  const [selectedLifeEventFields, setSelectedLifeEventFields] = useState([]);

  const lifeEventsFieldsLabels = ['lifeEventsAudience', 'category'];
  const handleLifeEventsFieldsFormDialogOpen = () => setLifeEventsFieldsFormDialogOpen(true);
  const handleLifeEventsFieldsFormDialogClose = () => {
    setLifeEventsFieldsFormDialogOpen(false);
    setSelectedLifeEventFields([])
  }

  const handleLifeEventsSelection = (selectedLifeEventField) => {
    // Update selectedLifeEvent state
    setSelectedLifeEventFields(selectedLifeEventField);

    // Open the dialog
    handleLifeEventsFieldsFormDialogOpen();
  };

  // Function to update lifeEventsFields
  const updateLifeEvents = (newData) => {
    setLifeEventsFields(prevData => {
      return updateOrAddDataBasedOnSourceId(prevData, newData);
    });
  };

  const onDeleteLifeEventsFields = async (lifeEventsField: any) => {
    // Filter out the item with the specified sourceId
    const updatedLifeEvents = lifeEventsFields.filter(
        (lifeEvent) => lifeEvent.sourceId !== lifeEventsField.sourceId
    );

    // Update the local state with the new lifeEvents array
    setLifeEventsFields(updatedLifeEvents);
  };

  /** Auto populate AdGroup Options **/
  useEffect(() => {
    if (selectedAdGroupOptions.sourceId) {
      setHowCampaignShouldBeTargetedSelectedValue(selectedAdGroupOptions?.howShouldTheCampaignBeTargeted || '')
      // Populate form fields with selectedAdGroupOptionsFields data
      setValue("adGroupName", selectedAdGroupOptions.adGroupName);
      setValue("pleaseProvideTheLinkToTheYouTubeVideo", selectedAdGroupOptions.pleaseProvideTheLinkToTheYouTubeVideo);
      setValue("additionalYouTubeVideoLink", selectedAdGroupOptions.additionalYouTubeVideoLink);
      setValue("advertiserWebsiteUrl", selectedAdGroupOptions.advertiserWebsiteUrl);
      setValue("specificSubpageUrlBeingPromoted", selectedAdGroupOptions.specificSubpageUrlBeingPromoted);
      setValue("howShouldTheCampaignBeTargeted", selectedAdGroupOptions.howShouldTheCampaignBeTargeted);
      setValue("audiences", selectedAdGroupOptions.audiences);

      // Populate form fields for selectedAffinityAudiences,selectedInMarketAudiences,selectedLifeEvents data
      setAffinityAudienceFields(selectedAdGroupOptions.affinityAudiences);
      setInMarketAudiencesFields(selectedAdGroupOptions.inMarketAudiences);
      setLifeEventsFields(selectedAdGroupOptions.lifeEvents);

      // PrePopulate targetGender checkboxes
      const populateTargetGenderCheckboxes = targetGenderCheckboxes.map(checkbox => {
        const matchingOption = selectedAdGroupOptions?.targetGender?.find(
            gender => gender === checkbox.label
        );
        const isChecked = !!matchingOption;
        return {
          ...checkbox,
          checked: isChecked,
        };
      });
      setTargetGenderCheckboxes(populateTargetGenderCheckboxes);

      // PrePopulate TargetAge checkboxes
      const populateTargetAgeCheckboxes = targetAgeCheckboxes.map(checkbox => {
        const matchingOption = selectedAdGroupOptions?.targetAge?.find(
            gender => gender === checkbox.label
        );
        const isChecked = !!matchingOption;
        return {
          ...checkbox,
          checked: isChecked,
        };
      });
      setTargetAgeCheckboxes(populateTargetAgeCheckboxes);

      // PrePopulate TargetParentalStatus checkboxes
      const populateTargetParentalStatusCheckboxes = targetParentalStatusCheckboxes.map(checkbox => {
        const matchingOption = selectedAdGroupOptions?.targetParentalStatus?.find(
            gender => gender === checkbox.label
        );
        const isChecked = !!matchingOption;
        return {
          ...checkbox,
          checked: isChecked,
        };
      });
      setTargetParentalStatusCheckboxes(populateTargetParentalStatusCheckboxes);

      // PrePopulate TargetIncome checkboxes
      const populateTargetIncomeCheckboxes = targetIncomeCheckboxes.map(checkbox => {
        const matchingOption = selectedAdGroupOptions?.targetIncome?.find(
            gender => gender === checkbox.label
        );
        const isChecked = !!matchingOption;
        return {
          ...checkbox,
          checked: isChecked,
        };
      });
      setTargetIncomeCheckboxes(populateTargetIncomeCheckboxes);
    }
  }, [selectedAdGroupOptions?.sourceId]);

  /** Add Ad Group **/
  const handleFormSubmit = async (formInputData) => {
    // List of keys to remove
    const unwantedKeys = [
      "Female", "Male", "targetGenderUnknown", "Parents", "NonParents",
      "targetParentalStatusUnknown", "11-20%", "18-24", "21-30%", "25-34", "31-40%", "35-44",
      "41-50%", "45-54", "55-64", "65+", "targetAgeUnknown", "Lower 50%", "Non-Parents",
      "Top 10%", "targetIncomeUnknown"
    ];

    // Filter out unwanted keys from formInputData
    const filteredData = Object.keys(formInputData).reduce((acc, key) => {
      if (!unwantedKeys.includes(key)) {
        acc[key] = formInputData[key];
      }
      return acc;
    }, {});

    // Ensure filteredData has the properties we need
    const formData = {
      ...filteredData,
      targetGender: [],
      targetAge: [],
      targetParentalStatus: [],
      targetIncome: []
    };

    if(formInputData.howShouldTheCampaignBeTargeted != 'Optimize it') {
      // Add selectedLabels as genders to the form data
      formData.targetGender = targetGenderCheckboxes
      .filter(option => option.checked)
      .map(option => option.label);

      // Add selectedLabels as Age to the form data
      formData.targetAge = targetAgeCheckboxes
      .filter(option => option.checked)
      .map(option => option.label);

      // Add selectedLabels as Parental Status to the form data
      formData.targetParentalStatus = targetParentalStatusCheckboxes
      .filter(option => option.checked)
      .map(option => option.label);

      // Add selectedLabels as Income Options to the form data
      formData.targetIncome = targetIncomeCheckboxes
      .filter(option => option.checked)
      .map(option => option.label);
    }

    let {sourceId} = selectedAdGroupOptions || {};
    if (!sourceId) {
      // Generate a unique sourceId if it does not exist
      sourceId = uuidv4();
    }

    // Update AdGroup Options with sourceId
    const updatedAdGroupOption = {
      sourceId,
      ...formData,
      affinityAudiences: [...affinityAudienceFields],
      lifeEvents: [...lifeEventsFields],
      inMarketAudiences: [...inMarketAudiencesFields],
    };

    updateAdGroupOptions(updatedAdGroupOption);

    // Close the form
    handleClose();
  };

  const handleOpen = () => {
    // Reset the form state
    reset({
      adGroupName: '',
      pleaseProvideTheLinkToTheYouTubeVideo: '',
      additionalYouTubeVideoLink: '',
      advertiserWebsiteUrl: '',
      specificSubpageUrlBeingPromoted: '',
      howShouldTheCampaignBeTargeted: '',
      audiences: '',
      affinityAudiences: {
        affinityAudience: '',
        category: '',
        subCategory: '',
        specifics: '',
        details: '',
      },
      inMarketAudiences: {
        inMarketAudience: '',
        category: '',
        subCategory: '',
        specifics: '',
        details: '',
      },
      lifeEvents: {
        lifeEventsAudience: '',
        category: '',
      },
    });
    setAffinityAudienceFields([]);
    setInMarketAudiencesFields([]);
    setLifeEventsFields([]);

    // Reset all TargetGender checkboxes to unchecked state
    const resetTargetGenderCheckboxes = targetGenderCheckboxes.map(checkbox => ({
      ...checkbox,
      checked: false,
    }));
    setTargetGenderCheckboxes(resetTargetGenderCheckboxes);

    // Reset all TargetAge checkboxes to unchecked state
    const resetTargetAgeCheckboxes = targetAgeCheckboxes.map(checkbox => ({
      ...checkbox,
      checked: false,
    }));
    setTargetAgeCheckboxes(resetTargetAgeCheckboxes);

    // Reset all TargetParentalStatus checkboxes to unchecked state
    const resetTargetParentalStatusCheckboxes = targetParentalStatusCheckboxes.map(checkbox => ({
      ...checkbox,
      checked: false,
    }));
    setTargetParentalStatusCheckboxes(resetTargetParentalStatusCheckboxes);

    // Reset all TargetIncome checkboxes to unchecked state
    const resetTargetIncomeCheckboxes = targetIncomeCheckboxes.map(checkbox => ({
      ...checkbox,
      checked: false,
    }));
    setTargetIncomeCheckboxes(resetTargetIncomeCheckboxes);

    onOpen();
  };

  const handleClose = () => {
    setHowCampaignShouldBeTargetedSelectedValue(undefined);
    onClose();
  };

  /** Ad Group - Target Gender Checkboxes **/
  const [targetGenderCheckboxes, setTargetGenderCheckboxes] = useState([
    {id: 'Female', label: 'Female', checked: false},
    {id: 'Male', label: 'Male', checked: false},
    {id: 'targetGenderUnknown', label: 'Unknown', checked: false},
  ]);

  const handleTargetGenderCheckboxChange = label => {
    // Toggle the checked state of the checkbox with the given id
    const updatedCheckboxes = targetGenderCheckboxes.map(checkbox =>
        checkbox.label === label ? {...checkbox, checked: !checkbox.checked} : checkbox
    );
    setTargetGenderCheckboxes(updatedCheckboxes);
  };

  /** Ad Group - Target Age Checkboxes **/
  const [targetAgeCheckboxes, setTargetAgeCheckboxes] = useState([
    {id: '18-24', label: '18-24', checked: false},
    {id: '25-34', label: '25-34', checked: false},
    {id: '35-44', label: '35-44', checked: false},
    {id: '45-54', label: '45-54', checked: false},
    {id: '55-64', label: '55-64', checked: false},
    {id: '65+', label: '65+', checked: false},
    {id: 'targetAgeUnknown', label: 'Unknown', checked: false},
  ]);


  const handleTargetAgeCheckboxChange = label => {
    // Toggle the checked state of the checkbox with the given id
    const updatedCheckboxes = targetAgeCheckboxes.map(checkbox =>
        checkbox.label === label ? {...checkbox, checked: !checkbox.checked} : checkbox
    );
    setTargetAgeCheckboxes(updatedCheckboxes);
  };

  /** Ad Group - Target Parental Status Checkboxes **/
  const [targetParentalStatusCheckboxes, setTargetParentalStatusCheckboxes] = useState([
    {id: 'Parents', label: 'Parents', checked: false},
    {id: 'Non-Parents', label: 'Non-Parents', checked: false},
    {id: 'targetParentalStatusUnknown', label: 'Unknown', checked: false},
  ]);


  const handleTargetParentalStatusCheckboxChange = label => {
    // Toggle the checked state of the checkbox with the given id
    const updatedCheckboxes = targetParentalStatusCheckboxes.map(checkbox =>
        checkbox.label === label ? {...checkbox, checked: !checkbox.checked} : checkbox
    );
    setTargetParentalStatusCheckboxes(updatedCheckboxes);
  };

  /** Ad Group - Income Option Checkboxes **/
  const [targetIncomeCheckboxes, setTargetIncomeCheckboxes] = useState([
    {id: 'Top 10%', label: 'Top 10%', checked: false},
    {id: '11-20%', label: '11-20%', checked: false},
    {id: '21-30%', label: '21-30%', checked: false},
    {id: '31-40%', label: '31-40%', checked: false},
    {id: '41-50%', label: '41-50%', checked: false},
    {id: 'Lower 50%', label: 'Lower 50%', checked: false},
    {id: 'targetIncomeUnknown', label: 'Unknown', checked: false},
  ]);


  const handleTargetIncomeCheckboxesChange = label => {
    // Toggle the checked state of the checkbox with the given id
    const updatedCheckboxes = targetIncomeCheckboxes.map(checkbox =>
        checkbox.label === label ? {...checkbox, checked: !checkbox.checked} : checkbox
    );
    setTargetIncomeCheckboxes(updatedCheckboxes);
  };

  return (
      <>
        {numberOfAdGroupsDropdownSelectedValue > 0 && adGroupOptionsFieldsSize < numberOfAdGroupsDropdownSelectedValue && (
            <IQButtonLink startIcon={<AddIcon />} onClick={() => handleOpen()}>
              {t('pages.youtube.advertising.adGroupOptions.addIconLabel')}
            </IQButtonLink>
        )}
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="lg"
            fullWidth
        >
          <DialogTitle>{t('pages.youtube.advertising.adGroupOptions.addIconLabel')}</DialogTitle>
          <DialogContent>
            <ValidationProvider schema={schema}>
              <FormProvider {...methods}>
                <form>
                  <GridContainer fullWidth>
                    <GridItem inlineItems sizes={[6, 6]}>
                      <Grid>
                        <IQFormInput
                            id="adGroupName"
                            theme={IQTheme}
                            labelText={t('pages.youtube.advertising.adGroupOptions.adGroupName')}
                            fontLabelWeight="600"
                            name="adGroupName"
                            fullWidth
                            maxLength={128}
                        />
                        <FormHelperText>{t('pages.youtube.advertising.adGroupOptions.adGroupNameHelpText')}</FormHelperText>
                      </Grid>
                      <Grid>
                        <IQFormInput
                            id="pleaseProvideTheLinkToTheYouTubeVideo"
                            theme={IQTheme}
                            labelText={t('pages.youtube.advertising.adGroupOptions.pleaseProvideTheLinkToTheYouTubeVideo')}
                            fontLabelWeight="600"
                            name="pleaseProvideTheLinkToTheYouTubeVideo"
                            fullWidth
                            showError
                            schema={schema}
                            adornmentIcon={<InsertLinkIcon/>}
                        />
                        <FormHelperText>{t('pages.youtube.advertising.adGroupOptions.youTubeVideoLinkHelpText')}</FormHelperText>
                      </Grid>
                    </GridItem>
                    <GridItem inlineItems sizes={[6, 6]}>
                      <Grid>
                        <IQFormTextArea
                            id="additionalYouTubeVideoLink"
                            labelText={t('pages.youtube.advertising.adGroupOptions.additionalYouTubeVideoLink')}
                            name="additionalYouTubeVideoLink"
                            rowCount={2}
                            fullWidth
                            fontLabelWeight='600'
                        />
                      </Grid>
                      <Grid>
                        <IQFormInput
                            id="advertiserWebsiteUrl"
                            theme={IQTheme}
                            labelText={t('pages.youtube.advertising.adGroupOptions.advertiserWebsiteUrl')}
                            fontLabelWeight="600"
                            name="advertiserWebsiteUrl"
                            fullWidth
                            showError
                            schema={schema}
                            adornmentIcon={<InsertLinkIcon/>}
                            maxLength={35}
                        />
                        <FormHelperText>{t('pages.youtube.advertising.adGroupOptions.advertiserWebsiteUrlHelpText')}</FormHelperText>
                      </Grid>
                    </GridItem>
                    <GridItem inlineItems sizes={[6, 6]}>
                      <Grid>
                        <IQFormInput
                            id="specificSubpageUrlBeingPromoted"
                            theme={IQTheme}
                            labelText={t('pages.youtube.advertising.adGroupOptions.specificSubpageUrlBeingPromoted')}
                            fontLabelWeight="600"
                            name="specificSubpageUrlBeingPromoted"
                            fullWidth
                            showError
                            schema={schema}
                            adornmentIcon={<InsertLinkIcon/>}
                        />
                      </Grid>
                      <Grid>
                        <IQFormSelect
                            id="howShouldTheCampaignBeTargeted"
                            name="howShouldTheCampaignBeTargeted"
                            labelText={t('pages.youtube.advertising.adGroupOptions.howShouldTheCampaignBeTargeted')}
                            items={howCampaignShouldBeTargetedDropdownItems}
                            defaultValue=""
                            theme={IQTheme}
                            fontLabelWeight="600"
                            fullWidth
                            required
                            schema={schema}
                            showError
                            onChange={handleHowCampaignShouldBeTargetedDropdownChange}
                            MenuProps={{
                              style: {maxHeight: 230},
                              PaperProps: {
                                sx: {
                                  '& .MuiMenuItem-root': {
                                    display: 'flex !important',
                                    justifyContent: 'flex-start !important',
                                    padding: '6px 16px'
                                  },
                                },
                              }
                            }}
                        />
                      </Grid>
                    </GridItem>
                    {
                        (howCampaignShouldBeTargetedSelectedValue === 'Use my selections' ||
                            howCampaignShouldBeTargetedSelectedValue === 'Use my selections & optimize it') && (
                            <GridItem sizes={[3, 3, 3, 3]}>
                              <Grid>
                                <Grid>
                                  <Typography variant="h6">
                                    {t('pages.youtube.advertising.adGroupOptions.targetGender.header')}
                                  </Typography>
                                </Grid>
                                <Grid marginBottom={0} sx={{
                                  paddingLeft: '12px',
                                  display: 'flex',
                                  flexDirection: 'column'
                                }}>
                                  {targetGenderCheckboxes.map((option) => (
                                      <Box mb={1}>
                                        <FormControlLabel control={
                                          <Checkbox
                                              checked={option.checked}
                                              onChange={() => handleTargetGenderCheckboxChange(option.label)
                                              }/>
                                        } label={option.label}/>
                                      </Box>
                                  ))}
                                  <FormHelperText sx={{
                                    mt: '0px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                    ml: '-7px'
                                  }}>
                                    <Typography>{t('pages.youtube.advertising.adGroupOptions.checkAllThatApplyHelpText')}</Typography>
                                  </FormHelperText>
                                </Grid>
                              </Grid>
                              <Grid>
                                <Grid>
                                  <Typography
                                      variant="h6">{t('pages.youtube.advertising.adGroupOptions.targetAge.header')}</Typography>
                                </Grid>
                                <Grid marginBottom={0} sx={{paddingLeft: '12px'}}>
                                  {targetAgeCheckboxes.map((option) => (
                                      <Box mb={1}>
                                        <FormControlLabel control={
                                          <Checkbox
                                              checked={option.checked}
                                              onChange={() => handleTargetAgeCheckboxChange(option.label)
                                              }/>
                                        } label={option.label}/>
                                      </Box>
                                  ))}
                                  <FormHelperText sx={{
                                    mt: '0px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                    ml: '-7px'
                                  }}>
                                    <Typography>{t('pages.youtube.advertising.adGroupOptions.checkAllThatApplyHelpText')}</Typography>
                                  </FormHelperText>
                                </Grid>
                              </Grid>
                              <Grid>
                                <Grid>
                                  <Typography
                                      variant="h6">{t('pages.youtube.advertising.adGroupOptions.targetParentalStatus.header')}</Typography>
                                </Grid>
                                <Grid marginBottom={0} sx={{paddingLeft: '12px'}}>
                                  {targetParentalStatusCheckboxes.map((option) => (
                                      <Box mb={1}>
                                        <FormControlLabel control={
                                          <Checkbox
                                              checked={option.checked}
                                              onChange={() => handleTargetParentalStatusCheckboxChange(option.label)
                                              }/>
                                        } label={option.label}/>
                                      </Box>
                                  ))}
                                  <FormHelperText sx={{
                                    mt: '0px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                    ml: '-7px'
                                  }}>
                                    <Typography>{t('pages.youtube.advertising.adGroupOptions.checkAllThatApplyHelpText')}</Typography>
                                  </FormHelperText>
                                </Grid>
                              </Grid>
                              <Grid>
                                <Grid>
                                  <Typography
                                      variant="h6">{t('pages.youtube.advertising.adGroupOptions.targetIncome.header')}
                                  </Typography>
                                </Grid>
                                <Grid>
                                  {targetIncomeCheckboxes.map((option) => (
                                      <Box mb={1}>
                                        <FormControlLabel control={
                                          <Checkbox
                                              checked={option.checked}
                                              onChange={() => handleTargetIncomeCheckboxesChange(option.label)
                                              }/>
                                        } label={option.label}/>
                                      </Box>
                                  ))}
                                  <FormHelperText sx={{
                                    mt: '0px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                    ml: '-7px'
                                  }}>
                                    <Typography>{t('pages.youtube.advertising.adGroupOptions.checkAllThatApplyHelpText')}</Typography>
                                  </FormHelperText>
                                </Grid>
                              </Grid>
                            </GridItem>
                        )}
                    <GridItem sizes={[12]}>
                      <IQFormTextArea
                          id="audiences"
                          labelText={t('pages.youtube.advertising.adGroupOptions.audiences')}
                          name="audiences"
                          rowCount={2}
                          fullWidth
                          fontLabelWeight="600"
                      />
                    </GridItem>
                  </GridContainer>
                </form>
                <CoNavigationConfirm
                    showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
                />
              </FormProvider>
            </ValidationProvider>

            <Grid>
              <GridItem sizes={[12]}>
                <Typography>
                  {t('pages.youtube.advertising.adGroupOptions.audience.helpText_1')}
                  <Link
                      href="https://gannett.sharepoint.com/:x:/t/SalesOperations/EbD51DMEvElKpeX52AbEc34BE-r7NolcOHjL8yPaObVuCg?e=fA6Igs"
                      target="_blank" rel="noopener noreferrer" sx={{marginLeft: '5px'}}>
                    {t('pages.youtube.advertising.adGroupOptions.audience.helpText_2')}
                  </Link>
                  {t('pages.youtube.advertising.adGroupOptions.audience.helpText_3')}
                </Typography>
              </GridItem>
              <Grid>
                <Typography
                    variant="h6">{t('pages.youtube.advertising.adGroupOptions.audience.header')}</Typography>
                {Array.isArray(affinityAudienceFields) && affinityAudienceFields.map((affinityAudienceField) => (
                    <GenericCard
                        key={affinityAudienceField.sourceId}
                        labels={affinityAudiencesFieldsLabels}
                        data={affinityAudienceField}
                        handleDelete={() => onDeleteAffinityAudienceFields(affinityAudienceField)}
                        onSelect={() => {
                        }}
                        onEdit={() => handleAffinityAudienceSelection(affinityAudienceField)}
                    />
                ))}
                <AffinityAudienceFormDialog open={affinityAudienceFormDialogOpen}
                                            onOpen={handleAffinityAudienceFormDialogOpen}
                                            onClose={handleAffinityAudienceFormDialogClose}
                                            updateAffinityAudiences={updateAffinityAudiences}
                                            selectedAffinityAudiences={selectedAffinityAudienceFields}
                />
              </Grid>
              <Grid>
                <Typography
                    variant="h6">{t('pages.youtube.advertising.adGroupOptions.inMarket.header')}</Typography>
                {Array.isArray(inMarketAudiencesFields) && inMarketAudiencesFields.map((inMarketAudiencesField) => (
                    <GenericCard
                        key={inMarketAudiencesField.sourceId}
                        labels={inMarketFieldsLabels}
                        data={inMarketAudiencesField}
                        handleDelete={() => onDeleteInMarketAudiencesFields(inMarketAudiencesField)}
                        onEdit={() => handleInMarketAudiencesSelection(inMarketAudiencesField)}
                        onSelect={() => {
                        }}
                    />
                ))}
                <InMarketAudienceFormDialog open={inMarketAudienceFormDialogOpen}
                                            onOpen={handleInMarketAudienceFormDialogOpen}
                                            onClose={handleInMarketAudienceFormDialogClose}
                                            updateInMarketAudiences={updateInMarketAudiences}
                                            selectedInMarketAudiences={selectedInMarketAudiencesFields}
                />
              </Grid>
              <Grid>
                <Typography
                    variant="h6">{t('pages.youtube.advertising.adGroupOptions.lifeEvents.header')}</Typography>
                {Array.isArray(lifeEventsFields) && lifeEventsFields.map((lifeEventsField) => (
                    <GenericCard
                        key={lifeEventsField.sourceId}
                        labels={lifeEventsFieldsLabels}
                        data={lifeEventsField}
                        handleDelete={() => onDeleteLifeEventsFields(lifeEventsField)}
                        onEdit={() => handleLifeEventsSelection(lifeEventsField)}
                        onSelect={() => {
                        }}
                    />
                ))}
                <LifeEventsFieldsFormDialog open={lifeEventsFieldsFormDialogOpen}
                                            onOpen={handleLifeEventsFieldsFormDialogOpen}
                                            onClose={handleLifeEventsFieldsFormDialogClose}
                                            updateLifeEvents={updateLifeEvents}
                                            selectedLifeEvent={selectedLifeEventFields}
                />
              </Grid>
            </Grid>
            <DialogActions>
              <Button
                  onClick={handleClose}>{t('pages.youtube.advertising.adGroupOptions.CloseBtn')}</Button>
              <Button disabled={!isValid}
                      onClick={handleSubmit(handleFormSubmit)}>{t("pages.youtube.advertising.adGroupOptions.submitBtn")}</Button>
            </DialogActions>

          </DialogContent>
        </Dialog>
      </>
  );
}
