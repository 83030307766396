import { IQThemeV2, ValidationProvider } from '@gannettdigital/shared-react-components';
import { Grid, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { useTopnavMenu } from 'hooks/useTopnavMenu';
import i18n from 'i18n/i18n';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import { Urls } from 'navigation/Urls';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useOutletContext, useParams } from 'react-router';
import {
  createGeneralQuestions, getGeneralQuestionsByOrderItemId, selectCurrentGeneralQuestions, setCurrentGeneralQuestions,
} from 'services/generalQuestionsSlice';
import { getIsPremiumListingsHealthcare } from 'services/premiumListingsSlice';
import { selectOrderFlow, selectOrderItems, selectOrdersContent } from 'services/ordersSlice';
import { useAppDispatch } from 'services/redux/store';
import { formatDate } from 'pages/overview/sections/landing/campaignLandingSection';
import {
  getCurrentOffer, selectCurrentOffer,
} from 'services/cefSlice';
import { selectActiveOfferingType } from 'services/navigationSlice';
import { genericProductHasTracking } from 'pages/generic-product/GenericProductConstants';
import { OrderFlow, products } from 'shared/constants';
import { TypeOfSaleConstants, TypesOfSale } from 'pages/shopping-cart/Constants';
import ErrorText from 'components/errorText/ErrorText';
import GridItem from 'components/grid/GridItem';
import schemaValidate from 'components/schemaValidate';
import { Trans } from 'react-i18next';
import schema from './GeneralQuestions.schema';
import { addonsOfferingTypes, defaultValues } from './generalQuestionsData';
import { Tracking } from './form-sections/Tracking';
import { DateRequests } from './form-sections/DateRequests';
import { Details, DetailsRebuild } from './form-sections/Details';
import { Payments } from './form-sections/Payments';
import { PrepopulateCheckbox } from './PrepopulateCheckbox';

const { t } = i18n;

interface Props {
  product: any
  onBack: () => void
  hasTracking: boolean
  topNavUrl: string
  isExistingCampaign?: boolean
}

export const defaultInputStyles = {
  '& .MuiInput-underline:after': {
    borderBottomColor: '#B2BAC2',
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#ffffff',
    height: '40px',
    '& fieldset': {
      borderColor: IQThemeV2.palette.info.dark,
      borderRadius: '2px',
    },
    '&.Mui-focused fieldset': {
      borderColor: IQThemeV2.palette.primary.main,
    },
  },
  '& .MuiAutocomplete-root .MuiOutlinedInput-root': {
    height: 'auto',
  },
  '& .MuiFormHelperText-root:not([role=alert])': {
    color: '#000000',
  },
  '& .MuiFormGroup-root[role=radiogroup] .MuiFormControlLabel-root': {
    width: 'fit-content',
  },
};

export const hideNumberFieldArrows = {
  // eslint-disable-next-line max-len
  '& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: '0',
  },
};

export default function GeneralQuestionsForm(props: Props) {
  const { isExistingCampaign } = props;
  const { orderId, orderItemId } = useParams();
  const offeringType = useSelector(selectActiveOfferingType);
  const [currentOrderItemId, setCurrentOrderItemId] = useState(null);
  const navigate = useNavigationHandler();
  const history: any = useLocation();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const [methodsSelected, setMethodsSelected] = useState(false);
  const [autocompleteDirty, setAutocompleteDirty] = useState(false);
  const topNav = useTopnavMenu(props.topNavUrl);
  const generalQuestionsData = useSelector(selectCurrentGeneralQuestions);
  const orderItems = useSelector(selectOrderItems);
  const currentOffer = useSelector(selectCurrentOffer);
  const orderData = useSelector(selectOrdersContent);

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [adminStartDate, setAdminStartDate] = useState('');
  const [paymentDate, setPaymentDate] = useState('');
  const [servicesProcessPayment, setServicesProcessPayment] = useState('');
  const [emailPreference, setEmailPreference] = useState('');
  const [trackingMethod, setTrackingMethod] = useState('');
  const [genericProductTracking, setGenericProductTracking] = useState(false);

  const orderFlow = useSelector(selectOrderFlow);
  const isPunchlist = (orderFlow === OrderFlow.PUNCHLIST || orderData.orderFlow === OrderFlow.PUNCHLIST);
  const isPiqPunchListOrder = (products.PIQ_LANDING_PAGES === offeringType && orderData.orderFlow === OrderFlow.PUNCHLIST);
  const isBlue = useMemo(() => orderItems.content?.filter(item => item.isBlue)?.length > 0, [orderItems]);

  const typeOfSale = useMemo(() => orderItems.content
    .filter(item => Number(item.id) === Number(orderItemId))?.[0]?.saleType, [orderItems]);
  const typeOfSaleLabel = TypesOfSale.filter(type => type.value === typeOfSale)?.[0]?.label;

  const context:any = useOutletContext();

  const methods = useForm({
    mode: 'all',
    defaultValues,
  });

  const {
    handleSubmit, register, unregister, watch, control, setValue,
    getValues, setError, clearErrors, reset, trigger, formState: {
      isValid, errors, isDirty, isSubmitSuccessful, isSubmitting,
    },
  } = methods;

  useEffect(() => {
    if (offeringType === 'generic-product' && orderItems.content.length > 0) {
      const item = orderItems.content.filter(item => item.id.toString() === orderItemId);
      if (item.length > 0 && genericProductHasTracking.includes(item[0]?.pdOfferingType)) {
        setGenericProductTracking(true);
      }
    }
  }, [orderItems]);

  useEffect(() => {
    if (orderItemId && offeringType !== 'MasterAddon') {
      setCurrentOrderItemId(orderItemId);
    } else if (context?.addonId) {
      setCurrentOrderItemId(context?.addonId);
    } else if (orderItemId && orderItemId !== '0') {
      setCurrentOrderItemId(orderItemId);
    }
  }, []);

  useEffect(() => {
    if (orderItemId && offeringType !== 'MasterAddon') {
      setCurrentOrderItemId(orderItemId);
    } else if (context?.addonId) {
      setCurrentOrderItemId(context?.addonId);
    }
  }, [context, offeringType]);

  useEffect(() => {
    if (currentOrderItemId) {
      dispatch(getCurrentOffer(currentOrderItemId));
      dispatch(getGeneralQuestionsByOrderItemId(currentOrderItemId));
    }
  }, [currentOrderItemId]);

  useEffect(() => {
    if (generalQuestionsData?.data) {
      const { data } = generalQuestionsData;
      reset({
        campaignName:data?.campaignName,
        budget: data?.budget,
        initialCycles: data?.initialCycles,
        budgetReallocationCid: data?.budgetReallocationCid,
        rebuildCid: data?.rebuildCid,
        runningMcid: data?.runningMcid,
        adminStartDate: data?.adminStartDate,
        requestedStart: data?.requestedStart ? new Date(data?.requestedStart) : '',
        delayedStart: data?.delayedStart ? new Date(data?.delayedStart) : '',
        paymentDate: data?.paymentDate,
        requestedPayDate: data?.requestedPayDate,
        delayedRequestedPayDate: data?.delayedRequestedPayDate ? new Date(data?.delayedRequestedPayDate) : '',
        paymentMethod: data?.paymentMethod,
        servicesProcessPayment: data?.servicesProcessPayment,
        emailPreference: data?.emailPreference,
        trackingMethod: data?.trackingMethod,
        averageTransactionValue: data?.averageTransactionValue,
        costPerLead: data?.costPerLead,
      });

      setPaymentMethods(data?.paymentMethod);
      setAdminStartDate(data?.adminStartDate);
      setPaymentDate(data?.paymentDate);
      setServicesProcessPayment(data?.servicesProcessPayment);
      setEmailPreference(data?.emailPreference);
      setTrackingMethod(data?.trackingMethod);
    }
  }, [generalQuestionsData]);

  const withinRange = (x, min, max) => x >= min && x <= max;

  useEffect(() => {
    if (generalQuestionsData.length === 0
      && Object.keys(currentOffer).length > 0
      && currentOffer?.salesForceOfferObject) {
      setValue('budget', currentOffer?.salesForceOfferObject?.minBudget);
      setValue('initialCycles', currentOffer?.salesForceOfferObject?.defaultCycle);
    }
  }, [generalQuestionsData, currentOffer]);

  useEffect(() => {
    if (!generalQuestionsData?.data?.initialCycles && currentOffer?.salesForceOfferObject?.defaultCycle) {
      setValue(
        'initialCycles',
        currentOffer?.salesForceOfferObject?.unlimited ? 99 : currentOffer?.salesForceOfferObject?.defaultCycle,
      );
    }
  }, [currentOffer, generalQuestionsData]);

  useEffect(() => {
    if (currentOffer && (!generalQuestionsData?.data?.budget && currentOffer?.salesForceOfferObject?.minBudget >= 0)) {
      const { minBudget, maxBudget } = currentOffer.salesForceOfferObject;
      if (withinRange(currentOffer?.salesForceOfferObject?.budget, minBudget, maxBudget)
        && (minBudget < maxBudget)) {
        setValue('budget', currentOffer?.salesForceOfferObject?.budget);
      } else setValue('budget', minBudget);
    }
  }, [generalQuestionsData, currentOffer]);

  const delayField = watch('adminStartDate') === 'Delay' || watch('adminStartDate') === 'Pending Site Launch';
  const requestField = watch('adminStartDate') === 'ASAP';
  const requestPayDateField = watch('paymentDate') === 'ASAP';
  const delayedRequestedPayDateField = watch('paymentDate') === 'Delay';
  const budgetEmpty = watch('budget') === '';
  const initialCyclesEmpty = watch('initialCycles') === '';

  const initialCampaignName = useMemo(() => ((isPunchlist || isBlue) ? currentOffer?.salesForceOfferObject?.campaignName
      : (currentOffer?.salesForceOfferObject?.salesforceOfferName
          || currentOffer?.salesForceOfferObject?.rlOfferName)), [isPunchlist, isBlue, currentOffer]);

  const campaignName = generalQuestionsData?.data?.campaignName || initialCampaignName

  useEffect(() => {
    if (!delayField) {
      unregister(['delayedStart', 'paymentDate']);
      setValue('delayedStart', '');
      setValue('paymentDate', '');
    }
  }, [delayField]);

  useEffect(() => {
    if (!requestField) {
      unregister('requestedStart');
      setValue('requestedStart', '');
    } else if (Object.keys(currentOffer).length > 0 && currentOffer?.salesForceOfferObject?.adminStartDate) {
      setValue('requestedStart', currentOffer?.salesForceOfferObject?.adminStartDate);
    }
  }, [requestField]);

  useEffect(() => {
    if (!requestPayDateField) {
      unregister('requestedPayDate');
      setValue('requestedPayDate', '');
    }
  }, [requestPayDateField]);

  useEffect(() => {
    if (!delayedRequestedPayDateField) {
      unregister('delayedRequestedPayDate');
      setValue('delayedRequestedPayDate', '');
    }
  }, [delayedRequestedPayDateField]);

  useEffect(() => {
    setMethodsSelected(getValues('paymentMethod').length > 0);
  }, [watch('paymentMethod')]);

  const paymentMethodsMinError = () => {
    setAutocompleteDirty(true);
    if (getValues('paymentMethod').length === 0) {
      setError('paymentMethod', {
        type: 'manual',
        message: t('pages.generalQuestions.paymentMethod.error'),
      }, { shouldFocus: false });
    } else {
      clearErrors('paymentMethod');
      trigger('paymentMethod');
    }
  };

  useEffect(() => {
    if (!props.hasTracking) {
      unregister('emailPreference');
      unregister('trackingMethod');
      unregister('averageTransactionValue');
      unregister('costPerLead');
    }
  }, [props.hasTracking]);

  // eslint-disable-next-line consistent-return, @typescript-eslint/no-unused-vars
  const handleFormSubmit = async (data) => {
    const formData = JSON.stringify({
      ...data,
      campaignName: data.campaignName || campaignName ,
      requestedStart: data.requestedStart ? formatDate(new Date(data.requestedStart)) : '',
      delayedStart: data.delayedStart ? formatDate(new Date(data.delayedStart)) : '',
      requestedPayDate: data.requestedPayDate ? formatDate(new Date(data.requestedPayDate)) : '',
      delayedRequestedPayDate: data.delayedRequestedPayDate ? formatDate(new Date(data.delayedRequestedPayDate)) : '',
      prepopulateTracking: data?.prepopulateTracking
        || generalQuestionsData?.data?.prepopulateTracking
        || props.hasTracking ? data?.prepopulateAll : null,
      prepopulateAll: data?.prepopulateAll || generalQuestionsData?.data?.prepopulateAll || false,
    });

    const resource = {
      data: formData,
      orderItemId: context?.addonId || orderItemId,
    };

    await dispatch(createGeneralQuestions(resource));
    dispatch(setCurrentGeneralQuestions({}));

    const otherOrderItems = orderItems.content
      .filter(item => ((item.id !== context?.addonId && item.id !== +orderItemId)
        && item.offeringType !== 'MasterAddon'));

    if (data?.prepopulateAll && !generalQuestionsData?.id) {
      otherOrderItems.forEach(async (item) => {
        const itemHasTracking = (['ChatProduct', 'ChatPlatinumProduct'].includes(item?.pdOfferingType)
          || addonsOfferingTypes.includes(item?.offeringType));

        const formData = {
          ...data,
          requestedStart: data.requestedStart ? formatDate(new Date(data.requestedStart)) : '',
          delayedStart: data.delayedStart ? formatDate(new Date(data.delayedStart)) : '',
          requestedPayDate: data.requestedPayDate ? formatDate(new Date(data.requestedPayDate)) : '',
          delayedRequestedPayDate: data.delayedRequestedPayDate
            ? formatDate(new Date(data.delayedRequestedPayDate)) : '',
          prepopulateTracking: props.hasTracking ? true : null,
          campaignName: '',
          budget: '',
          initialCycles: '',
          emailPreference: !itemHasTracking ? '' : data.emailPreference,
          trackingMethod: !itemHasTracking ? '' : data.trackingMethod,
          averageTransactionValue: !itemHasTracking ? '' : data.averageTransactionValue,
          costPerLead: !itemHasTracking ? '' : data.costPerLead,
          budgetReallocationCid: '',
          rebuildCid: '',
          runningMcid: '',
        };

        const resource = {
          data: JSON.stringify(formData),
          orderItemId: item.id,
        };

        await dispatch(createGeneralQuestions(resource));
      });
    }

    if (data.prepopulateAll === false) {
      otherOrderItems.forEach(async (item) => {
        const resource = {
          data: JSON.stringify({
            ...defaultValues,
            prepopulateAll: false,
            prepopulateTracking: props.hasTracking ? false : null,
          }),
          orderItemId: item.id,
        };

        await dispatch(createGeneralQuestions(resource));
      });
    }

    if (data.prepopulateTracking && !generalQuestionsData?.data?.prepopulateTracking) {
      otherOrderItems.forEach(async (item) => {
        const itemHasTracking = (['ChatProduct', 'ChatPlatinumProduct'].includes(item?.pdOfferingType)
          || addonsOfferingTypes.includes(item?.offeringType));

        const currentData = await appDispatch(getGeneralQuestionsByOrderItemId(item.id)).then((res) => res.payload);

        if (itemHasTracking) {
          const formData = {
            ...currentData.data,
            prepopulateTracking: true,
            emailPreference: data.emailPreference,
            trackingMethod: data.trackingMethod,
            averageTransactionValue: data.averageTransactionValue,
            costPerLead: data.costPerLead,
          };

          const resource = {
            data: JSON.stringify(formData),
            orderItemId: item.id,
          };

          await dispatch(createGeneralQuestions(resource));
        }
      });
    }

    if (data.prepopulateTracking === false) {
      otherOrderItems.forEach(async (item) => {
        const itemHasTracking = (['ChatProduct', 'ChatPlatinumProduct'].includes(item?.pdOfferingType)
          || addonsOfferingTypes.includes(item?.offeringType));

        const currentData = await appDispatch(getGeneralQuestionsByOrderItemId(item.id)).then((res) => res.payload);

        if (itemHasTracking) {
          const formData = {
            ...currentData.data,
            prepopulateTracking: false,
          };

          const resource = {
            data: JSON.stringify(formData),
            orderItemId: item.id,
          };

          await dispatch(createGeneralQuestions(resource));
        }
      });
    }

    if (history.state?.previousPath.includes('overview')) {
      return navigate.to(Urls.Overview);
    }

    if (topNav) {
      navigate.to(topNav.nextStep(typeOfSale === TypeOfSaleConstants.REBUILD));
    } else navigate.to(Urls.Domains);
  };
  const onBackFunc = () => {
    dispatch(setCurrentGeneralQuestions({}));
    if (offeringType === 'MasterAddon') {
      navigate.to(topNav.previousStep());
    } else navigate.to(Urls.ProductMoveBackward);
  };

  useEffect(() => {
    dispatch(getIsPremiumListingsHealthcare(orderId));
  }, [orderId]);

  const prepopulateTrackingCheckbox = () => (
    props.hasTracking
    && generalQuestionsData?.id
    && generalQuestionsData?.data?.prepopulateTracking === null) && (
    <PrepopulateCheckbox fieldName="prepopulateTracking" tracking />
  );

  const budgetLimits = {
    min: currentOffer?.salesForceOfferObject?.minBudget || 0,
    max: currentOffer?.salesForceOfferObject?.maxBudget || 99999,
  };

  return isExistingCampaign ? (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
          <DefaultPageLayout
            disableContinue={!isValid}
            onBack={onBackFunc}
            header={typeOfSaleLabel || t('pages.generalQuestions.title')}
            description={(
              <Trans
                i18nKey="pages.generalQuestions.adModificationDescription"
                components={{ br: <br /> }}
              />
            )}
            onContinue={handleSubmit(handleFormSubmit)}
          >
            <Grid container xs={10} sx={{ ...defaultInputStyles }}>
              <GridItem sizes={[8]} marginBottom={3}>
                <GridItem marginBottom={1}>
                  <Typography variant="subtitle2" component="h3" fontSize={16}
                    color={errors.runningMcid ? IQThemeV2.palette.error.main : '#000000'}
                  >
                    {t('pages.generalQuestions.runningMcid.title')}
                  </Typography>
                  <GridItem marginBottom={0}>
                    <TextField
                      hiddenLabel
                      required
                      id="runningMcid"
                      name="runningMcid"
                      fullWidth
                      error={!!errors.runningMcid}
                      {...register('runningMcid', {
                        validate: (value) => schemaValidate(value, 'runningMcid', schema),
                      })}
                    />
                    <ErrorText
                      hasError={!!errors.runningMcid}
                      errorText={t('pages.generalQuestions.runningMcid.error')}
                    />
                  </GridItem>
                </GridItem>
              </GridItem>
            </Grid>
          </DefaultPageLayout>
        </Box>
        <CoNavigationConfirm
          showDialog={(isDirty || autocompleteDirty) && !(isSubmitSuccessful || isSubmitting)}
        />
      </FormProvider>
    </ValidationProvider>
  ) : (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
          <DefaultPageLayout
            disableContinue={
              !isValid
              || (!isPunchlist && !methodsSelected)
              || (budgetEmpty && typeOfSale !== TypeOfSaleConstants.REBUILD)
              || (initialCyclesEmpty && typeOfSale !== TypeOfSaleConstants.REBUILD)
            }
            onBack={onBackFunc}
            header={typeOfSaleLabel || t('pages.generalQuestions.title')}
            onContinue={handleSubmit(handleFormSubmit)}
          >
            <Grid container xs={10} sx={{ ...defaultInputStyles }}>
              {/* eslint-disable-next-line max-len */}
              {typeOfSale !== TypeOfSaleConstants.REBUILD
                  ? Details(errors, register, control, budgetLimits, campaignName, typeOfSale, setValue)
                : DetailsRebuild(errors, register)}

              {typeOfSale !== TypeOfSaleConstants.REBUILD && DateRequests(
                adminStartDate,
                setAdminStartDate,
                register,
                errors,
                requestField,
                delayField,
                paymentDate,
                setPaymentDate,
                requestPayDateField,
                delayedRequestedPayDateField,
              )}

              {((props.hasTracking || genericProductTracking)
              && typeOfSale !== TypeOfSaleConstants.REBUILD) && Tracking(register, errors, {
                emailPreference, setEmailPreference, trackingMethod, setTrackingMethod,
              }, offeringType, isPiqPunchListOrder)}

              {typeOfSale !== TypeOfSaleConstants.REBUILD && prepopulateTrackingCheckbox()}

              {!isPunchlist && Payments(
                register,
                errors,
                setValue,
                getValues,
                paymentMethods,
                setPaymentMethods,
                paymentMethodsMinError,
                servicesProcessPayment,
                setServicesProcessPayment,
                typeOfSale,
              )}

              {(!generalQuestionsData?.data?.prepopulateAll
              && !generalQuestionsData?.id
              && !isPunchlist
              && typeOfSale !== TypeOfSaleConstants.REBUILD) && (
                <PrepopulateCheckbox fieldName="prepopulateAll" tracking={false} />
              )}

            </Grid>
          </DefaultPageLayout>
        </Box>
        <CoNavigationConfirm
          showDialog={(isDirty || autocompleteDirty) && !(isSubmitSuccessful || isSubmitting)}
        />
      </FormProvider>
    </ValidationProvider>
  );
}
