import * as yup from 'yup';
import { FormHelperText, TextField, Typography } from '@mui/material';
import ErrorText from 'components/errorText/ErrorText';
import i18n from 'i18n/i18n';
import schemaValidate from 'components/schemaValidate';
import { hideNumberFieldArrows } from '../GeneralQuestionsForm';
import schema from '../GeneralQuestions.schema';

const { t } = i18n;

const budgetLimitError = (limits) => (
  `Campaign creation may fail if budget not equal to '$${limits.min}/${limits.max} budget amount'`
);

export const budgetTextField = (errors, register, limits) => (
  <>
    <TextField
      hiddenLabel
      required
      id="budget"
      name="budget"
      type="number"
      fullWidth
      error={!!errors.budget}
      sx={hideNumberFieldArrows}
      disabled={limits.min === limits.max}
      InputProps={{
        startAdornment: <Typography variant="body1">$</Typography>,
      }}
      {...register('budget', {
        validate: (value) => schemaValidate(value, 'budget', schema),
      })}
    />
    <ErrorText
      hasError={!!errors.budget}
      errorText={errors?.budget?.message}
    />
    {limits.min !== limits.max && (
      <FormHelperText>{`Budget value should be between ${limits.min} and ${limits.max}`}</FormHelperText>
    )}
  </>
);

export const renderBudgetField = (errors, register, limits) => {
  schema.yupValidations.budget = yup.number()
    .typeError(t('pages.generalQuestions.budget.error'))
    .min(limits.min, budgetLimitError(limits))
    .max(limits.max, budgetLimitError(limits))
    .required(t('pages.generalQuestions.budget.error'));
  return budgetTextField(errors, register, limits);
};
