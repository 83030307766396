import { Typography, Box, Grid } from '@mui/material';
import GridItem from 'components/grid/GridItem';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'services/redux/store';
import { useParams } from 'react-router';
import { getProduct } from 'services/productsSlice';
import { displayNotRequiredField, editButton } from 'pages/overview/Overview';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { Urls } from 'navigation/Urls';
import OrderTracker from 'pages/orders/OrderTracker';
import { ProductTypes, getFiles } from 'services/fileUploaderSlice';
import { renderSocialUrls } from 'pages/overview/sections/Business/BusinessLocation';
import { headingStyle } from '../Business/BusinessSection';
import { actionItems } from '../common/actionItems';
import { GeneralQuestionsSection } from '../generalQuestions/GeneralQuestionsSection';
import { displayAssets } from '../sites/sitesSection';

export default function CustomerCenterSection({ product, isEditable, showTracker }: any) {
  const appDispatch = useAppDispatch();
  const navigate = useNavigationHandler();
  const { orderId } = useParams();
  const [ccData, setCcData] = useState({} as any);
  const [existingAssets, setExistingAssets] = useState([]);

  const getAssets = (offeringId, offeringType) => {
    appDispatch(getFiles({
      websiteId: offeringId,
      uploaderType: ProductTypes.EXISTING_CONTACT_LIST,
      offeringType,
    })).then((response) => setExistingAssets(response.payload));
  };

  const getProductData = (orderItemId) => {
    appDispatch(getProduct({
      orderItemId,
    })).then((response) => setCcData(response.payload));
  };

  useEffect(() => {
    if (product) {
      const offeringType = product.pdOfferingType;
      getProductData(product?.id);
      getAssets(product?.offeringId, offeringType);
    }
  }, [product, orderId]);

  const offeringType = product.offeringType === 'ProductsData' ? product.pdOfferingType : product.offeringType;

  const filterSocial = (name) => {
    const filtered = ccData?.data?.accounts?.filter(acc => acc.name === name)[0];
    if (filtered?.enabled) return filtered.url;
    return '';
  };

  const socialAccounts = {
    facebookUrl: filterSocial('Facebook'),
    twitterUrl: filterSocial('Twitter'),
    instagramUrl: filterSocial('Instagram'),
    youtubeUrl: filterSocial('YouTube'),
  };

  const renderProductData = () => (
    <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid black' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {editButton(isEditable, () => navigate.to(
          Urls.CustomerCenterStrategy,
          product.id,
        ))}
        <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Solution Strategy</Typography>
      </Box>
      <Box sx={{ marginTop: '15px' }}>
        <GridItem sizes={[6, 6]}>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Phone Tracking</Typography>
              <Typography>
                <Typography textTransform="capitalize">{ccData?.data?.phoneTracking}</Typography>
              </Typography>
            </Box>
            { ccData?.data?.phoneTracking === 'yes'
                  && React.Children.toArray(ccData?.data?.trackingLines.map((line, i) => (
                    <Box mb={2}>
                      <Typography sx={headingStyle} color="primary">{`Tracking Line ${i + 1}`}</Typography>
                      <Typography>{`Original Number: ${line.originalNumber}`}</Typography>
                      <Typography>{`Forward Number: ${line.forwardToNumber}`}</Typography>
                      <Typography>{`Area Code Preference Number: ${line.forwardToNumber}`}</Typography>
                    </Box>
                  )))}
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Edge Lead Notification Email</Typography>
              <Typography>
                {displayNotRequiredField('edge lead notification email', ccData?.data?.edgeLeadEmail)}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Existing Contact List</Typography>
              {displayAssets(existingAssets, 'Uploaded File')}
            </Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Link Social Media</Typography>
              <Typography textTransform="capitalize">
                {displayNotRequiredField('social media', ccData?.data?.linkSocialMedia)}
              </Typography>
            </Box>
            {
              ccData?.data?.linkSocialMedia === 'yes'
                && (
                <Box mb={2}>
                  <Typography sx={headingStyle} color="primary">Social Media Accounts</Typography>
                  {renderSocialUrls(socialAccounts)}
                </Box>
                )
              }
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Additional Notes/Instructions</Typography>
              <Typography>
                {displayNotRequiredField('additional notes/instructions', ccData?.data?.additionalNotes)}
              </Typography>
            </Box>
          </Box>
        </GridItem>
      </Box>
    </GridItem>
  );

  return (
    <Grid container sx={{ borderTop: '1px solid #716F6F' }}>
      {/* Status Tracker */}
      {showTracker && (
      <GridItem sizes={[12, 12]} marginBottom={8} sx={{
        padding: '40px 0 0', borderBottom: '1px solid #000000',
      }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {editButton(isEditable, () => {})}
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Order Status</Typography>
        </Box>
        <Box>
          <OrderTracker
            key={product?.id}
            status={product?.trackerStatus}
            productName={product?.pdOfferingType}
            product={product}
          />
        </Box>
      </GridItem>
      )}

      {/* General Questions */}
      {GeneralQuestionsSection(offeringType, product.offeringId, product.id, navigate, isEditable) }

      {!product.isRebuild && renderProductData()}

      {/* Actions items */}
      {showTracker && actionItems(false, product?.id)}
    </Grid>
  );
}
