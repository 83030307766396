import * as yup from 'yup';
import i18n from 'i18n/i18n';

const {t} = i18n;

const schema = {
  adGroupName: yup.string().notRequired(),
  pleaseProvideTheLinkToTheYouTubeVideo: yup.string().url(t('pages.youtube.advertising.adGroupOptions.youTubeVideoLinkErrorMsg')).notRequired(),
  additionalYouTubeVideoLink: yup.string().notRequired(),
  advertiserWebsiteUrl: yup.string().url(t('pages.youtube.advertising.adGroupOptions.advertiserWebsiteUrlErrorMsg')).required(t('pages.youtube.advertising.adGroupOptions.advertiserWebsiteUrlErrorMsg')).nullable(),
  specificSubpageUrlBeingPromoted: yup.string().url(t('pages.youtube.advertising.adGroupOptions.specificSubpageUrlBeingPromotedErrorMsg')).notRequired(),
  howShouldTheCampaignBeTargeted: yup.string().required(),
  audiences: yup.string().notRequired(),
};

export default {
  yupValidations: schema,
  customValidations: {},
};
