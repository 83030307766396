/* eslint-disable import/no-extraneous-dependencies */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';
import FileUploader from 'pages/generic-product/details/custom-fields/FileUploader';
import { ProductTypes } from 'services/fileUploaderSlice';
import Dropdown from 'pages/generic-product/details/custom-fields/Dropdown';
import { validateUrl } from 'pages/generic-product/details/custom-fields/UrlTextField';
import { validateAgeRange } from 'pages/generic-product/details/utils/genericValidation';

const { t } = i18n;
const radiusMileOrKilometerDistanceEnum = Array.from({ length: 20 }, (_, i) => ((i + 1) * 5).toString());

export const CustomSolutionsSnapchatSchema: RJSFSchema | any = (data) => ({
  title: t('pages.custom.solutions.snapchat.pageTitle'),
  // eslint-disable-next-line max-len
  type: 'object',
  required: ['proposalForm', 'landingPageUrl', 'highlightKeyDifferentiators', 'focusOnTimedEvent',
    'gender', 'clientProvidingCreative'],
  properties: {
    proposalForm: {
      type: 'string',
      title: t('pages.custom.solutions.common.proposalFormLabel'),
      default: '',
      items: {
        enum: [
          t('form.big-box-labels.yes'),
          t('form.big-box-labels.no'),
        ],
      },
    },
    landingPageUrl: {
      type: 'string',
      title: t('pages.custom.solutions.common.landingPageURLLabel'),
      default: '',
    },
    highlightKeyDifferentiators: {
      type: 'string',
      title: t('pages.custom.solutions.common.keyDifferentiatorsRadioLabel'),
      default: '',
      items: {
        enum: [
          t('form.big-box-labels.yes'),
          t('form.big-box-labels.no'),
        ],
      },
    },
    focusOnTimedEvent: {
      type: 'string',
      title: t('pages.custom.solutions.common.clientFocusLabel'),
      enum: [
        t('form.big-box-labels.yes'),
        t('form.big-box-labels.no'),
      ],
    },
    advertisersLegalBusinessName: {
      type: 'string',
      title: t('pages.custom.solutions.snapchat.advertisersNameLabel'),
      default: '',
    },
    clientHaveABusinessProfile: {
      type: 'string',
      title: t('pages.custom.solutions.snapchat.clientHaveABusinessProfile'),
      enum: [
        t('form.big-box-labels.yes'),
        t('form.big-box-labels.no'),
      ],
    },
    campaignObjective: {
      type: 'string',
      title: t('pages.custom.solutions.snapchat.objectiveCampaignLabel'),
      default: '',
      enum: [
        '',
        t('pages.custom.solutions.common.websiteTrafficOption'),
        t('pages.custom.solutions.common.brandOption'),
      ],
    },
    geoTargetingType: {
      type: 'string',
      title: t('pages.custom.solutions.common.geoTargetingTypeLabel'),
      default: '',
      enum: [
        '',
        t('pages.custom.solutions.common.countryOption'),
        t('pages.custom.solutions.common.statesOption'),
        t('pages.custom.solutions.common.zipCodesOption'),
        t('pages.custom.solutions.snapchat.metrosOption'),
        t('pages.custom.solutions.snapchat.radiusOption'),
      ],
    },
    housingOrCreditOrEmploymentCampaign: {
      type: 'string',
      title: t('pages.custom.solutions.snapchat.housingCreditOrEmploymentCampaignLabel'),
      default: '',
      enum: [
        '',
        t('form.big-box-labels.yes'),
        t('form.big-box-labels.no'),
      ],
    },
    politicalCampaign: {
      type: 'string',
      title: t('pages.custom.solutions.snapchat.politicalCampaignLabel'),
      default: '',
      enum: [
        '',
        t('form.big-box-labels.yes'),
        t('form.big-box-labels.no'),
      ],
    },
    callToAction: {
      type: 'string',
      title: t('pages.custom.solutions.common.selectCallToActionDropDownLabel'),
      default: '',
      enum: [
        '',
        t('pages.custom.solutions.common.buyTicketsOption'),
        t('pages.custom.solutions.snapchat.applyNowOption'),
        t('pages.custom.solutions.snapchat.getNowOption'),
        t('pages.custom.solutions.snapchat.listenOption'),
        t('pages.custom.solutions.snapchat.moreOption'),
        t('pages.custom.solutions.snapchat.orderNowOption'),
        t('pages.custom.solutions.snapchat.playOption'),
        t('pages.custom.solutions.snapchat.bookNowOption'),
        t('pages.custom.solutions.common.shopNowOption'),
        t('pages.custom.solutions.snapchat.showTimesOption'),
        t('pages.custom.solutions.snapchat.signUpOption'),
        t('pages.custom.solutions.snapchat.viewOption'),
        t('pages.custom.solutions.snapchat.watchOption'),
      ],
    },
    minimumAge: {
      type: 'integer',
      title: t('pages.custom.solutions.common.minAgeLabel'),
    },
    maximumAge: {
      type: 'integer',
      title: t('pages.custom.solutions.common.maxAgeLabel'),
    },
    gender: {
      type: 'array',
      title: t('pages.custom.solutions.common.genderLabel'),
      items: {
        type: 'string',
        enum: [
          t('pages.custom.solutions.common.maleLabel'),
          t('pages.custom.solutions.common.femaleLabel'),
        ],
      },
      uniqueItems: true,
      minItems: 1,
    },
    targetingPreferences: {
      type: 'string',
      title: t('pages.custom.solutions.snapchat.targetingPreferencesLabel'),
      default: '',
      items: {
        enum: [
          t('pages.custom.solutions.common.interestsOption'),
          t('pages.custom.solutions.common.realTimeContextsOption'),
          t('pages.custom.solutions.common.genresOption'),
        ],
      },
    },
    clientProvidingCreative: {
      type: 'string',
      title: t('pages.custom.solutions.common.clientProvidingCreativeRadioLabel'),
      default: '',
      description: t('pages.custom.solutions.common.clientProvidingCreativeRadioHelperLabel'),
      items: {
        enum: [
          t('pages.custom.solutions.common.noOption'),
          t('pages.custom.solutions.common.yesAllLabel'),
          t('pages.custom.solutions.common.yesSomeLabel'),
        ],
      },
    },
    notesOrInstructions: {
      type: 'string',
      title: t('pages.custom.solutions.common.notesInstructionsLabel'),
      default: '',
    },
  },
  dependencies: {
    highlightKeyDifferentiators: {
      oneOf: [
        {
          properties: {
            highlightKeyDifferentiators: {
              enum: [t('form.big-box-labels.yes')],
            },
            keyDifferentiatorsToHighlight: {
              type: 'string',
              title: t('pages.custom.solutions.snapchat.keyDifferentiatorsToHighlightLabel'),
              default: '',
            },
          },
        },
        {
          properties: {
            highlightKeyDifferentiators: {
              enum: [t('form.big-box-labels.no')],
            },
          },
        },
      ],
    },
    clientHaveABusinessProfile: {
      oneOf: [
        {
          properties: {
            clientHaveABusinessProfile: {
              enum: [t('form.big-box-labels.yes')],
            },
            snapchatProfileId: {
              type: 'string',
              title: t('pages.custom.solutions.snapchat.snapchatProfileId'),
              default: '',
            },
          },
        },
        {
          properties: {
            clientHaveABusinessProfile: {
              enum: [t('form.big-box-labels.no')],
            },
            snapchatProfileIdNoDescription: {
              type: 'string',
              title: t('pages.custom.solutions.snapchat.snapchatProfileIdNoDescription'),
            },
          },
        },
      ],
    },
    focusOnTimedEvent: {
      oneOf: [
        {
          properties: {
            focusOnTimedEvent: {
              enum: [t('form.big-box-labels.yes')],
            },
            detailsOfEvent: {
              type: 'string',
              title: t('pages.custom.solutions.common.detailsOfEventLabel'),
              default: '',
            },
            startDate: {
              type: 'string',
              title: t('pages.custom.solutions.common.startDateLabel'),
              format: 'date',
            },
            endDate: {
              type: 'string',
              title: t('pages.custom.solutions.common.endDateLabel'),
              format: 'date',
            },
          },
        },
        {
          properties: {
            focusOnTimedEvent: {
              enum: [t('form.big-box-labels.no')],
            },
          },
        },
      ],
    },
    targetingPreferences: {
      oneOf: [
        {
          properties: {
            targetingPreferences: {
              enum: [t('pages.custom.solutions.common.interestsOption')],
            },
            targetingPreferencesLabel: {
              type: 'string',
              title: t('pages.custom.solutions.common.selectAllLabel'),
            },
            targetingPreferencesInterests: {
              type: 'array',
              title: '',
              items: {
                type: 'string',
                enum: [
                  t('pages.custom.solutions.common.businessOption'),
                  t('pages.custom.solutions.common.comedyOption'),
                  t('pages.custom.solutions.common.commutingOption'),
                  t('pages.custom.solutions.common.cookingOption'),
                  t('pages.custom.solutions.common.cultureSocietyOption'),
                  t('pages.custom.solutions.common.educationOption'),
                  t('pages.custom.solutions.common.fitnessOption'),
                  t('pages.custom.solutions.common.gamingOption'),
                  t('pages.custom.solutions.common.healthLifestyleOption'),
                  t('pages.custom.solutions.common.inCarOption'),
                  t('pages.custom.solutions.common.parentingOption'),
                  t('pages.custom.solutions.common.partyingOption'),
                  t('pages.custom.solutions.common.podcastsOption'),
                  t('pages.custom.solutions.common.runningOption'),
                  t('pages.custom.solutions.common.studyingOption'),
                  t('pages.custom.solutions.common.techOption'),
                  t('pages.custom.solutions.common.theaterOption'),
                  t('pages.custom.solutions.common.travelOption'),
                  t('pages.custom.solutions.common.tvFilmOption'),
                ],
              },
              uniqueItems: true,
            },
          },
        },
        {
          properties: {
            targetingPreferences: {
              enum: [t('pages.custom.solutions.common.realTimeContextsOption')],
            },
            targetingPreferencesLabel: {
              type: 'string',
              title: t('pages.custom.solutions.common.selectAllLabel'),
            },
            targetingPreferencesRealtimeContexts: {
              type: 'array',
              title: '',
              items: {
                type: 'string',
                enum: [
                  t('pages.custom.solutions.common.chillOption'),
                  t('pages.custom.solutions.common.cookingOption'),
                  t('pages.custom.solutions.common.dinnerOption'),
                  t('pages.custom.solutions.common.focusOption'),
                  t('pages.custom.solutions.common.gamingOption'),
                  t('pages.custom.solutions.common.holidaysOption'),
                  t('pages.custom.solutions.common.partyOption'),
                  t('pages.custom.solutions.common.travelOption'),
                  t('pages.custom.solutions.common.workoutOption'),
                ],
              },
              uniqueItems: true,
            },
          },
        },
        {
          properties: {
            targetingPreferences: {
              enum: [t('pages.custom.solutions.common.genresOption')],
            },
            targetingPreferencesLabel: {
              type: 'string',
              title: t('pages.custom.solutions.common.selectAllLabel'),
            },
            targetingPreferencesGenres: {
              type: 'array',
              title: '',
              items: {
                type: 'string',
                enum: [
                  t('pages.custom.solutions.common.alternativeOption'),
                  t('pages.custom.solutions.common.bluesOption'),
                  t('pages.custom.solutions.common.christianOption'),
                  t('pages.custom.solutions.common.classicalOption'),
                  t('pages.custom.solutions.common.countryOption'),
                  t('pages.custom.solutions.common.easyListeningOption'),
                  t('pages.custom.solutions.common.edmOption'),
                  t('pages.custom.solutions.common.electronicaOption'),
                  t('pages.custom.solutions.common.folkOption'),
                  t('pages.custom.solutions.common.funkOption'),
                  t('pages.custom.solutions.common.hipHopOption'),
                  t('pages.custom.solutions.common.holidayOption'),
                  t('pages.custom.solutions.common.houseOption'),
                  t('pages.custom.solutions.common.indieRockOption'),
                  t('pages.custom.solutions.common.jazzOption'),
                  t('pages.custom.solutions.common.latinOption'),
                  t('pages.custom.solutions.common.metalOption'),
                  t('pages.custom.solutions.common.newAgeOption'),
                  t('pages.custom.solutions.common.popOption'),
                  t('pages.custom.solutions.common.punkOption'),
                  t('pages.custom.solutions.common.reggaeOption'),
                  t('pages.custom.solutions.common.rnbOption'),
                  t('pages.custom.solutions.common.rockOption'),
                  t('pages.custom.solutions.common.soundTrackOption'),
                  t('pages.custom.solutions.common.spokenAudioOption'),
                  t('pages.custom.solutions.common.traditionalOption'),
                ],
              },
              uniqueItems: true,
            },
          },
        },
      ],
    },
    clientProvidingCreative: {
      oneOf: [
        {
          properties: {
            clientProvidingCreative: {
              enum: [t('form.big-box-labels.no')],
            },
          },
        },
        {
          properties: {
            clientProvidingCreative: {
              enum: [t('pages.custom.solutions.common.yesAllLabel')],
            },
            uploader_allCreativeFile: {
              type: 'string',
              title: '',
              default: '',
            },
          },
          required: ['uploader_allCreativeFile'],
        },
        {
          properties: {
            clientProvidingCreative: {
              enum: [t('pages.custom.solutions.common.yesSomeLabel')],
            },
            uploader_someCreativeFile: {
              type: 'string',
              title: '',
              default: '',
            },
          },
          required: ['uploader_someCreativeFile'],
        },
      ],
    },
    geoTargetingType: {
      oneOf: [
        {
          properties: {
            geoTargetingType: {
              enum: ['', 'Country', 'State', 'Zip Codes', 'Metros / DMA'],
            },
            geoTargetingDetails: {
              type: 'string',
              title: t('pages.custom.solutions.common.geoTargetingDetailsLabel'),
              default: '',
            },
          },
        },
        {
          properties: {
            geoTargetingType: {
              enum: ['Radius'],
            },
            addressToUse: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.targeting.listTheAddressToUse'),
              default: data?.addressToUse,
            },
            radiusMileOrKilometerDistance: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.targeting.radiusMileOrKilometerDistanceLabel'),
              enum: [...radiusMileOrKilometerDistanceEnum],
            },
          },
        },
      ],
    },
  },
});

const textFieldKeys = [
  'detailsOfEvent',
  'snapchatProfileId',
  'keyDifferentiatorsToHighlight',
  'geoTargetingDetails',
];

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textInputField' },
}), {});

const radioFieldKeys = [
  'proposalForm',
  'highlightKeyDifferentiators',
  'targetingPreferences',
  'clientProvidingCreative',
];

const radioFields = radioFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'radioButton' },
}), {});

const checkBoxesFieldKeys = [
  'targetingPreferencesInterests',
  'targetingPreferencesRealtimeContexts',
  'targetingPreferencesGenres',
];

const checkBoxesFields = checkBoxesFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'checkboxes' },
}), {});

const dropDownKeys = [
  'callToAction',
  'campaignObjective',
  'clientHaveABusinessProfile',
  'geoTargetingType',
  'housingOrCreditOrEmploymentCampaign',
  'politicalCampaign',
  'radiusMileOrKilometerDistance',
];

const dropDownFields = dropDownKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'dropdown' },
}), {});

const dateKeys = [
  'startDate',
  'endDate',
];

const dateFields = dateKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'datePickerField' },
}), {});

const integerInputFieldKeys = [
  'minimumAge',
  'maximumAge',
];

const integerInputField = integerInputFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'integerInputField' },
}), {});

export const CustomSolutionsSnapchatUISchema = {
  'ui:order': [
    'proposalForm', 'landingPageUrl', 'highlightKeyDifferentiators', 'keyDifferentiatorsToHighlight',
    'focusOnTimedEvent', 'detailsOfEvent', 'startDate', 'endDate', 'advertisersLegalBusinessName',
    'clientHaveABusinessProfile', 'snapchatProfileId', 'snapchatProfileIdNoDescription',
    'campaignObjective', 'geoTargetingType', 'geoTargetingDetails', 'radiusMileOrKilometerDistance', 'addressToUse',
    'housingOrCreditOrEmploymentCampaign', 'politicalCampaign', 'callToAction',
    'minimumAge', 'maximumAge', 'gender', 'targetingPreferences', 'targetingPreferencesLabel',
    'targetingPreferencesInterests', 'targetingPreferencesRealtimeContexts', 'targetingPreferencesGenres',
    'clientProvidingCreative', 'uploader_allCreativeFile', 'uploader_someCreativeFile', 'notesOrInstructions',
  ],
  gender: {
    'ui:widget': 'checkBoxField',
    'ui:description': t('pages.custom.solutions.common.checkAllLabel'),
    'ui:style': {
      marginTop: '30px',
    },
  },
  targetingPreferencesLabel: {
    'ui:widget': 'textLabel',
    'ui:style': {
      marginBottom: '8px',
    },
  },
  focusOnTimedEvent: {
    'ui:widget': (props) => (
      <Dropdown {...props}
        errorMessage="Please select if the Client want to focus on a timed event"
      />
    ),
    'ui:placeholder': t('pages.custom.solutions.common.flightedCampaignHelperLabel'),
  },
  landingPageUrl: {
    'ui:widget': 'UrlTextWidget',
    'ui:placeholder': t('pages.custom.solutions.common.landingPageURLHelperLabel'),
  },
  advertisersLegalBusinessName: {
    'ui:widget': 'textInputField',
    'ui:style': {
      marginTop: '12px',
    },
  },
  snapchatProfileIdNoDescription: {
    'ui:widget': 'textLabel',
  },
  uploader_allCreativeFile: {
    'ui:widget': (props) => (
      <FileUploader
        {...props}
        uploaderType={ProductTypes.CUSTOM_SOLUTIONS_SNAPCHAT_UPLOADER_1}
        totalAllowedFiles={1}
        imageTypes={[]}
        videoTypes={[]}
        documentTypes={['.pdf']}
        supportingText=""
      />
    ),
  },
  uploader_someCreativeFile: {
    'ui:widget': (props) => (
      <FileUploader
        {...props}
        uploaderType={ProductTypes.CUSTOM_SOLUTIONS_SNAPCHAT_UPLOADER_2}
        totalAllowedFiles={1}
        imageTypes={[]}
        videoTypes={[]}
        documentTypes={['.pdf']}
        supportingText=""
      />
    ),
  },
  notesOrInstructions: {
    'ui:widget': 'TextareaWidget',
  },
  addressToUse: { "ui:widget": "addressField" },

  ...integerInputField,
  ...dateFields,
  ...dropDownFields,
  ...checkBoxesFields,
  ...radioFields,
  ...textFields,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CustomSolutionsSnapchatCustomValidate = (formData: any, errors: any, uiSchema: any) => {
  if (formData?.proposalForm === '') {
    errors.proposalForm.addError(t('pages.custom.solutions.common.proposalFormError'));
    errors.addError(true);
  }
  if (formData?.clientProvidingCreative === '') {
    errors.clientProvidingCreative.addError(t('pages.custom.solutions.common.clientProvidingCreativeError'));
    errors.addError(true);
  }
  if (formData?.keyDifferentiators === '') {
    errors.keyDifferentiators.addError(t('pages.custom.solutions.common.keyDifferentiatorsRadioError'));
    errors.addError(true);
  }
  if (formData?.landingPageUrl === '' || !validateUrl(formData?.landingPageUrl)) {
    errors.landingPageUrl.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
    errors.addError(true);
  }
  if (new Date(formData?.endDate) < new Date(formData?.startDate)) {
    errors.endDate.addError(t('pages.socialAds.socialAdsWebClicksSection.inputError.endDateErrorMessage'));
    errors.addError(true);
  }
  validateAgeRange(formData, errors);
  return errors;
};
