import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Container, Divider, Grid } from '@mui/material';
import { CoButtonBack } from 'components/buttons/CoButtonBack';
import { CoButtonContinue } from 'components/buttons/CoButtonContinue';
import { useDispatch, useSelector } from 'react-redux';
import { selectGenericStepperActiveStep, setActiveStep } from 'services/genericForm';
import { useQuery } from 'pages/themes/details/ThemeDetails';
import { useSearchParams } from 'react-router-dom';
import { useTopnavMenu } from 'hooks/useTopnavMenu';
import { Urls } from 'navigation/Urls';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { FormComponent } from '../DetailsPageForm';

export default function GenericStepper(props: any) {
  const { steps } = props;
  const dispatch = useDispatch();
  const navigate = useNavigationHandler();
  const query = useQuery();
  const [searchParams, setSearchParams] = useSearchParams();
  const topnav = useTopnavMenu(Urls.GenericProductDetails);
  const activeStep = useSelector(selectGenericStepperActiveStep);

  const updateQueryParams = () => {
    const params: any = {};
    if (searchParams.get('step')) params.step = searchParams.get('step');
    setSearchParams(params);
  };

  const handleNext = () => {
    const newStep = activeStep + 1;
    setSearchParams({ step: newStep });
    dispatch(setActiveStep(newStep));
  };

  const handleBack = () => {
    if (activeStep === 0) {
      navigate.to(topnav.previousStep());
      return;
    }
    const newStep = activeStep - 1;
    setSearchParams({ step: newStep.toString() });
    dispatch(setActiveStep(newStep));
  };

  useEffect(() => {
    const step = query.get('step');
    if (step) dispatch(setActiveStep(Number(step)));
    updateQueryParams();
  }, [query]);

  useEffect(() => {
    props.setHandleNext(() => handleNext);
  }, [query, activeStep]);

  useEffect(() => {
    if (activeStep === steps.length) {
      dispatch(setActiveStep(0));
      props.onComplete();
    }
  }, [activeStep]);

  const renderStepPreview = (activeStep, index) => ((activeStep === 0 && index < activeStep + 3)
      || (index > (activeStep - 2) && index < (activeStep + 2))
      || (activeStep === steps.length - 1 && index > activeStep - 3));

  return (
    <Container
      sx={{
        maxWidth: '100% !important',
        padding: 2,
      }}
    >
      <Stepper activeStep={activeStep} sx={{ width: '100%' }}>
        {steps.map((step, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return renderStepPreview(activeStep, index) ? (
            <Step key={step.title} {...stepProps}>
              <StepLabel {...labelProps}>{step.title}</StepLabel>
            </Step>
          ) : (
            <Box />
          );
        })}
      </Stepper>
      {activeStep !== steps.length && (
        <FormComponent
          currentSchema={steps[activeStep].schema}
          product={props.product}
          onSubmitEvent={props.onSubmitEvent}
          currentUISchema={steps[activeStep].uiSchema}
          customValidate={steps[activeStep].customValidate}
          onBack={props.onBack}
          onValid={props.onValid}
          setProduct={props.setProduct}
          isStepper
        >
          <Grid item xs={12}>
            <Divider sx={{ margin: '100px 0 40px !important' }} />
          </Grid>
          <Grid item xs={12} sx={{ paddingBottom: '117px' }}>
            <Box>
              <CoButtonBack
                label="Back"
                onClick={handleBack}
                sx={{ float: 'left', minWidth: 'fit-content' }}
              />
              <CoButtonContinue
                label={activeStep === steps.length - 1 ? 'Finish' : props.continueButtonLabel}
                disabled={props.hasErrors}
                sx={{ float: 'right', minWidth: 'fit-content' }}
                id="continueButton"
                type="submit"
              />
            </Box>
          </Grid>
        </FormComponent>
      )}
    </Container>
  );
}
