import {
  AppBar, Button, Container, Menu, MenuItem, Toolbar, useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import React, {
  Children,
  forwardRef, useEffect, useMemo, useRef, useState,
} from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import IQTopNavIcon from 'icons/IQTopNavIcon.svg';
import { Urls } from 'navigation/Urls';
import { selectCurrentCountry, setProduct } from 'services/topNavMenuSlice';
import {
  ThemeFilterType, getMenuProducts, resetCurrent,
  selectMenuThemeTypes, setFilter, setFilteredThemes,
} from 'services/themesSlice';
import { selectOrdersContent } from 'services/ordersSlice';
import { getCartItemList } from 'services/cartSlice';
import {
  getUserInfo, selectUserAccess, selectUserRole,
} from 'services/userSlice';
import { getProductTypesMenu, selectProductTypesMenu } from 'services/productsSlice';
import CoCountrySelector from './CoCountrySelector';
import CoCart from './CoCart';
import CoUserMenu from './CoUserMenu';

const NavButton = forwardRef((props: any, ref) => {
  const theme = useTheme();

  return (
    <Button sx={{
      color: 'white',
      fontWeight: 'normal',
      zIndex: 100000,
      padding: '0 15px !important',
      height: '100%',
      borderRadius: 0,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    }}
      ref={ref}
      {...props}
    >
      {props.children}
    </Button>
  );
});

const NavMenu = (props: any) => {
  const theme = useTheme();

  return (
    <Menu sx={{
      '& .MuiPaper-root': {
        backgroundColor: theme.palette.text.primary,
        color: 'white',
      },
    }}
      PopoverClasses={{ root: 'custom-popover' }}
      {...props}
    >
      {props.children}
    </Menu>
  );
};

const NavMenuItem = (props: any) => {
  const theme = useTheme();

  return (
    <MenuItem
      sx={{
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
        },
        fontSize: 14,
      }}
      {...props}
    >
      {props.children}
    </MenuItem>
  );
};

export default function CoTopNav() {
  const dispatch = useDispatch();
  const userRole = useSelector(selectUserRole);

  useEffect(() => {
    if (!userRole) dispatch(getUserInfo('me'));
    dispatch(getProductTypesMenu({}));
  }, []);

  const productButtonRef = useRef(null);
  const adminButtonRef = useRef(null);
  const toolsButtonRef = useRef(null);
  const [productsOpen, setProductsOpen] = useState(false);
  const [adminOpen, setAdminOpen] = useState(false);
  const [toolsOpen, setToolsOpen] = useState(false);
  const menuThemes = useSelector(selectMenuThemeTypes);
  const selectedCountry = useSelector(selectCurrentCountry);
  const currentOrder = useSelector(selectOrdersContent);
  const productTypes = useSelector(selectProductTypesMenu);
  const [dropdownMenu, setDropdownMenu] = useState([]);
  const navigate = useNavigate();
  const access = useSelector(selectUserAccess);
  const [canDoGSC, setCanDoGSC] = useState(false);

  const buildMenu = async () => {
    const productTypesFiltered = Object.keys(productTypes);
    const newproductTypes = [];
    const isProd = (window.location.href
      && ((window.location.href.indexOf('orders.localiq.com') >= 0)
      || (process.env.NODE_ENV === 'production')));

    productTypesFiltered.forEach(element => {
      // check if enabled, by stripe flag, temporary solution
      const typesFiltered = productTypes[element].filter(type => ((!isProd) || (type.stripe && type.stripe === true)));
      if (typesFiltered && typesFiltered.length > 0) {
        newproductTypes.push({
          title: element,
          productType: typesFiltered.map(type => type.code.replaceAll(' ', '+')),
        });
      }
    });

    setDropdownMenu(newproductTypes);
  };

  useEffect(() => {
    if (menuThemes.length > 0) {
      buildMenu();
    } else dispatch(getMenuProducts(selectedCountry.name));
  }, [menuThemes]);

  useEffect(() => {
    if (currentOrder.id) {
      dispatch(getCartItemList(currentOrder.id));
    }
  }, [currentOrder]);

  const handleProductsMenuOpen = () => setProductsOpen(true);

  const handleProductsClose = () => setProductsOpen(false);

  const handleAdminOpen = () => setAdminOpen(true);

  const handleAdminClose = () => setAdminOpen(false);

  const closeAllMenus = () => {
    setProductsOpen(false);
    setAdminOpen(false);
  };

  const handleToolsOpen = () => setToolsOpen(true);

  const handleToolsClose = () => setToolsOpen(false);

  const goToProduct = async (product) => {
    dispatch(setFilter({ type: ThemeFilterType.PRODUCT_TYPE, value: product }));
    if (Array.isArray(product)) {
      const allChildren = [];
      product.forEach(type => {
        const filtered = menuThemes.filter(theme => theme.product.replaceAll(' ', '+') === type);
        if (filtered.length > 0) filtered.forEach(item => allChildren.push(item));
      });
      dispatch(setFilteredThemes(allChildren));
    } else {
      const filtered = menuThemes.filter(theme => theme.product.replaceAll(' ', '+') === product);
      dispatch(setFilteredThemes(filtered));
    }

    dispatch(resetCurrent({}));
    dispatch(setProduct(product));
    const url = `/${Urls.Themes}?product=${product}`;
    navigate(url);
  };

  const redirectToHome = async () => {
    dispatch(setFilter({ type: ThemeFilterType.PRODUCT_TYPE, value: '' }));
    const filtered = menuThemes.filter(theme => (theme.featured && theme.active));
    dispatch(setFilteredThemes(filtered));
    dispatch(resetCurrent({}));
    navigate(`/${Urls.Themes}`);
  };

  useMemo(() => {
    if (access.isAdmin || access.canDoFulfillment) setCanDoGSC(true);
  }, [access]);

  const renderCarbon = () => {
    if (access.canDoCarbon || access.isAdmin) {
      return (
        <NavMenuItem style={{ marginLeft: -5 }}
          onMouseLeave={closeAllMenus}
          onClick={() => navigate('/carbon')}
        >
          Carbon
        </NavMenuItem>
      );
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  };

  // eslint-disable-next-line consistent-return
  const renderSearchConsole = () => {
    if (canDoGSC) {
      return (
        <NavMenuItem
          style={{ marginLeft: -5 }}
          onClick={() => navigate('/google_search_console')}
        >
          Google Search Console
        </NavMenuItem>
      );
    }
  };

  // eslint-disable-next-line consistent-return
  const renderContractUpload = () => {
    return (
      <NavMenuItem
        style={{ marginLeft: -5 }}
        onClick={() => navigate('/airkit-archive')}
      >
        Airkit Archive
      </NavMenuItem>
    );
  };

  const renderSendgridChecker = () => {
    if (access.isAdmin) {
      return (
        <NavMenuItem
          style={{ marginLeft: -5 }}
          onClick={() => navigate('/sendgrid-checker')}
        >
          Sendgrid Checker
        </NavMenuItem>
      );
    }
  };

  // eslint-disable-next-line consistent-return
  const renderTools = () => {
    return (
      <>
        <NavButton ref={toolsButtonRef} onMouseOver={handleToolsOpen} onMouseOut={handleToolsClose}>
          Tools
          <ArrowDropDownIcon />
        </NavButton>
        <NavMenu
          anchorEl={toolsButtonRef.current}
          open={toolsOpen}
          onClose={handleToolsClose}
          MenuListProps={{ onMouseLeave: handleToolsClose, onMouseOver: handleToolsOpen }}
        >
          {renderCarbon()}
          {renderSearchConsole()}
          {renderContractUpload()}
          {renderSendgridChecker()}
        </NavMenu>
      </>
    );
  };

  const renderAdmin = () => {
    if (!access.isAdmin) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }
    return (
      <>
        <NavButton ref={adminButtonRef} onMouseOver={handleAdminOpen} onMouseOut={handleAdminClose}>
          Admin
          <ArrowDropDownIcon />
        </NavButton>
        <NavMenu
          anchorEl={adminButtonRef.current}
          open={adminOpen}
          onClose={handleAdminClose}
          MenuListProps={{ onMouseLeave: handleAdminClose, onMouseOver: handleAdminOpen }}
        >
          <NavMenuItem onClick={() => navigate('/admin/dashboard')}>Dashboard</NavMenuItem>
          <NavMenuItem onClick={() => navigate('/admin/themes')}>Themes</NavMenuItem>
          <NavMenuItem onClick={() => navigate('/admin/bundles')}>Bundles</NavMenuItem>
          <NavMenuItem onClick={() => navigate('/admin/manage_cids')}>CID Update</NavMenuItem>
          <NavMenuItem onClick={() => navigate('/admin/manage_product_mapping')}>Manage RL Offers</NavMenuItem>
          <NavMenuItem onClick={() => navigate('/public/fb/facebook_url')}>Facebook Url</NavMenuItem>
          <NavMenuItem onClick={() => navigate('/admin/manage_users')}>Manage Users</NavMenuItem>
          <NavMenuItem onClick={() => navigate('/admin/gsc_account')}>Create GSC Account</NavMenuItem>
          <NavMenuItem onClick={() => navigate('/admin/cef_object_creator')}>CEF Object Creator</NavMenuItem>
        </NavMenu>
      </>
    );
  };

  return (
    <AppBar position="fixed"
      sx={{
        backgroundColor: 'black',
        height: '50px',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Container sx={{
        maxWidth: 'initial !important',
        marginLeft: 0,
        marginRight: 0,
      }}
      >
        <Toolbar
          disableGutters
          sx={{ minHeight: '50px !important', padding: '0 !important', justifyContent: 'space-between' }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', padding: '0 !important' }}>
            <Box onClick={redirectToHome}
              sx={{ cursor: 'pointer', padding: '0 !important', width: '126px' }}
            >
              <img src={IQTopNavIcon} alt="IQTopNavIcon" />
            </Box>
            <Box sx={{ padding: '0 !important', marginLeft: '20px', height: '50px' }}>
              <NavButton onMouseLeave={closeAllMenus} onClick={redirectToHome}>
                Create New Order
              </NavButton>
              <NavButton ref={productButtonRef} onMouseOver={handleProductsMenuOpen} onMouseOut={handleProductsClose}>
                Products
                <ArrowDropDownIcon />
              </NavButton>
              <NavMenu
                anchorEl={productButtonRef.current}
                open={productsOpen}
                onClose={handleProductsClose}
                MenuListProps={{ onMouseLeave: handleProductsClose, onMouseOver: handleProductsMenuOpen }}
              >
                {Children.toArray(dropdownMenu.map(menuItem => (
                  <NavMenuItem onClick={() => goToProduct(menuItem.productType)}>{menuItem.title}</NavMenuItem>
                )))}
              </NavMenu>
              <NavButton onMouseLeave={closeAllMenus} onClick={() => navigate('/orders')}>
                My Orders
              </NavButton>
              {renderTools()}
              {renderAdmin()}
            </Box>
          </Box>
          <Box sx={{ padding: '0 !important', marginLeft: '20px', height: '50px' }}>
            <CoCountrySelector />
            <CoCart />
            <CoUserMenu />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
