import {
  Divider, FormControl, Grid, InputLabel, MenuItem, Select, Tooltip, Typography,
} from '@mui/material';
import { useState } from 'react';
import ErrorText from 'components/NePhoneInputContainer/IQErrorText/IQErrorText';

interface Props {
  label: string;
  name: string;
  value: number;
  data: any;
  register: any;
  errors: any;
  offeringType: string;
}

export default function CampaignIdSelect({

  label, offeringType, name, data, register, errors, value,
}: Props) {
  const [campaignValue, setCampaignValue] = useState(value);

  const handleChange = (event) => {
    setCampaignValue(event.target.value);
    register(name).onChange(event);
  };

  const renderCampaignText = (obj) => {
    if (!obj) return 'Select CID';
    // eslint-disable-next-line no-useless-concat
    return `CID: ${obj.id}, ` + `Campaign Name: ${obj.name}, `
   + `Payment Status: ${obj.status}`;
  };

  const renderCampaignValue = (id) => {
    if (typeof id === 'undefined') return 'Select CID';
    const option = data.find(el => (el.id === +id));

    return renderCampaignText(option);
  };

  const renderDropdown = (options) => {
    const keywords = offeringType?.replace(/([A-Z])/g, ' $1').split(' ').filter(Boolean);
    const regex = new RegExp(`${keywords.join('|')}`, 'gi');

    const sortedOptions = options.map(option => {
      let points = 0;

      const productNameMatcher = option.productName.toLowerCase().match(regex);
      if (productNameMatcher) {
        points += productNameMatcher.length;
      }

      const productFamilyMatcher = option.productFamilyName.toLowerCase().match(regex);
      if (productFamilyMatcher) {
        points += productFamilyMatcher.length;
      }

      return { ...option, points };
    }).sort((a, b) => b.points - a.points);

    const dropdownOptions = [
      <MenuItem key={0} disableGutters style={{ padding: '0 8px' }}>
        <Grid item xs={2}><Typography fontWeight="bold" variant="subtitle1" color="inherit">CID</Typography></Grid>
        <Grid item xs={7}><Typography fontWeight="bold" variant="subtitle1">Campaign Name</Typography></Grid>
        <Grid item xs={2}><Typography fontWeight="bold" variant="subtitle1">Payment Status</Typography></Grid>
      </MenuItem>,
      <Divider key={1} variant="fullWidth" sx={{ borderColor: 'text.disabled' }} />,
      sortedOptions.map(option => (
        <MenuItem key={option.id} disableGutters value={option.id}>
          <Tooltip title={renderCampaignText(option)} placement="bottom-end">
            <Grid container style={{ padding: '8px' }}>
              <Grid item xs={2}><Typography>{option.id}</Typography></Grid>
              <Grid item xs={7}><Typography noWrap>{option.name}</Typography></Grid>
              <Grid item xs={2}>
                <Typography color={option.status === 'Running' ? 'success.dark' : 'inherit'}>
                  {option.status}
                </Typography>
              </Grid>
            </Grid>
          </Tooltip>
        </MenuItem>
        // <Divider variant="fullWidth" sx={{ borderColor: 'text.disabled' }} />
      )),
    ];
    return dropdownOptions;
  };

  const hasError = !!errors[name];
  const errorMessage = hasError ? errors[name]?.message : '';

  return (
    <Grid container>
      <Grid item xs={12} sx={{ marginBottom: '8px' }}>
        <Typography color={hasError ? 'error.main' : 'inherit'} fontWeight={600}>{`${label} *`}</Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: '8px' }}>
        <FormControl fullWidth>
          <InputLabel id={name} shrink={false}>{(campaignValue === null) && 'Select CID'}</InputLabel>
          <Select
            {...register(name, { required: 'Please select a CID to continue.' })}
            labelId="campaignId-label"
            id={name}
            value={campaignValue}
            onChange={handleChange}
            renderValue={(selected) => (
              <Tooltip title={renderCampaignValue(selected)} placement="right-end">
                <Typography noWrap>{renderCampaignValue(selected)}</Typography>
              </Tooltip>
            )}
            sx={{
              backgroundColor: 'common.white',
              '& .MuiOutlinedInput-notchedOutline': { borderColor: `${hasError ? 'error.main' : 'inherit'}` },
            }}
          >
            {renderDropdown(data)}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: '16px' }}>
        {hasError && (
        <ErrorText
          hasError={hasError}
          errorMessage={errorMessage as string}
          alignItems="flex-start"
        />
        )}
      </Grid>
    </Grid>
  );
}
