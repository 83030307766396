import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Link,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import DoneIcon from '@mui/icons-material/Done';
import BadgeIcon from 'icons/badge.svg';
import BadgeBlue from 'icons/badgeBlue.svg';
import BadgeOrange from 'icons/badgeOrange.svg';
import BadgeGold from 'icons/badgeGold.svg';
import {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getThemeBundles, selectAllAddons, selectThemeBundles, Theme,} from 'services/themesSlice';
import {makeStyles} from '@mui/styles';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {
  addItemToOrder,
  getOrderItems,
  requestNewOrder,
  selectOrdersContent,
  setOrderItemTypeOfSale,
  startOrder,
} from 'services/ordersSlice';
import {useAppDispatch} from 'services/redux/store';
import {Urls} from 'navigation/Urls';
import {
  removeCartItem,
  selectCartItems,
  updateCartAddons,
  updateCartAddonsSelection,
} from 'services/cartSlice';
import {useNavigate, useParams} from 'react-router';
import {useNavigationHandler} from 'hooks/useNavigationHandler';
import {selectCurrentCountry} from 'services/topNavMenuSlice';
import {useTranslation} from 'react-i18next';
import {mapAddons} from 'pages/salesforce-entry/OpportunityData';
import {putProduct} from 'services/productsSlice';
import {TypeOfSaleConstants} from 'pages/shopping-cart/Constants';
import ThemeDisclaimerModal from './ThemeDisclaimerModal';
import AddonsDisclaimerModal from './AddonsDisclaimerModal';
import {
  ausCustomFacebook,
  ausDemandGen,
  ausGoogleDisplayNetwork,
  ausLinkedin,
  ausLiveChat,
  ausPerformanceMax,
  ausSite,
  ausTiktok,
  ausTotalTrack,
  ausVideoCreativeService,
  ausYoutube,
  ausPremiumListings1To9,
  crmIntegrationAddon,
  csWazePilot,
  customCampaignLandingPages,
  customReporting,
  customSolutionsLinkedin,
  customSolutionsPandora,
  customSolutionsSnapchat,
  customSolutionsSpotify,
  customSolutionTiktok,
  customSolutionTwitter,
  customTracking,
  displayCreativeServiceCustom,
  displayCreativeServices,
  dmsNextProducts,
  enhancedReportingAddon,
  getCreativeServiceFee,
  googleTagManager,
  localServiceAds,
  localServicesAdsManagementServices,
  multiMediaCreatives,
  nationalSem,
  performanceMaxBeta,
  premiumListingsTenPlusPhysicianDirectories,
  promotionsMixMedia,
  promotionsPageAndCreativesReachLocalBetaOnly,
  rlBrandedContent,
  sem,
  seoAddons,
  seoBlogProducts,
  seoProducts,
  shoppingAds,
  siteRetargetingWithLookalikesGDN,
  smbProducts,
  smmAddons,
  smmProducts,
  socialAds,
  videoCreativeServicePackage,
  website,
  yelp,
  youtube,
} from '../ThemeConstants';
import {useQuery} from './ThemeDetails';
import {renderPL, renderSMM, renderXMO, renderXMOUpgrade,} from './Bundle';
import {productNameMap, siteMap} from './BundleConstants';
import {checkCartValidations} from './CartValidations';

interface Props {
  theme: Theme
  selectedVariant: Theme
  isBlue?: boolean
  partnerAccount?: string | number
}

const setBadge = (product) => {
  if ([ 'website - gold', 'master addons' ].includes(product)) return BadgeGold;
  if ([ ...seoProducts, ...seoBlogProducts, ...seoAddons, ...smmProducts,
    ...smmAddons, ...customReporting, ...displayCreativeServices, ...displayCreativeServiceCustom,
    ...customSolutionsSpotify, ...customSolutionsSnapchat, ...customSolutionsPandora, ...smbProducts,
    ...videoCreativeServicePackage, ...rlBrandedContent, ...customSolutionsLinkedin, ...socialAds,
    ...customSolutionTiktok, ...customTracking, ...googleTagManager, ...localServiceAds, ...shoppingAds,
    ...siteRetargetingWithLookalikesGDN, ...yelp, ...youtube, ...sem, ...crmIntegrationAddon, ...csWazePilot,
    ...customCampaignLandingPages, ...enhancedReportingAddon, ...localServicesAdsManagementServices, ...nationalSem,
    ...performanceMaxBeta, ...website, ...premiumListingsTenPlusPhysicianDirectories, ...getCreativeServiceFee,
    ...customSolutionTwitter, ...promotionsPageAndCreativesReachLocalBetaOnly, ...promotionsMixMedia,
    ...ausTiktok, ...ausTotalTrack, ...ausLinkedin, ...ausCustomFacebook, ...ausPerformanceMax, ...ausYoutube,
    ...ausLiveChat, ...multiMediaCreatives, ...ausDemandGen, ...ausSite, ...ausVideoCreativeService, ...ausGoogleDisplayNetwork, ...ausPremiumListings1To9]
  .includes(product)) return BadgeOrange;
  if ([...dmsNextProducts, 'xmo', 'xmo-upgrade'].includes(product)) return BadgeBlue;
  return BadgeIcon;
};

const Badge = (product) => (
  <img src={setBadge(product)} alt="Badge"
    style={{
      width: '24px',
      height: '24px',
    }}
  />
);

const EnabledItem = ({ text }: { text: string }) => (
  <Box display="flex" flexDirection="row" alignItems="center"
    marginY={1}
  >
    <DoneIcon htmlColor="#00C806" />
    <Typography marginLeft={1}>{text}</Typography>
  </Box>
);

const useStyles = makeStyles(() => ({
  popper: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: 'white',
      color: 'gray',
      border: '1px solid lightgray',
      borderRadius: '8px',
      padding: '15px',
      fontSize: '14px',
      fontWeight: 'lighter',
    },
  },
}));

export default function BundlesSection({
  theme, selectedVariant, isBlue, partnerAccount,
}: Props) {
  const { t } = useTranslation();
  const themeStyles = useTheme();
  const { opportunityId, offerId } = useParams();
  const query = useQuery();
  const navigate = useNavigate();
  const navigationHandler = useNavigationHandler();
  const currentOrder = useSelector(selectOrdersContent);
  const bundles = useSelector(selectThemeBundles);
  const addons = useSelector(selectAllAddons);
  const cartItems = useSelector(selectCartItems);
  const selectedCountry = useSelector(selectCurrentCountry);
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const [addonsExpanded, setAddonsExpanded] = useState(false);
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);
  const [showAddonsModal, setShowAddonsModal] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const maxAddons = 10;
  const classes = useStyles();
  const quoteProducts = ['website - custom landing page', 'website - custom'];
  const disclaimerType = useMemo(() => {
    switch (theme.product) {
      case 'website - custom landing page':
        return 'landingPage';
      case 'premium listing':
        return 'premiumListings';
      default:
        return 'website';
    }
  }, [theme]);

  const queryAddon = query.get('addon');
  const isHealthcare = query.get('healthCare');
  const smmRadioValue = query.get('smmStandard');
  const xmoRadioValue = query.get('xmo');
  const xmoUpgradeRadioValue = query.get('xmo-upgrade');

  const checkBundle = () => {
    if (theme.product === 'smm-standard') return smmRadioValue || 1;
    if (theme.product === 'xmo') return xmoRadioValue || 1;
    if (theme.product === 'xmo-upgrade') return xmoUpgradeRadioValue || 1;
    return -1;
  };

  const checkForBlue = `?isBlue=${isBlue}`;

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      bundleId: checkBundle(),
      addons: [...Array(maxAddons)].map(() => ({ selected: false })),
    },
  });

  const {
    getValues, setValue, control, handleSubmit, reset,
  } = methods;

  useMemo(() => {
    if (isHealthcare) {
      setValue('bundleId', 2);
      setExpandedIndex(2);
    }
  }, [isHealthcare]);

  useEffect(() => {
    if (!theme || !theme.id) return;
    dispatch(getThemeBundles(theme.id));
    if (theme.product === 'master addons' && !opportunityId) {
      setAddonsExpanded(true);
      setDisableButton(true);
    }

    if (theme.product === 'smm-standard') {
      setValue('bundleId', (smmRadioValue || -1));
      setExpandedIndex(Number(smmRadioValue) || 1);
    }

    if (theme.product === 'xmo') {
      setValue('bundleId', (xmoRadioValue || 1));
    }

    if (theme.product === 'xmo-upgrade') {
      setValue('bundleId', (xmoUpgradeRadioValue || 1));
    }
  }, [theme]);

  useEffect(() => {
    if (theme.product !== 'website - gold' && addons.length > 0) {
      setValue('addons', addons.map(addon => ({ ...addon, selected: false })));
    }
  }, [theme, addons]);

  const selectBundle = (index) => {
    setExpandedIndex(index);
    const bundle = index !== -1 ? bundles[index] : { id: -1, addons: [] };
    addons.forEach((addon, index) => {
      setValue(`addons.${index}`, {
        ...addon,
        selected: bundle.addons.some(add => {
          if (addon.name === 'Email Hosting' && add.name === 'Email Hosting + Archiving') return true;
          if (addon.name === add.name) return true;
          return false;
        }),
      });
    });
    setValue('bundleId', bundle.id);
  };

  useEffect(() => {
    if (bundles?.length > 0) selectBundle(-1);
  }, [bundles]);

  const isAddonSelected = (addons, name) => {
    if (opportunityId) {
      if (mapAddons[queryAddon] === name) return true;
      // return cartAddons[mapAddonsToCart[name]] || false;
    }
    return addons.filter(e => e.name === name).length > 0;
  };

  const addItem = async (item, bundleId, selectedAddons) => {
    const content = {
      themeId: item.id,
      sourceId: null,
      productType: item.product,
      slug: null,
      tier: null,
      healthcare: (theme.product === 'premium listing' && bundleId === 2),
      salesforceId: offerId || null,
      isBlue,
      partnerAccount,
    };

    const mapCartItems = cartItems.content.map(item => item.productName);
    const masterAddons = 'master addons';
    const isMasterAddon = item.product === masterAddons;
    const url = partnerAccount ? `${Urls.ShoppingCart}?partnerAccount=${partnerAccount}` : Urls.ShoppingCart;
    const redirectToCart = opportunityId
      ? `${Urls.ShoppingCart}/${Urls.Cef}/${opportunityId}${checkForBlue}` : url;

    if (checkCartValidations({
      navigate, cartItems, redirectToCart, item, opportunityId,
    }) !== false) {
      return;
    }

    if (!isMasterAddon) {
      await appDispatch(dispatch(addItemToOrder({
        orderId: currentOrder.id,
        content: (bundleId !== -1 && !['premium listing', 'smm-standard', 'xmo', 'xmo-upgrade'].includes(theme.product))
          ? { ...content, bundleId } : content,
      }))).then(async (response: any) => {
        await dispatch(setOrderItemTypeOfSale({
          orderItemId: response.payload.id,
          content: TypeOfSaleConstants.NEW_SALE,
        }));
        navigate(!opportunityId
          ? url : `${Urls.ShoppingCart}/${Urls.Cef}/${opportunityId}${checkForBlue}`);
        if (['smm-standard', 'xmo', 'xmo-upgrade'].includes(theme.product)) {
          dispatch(putProduct({
            orderItemId: response.payload.id,
            content: { variant: bundleId !== -1 ? bundleId : 1 },
          }));
        }
      });
    }

    if (isMasterAddon) {
      if (!opportunityId || !mapCartItems.includes(siteMap[item.product])) {
        await appDispatch(dispatch(addItemToOrder({ orderId: currentOrder.id, content })))
          .then(async () => navigate(!opportunityId
            ? url : `${Urls.ShoppingCart}/${Urls.Cef}/${opportunityId}${checkForBlue}`));
      }
    }

    if (bundleId === -1 && selectedAddons.length > 0) {
      if (theme.product !== masterAddons) {
        await dispatch(addItemToOrder({
          orderId: currentOrder.id,
          content: {
            themeId: null,
            sourceId: null,
            productType: masterAddons,
            themeSlug: 'master-addons',
            slug: null,
            tier: null,
          },
        }));
      }
    }

    if (selectedAddons.length > 0 || opportunityId) {
      await appDispatch(dispatch(getOrderItems(currentOrder.id))).then(async (response) => {
        const orderItemId = response.payload.filter(item => item.offeringType === 'MasterAddon')[0]?.id;
        const resource = {
          expandableMenus: isAddonSelected(selectedAddons, 'Expandable Menus'),
          paymentForms: isAddonSelected(selectedAddons, 'Payment Forms'),
          popups: isAddonSelected(selectedAddons, 'Pop-up'),
          videoBackground: isAddonSelected(selectedAddons, 'Video Background'),
        };

        await dispatch(updateCartAddonsSelection({ orderItemId, resource }));
        if (item.product === 'master addons' && mapCartItems.includes(siteMap[item.product]) && opportunityId) {
          await dispatch(updateCartAddons(resource as any));
          navigate(`${Urls.ShoppingCart}/${Urls.Cef}/${opportunityId}${checkForBlue}`);
        }
      });
    }
  };

  const addToCart = ({ bundleId, addons }) => {
    setShowDisclaimerModal(false);
    const selectedAddons = addons.filter(addon => (addon?.name && addon.selected));
    const isSilver = theme.product === 'website - standard';

    addItem(isSilver ? (selectedVariant || theme) : theme, bundleId, selectedAddons);
  };

  const deleteItem = (orderItemId) => dispatch(removeCartItem(orderItemId));

  const requestQuote = async () => {
    if (cartItems.content.length > 0) {
      const goldSite = cartItems.content.filter(item => item.productName === 'GoldSite');
      if (goldSite.length > 0) await deleteItem(goldSite[0].orderItem.id);
      await cartItems.content.forEach(item => deleteItem(item.orderItem.id));
    }
    await dispatch(requestNewOrder);
    setShowDisclaimerModal(false);
    await dispatch(addItemToOrder({
      orderId: currentOrder.id,
      content: {
        themeId: theme.id,
        sourceId: null,
        productType: theme.product,
        slug: null,
        tier: null,
        healthcare: false,
      },
    }));

    await dispatch(startOrder({ orderId: currentOrder.id, orderFlow: 'INTERNAL' }));
    navigationHandler.to(`${currentOrder.id}/businesses/search`);
  };

  const updateButton = () => {
    setDisableButton((theme.product === 'master addons'
      && getValues('addons').filter((addon: any) => (addon?.name && addon.selected)).length === 0));
  };

  const onSubmit = (data) => {
    if (theme.product === 'premium listing' && data.bundleId === 2) {
      setShowDisclaimerModal(true);
    } else {
      addToCart(data);
    }
  };

  const handleCartButton = () => ((['website - gold', 'website - standard']
    .includes(theme.product) && !opportunityId) ? setShowAddonsModal(true) : onSubmit(getValues()));

  const renderAddons = (addons?.length > 0
    && (['website - standard', 'website - gold'].includes(theme.product))) && (
      <Grid container>
        <Grid item xs={12}>
          <Divider sx={{ marginY: 2, borderColor: 'black' }} />
        </Grid>
        <Grid item xs={10}>
          <Typography fontWeight="400" fontSize="18px">Discover more features with Add-ons</Typography>
        </Grid>

        {(['website - standard', 'website - gold'].includes(theme.product)) && (
          <>
            <Grid item xs={2}>
              <IconButton sx={{ color: `${themeStyles.palette.primary.main}`, marginLeft: '13px' }}
                onClick={() => setAddonsExpanded(!addonsExpanded)}
              >
                {addonsExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Grid>
            <Grid item xs={12} marginTop={2}>
              <Link fontSize="14px" href="https://orders.localiq.com/ourThemes/detail?id=179" target="_blank">
                <Typography>Learn more</Typography>
              </Link>
            </Grid>
          </>
        )}
        <Collapse in={addonsExpanded} sx={{ marginTop: 2 }}>
          <FormGroup>
            {(['website - standard', 'website - gold'].includes(theme.product)) && addons.map((addon, index) => (
              <FormControlLabel key={addon.id} control={(
                <Controller
                  name={`addons.${index}.selected`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox
                      onChange={e => { onChange(e.target.checked); updateButton(); }}
                      checked={value}
                    />
                  )}
                />
                )}
                label={(
                  <Tooltip
                    title={(
                      <>
                        {addon.description}
                        {addon.exampleUrl && (
                          <Link
                            sx={{ textDecoration: 'none', marginLeft: 1 }}
                            href={addon.exampleUrl} target="_blank"
                          >
                            View Example
                          </Link>
                        )}
                      </>
                    )}
                    classes={{ popper: classes.popper }}
                    arrow
                  >
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <Typography fontWeight="light"
                        sx={{ color: theme.product !== 'master addons' ? 'text.primary' : 'text.secondary' }}
                      >
                        {addon.name}
                      </Typography>
                      {theme.product !== 'master addons' && (
                        <InfoIcon color="primary" sx={{ marginLeft: 1, fontSize: '15px' }} />
                      )}
                    </Box>
                  </Tooltip>
                )}
              />
            ))}
          </FormGroup>
        </Collapse>
      </Grid>
  );

  const renderBundles = bundles?.length > 0 && (
    <RadioGroup name="bundleId" defaultValue={theme.product !== 'website - gold' ? bundles[0].id : -1}>
      {theme.product !== 'website - gold' && (
        <FormControlLabel onClick={() => selectBundle(-1)}
          key={-1} value={-1} control={<Radio />}
          label={(
            <Typography fontSize="20px" fontWeight="500"
              display="flex" alignItems="center" justifyContent="space-between"
              flexGrow={1} sx={{ color: '#142160' }}
            >
              Core
            </Typography>
          )}
        />
      )}
      {theme.product !== 'website - gold' && bundles.map((bundle, index) => (
        <>
          <FormControlLabel onClick={() => selectBundle(index)} sx={{ marginRight: '0' }}
            key={bundle.id} value={bundle.id} control={<Radio />}
            label={(
              <Typography fontSize="20px" fontWeight="400"
                display="flex" alignItems="center" justifyContent="space-between"
                flexGrow={1} sx={{ color: '#142160' }}
              >
                {bundle.name}
                {(bundle.addons?.length > 0 || bundle.features?.length > 0) && (
                  <IconButton sx={{ color: `${themeStyles.palette.primary.main}` }}>
                    {expandedIndex === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                )}
              </Typography>
            )}
          />
          {(bundle.addons?.length > 0 || bundle.features?.length > 0)
            && (
              <Collapse in={expandedIndex === index} sx={{ paddingLeft: 4 }}>
                {bundle.addons?.length > 0 && (
                  <>
                    <Typography marginTop={1} fontWeight="bold">Included Add-ons</Typography>
                    {bundle.addons.map(addon => (
                      <EnabledItem text={addon.name} key={addon.id} />
                    ))}
                  </>
                )}
                {bundle.products?.length > 0 && (
                  <>
                    <Typography marginTop={2} fontWeight="bold">Included Products</Typography>
                    {bundle.products.map(product => (
                      <EnabledItem
                        text={product.productType === 'e-commerce microsite'
                          ? 'E-Commerce Microsite (10 Products)'
                          : product.name}
                        key={product.id}
                      />
                    ))}
                  </>
                )}
              </Collapse>
            )}
        </>
      ))}
    </RadioGroup>
  );

  const getProductName = () => {
    const bundleName = query.get('bundle');
    const addonName = query.get('addon');

    if (bundleName) return `${productNameMap[theme.product]} - ${bundleName}`;
    if (addonName) return mapAddons[addonName];
    return productNameMap[theme.product];
  };

  return (
    <FormProvider {...methods}>
      {!quoteProducts.includes(theme.product) && (
        <Box paddingX={4} paddingY={5} sx={{ border: '1px solid lightgray', borderRadius: '7px' }}>
          <Box display="flex" flexDirection="row" alignItems="center"
            marginBottom={3}
          >
            {Badge(theme.product)}
            <Typography fontWeight="400" fontSize="22px" marginLeft={1}>
              {getProductName()}
            </Typography>
          </Box>
          {opportunityId && (
            <Box mb={3}>
              <Typography>
                {theme.product === 'premium listing' && t('pages.opportunity.themeDetails.premiumListingsMessage')}
              </Typography>
            </Box>
          )}
          {theme.product === 'xmo' && (
            <Box mb={3}>
              <Typography>
                {t('pages.xmo.themeDetails.description')}
              </Typography>
            </Box>
          )}
          {theme.product === 'xmo-upgrade' && (
            <Box mb={3}>
              <Typography>
                {t('pages.xmo.themeDetails.description')}
              </Typography>
            </Box>
          )}
          {renderPL({
            theme,
            isHealthcare,
            opportunityId,
            setValue,
            setExpandedIndex,
            expandedIndex,
            selectedCountry,
          })}

          {renderSMM({
            theme,
            opportunityId,
            reset,
            expandedIndex,
            setExpandedIndex,
            smmRadioValue,
          })}

          {renderXMO({
            theme,
            opportunityId,
            reset,
            expandedIndex,
            setExpandedIndex,
            xmoRadioValue,
          })}
          {renderXMOUpgrade({
            theme,
            opportunityId,
            reset,
            expandedIndex,
            setExpandedIndex,
            xmoUpgradeRadioValue,
          })}
          {renderBundles}
          {renderAddons}
          <Box flexGrow={1} marginTop={3}>
            <Button variant="contained" fullWidth sx={{ fontSize: '22px' }}
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              onClick={(_e: any) => handleCartButton()}
              disabled={disableButton}
            >
              Add to Cart
            </Button>
          </Box>
        </Box>
      )}
      {quoteProducts.includes(theme.product) && (
        <Box flexGrow={1} marginTop={3}>
          <Button variant="contained" fullWidth sx={{ fontSize: '22px' }}
            onClick={() => setShowDisclaimerModal(true)}
          >
            Request Quote
          </Button>
        </Box>
      )}
      <ThemeDisclaimerModal
        open={showDisclaimerModal}
        handleClose={() => setShowDisclaimerModal(false)}
        handleContinue={() => {
          if (theme.product === 'premium listing') addToCart(getValues()); else requestQuote();
        }}
        type={disclaimerType}
      />
      <AddonsDisclaimerModal
        open={showAddonsModal}
        handleClose={() => setShowAddonsModal(false)}
        handleContinue={handleSubmit(onSubmit)}
      />
    </FormProvider>
  );
}
