/* eslint-disable no-param-reassign */
import _ from 'lodash';
import { createAction, createReducer } from '@reduxjs/toolkit';
import { StatusType } from 'models/StatusType';
import { BusinessLocation } from 'models/BusinessType';
import AxiosAPI, { AxiosCobaltAPI } from './common/axios';
import { thunkBuilder } from './common/functions';
import { createAsyncThunkCatchErrors } from './errorSlice';
import { WebsiteOfferingTypes } from './websitesSlice';
import { RootState } from './redux/store';

export interface BusinessLocationState {
  locations: StatusType<BusinessLocation[]>
  currentLocation: StatusType<BusinessLocation>
  phoneNumbers: StatusType<any>
  contact: StatusType<any>
  hours: StatusType<any>
  specialHours: StatusType<any>
  logo_attachments: StatusType<any>
  additional: StatusType<any>
  address: StatusType<any>
  serviceAreas: StatusType<any>
  provider: StatusType<any>
  providers: StatusType<any>
  currentProviderCategories: StatusType<any>
  providerCategories: StatusType<any>
}

const initialState: BusinessLocationState = {
  locations: {
    status: 'idle',
    message: '',
    content: [],
  },
  currentLocation: {
    status: 'idle',
    message: '',
    content: {},
  },
  phoneNumbers: {
    status: 'idle',
    message: '',
    content: [],
  },
  contact: {
    status: 'idle',
    message: '',
    content: {},
  },
  hours: {
    status: 'idle',
    message: '',
    content: {},
  },
  specialHours: {
    status: 'idle',
    message: '',
    content: [],
  },
  logo_attachments: {
    status: 'idle',
    message: '',
    content: [],
  },
  additional: {
    status: 'idle',
    message: '',
    content: {},
  },
  address: {
    status: 'idle',
    message: '',
    content: {},
  },
  serviceAreas: {
    status: 'idle',
    message: '',
    content: [],
  },
  provider: {
    status: 'idle',
    message: '',
    content: {},
  },
  providers: {
    status: 'idle',
    message: '',
    content: [],
  },
  currentProviderCategories: {
    status: 'idle',
    message: '',
    content: [],
  },
  providerCategories: {
    status: 'idle',
    message: '',
    content: [],
  },
};

export const getBusinessLocations = createAsyncThunkCatchErrors(
  'businessLocations/get',
  async (businessId: string) => {
    const response = await AxiosAPI.get(`/business/${businessId}/locations`);
    return response.data.content;
  },
);

export const getBusinessLocation = createAsyncThunkCatchErrors(
  'businessLocation/address/get',
  async (locationId: number) => {
    const response = await AxiosAPI.get(`/business/locations/${locationId}`);
    return response.data;
  },
);

export const getBusinessLocationServiceAreas = createAsyncThunkCatchErrors(
  'businessLocation/serviceAreas/get',
  async (locationId: number) => {
    const response = await AxiosAPI.get(`/business/locations/${locationId}/service-area`);
    return response.data;
  },
);

export const updateBusinessLocationServiceAreas = createAsyncThunkCatchErrors(
  'businessLocation/serviceAreas/update',
  async ({
    locationId, businessLocationServiceAreaList,
  } : {
    locationId: number, businessLocationServiceAreaList: any,
  }) => {
    const response = await AxiosAPI.put(
      `/business/locations/${locationId}/service-area`,
      businessLocationServiceAreaList,
    );
    return response.data;
  },
);

export const deleteBusinessLocation = createAsyncThunkCatchErrors(
  'businessLocations/delete',
  async (id: string) => {
    const response = await AxiosAPI.delete(`/business/locations/${id}`);
    return response.data.content;
  },
);

export const createBusinessLocation = createAsyncThunkCatchErrors(
  'businessLocations/create',
  async ({ businessId, defaultLocationToCreate }) => {
    const response = await AxiosAPI.post(`/business/${businessId}/locations`, defaultLocationToCreate);
    return response.data.content;
  },
);

export const updateBusinessLocation = createAsyncThunkCatchErrors(
  'businessLocations/create',
  async ({ businessId, resource } : { businessId: string, resource: any }) => {
    const response = await AxiosAPI.put(`/business/${businessId}/locations`, resource);
    return response.data.content;
  },
);

export const updateSingleBusinessLocation = createAsyncThunkCatchErrors(
  'businessLocations/create',
  async (resource) => {
    const response = await AxiosAPI.put('/business/locations', resource);
    return response.data.content;
  },
);

// update contact information
export const updateContactInformation = createAsyncThunkCatchErrors(
  'businessLocations/contact/update',
  async ({ locationId, resource } : { locationId: string, resource: any }) => {
    const response = await AxiosAPI.put(`/business/locations/${locationId}/contact`, resource);
    return response.data.content;
  },
);

// update location information
export const updateLocationInfo = createAsyncThunkCatchErrors(
  'businessLocations/info/update',
  async ({ locationId, resource } : { locationId: string, resource: any }) => {
    const response = await AxiosAPI.put(`/business/locations/${locationId}/info`, resource);
    return response.data.content;
  },
);

// update location logo
export const updateLocationLogo = createAsyncThunkCatchErrors(
  'businessLocations/logo/update',
  async ({ locationId, resource } : { locationId: string, resource: any }) => {
    const response = await AxiosAPI.put(`/business/locations/${locationId}/logos`, resource);
    return response.data.content;
  },
);

// update location photo
export const updateLocationPhoto = createAsyncThunkCatchErrors(
  'businessLocations/photo/update',
  async ({ locationId, resource } : { locationId: string, resource: any }) => {
    const response = await AxiosAPI.put(`/business/locations/${locationId}/media/photos`, resource);
    return response.data.content;
  },
);

// update location notes
export const updateLocationNotesAndOpening = createAsyncThunkCatchErrors(
  'businessLocations/notes/update',
  async ({ locationId, resource } : { locationId: string, resource: any }) => {
    const response = await AxiosAPI.put(`/business/locations/${locationId}/notesAndOpening`, resource);
    return response.data.content;
  },
);

// get location phones
export const getLocationPhones = createAsyncThunkCatchErrors(
  'businessLocations/phones/get',
  async (locationId: number) => {
    const response = await AxiosAPI.get(`/business/locations/${locationId}/phones`);
    return response.data;
  },
);

// update location status to complete
export const updateLocationStatus = createAsyncThunkCatchErrors(
  'businessLocations/status/update',
  async ({ locationId } : { locationId: string }) => {
    const response = await AxiosAPI.put(`/business/locations/${locationId}/status/complete`);
    return response.data.content;
  },
);

// update location status to not complete
export const updateLocationStatusToFalse = createAsyncThunkCatchErrors(
  'businessLocations/status/updateFalse',
  async ({ locationId } : { locationId: string }) => {
    const response = await AxiosAPI.put(`/business/locations/${locationId}/status/not-complete`);
    return response.data.content;
  },
);

// update location brand
export const updateLocationBrand = createAsyncThunkCatchErrors(
  'businessLocations/brand/update',
  async ({ locationId, resource } : { locationId: string, resource: any }) => {
    const response = await AxiosAPI.put(`/business/locations/${locationId}/brands`, resource);
    return response.data.content;
  },
);

// update location competitors
export const updateLocationCompetitors = createAsyncThunkCatchErrors(
  'businessLocations/competitors/update',
  async ({ locationId, resource } : { locationId: string, resource: any }) => {
    const response = await AxiosAPI.put(`/business/locations/${locationId}/competitors`, resource);
    return response.data.content;
  },
);

// update location demographics
export const updateLocationDemographics = createAsyncThunkCatchErrors(
  'businessLocations/demographics/update',
  async ({ locationId, resource } : { locationId: string, resource: any }) => {
    const response = await AxiosAPI.put(`/business/locations/${locationId}/demographics`, resource);
    return response.data.content;
  },
);

// update location languages
export const updateLocationLanguages = createAsyncThunkCatchErrors(
  'businessLocations/languages/update',
  async ({ locationId, resource } : { locationId: string, resource: any }) => {
    const response = await AxiosAPI.put(`/business/locations/${locationId}/languages`, resource);
    return response.data.content;
  },
);

// update location payments
export const updateLocationPayments = createAsyncThunkCatchErrors(
  'businessLocations/payments/update',
  async ({ locationId, resource } : { locationId: string, resource: any }) => {
    const response = await AxiosAPI.put(`/business/locations/${locationId}/payments`, resource);
    return response.data.content;
  },
);

// update location services
export const updateLocationServices = createAsyncThunkCatchErrors(
  'businessLocations/services/update',
  async ({ locationId, resource } : { locationId: string, resource: any }) => {
    const response = await AxiosAPI.put(`/business/locations/${locationId}/services`, resource);
    return response.data.content;
  },
);

// create location hours
export const createLocationHours = createAsyncThunkCatchErrors(
  'businessLocations/hours/create',
  async ({ locationId, resource } : { locationId: string, resource: any }) => {
    const response = await AxiosAPI.post(`/business/locations/${locationId}/hours`, resource);
    return response.data.content;
  },
);

// update location hours
export const updateLocationHours = createAsyncThunkCatchErrors(
  'businessLocations/hours/update',
  async ({ locationId, businessHoursTotalRequest } : { locationId: string, businessHoursTotalRequest: any }) => {
    const response = await AxiosAPI.put(`/business/locations/${locationId}/hours`, businessHoursTotalRequest);
    return response.data.content;
  },
);

// get location special hours
export const getLocationSpecialHours = createAsyncThunkCatchErrors(
  'businessLocations/specialHours/get',
  async (locationId: number) => {
    const response = await AxiosAPI.get(`/business/locations/${locationId}/specialHours`);
    return response.data;
  },
);

// create location special hours
export const createLocationSpecialHours = createAsyncThunkCatchErrors(
  'businessLocations/specialHours/create',
  async ({ locationId, resource } : { locationId: string, resource: any }) => {
    const response = await AxiosAPI.post(`/business/locations/${locationId}/specialHours`, resource);
    return response.data.content;
  },
);

// update location special hours
export const updateLocationSpecialHours = createAsyncThunkCatchErrors(
  'businessLocations/specialHours/update',
  async ({ locationId, resource } : { locationId: string, resource: any }) => {
    const response = await AxiosAPI.put(`/business/locations/${locationId}/specialHours`, resource);
    return response.data.content;
  },
);

// create or update provider
export const createOrUpdateProvider = createAsyncThunkCatchErrors(
  'businessLocations/provider/update',
  async ({ locationId, resource } : { locationId: string, resource: any }) => {
    const response = await AxiosAPI.put(`/providers/${locationId}/providers&categories`, resource);
    return response.data.content;
  },
);

// GET provider
export const getProvider = createAsyncThunkCatchErrors(
  'businessLocations/provider/get',
  async (locationId: number) => {
    const response = await AxiosAPI.get(`/providers/${locationId}`);
    return response.data;
  },
);

// update location videos
export const updateLocationVideos = createAsyncThunkCatchErrors(
  'businessLocations/videos/update',
  async ({ locationId, resource } : { locationId: string, resource: any }) => {
    const response = await AxiosAPI.put(`/business/locations/${locationId}/media/videos`, resource);
    return response.data.content;
  },
);

// update video same for all
export const updateLocationVideoSameForAll = createAsyncThunkCatchErrors(
  'businessLocations/videos/same',
  async ({ locationId, sameVideo } : { locationId: string, sameVideo: boolean }) => {
    const response = await AxiosAPI.post(
      `/business/locations/${locationId}/media/videos/same`,
      sameVideo,
      { headers: { 'Content-Type': 'application/json' } },
    );
    return response.data.content;
  },
);

// update fulfilled attributes for the premium listing locations
export const updateLocationFulfilledAttribute = createAsyncThunkCatchErrors(
  'businessLocations/fulfilled/update',
  // eslint-disable-next-line max-len
  async ({ locationId, fulfilledAttribute, value } : { locationId: string, fulfilledAttribute: string, value?: string }) => {
    const response = await AxiosAPI.put(
      `/business/locations/${locationId}/fulfilled/${fulfilledAttribute}`,
      value,
      { headers: { 'Content-Type': 'text/plain' } },
    );
    return response.data;
  },
);

// update provider categories for the premium listings healthcare
export const updateProviderCategories = createAsyncThunkCatchErrors(
  'businessLocations/providerCategories/update',
  async ({ providerId, resource } : { providerId: string, resource: any }) => {
    const response = await AxiosAPI.put(`/providers/${providerId}/providers-categories`, resource);
    return response.data.content;
  },
);

export const getProviderCategories = createAsyncThunkCatchErrors(
  'businessLocations/providerCategories/get',
  async (providerId: number) => {
    const response = await AxiosAPI.get(`/providers/${providerId}/providers-categories`);
    return response.data;
  },
);

// List all the provider categories for the premium listings healthcare
export const listProviderCategories = createAsyncThunkCatchErrors(
  'businessLocations/providerCategories/list',
  async () => {
    const response = await AxiosAPI.get('/providers/providers-categories');
    return response.data;
  },
);

const FileUploadProductType = {
  Website: 'Website',
  GoldSite: 'Gold Site',
  EcommerceMicrosite: 'E-Commerce Microsite',
  LandingPage: 'Custom Landing Page Quote',
  PremiumListing: 'Premium Listings',
} as const;

export const uploadPhotos = createAsyncThunkCatchErrors(
  'businessLocations/files/post',
  async ({
    websiteId, files, uploaderType, offeringType,
  }
  : {
    websiteId: string | number,
    files: any,
    uploaderType: string,
    offeringType: WebsiteOfferingTypes
  }) => {
    const uploadedFilesPromises = files.map(async file => {
      const formData = new FormData();
      formData.set('bucketName', process.env.REACT_APP_BUCKET_NAME || '');
      formData.set('directory', `${websiteId}`);
      formData.set('files', file);
      const filesResponse = await AxiosAPI.post(
        '/files/upload',
        formData,
        { headers: { 'Content-type': 'multipart/form-data' } },
      );
      const fileName = Object.keys(filesResponse.data)[0];
      const fileElement = Object.values(filesResponse.data)[0][0];
      const uploadInfo = {
        fileName,
        fileSize: file.size,
        productId: websiteId,
        productType: FileUploadProductType[offeringType],
        uploaderType,
        url: fileElement.url,
      };
      const uploadResponse = await AxiosAPI.post(
        'upload',
        uploadInfo,
      );
      return { ...uploadResponse.data, mediaType: 'images' };
    });
    return Promise.all(uploadedFilesPromises);
  },
);

export const deleteFile = createAsyncThunkCatchErrors(
  'businessLocations/files/delete',
  async ({ websiteId, file, uploaderType } : { websiteId: string | number, file: any, uploaderType: string }) => {
    const splittedUrl = file.url ? file.url.split('/') : [''];
    const fileName = splittedUrl[splittedUrl.length - 1];
    AxiosAPI.delete(`files?bucketName=&directory=${websiteId}&files=${fileName}`);

    const response = await AxiosAPI.delete(`/upload/${file.id}`);
    const id = response.status === 200 ? file.id : null;
    return { id, uploaderType };
  },
);

export const copyLocation = createAsyncThunkCatchErrors(
  'location/copy',
  async ({ locationId, targetId } : { locationId: string, targetId: string }) => {
    const response = await AxiosCobaltAPI.post(`/locations/${locationId}/copy`, { target_id: targetId });
    return response.data;
  },
);

export const setAddress = createAction<any>('businessLocation/address/set');
export const setContact = createAction<any>('businessLocation/contact/set');
export const setHours = createAction<any>('businessLocation/hours/set');
export const setSpecialHours = createAction<any>('businessLocation/specialHours/set');
export const setPhotos = createAction<any>('businessLocation/photos/set');
export const setAdditional = createAction<any>('businessLocation/additional/set');
export const setServiceAreas = createAction<any>('businessLocation/serviceAreas/set');
export const setLocations = createAction<any>('healthcare/locations/set');
export const setProvider = createAction<any>('healthcare/provider/set');
export const setProviders = createAction<any>('healthcare/providers/set');
export const setCurrentProviderCategories = createAction<any>('healthcare/currentCategories/set');

export const businessLocationSlice = createReducer(
  initialState,
  (builder) => {
    thunkBuilder(builder)
      .addCase(getBusinessLocation, 'currentLocation')
      .addCase(getBusinessLocationServiceAreas, 'serviceAreas')
      .addCase(getLocationPhones, 'phoneNumbers')
      .addCase(getLocationSpecialHours, 'specialHours')
      .addCase(getProvider, 'provider')
      .addCase(getProviderCategories, 'currentProviderCategories')
      .addCase(listProviderCategories, 'providerCategories');

    builder
      .addCase(getBusinessLocations.fulfilled, (state, action) => {
        const allLocations = action.payload.constructor === Array ? action.payload : action.payload.locations;
        const loc = allLocations.filter(e => e.isProvider !== true);
        const prov = allLocations.filter(e => e.isProvider === true);
        state.locations.content = loc;
        state.providers.content = prov;
      })
      .addCase(setLocations, (state, action) => {
        state.locations.content = action.payload;
      })
      .addCase(setProviders, (state, action) => {
        state.providers.content = action.payload;
      })
      .addCase(setProvider, (state, action) => {
        state.provider.content = action.payload;
      })
      .addCase(setCurrentProviderCategories, (state, action) => {
        state.currentProviderCategories.content = action.payload;
      })
      .addCase(createBusinessLocation.fulfilled, (state, action) => {
        state.address.content = action.payload;
      })
      .addCase(setContact, (state, action) => {
        state.contact.content = action.payload;
      })
      .addCase(setHours, (state, action) => {
        state.hours.content = action.payload;
      })
      .addCase(setSpecialHours, (state, action) => {
        state.specialHours.content = action.payload;
      })
      .addCase(setPhotos, (state, action) => {
        state.logo_attachments.content = action.payload;
      })
      .addCase(setAdditional, (state, action) => {
        state.additional.content = action.payload;
      })
      .addCase(setServiceAreas, (state, action) => {
        state.serviceAreas.content = action.payload;
      })
      .addCase(uploadPhotos.pending, (state, action) => {
        const { uploaderType } = action.meta.arg;
        state[uploaderType].status = 'loading';
      })
      .addCase(uploadPhotos.fulfilled, (state, action) => {
        const [{ uploaderType }] = action.payload;
        state[uploaderType].content = _.unionBy(state[uploaderType].content, action.payload, 'id');
        state[uploaderType].status = 'idle';
      })
      .addCase(deleteFile.fulfilled, (state, action) => {
        const { id } = action.payload;
        state.logo_attachments.content = state.logo_attachments.content.filter(f => f.id !== id);
      });
  },
);

export const selectBusinessLocations = (state: RootState) => state.businessLocation.locations.content;
export const selectBusinessLocationsWithStatus = (state: RootState) => state.businessLocation.locations;
export const selectCurrentLocation = (state: RootState) => state.businessLocation.currentLocation.content;
export const selectBusinessLocationServiceAreas = (state: RootState) => state.businessLocation.serviceAreas;
export const selectPhoneNumbers = (state: RootState) => state.businessLocation.phoneNumbers.content;

// remove later
export const selectAddress = (state: RootState) => state.businessLocation.address.content;
export const selectContact = (state: RootState) => state.businessLocation.contact.content;
export const selectHours = (state: RootState) => state.businessLocation.hours.content;
export const selectSpecialHours = (state: RootState) => state.businessLocation.specialHours.content;
export const selectPhotos = (state: RootState) => state.businessLocation.logo_attachments;
export const selectAdditional = (state: RootState) => state.businessLocation.additional.content;
export const selectProvider = (state: RootState) => state.businessLocation.provider.content;
export const selectProviders = (state: RootState) => state.businessLocation.providers.content;
export const selectCurrentProviderCategories = (state: RootState) =>
  state.businessLocation.currentProviderCategories.content;
export const selectProviderCategories = (state: RootState) => state.businessLocation.providerCategories.content;

export default businessLocationSlice;
