import { useEffect, useState } from 'react';
import { Typography, Box, Link } from '@mui/material';
import GridItem from 'components/grid/GridItem';
import { useParams } from 'react-router';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { Urls } from 'navigation/Urls';
import { useAppDispatch } from 'services/redux/store';
import { getExpandableMenu } from 'services/addons/addonsSlice';
import { renderStatusPill } from 'pages/orders/OrdersTable';
import { editButton, displayNotRequiredField } from 'pages/overview/Overview';
import {
  ProductTypes, getFiles, getGettyAssets, setFiles,
} from 'services/fileUploaderSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrderItems } from 'services/ordersSlice';
import { headingStyle } from '../Business/BusinessSection';
import { actionItems } from '../common/actionItems';
import { findAddonId } from './masterAddonSection';

export default function ExpandableMenuSection({
  product, isEditable, showActionItems, openBusinessAndSalesAgent,
}: any) {
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const navigate = useNavigationHandler();
  const { orderId } = useParams();
  const orderItems = useSelector(selectOrderItems);
  const [expandableMenusData, setExpandableMenusData] = useState({} as any);
  const [stockAssets, setStockAssets] = useState([] as any);
  const [addonStatus, setAddonStatus] = useState('');
  const [existingAssets, setExistingAssets] = useState([] as any);
  const addonName = 'expandableMenu';

  useEffect(() => {
    if (orderId) {
      appDispatch(getExpandableMenu(orderId)).then((response) => setExpandableMenusData(response.payload));
    }
  }, [orderId]);

  const getAssets = () => {
    appDispatch(getGettyAssets({ orderId, orderItemId: product?.id, productType: ProductTypes.EXPANDABLE_MENU }))
      .then((response) => setStockAssets(response.payload));

    appDispatch(getFiles({
      websiteId: product?.offeringId,
      uploaderType: ProductTypes.EXPANDABLE_MENU,
      offeringType: 'MasterAddon',
    })).then((response) => setExistingAssets(response.payload));
  };

  useEffect(() => {
    if (expandableMenusData?.id) getAssets();
  }, [expandableMenusData]);

  useEffect(() => {
    if (orderItems?.content?.length && addonName) {
      const currentAddon = orderItems.content
        .filter((item) => item.offeringType?.toLowerCase() === addonName?.toLowerCase());
      if (currentAddon.length > 0) setAddonStatus(currentAddon[0].status);
    }
  }, [orderItems]);

  return (
    <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid #000000' }}>
      <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
        Expandable Menus
        {!isEditable && renderStatusPill(addonStatus)}
      </Typography>

      <Box>

        {/* Expandable Menus */}
        <Box sx={{ display: 'flex', margin: '10px 0' }}>
          {editButton(isEditable, async () => {
            await dispatch(setFiles([]));
            navigate.to(
              Urls.ExpandableMenus,
              product.id,
            );
          })}
          <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Expandable Menus Details</Typography>
        </Box>
        <GridItem sizes={[6, 6]} sx={{ wordWrap: 'break-word' }}>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Include Images</Typography>
              <Typography sx={{ textTransform: 'capitalize' }}>{expandableMenusData?.includeImages}</Typography>
            </Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Stock Assets</Typography>
              {stockAssets?.length
                ? stockAssets.map((asset, index) => (
                  <Box key={asset.url} mb={1}>
                    <Link href={asset.url}>
                      <Typography>{`Stock Asset ${index + 1}`}</Typography>
                    </Link>
                    {asset?.notes
                      ? <Typography>{`Comments: ${asset.notes}`}</Typography>
                      : <Typography fontWeight="bold">No comments</Typography>}
                  </Box>
                ))
                : <Typography fontWeight="bold">No stock assets</Typography>}
            </Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Uploads</Typography>
              {existingAssets?.length
                ? existingAssets.map((asset) => (
                  <Box key={asset.url} mb={1}>
                    <Link href={asset.url}>
                      <Typography>{asset.fileName}</Typography>
                    </Link>
                    {asset?.notes
                      ? <Typography>{`Comments: ${asset.notes}`}</Typography>
                      : <Typography fontWeight="bold">No comments</Typography>}
                  </Box>
                ))
                : <Typography fontWeight="bold">No uploads</Typography>}
            </Box>
          </Box>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Menu Requirements</Typography>
              <Typography>{expandableMenusData?.menuRequirements}</Typography>
            </Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Additional Notes/Instructions</Typography>
              {displayNotRequiredField('additional notes/instructions', expandableMenusData?.additionalNotes)}
            </Box>
          </Box>
        </GridItem>

        {/* Actions items */}
        {showActionItems && actionItems(false, findAddonId(orderItems, addonName), false, openBusinessAndSalesAgent)}
      </Box>
    </GridItem>
  );
}
