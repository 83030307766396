/* eslint-disable no-param-reassign */
import {DefaultPageLayout} from 'layouts/DefaultPageLayout';
import GridItem from 'components/grid/GridItem';
import GridContainer from 'components/grid/GridContainer';
import {
  IQFormInput,
  IQFormTextArea,
  IQTheme, IQThemeV2,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import {
  Typography,
  RadioGroup,
  FormControlLabel, Radio
} from '@mui/material';
import {FormProvider, useForm} from 'react-hook-form';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import schema from './XmoChat.schema';
import {useTranslation} from "react-i18next";
import schemaValidate from "components/schemaValidate";
import React, {useState} from "react";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import NePhoneInput from "components/NePhoneInputContainer/IQPhoneInputContainer";
import {yesNoRadioOptions} from "pages/xmo/tactics/chat/XmoChatService";

interface Props {
  product: any;
  onSubmit: (data: any) => void;
  onBack: () => void;
}

export type XmoChatProps = {
  enableSmartChatbotSilver: string;
  leadDeliveryEmails: string;
  companyUrl: string;
  localBusinessPhoneNumber: string;
};

export default function XmoChatForm({product, onSubmit, onBack}: Props) {
  const {t} = useTranslation();
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      enableSmartChatbotSilver: product?.enableSmartChatbotSilver || '',
      leadDeliveryEmails: product?.leadDeliveryEmails || '',
      companyUrl: product?.companyUrl || '',
      localBusinessPhoneNumber: product?.localBusinessPhoneNumber || '',
    },
  });

  const handleFormSubmit = (data: any) => {
    const pageFormData = {
      chat: data
    };
    onSubmit(pageFormData);
  };

  const {
    handleSubmit,
    register,
    formState: {
      isValid,
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
    },
  } = methods;

  const [isEnableSmartChatbotSilver, setIsEnableSmartChatbotSilver] = useState(product?.enableSmartChatbotSilver || '');

  return (
      <DefaultPageLayout
          disableContinue={!isValid}
          onBack={onBack}
          header={t('pages.xmo.tactics.chatbotSilver.title')}
          onContinue={handleSubmit(handleFormSubmit)}
      >
        <ValidationProvider schema={schema}>
          <FormProvider {...methods}>
            <GridContainer fullWidth>
              <GridItem sizes={[12]}>
                <Typography
                    sx={{fontWeight: 'bold'}}>{t('pages.xmo.tactics.chatbotSilver.enableSmartChatbotSilverLabel')}
                  <RadioGroup
                      id='enableSmartChatbotSilver'
                      name='enableSmartChatbotSilver'
                      defaultValue={product?.enableSmartChatbotSilver || ''}
                      onChange={(e: any) => setIsEnableSmartChatbotSilver(e.target.value)}
                      row
                  >
                    {yesNoRadioOptions.map(opt => (
                        <FormControlLabel
                            key={opt.label}
                            control={<Radio value={opt.label}/>}
                            label={opt.label}
                            {...register('enableSmartChatbotSilver', {
                              validate: (value) => schemaValidate(value, 'enableSmartChatbotSilver', schema),
                            })}
                        />
                    ))}
                  </RadioGroup>
                </Typography>
              </GridItem>

              {isEnableSmartChatbotSilver === t('pages.xmo.reach.useAutomaticPlacementOnAdsOptions.yes') && (
                  <GridItem sizes={[12]}>
                    <Typography variant="h6"
                                sx={{marginBottom: '-16px'}}>{t('pages.xmo.tactics.chatbotSilver.chatDetailsLabel')}</Typography>
                    <Typography fontSize={17}>
                      {t('pages.xmo.tactics.chatbotSilver.completeOrderHelper1')}
                      <a href="https://order.localiq.com" target="_blank"
                         rel="noopener noreferrer">{t('pages.xmo.tactics.chatbotSilver.orderUrl')}</a>
                      {t('pages.xmo.tactics.chatbotSilver.completeOrderHelper2')}
                    </Typography>
                  </GridItem>
              )}

              {isEnableSmartChatbotSilver === t('pages.xmo.reach.useAutomaticPlacementOnAdsOptions.yes') && (
                  <GridItem sx={{marginBottom: 0, textarea: {width: 'initial', marginBottom: 0}}}>
                    <IQFormTextArea
                        id="leadDeliveryEmails"
                        name="leadDeliveryEmails"
                        labelText={t('pages.xmo.tactics.chatbotSilver.leadDeliveryEmailsLabel')}
                        fontLabelWeight="600"
                        fullWidth
                        rowCount={2}
                    />
                    <Typography variant="body2" color="text.secondary" sx={{marginTop: -1}}>
                      {t('pages.xmo.tactics.chatbotSilver.leadDeliveryEmailsHelper')}
                    </Typography>
                  </GridItem>
              )}

              {isEnableSmartChatbotSilver === t('pages.xmo.reach.useAutomaticPlacementOnAdsOptions.yes') && (
                  <GridItem sizes={[12]}>
                    <IQFormInput
                        id='companyUrl'
                        name='companyUrl'
                        labelText={t('pages.xmo.tactics.chatbotSilver.companyUrlLabel')}
                        fullWidth
                        theme={IQTheme}
                        fontLabelWeight='600'
                        schema={schema}
                        adornmentIcon={<InsertLinkIcon/>}
                        showError
                    />
                  </GridItem>
              )}

              {isEnableSmartChatbotSilver === t('pages.xmo.reach.useAutomaticPlacementOnAdsOptions.yes') && (
                  <GridItem sizes={[12]}>
                    <NePhoneInput
                        name="localBusinessPhoneNumber"
                        country="US"
                        hasExtension={false}
                        phoneLabelOptions={{
                          labelText: t('pages.xmo.tactics.chatbotSilver.localBusinessPhoneNumberLabel'),
                          hasError: true,
                          theme: IQThemeV2,
                          required: true,
                        }}
                        hasType={false}
                        countryCallingCodeEditable={false}
                        international
                    />
                  </GridItem>
              )}
            </GridContainer>
            <CoNavigationConfirm
                showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
            />
          </FormProvider>
        </ValidationProvider>
      </DefaultPageLayout>
  );
}
