/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Box, Typography, RadioGroup, FormControlLabel, Radio, Grid,
  MenuItem,
} from '@mui/material';
import {
  IQDatePicker,
  IQFormInput,
  IQFormTextArea,
  IQInputEmail,
  IQTheme,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import GridItem from 'components/grid/GridItem';
import GridContainer from 'components/grid/GridContainer';
import schemaValidate from 'components/schemaValidate';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import ErrorIcon from '@mui/icons-material/Error';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { selectProduct } from 'services/productsSlice';
import NeDropdown from 'components/dropdown/NeDropdown';
import schema from './XmoGeneral.schema';

interface Props {
  product: any;
  onSubmit: (data: any) => void;
  onBack: () => void;
}

export type DetailsFormProps = {
  businessName: string,
  businessWebsiteUrl: string,
  advertiserEmail: string,
  csmName: string,
  csmEmail: string,
  timedEventPromotionOrSale: string,
  startDate: string,
  endDate: string,
  detailsOfEvent: string,
  inventoryTier: string,
};

const inventoryTierItems = [
  'Recommended - all inclusive',
  'Representative - excludes Youtube, CTV/OTT',
  'Essential - excludes Youtube, pre-roll video, CTV/OTT',
];

export default function XmoGeneralPageForm({ product, onSubmit, onBack }: Props) {
  const { t } = useTranslation();
  const { orderItemId } = useParams();
  const { content: productContent } = useSelector(selectProduct);
  const [xmoVariant, setXmoVariant] = useState(0);
  const [timedEventPromotionOrSale, setTimedEventPromotionOrSale] = useState(product?.timedEventPromotionOrSale || '');
  const [startDateData, setStartDateData] = useState(product?.startDate || '');
  const [endDateData, setEndDateData] = useState(product?.endDate || '');
  const [dateError, setDateError] = useState<boolean>(false);
  const [startDateError, setStartDateError] = useState<boolean>(false);
  const [endDateError, setEndDateError] = useState<boolean>(false);

  useEffect(() => {
    if (Object.keys(productContent).length > 0
    && Number(productContent?.orderItemId) === Number(orderItemId)
    && productContent?.data?.variant) {
      setXmoVariant(productContent.data.variant);
    }
  }, [productContent]);

  const methods = useForm<DetailsFormProps>({
    mode: 'onChange',
    defaultValues: {
      businessName: product?.businessName || '',
      businessWebsiteUrl: product?.businessWebsiteUrl || '',
      advertiserEmail: product?.advertiserEmail || '',
      csmName: product?.csmName || '',
      csmEmail: product?.csmEmail || '',
      timedEventPromotionOrSale: product?.timedEventPromotionOrSale || '',
      startDate: product?.startDate || '',
      endDate: product?.endDate || '',
      detailsOfEvent: product?.detailsOfEvent || '',
      inventoryTier: product?.inventoryTier || '',
    },
  });

  const handleFormSubmit = (data: DetailsFormProps) => {
    if (data.timedEventPromotionOrSale !== undefined && data.timedEventPromotionOrSale === 'No') {
      data.startDate = '';
      data.endDate = '';
      data.detailsOfEvent = '';
    }

    const pageFormData = {
      solutionStrategy: {
        ...product, ...data,
      },
    };
    onSubmit(pageFormData);
  };

  const {
    register, handleSubmit, formState: {
      isValid, isDirty, isSubmitSuccessful, isSubmitting,
    },
  } = methods;

  const handleTimeEventRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTimedEventPromotionOrSale(event.target.value);
  };

  useEffect(() => {
    if (startDateData !== '' && endDateData !== '') {
      if (new Date(startDateData).getTime() > new Date(endDateData).getTime()) {
        setDateError(true);
      } else {
        setDateError(false);
      }
    }

    function dateErrorCheck(dateToCompare: any) {
      const currentDate = new Date();
      const formattedDate = `${(currentDate.getMonth() + 1).toString().padStart(2, '0')}/${
        currentDate.getDate().toString().padStart(2, '0')}/${
        currentDate.getFullYear()}`;
      return new Date(dateToCompare).getTime() < new Date(formattedDate).getTime();
    }

    if (startDateData !== '') {
      setStartDateError(dateErrorCheck(startDateData));
    }
    if (endDateData !== '') {
      setEndDateError(dateErrorCheck(endDateData));
    }
  }, [startDateData, endDateData]);

  return (
    <DefaultPageLayout
      disableContinue={!isValid || dateError || startDateError || endDateError}
      onBack={onBack}
      header={t('pages.xmo.solutionsStrategy.general.generalTitle')}
      onContinue={handleSubmit(handleFormSubmit)}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Box
            id="mainForm"
            component="form"
            sx={{ display: 'flex' }}
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <GridContainer fullWidth>
              <GridItem sizes={[12]}>
                <Typography
                  variant="h6"
                >
                  {t('pages.xmo.solutionsStrategy.general.xmoDetailsTitle')}
                </Typography>
              </GridItem>
              <GridItem sizes={[12]}>
                <IQFormInput
                  schema={schema}
                  theme={IQTheme}
                  fontLabelWeight="600"
                  id="businessName"
                  name="businessName"
                  fullWidth
                  labelText={t('pages.xmo.solutionsStrategy.general.businessName')}
                />
              </GridItem>
              <GridItem sizes={[12]}>
                <IQFormInput
                  id="businessWebsiteUrl"
                  name="businessWebsiteUrl"
                  labelText={t('pages.xmo.solutionsStrategy.general.businessWebsiteUrl')}
                  fullWidth
                  theme={IQTheme}
                  fontLabelWeight="600"
                  schema={schema}
                  adornmentIcon={<InsertLinkIcon />}
                  showError
                />
              </GridItem>
              <GridItem sizes={[12]}>
                <IQInputEmail
                  schema={schema}
                  theme={IQTheme}
                  fontLabelWeight="600"
                  field="advertiserEmail"
                  name="advertiserEmail"
                  required
                  fullWidth
                  adornmentIcon={<MailOutlineIcon />}
                  label={t('pages.xmo.solutionsStrategy.general.advertiserEmail')}
                />
              </GridItem>
              <GridItem sizes={[12]}>
                <Typography
                  variant="h6"
                >
                  {t('pages.xmo.solutionsStrategy.general.csmInfoTitle')}
                </Typography>
              </GridItem>
              <GridItem sizes={[12]}>
                <IQFormInput
                  schema={schema}
                  theme={IQTheme}
                  fontLabelWeight="600"
                  id="csmName"
                  name="csmName"
                  fullWidth
                  labelText={t('pages.xmo.solutionsStrategy.general.csmName')}
                />
              </GridItem>
              <GridItem sizes={[12]}>
                <IQInputEmail
                  schema={schema}
                  theme={IQTheme}
                  fontLabelWeight="600"
                  field="csmEmail"
                  name="csmEmail"
                  required
                  fullWidth
                  adornmentIcon={<MailOutlineIcon />}
                  label={t('pages.xmo.solutionsStrategy.general.csmEmail')}
                />
              </GridItem>
              <GridItem sizes={[12]}>
                <Typography
                  variant="h6"
                >
                  {t('pages.xmo.solutionsStrategy.general.timedEventTitle')}
                </Typography>
              </GridItem>
              <GridItem sizes={[12]}>
                <Typography fontWeight={600} variant="subtitle1">
                  {t('pages.xmo.solutionsStrategy.general.timedEventPromotionOrSale')}
                  <RadioGroup
                    id="timedEventPromotionOrSale"
                    name="timedEventPromotionOrSale"
                    defaultValue={product?.timedEventPromotionOrSale || ''}
                    onChange={handleTimeEventRadioChange}
                    row
                  >
                    {[t('pages.xmo.solutionsStrategy.inputValues.yesInputValue'),
                      t('pages.xmo.solutionsStrategy.inputValues.noInputValue')].map(opt => (
                        <FormControlLabel
                          key={opt}
                          control={<Radio value={opt} />}
                          label={opt}
                          {...methods.register('timedEventPromotionOrSale', {
                            validate: (value) => schemaValidate(value, 'timedEventPromotionOrSale', schema),
                          })}
                        />
                    ))}
                  </RadioGroup>
                </Typography>
              </GridItem>
              {timedEventPromotionOrSale === t('pages.xmo.solutionsStrategy.inputValues.yesInputValue') && (
                <>
                  <GridItem sizes={[6, 6]} columnSpacing={2}>
                    <Controller
                      name="startDate"
                      control={methods.control}
                      render={({ field }) => (
                        <>
                          <IQDatePicker
                            id="startDate"
                            {...field}
                            schema={schema}
                            theme={IQTheme}
                            fontLabelWeight="600"
                            labelText={t('pages.xmo.solutionsStrategy.general.startDateLabel')}
                            required
                            fullWidth
                            onChange={(e: any) => setStartDateData(e)}
                          />
                          {startDateError
                            && (
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography
                                  sx={{
                                    color: '#C20F1E',
                                    fontSize: '14px',
                                    marginTop: '10px',
                                    marginBottom: '15px',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                  variant="subtitle1"
                                >
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <ErrorIcon
                                      sx={{
                                        color: '#C20F1E',
                                        fontSize: '16px',
                                        marginRight: '5px',
                                      }}
                                    />
                                    {t('pages.xmo.solutionsStrategy.general.dateGreaterThanCurrentDateError')}
                                  </Box>
                                </Typography>
                              </Grid>
                            </Grid>
                            )}
                        </>
                      )}
                    />
                    <Controller
                      name="endDate"
                      control={methods.control}
                      render={({ field }) => (
                        <>
                          <IQDatePicker
                            id="endDate"
                            {...field}
                            schema={schema}
                            theme={IQTheme}
                            fontLabelWeight="600"
                            labelText={t('pages.xmo.solutionsStrategy.general.endDateLabel')}
                            required
                            fullWidth
                            onChange={(e: any) => setEndDateData(e)}
                          />
                          {endDateError
                            && (
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography
                                  sx={{
                                    color: '#C20F1E',
                                    fontSize: '14px',
                                    marginTop: '10px',
                                    marginBottom: '15px',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                  variant="subtitle1"
                                >
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <ErrorIcon
                                      sx={{
                                        color: '#C20F1E',
                                        fontSize: '16px',
                                        marginRight: '5px',
                                      }}
                                    />
                                    {t('pages.xmo.solutionsStrategy.general.dateGreaterThanCurrentDateError')}
                                  </Box>
                                </Typography>
                              </Grid>
                            </Grid>
                            )}
                        </>
                      )}
                    />
                  </GridItem>
                  {dateError
                    && (
                    <GridItem sizes={[12]} marginTop={-2}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              color: '#C20F1E',
                              fontSize: '14px',
                              marginTop: '0px',
                              marginBottom: '15px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                            variant="subtitle1"
                          >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <ErrorIcon
                                sx={{
                                  color: '#C20F1E',
                                  fontSize: '16px',
                                  marginRight: '5px',
                                }}
                              />
                              {t('pages.xmo.solutionsStrategy.general.genericStartAndEndDateError')}
                            </Box>
                          </Typography>
                        </Grid>
                      </Grid>
                    </GridItem>
                    )}
                  <GridItem sizes={[12]}>
                    <Controller
                      name="detailsOfEvent"
                      control={methods.control}
                      render={({ field }) => (
                        <IQFormTextArea
                          {...field}
                          fontLabelWeight="600"
                          id="detailsOfEvent"
                          name="detailsOfEvent"
                          fullWidth
                          labelText={t('pages.xmo.solutionsStrategy.general.detailsOfEvent')}
                        />
                      )}
                    />
                  </GridItem>
                </>
              )}
              {/* missing field here */}
              {xmoVariant === 3 && (
                <Grid item mb={3} xs={12}>
                  <Grid item mb={1} sx={{
                    '& .MuiSelect-select': {
                      border: '1px solid black',
                    },
                  }}
                  >
                    <NeDropdown
                      name="inventoryTier"
                      label={t('pages.xmo.solutionsStrategy.general.inventoryTierLabel')}
                      {...register('inventoryTier')}
                      required={false}
                      defaultValue={product?.inventoryTier || ''}
                    >
                      {inventoryTierItems.map(opt => (
                        <MenuItem value={opt} key={opt}>{opt}</MenuItem>
                      ))}
                    </NeDropdown>
                  </Grid>
                </Grid>
              )}
            </GridContainer>
          </Box>
          <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
          />
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
