import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import { IQCartOverlay, IQLeftNavLogo } from '@gannettdigital/shared-react-components';
import { Box, IconButton } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  getOrder,
  getOrderItems,
  selectAllItemsRebuild,
  selectIsPartnerFlow,
  selectOrderFlow,
  selectOrderItems,
  selectOrdersContent,
  updateOrderFlow,
} from 'services/ordersSlice';
import { Urls } from 'navigation/Urls';
import TextIconNeon from 'icons/IQLeftNavIconNeon.svg';
import ContentLoader from 'components/contentLoader/ContentLoader';
import { getMenuStructure, sortOrderItems } from 'services/menus';
import NeLeftNavStepper from 'components/navigation/NeLeftStepper';
import {
  buildProductsMenu,
  changeActiveOfferingType, changeActiveProductId,
  selectActiveOfferingType, selectNavigationMenu,
  selectNavMenuBuilt, updateActiveStep, flattenMenu,
} from 'services/navigationSlice';
import { getAddons, selectAddonSteps } from 'services/addons/addonsSlice';
import { OrderFlow } from 'shared/constants';
import { coreMenu } from 'services/menus/coreMenu';
import styled from '@emotion/styled';
import IconLogo from 'components/logo/IconLogo';
import { useAppDispatch } from 'services/redux/store-schedule';
import { getProduct } from 'services/productsSlice';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { setXmoVariantMenu } from 'pages/xmo/xmoUtils';
import { TypeOfSaleConstants, TypesOfSale } from 'pages/shopping-cart/Constants';

interface LeftNavMenuProps {
  menu?: string
  isPunchListFlow?: boolean
  toggleMenu?: boolean
  setToggleMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

const NeLeftNavContainer = styled('div')<Pick<LeftNavMenuProps, 'toggleMenu' | 'isPunchListFlow'>>`
  position: fixed;
  background: ${(props) => props.theme.palette.common.white};
  height: calc(100vh - 90px);
  max-width: calc(260px - 68px);
  padding: 34px 34px 60px;
  ${({ isPunchListFlow }) => (isPunchListFlow && css`
    border-top-right-radius: 32px;
  `)}
  ${({ toggleMenu }) => (!toggleMenu && css`
    width: 100%;
  `)}
`;

export const isCustomProduct = (orderItems) => orderItems.some(item => item.offeringType === 'CustomWebsite'
    || item.offeringType === 'CustomLandingPage');

const buildDynamicMenu = (orderItems: any[], isSalesforce: boolean, isPunchlist: boolean, isPartnerFlow: boolean) => {
  const sortedItems = sortOrderItems(orderItems.slice(), isSalesforce, isPunchlist);
  const menu = coreMenu.slice();
  if (isPunchlist || isSalesforce || isCustomProduct(orderItems) || isPartnerFlow) {
    const businessWithoutCampaignStep = {
      subSteps: menu[1]?.subSteps?.filter(step => step.url !== Urls.BusinessCampaign),
    };
    menu[1] = { ...menu[1], ...businessWithoutCampaignStep };
  }

  const orderItemsMenus = sortedItems.map(orderItem => {
    const menuObj = getMenuStructure(orderItem.pdOfferingType || orderItem.offeringType);
    return { ...menuObj, builtFor: orderItem.id };
  });

  menu.splice(2, 0, ...orderItemsMenus);
  return menu;
};

export default function LeftNavMenu({
  menu, isPunchListFlow, toggleMenu, setToggleMenu,
}: LeftNavMenuProps) {
  const { orderId, orderItemId } = useParams();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const menuBuilt = useSelector(selectNavMenuBuilt);
  const menuSteps = useSelector(selectNavigationMenu);
  const { content: orderItems } = useSelector(selectOrderItems);
  const orderData = useSelector(selectOrdersContent);
  const orderFlow = useSelector(selectOrderFlow);
  const activeOfferingType = useSelector(selectActiveOfferingType);
  const addonSteps = useSelector(selectAddonSteps);
  const isPartnerFlow = useSelector(selectIsPartnerFlow);
  const isAddonIncluded = (orderitems) => orderitems.some(item => item.offeringType === 'MasterAddon');
  const navigate = useNavigationHandler();
  const [isXmo, setIsXmo] = useState(false);
  const [xmoVariants, setXmoVariants] = useState([]);
  const xmoItems = orderItems.filter(item => item.pdOfferingType === 'XMO');
  const allItemsRebuild = useSelector(selectAllItemsRebuild);

  const flatMenu = flattenMenu(menuSteps).filter(step => step.url !== '');

  // type of sale
  const itemIsRebuild = (oId) => {
    const typeOfSale = orderItems.filter(item => Number(item.id) === Number(oId))?.[0]?.saleType;
    return (typeOfSale === TypeOfSaleConstants.REBUILD);
  };

  const getTypeOfSaleLabel = (oId) => {
    const typeOfSale = orderItems.filter(item => Number(item.id) === Number(oId))?.[0]?.saleType;
    return TypesOfSale.filter(type => type.value === typeOfSale)?.[0]?.label;
  };

  const onClick = (stepUrl: string, offeringType: string, orderItemId: string | number) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    flatMenu.forEach((value) => {
      if (!orderItemId && (value.url === stepUrl) && ['completed', 'active'].includes(value.status)) {
        navigate.to(stepUrl);
      }

      if (orderItemId && (value.url === stepUrl) && (value.builtFor === orderItemId)
          && ['completed', 'active'].includes(value.status)
          && (!itemIsRebuild(orderItemId) || (itemIsRebuild(orderItemId) && stepUrl === Urls.GeneralQuestions))) {
        if (value.builtFor) {
          navigate.to(stepUrl, value.builtFor);
        }
      }
    });
  };

  useMemo(() => {
    if (xmoItems.length > 0) {
      xmoItems.forEach(item => {
        appDispatch(getProduct({ orderItemId: item.id })).then((res) => {
          setXmoVariants(prev => [...prev, { id: item.id, variant: res.payload?.data?.variant }]);
        });
      });
      setIsXmo(true);
    }
    if (orderItems.length > 0 && isAddonIncluded(orderItems)) dispatch(getAddons(orderId));
    if (orderItems.length > 0 && orderItemId) {
      const currentItem = orderItems.filter(item => item.id === Number(orderItemId));
      const customProductOrder = orderItems.filter(item => ['CustomLandingPage', 'CustomWebsite']
        .includes(item.offeringType))?.length > 0;
      if (currentItem && !customProductOrder) {
        dispatch(changeActiveProductId(currentItem[0].offeringId));
        const offeringType = currentItem[0].offeringType === 'ProductsData'
          ? currentItem[0].pdOfferingType : currentItem[0].offeringType;
        dispatch(changeActiveOfferingType(offeringType));
      }

      if (customProductOrder) {
        dispatch(changeActiveProductId(orderItems[0].offeringId));
        dispatch(changeActiveOfferingType(orderItems[0].offeringType));
      }
    }
  }, [orderItems, orderItemId]);

  useEffect(() => {
    dispatch(getOrder(orderId));
    dispatch(getOrderItems(orderId));
  }, [orderId]);

  const updateGeneralQuestionsLabel = (menu) => (
    menu.map(item => ({
      ...item,
      subSteps: Array.isArray(item.subSteps)
        ? item.subSteps.map(step => (step.label === 'General Questions'
          ? {
            ...step,
            label: getTypeOfSaleLabel(item?.builtFor) || step.label,
          }
          : step
        ))
        : null,
    }))
  );

  useEffect(() => {
    if (orderItems.length > 0
      && !menuBuilt && Object.keys(orderData).length > 0
      && ((isXmo && xmoVariants.length === xmoItems.length) || !isXmo)) {
      const isSalesforce = (orderFlow === OrderFlow.SALES_FORCE || orderData.orderFlow === OrderFlow.SALES_FORCE
        || orderItems?.filter(item => item?.salesForceOfferId)?.length > 0);

      if (isSalesforce) dispatch(updateOrderFlow(OrderFlow.SALES_FORCE));
      if (isPunchListFlow) dispatch(updateOrderFlow(OrderFlow.PUNCHLIST));

      let menu = buildDynamicMenu(orderItems, isSalesforce, isPunchListFlow, isPartnerFlow);
      const selectedAddonSteps = addonSteps.map(addon => addon.toLowerCase());
      if (selectedAddonSteps.length > 1) {
        const index = menu.findIndex(item => item.offeringType === 'MasterAddon');
        if (index !== -1) {
          menu[index].subSteps = menu[index].subSteps.filter(step =>
            selectedAddonSteps.includes(step.offeringType.toLowerCase()));
        }
      }

      if (isPunchListFlow) {
        menu = menu.map(item => ({
          ...item,
          subSteps: Array.isArray(item.subSteps) ? item.subSteps.filter(step => step.label !== 'Search')
            : null,
        }));
      }

      if (!isSalesforce && !isPunchListFlow && !isPartnerFlow) {
        menu = menu.map(item => ({
          ...item,
          subSteps: Array.isArray(item.subSteps)
            ? item.subSteps.filter(step => (step.label !== 'General Questions'
              || (step.label === 'General Questions' && itemIsRebuild(item?.builtFor))))
            : null,
        }));
        menu = updateGeneralQuestionsLabel(menu);
      } else {
        menu = updateGeneralQuestionsLabel(menu);
      }

      if (allItemsRebuild) {
        menu = menu.map(item => ({
          ...item,
          subSteps: Array.isArray(item.subSteps) ? item.subSteps.filter(step => step.label !== 'Campaign IDs') : null,
        }));
      }

      if (!isPunchListFlow) {
        menu = menu.map(item => ({
          ...item,
          subSteps: Array.isArray(item.subSteps) ? item.subSteps.map(step => {
            if (step.label !== 'Design') return step;
            return {
              ...step,
              subSteps: Array.isArray(step.subSteps)
                ? step.subSteps.filter(substep => substep.name !== 'Theme') : null,
            };
          }) : null,
        }));

        menu = menu.map(item => ({
          ...item,
          subSteps: Array.isArray(item.subSteps)
            ? item.subSteps.filter(step => (
              (item.offeringType === 'PiQLandingPages' && step.label !== 'Domain')
              || item.offeringType !== 'PiQLandingPages'
            )) : null,
        }));
      }

      if (isXmo) menu = setXmoVariantMenu(menu, xmoVariants);

      if (isAddonIncluded(orderItems) && (addonSteps.length > 0)) {
        const addonSubSteps = menu.filter(step => step.offeringType === 'MasterAddon')?.[0]?.subSteps;
        if (addonSubSteps.length === addonSteps.length) dispatch(buildProductsMenu(menu));
      }

      if (!isAddonIncluded(orderItems)) dispatch(buildProductsMenu(menu));
    }
  }, [orderItems, addonSteps, menuBuilt, orderData, xmoVariants]);

  useEffect(() => {
    if (menuBuilt) {
      dispatch(updateActiveStep({ url: menu, offeringType: activeOfferingType, orderItemId }));
    }
  }, [menuBuilt, menu]);

  const Loader = (
    <Box sx={{
      display: 'flex', justifyContent: 'center', top: '10%', position: 'relative',
    }}
    >
      {/* @ts-ignore */}
      <ContentLoader status="loading" message="loading" />
    </Box>
  );

  const openStep = useMemo(
    () => menuSteps.findIndex(step => step.offeringType === activeOfferingType
        && step.builtFor === Number(orderItemId)),
    [menuSteps, activeOfferingType, orderItemId],
  );

  const NavStepper = menuBuilt
    ? (
      <NeLeftNavStepper leftNavSteps={menuSteps} defaultOpenStepIndex={openStep}
        onStepClick={onClick} toggleMenu={toggleMenu}
      />
    )
    : Loader;

  const Logo = toggleMenu ? <IconLogo /> : <IQLeftNavLogo IQLeftNavIcon={TextIconNeon} />;

  const toggleButton = (
    <IconButton
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        backgroundColor: 'white',
        border: '2px solid white',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        position: 'absolute',
        top: '65px',
        left: toggleMenu ? '100px' : '234px',
      }}
      onClick={() => setToggleMenu(!toggleMenu)}
    >
      <ChevronRightIcon style={{ color: 'black', transform: `${toggleMenu ? 'rotate(0deg)' : 'rotate(180deg)'}` }} />
    </IconButton>
  );
  // style={{  }}

  return (
    <NeLeftNavContainer toggleMenu={toggleMenu} isPunchListFlow={isPunchListFlow}>
      <Box sx={{
        display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'space-between',
      }}
      >
        <div>
          { Logo }
          { isPunchListFlow && toggleButton}
          { NavStepper }
        </div>
        {!isPunchListFlow && <IQCartOverlay items={[]} />}
      </Box>
    </NeLeftNavContainer>
  );
}
