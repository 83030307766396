import * as yup from "yup";
import i18n from 'i18n/i18n';
import {urlValidationRegex} from "shared/constants";

const {t} = i18n;

const validateURLs = (urls) => {
  const urlArray = urls.split(',').map(url => url.trim());
  for (const url of urlArray) {
    if (!urlValidationRegex.test(url)) {
      return false;
    }
  }
  return true;
};

const schema = {
  minimumAge: yup.number().required().nullable()
  .typeError(t('pages.generic-translation.minAgeNumberErrorMessage'))
  .test('is-less-than-or-equal-to-99', t('pages.generic-translation.minAgeLimitErrorMessage'), function (value) {
    return value <= 99;
  }),
  maximumAge: yup.number().required().nullable()
  .typeError(t('pages.generic-translation.maxAgeNumberErrorMessage'))
  .test('is-not-greater-than-99', t('pages.generic-translation.maxAgeLimitErrorMessage'), function (value) {
    return value <= 99;
  }),
  gender: yup.string().required(t('pages.custom.solutions.common.genderError')),
  callToAction: yup.string().required(t('pages.xmo.tactics.socialAdsRetargeting.callToActionError')),
  automaticOrOptimizedPlacementOnAds: yup.string().required(t('pages.xmo.tactics.socialAdsRetargeting.automaticOrOptimizedError')),
  clientWantEnableWebsiteRetargeting: yup.string().required(t('pages.xmo.tactics.socialAdsRetargeting.clientWantEnableWebsiteRetargetingError')),
  enableTargetingPeopleVisitedWebsite: yup.string().required(t('pages.xmo.tactics.socialAdsRetargeting.enableTargetingPeopleVisitedWebsiteError')),
  selectPageUrl: yup.string().required().test('selectPage-valid-url(s)', t('pages.xmo.tactics.socialAdsRetargeting.selectPageURLError'),
      value => {
        return validateURLs(value);
      }),
  numberOfDaysRetargetFacebookVisitors: yup.string().required(t('pages.xmo.tactics.socialAdsRetargeting.numberOfDaysRetargetFacebookVisitorsSelectError')),
  websiteRetargetingOptOutUrl: yup.string().notRequired().test(
      'website-valid-url(s)',
      t('pages.xmo.tactics.socialAdsRetargeting.selectPageURLError'),
      function (value) {
        if (!value || value.trim() === '') {
          return true; //for blank values
        }
        return validateURLs(value);
      }
  ),
  enableTargetingPeopleSimilarToWhoLikedFacebook: yup.string().required(t('pages.xmo.tactics.socialAdsRetargeting.enableTargetingPeopleLikedFBError')),
  wantToRetargetSearchCampaignLeadsOnFacebook: yup.string().required(t('pages.xmo.tactics.socialAdsRetargeting.wantToRetargetSearchCampaignLeadsOnFacebookError')),
  numberOfDaysToRetarget: yup.string().required(t('pages.xmo.tactics.socialAdsRetargeting.numberOfDaysToRetargetError')),
  typeOfVisualStrategyClientWantToEmploy: yup.string().required(t('pages.xmo.tactics.socialAdsRetargeting.whatTypeOfVisualStrategyError')),
  willClientBeProvidingImagesToUse: yup.string().required(t('pages.xmo.tactics.socialAdsRetargeting.willTheClientBeProvidingImagesToUseError')),
  willThisCampaignUtilizeCarouselFormat: yup.string().required(t('pages.xmo.tactics.socialAdsRetargeting.willThisCampaignUtilizeCarouselFormatError')),
  willClientBeProvidingTheirOwnTextCreative: yup.string().required(t('pages.xmo.tactics.socialAdsRetargeting.willTheClientBeProvidingTheirOwnTextCreativeError')),
  adHeadLine: yup.string().required(t('pages.xmo.tactics.socialAdsRetargeting.adHeadlineError')),
  adBodyText: yup.string().required(t('pages.xmo.tactics.socialAdsRetargeting.adBodyTextError')),
  newsFeedLinkDescription: yup.string().required(t('pages.xmo.tactics.socialAdsRetargeting.newsfeedLinkDescriptionError')),
  inventoryToFeatureInCampaign: yup.string().required(t('pages.xmo.tactics.socialAdsRetargeting.inventoryToFeatureError')),
  productsOrServicesOrOffersToPromoteInCampaign: yup.array()
  .min(1, t('pages.xmo.tactics.socialAdsRetargeting.productsOrServicesOrOffersError')),
};

export default {
  yupValidations: schema,
  customValidations: {},
};