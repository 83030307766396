import { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import GridItem from 'components/grid/GridItem';
import { ProductTypes, getFiles, getGettyAssets } from 'services/fileUploaderSlice';
import { useParams } from 'react-router';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { Urls } from 'navigation/Urls';
import { useAppDispatch } from 'services/redux/store';
import { displayNotRequiredField, editButton } from 'pages/overview/Overview';
import { getHeroVideoAdditionalNotes } from 'services/addons/addonsSlice';
import { useSelector } from 'react-redux';
import { selectOrderItems } from 'services/ordersSlice';
import { renderStatusPill } from 'pages/orders/OrdersTable';
import { headingStyle } from '../Business/BusinessSection';
import { actionItems } from '../common/actionItems';
import { displayAssets } from '../sites/sitesSection';
import { findAddonId } from './masterAddonSection';

export default function VideoBackgroundSection({
  product, isEditable, showActionItems, openBusinessAndSalesAgent,
}: any) {
  const appDispatch = useAppDispatch();
  const navigate = useNavigationHandler();
  const { orderId } = useParams();
  const orderItems = useSelector(selectOrderItems);
  const [stockAssets, setStockAssets] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [addonStatus, setAddonStatus] = useState('');
  const [videoBgContent, setVideoBgContent] = useState({} as any);
  const addonName = 'videoBackground';

  const getAssets = () => {
    appDispatch(getGettyAssets({ orderId, orderItemId: product?.id, productType: ProductTypes.HERO_VIDEO }))
      .then((response) => setStockAssets(response.payload));

    appDispatch(getFiles({
      websiteId: product?.offeringId,
      uploaderType: ProductTypes.HERO_VIDEO,
      offeringType: 'MasterAddon',
    })).then((response) => setAttachments(response.payload));
  };

  useEffect(() => {
    if (orderId && product) {
      getAssets();
      appDispatch(getHeroVideoAdditionalNotes({ orderId }))
        .then((response) => setVideoBgContent(response.payload?.at(0)));
    }
  }, [orderId, product]);

  useEffect(() => {
    if (orderItems?.content?.length && addonName) {
      const currentAddon = orderItems.content
        .filter((item) => item.offeringType?.toLowerCase() === addonName?.toLowerCase());
      if (currentAddon.length > 0) setAddonStatus(currentAddon[0].status);
    }
  }, [orderItems]);

  return (
    <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid #000000' }}>
      <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
        Video Background
        {!isEditable && renderStatusPill(addonStatus)}
      </Typography>

      <Box>

        {/* Video Details */}
        <Box sx={{ display: 'flex', margin: '10px 0' }}>
          {editButton(isEditable, () =>
            navigate.to(Urls.HeroVideo, product.id))}
          <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Video Details</Typography>
        </Box>
        <GridItem sizes={[6, 6]} sx={{ wordWrap: 'break-word' }}>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Stock Assets</Typography>
              {displayAssets(stockAssets, 'Stock')}
            </Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Attachments</Typography>
              {displayAssets(attachments, 'Attachment')}
            </Box>
          </Box>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Additional Notes/Instructions</Typography>
              {displayNotRequiredField('additional notes/instructions', videoBgContent?.additionalNotes)}
            </Box>
          </Box>
        </GridItem>

        {/* Actions items */}
        {showActionItems && actionItems(false, findAddonId(orderItems, addonName), false, openBusinessAndSalesAgent)}
      </Box>
    </GridItem>
  );
}
