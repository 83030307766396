import i18n from 'i18n/i18n';

import {
  AusPerformanceMaxDataCollectionChildCustomValidate,
  AusPerformanceMaxDataCollectionChildSchema, AusPerformanceMaxDataCollectionChildUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/AusPerformanceMaxDataCollection";
import {
  AssetGroupsCustomValidate,
  AssetGroupsSchema, AssetGroupsUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/AssetGroupSchema";
import {
  CampaignCreativeCustomValidate,
  CampaignCreativeSchema, CampaignCreativeUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/CampaignCreative";
import {
  WebEventsCustomValidate,
  WebEventsSchema,
  WebEventsUISchema,
} from 'pages/generic-product/details/product-schema/aus-product-schema/WebEventsSchema';
import {
  CallTrackingCustomValidate,
  CallTrackingSchema,
  CallTrackingUISchema,
} from 'pages/generic-product/details/product-schema/aus-product-schema/CallTrackingSchema';
import {
  TrackingNumberDetailsCustomValidate,
  TrackingNumberDetailsSchema,
  TrackingNumberDetailsUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/TrackingNumberDetails";
import {
  NotesAndInstructionsCustomValidate,
  NotesAndInstructionsSchema,
  NotesAndInstructionsUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/NotesAndInstructionsSchema";
const { t } = i18n;

export const AusPerformanceMaxSchema = (data) => {
  const schema =  [
    {
      type: 'object',
      title: t('pages.ausProduct.ausPerformanceMax.performanceMax.title'),
      schema: AusPerformanceMaxDataCollectionChildSchema(data),
      uiSchema: AusPerformanceMaxDataCollectionChildUISchema,
      customValidate: AusPerformanceMaxDataCollectionChildCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.ausPerformanceMax.assetGroup.title'),
      schema: AssetGroupsSchema(data),
      uiSchema: AssetGroupsUISchema,
      customValidate: AssetGroupsCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.ausPerformanceMax.campaignCreative.title'),
      schema: CampaignCreativeSchema(data),
      uiSchema: CampaignCreativeUISchema,
      customValidate: CampaignCreativeCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.trackingNumber1Details.title'),
      schema: TrackingNumberDetailsSchema(data),
      uiSchema: TrackingNumberDetailsUISchema(data),
      customValidate: TrackingNumberDetailsCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.notesAndInstructions.title'),
      schema: NotesAndInstructionsSchema(data),
      uiSchema: NotesAndInstructionsUISchema,
      customValidate: NotesAndInstructionsCustomValidate,
    }
  ];

  const trackingRequirements = data.trackingRequirements;
  const indexToInsert = 3;

  if (trackingRequirements === "Call and Web Event Tracking") {
    schema.splice(indexToInsert, 0, {
      type: 'object',
      title: t('pages.ausProduct.webEvents.title'),
      schema: WebEventsSchema(data),
      uiSchema: WebEventsUISchema(data),
      customValidate: WebEventsCustomValidate,
    });
    schema.splice(indexToInsert + 1, 0, {
      type: 'object',
      title: t('pages.ausProduct.callTracking.title'),
      schema: CallTrackingSchema(data),
      uiSchema: CallTrackingUISchema,
      customValidate: CallTrackingCustomValidate,
    });
  } else if (trackingRequirements === "Web Event Tracking") {
    schema.splice(indexToInsert, 0, {
      type: 'object',
      title: t('pages.ausProduct.webEvents.title'),
      schema: WebEventsSchema(data),
      uiSchema: WebEventsUISchema(data),
      customValidate: WebEventsCustomValidate,
    });
  } else if (trackingRequirements === "Call Tracking") {
    schema.splice(indexToInsert, 0, {
      type: 'object',
      title: t('pages.ausProduct.callTracking.title'),
      schema: CallTrackingSchema(data),
      uiSchema: CallTrackingUISchema,
      customValidate: CallTrackingCustomValidate,
    });
  }

return schema;
};

export const AusPerformanceMaxUISchema = (data) => ({
  'ui:order': [
    ...AusPerformanceMaxDataCollectionChildUISchema['ui:order'],
    ...AssetGroupsUISchema['ui:order'],
    ...CampaignCreativeUISchema['ui:order'],
    ...TrackingNumberDetailsUISchema(data)['ui:order'],
    ...NotesAndInstructionsUISchema['ui:order'],
    ...WebEventsUISchema(data)['ui:order'],
    ...CallTrackingUISchema['ui:order'],
  ],
});

export const AusPerformanceMaxCustomValidate = (formData: any, errors, uiSchema) => errors;