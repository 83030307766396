export const targetingTypeDropdownItems = [
  {value: 'Radius', description: 'Radius'},
  {value: 'City', description: 'City (not available in CAN)'},
  {value: 'DMA', description: 'DMA'},
  {value: 'National', description: 'National'},
  {value: 'Multi-Radius', description: 'Multi-Radius'},
  {value: 'Zip/Postal Code', description: 'Zip/Postal Code'},
  {value: 'State', description: 'State'},
];

export function targetingTypeRadiusDistanceDropdownItems() {
  let options = [];
  for (let i = 5; i <= 100; i += 5) {
    options.push({value: i, description: i});
  }
  return options;
}