import {
  Typography, Box, Grid,
} from '@mui/material';
import GridItem from 'components/grid/GridItem';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'services/redux/store';
import { useParams } from 'react-router';
import { getProduct } from 'services/productsSlice';
import { displayNotRequiredField, editButton } from 'pages/overview/Overview';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import OrderTracker from 'pages/orders/OrderTracker';
import { Urls } from 'navigation/Urls';
import { ProductTypes, getFiles } from 'services/fileUploaderSlice';
import { useSelector } from 'react-redux';
import { selectOrderFlow, selectOrdersContent } from 'services/ordersSlice';
import { OrderFlow } from 'shared/constants';
import { actionItems } from '../common/actionItems';
import { GeneralQuestionsSection } from '../generalQuestions/GeneralQuestionsSection';
import { headingStyle } from '../Business/BusinessSection';
import { displayAssets } from '../sites/sitesSection';

export default function PiQLandingPagesSection({ product, isEditable, showTracker }: any) {
  const appDispatch = useAppDispatch();
  const navigate = useNavigationHandler();
  const { orderId } = useParams();
  const [piqData, setPiQData] = useState({} as any);
  const [backgroundImages, setBackgroundImages] = useState([]);
  const [logoAssets, setLogoAssets] = useState([]);
  const order = useSelector(selectOrdersContent);
  const orderFlow = useSelector(selectOrderFlow);

  const isPunchlist = [orderFlow, order.orderFlow].includes(OrderFlow.PUNCHLIST);

  const getBackgroundImages = (offeringId, offeringType) => {
    appDispatch(getFiles({
      websiteId: offeringId,
      uploaderType: ProductTypes.PIQ_DOCUMENT,
      offeringType,
    })).then((response) => setBackgroundImages(response.payload));
  };

  const getLogoAssets = (offeringId, offeringType) => {
    appDispatch(getFiles({
      websiteId: offeringId,
      uploaderType: ProductTypes.PIQ_ASSET,
      offeringType,
    })).then((response) => setLogoAssets(response.payload));
  };

  const getProductData = (orderItemId) => {
    appDispatch(getProduct({
      orderItemId,
    })).then((response) => setPiQData(response.payload));
  };

  useEffect(() => {
    if (product) {
      getProductData(product?.id);
      getBackgroundImages(product?.offeringId, product.pdOfferingType);
      getLogoAssets(product?.offeringId, product.pdOfferingType);
    }
  }, [product, orderId]);

  const offeringType = product.offeringType === 'ProductsData' ? product.pdOfferingType : product.offeringType;
  const status = product?.trackerStatus === 'Completed' ? 'Seo Live' : product?.trackerStatus;

  const renderProductData = () => (
    <>
      {isPunchlist && (
      <GridItem sizes={[12, 6, 6]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid #000000' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {editButton(isEditable, () => navigate.to(
            Urls.PiQDomain,
            product.id,
          ))}
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Domain</Typography>
        </Box>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary" marginTop={2}>Domain Option</Typography>
            <Typography>
              {piqData?.data?.domainSelection !== 'clientProvidedDomain'
                ? 'LocaliQ-Provided Domain'
                : 'Client-Provided Domain'}
            </Typography>
          </Box>
        </Box>
        <Box>
          {piqData?.data?.domainSelection === 'clientProvidedDomain' && (
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary" marginTop={2}>Client-Provided Domain</Typography>
            <Typography>
              {/* eslint-disable-next-line max-len */}
              {`https://${piqData?.data?.subdomain}.${piqData?.data?.primaryDomain}.${piqData?.data?.tld}`}
            </Typography>
          </Box>
          )}
        </Box>
      </GridItem>
      )}

      {/* Content */}
      <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid #000000' }}>
        <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Content</Typography>
        <Box>
          {/* Theme Design */}
          <Box sx={{ display: 'flex', marginBottom: '10px' }}>
            {editButton(isEditable, () => navigate.to(
              Urls.PiQWebsite,
              product.id,
            ))}
            <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Website</Typography>
          </Box>
          <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Current Website refereence</Typography>
                {displayNotRequiredField('Reference', piqData?.data?.currentWebsite)}
              </Box>
            </Box>
            <Box />
          </GridItem>

          {/* Page Info */}
          <Box sx={{ display: 'flex', margin: '10px 0' }}>
            {editButton(isEditable, () => navigate.to(
              Urls.PiQPageInfo,
              product.id,
            ))}
            <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Page Info</Typography>
          </Box>
          <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Headline</Typography>
                {displayNotRequiredField('Headline', piqData?.data?.headline)}
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Description</Typography>
                {displayNotRequiredField('Description', piqData?.data?.description)}
              </Box>
            </Box>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Call-to-Actions</Typography>
                {displayNotRequiredField('Call-to-Actions', piqData?.data?.callToActions)}
              </Box>
            </Box>
          </GridItem>

          {/* Consumer Form Fill Fields */}
          <Box sx={{ display: 'flex', margin: '10px 0' }}>
            {editButton(isEditable, () => navigate.to(
              Urls.PiQFormFields,
              product.id,
            ))}
            <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Consumer Form Fill Fields</Typography>
          </Box>
          <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Address Required</Typography>
                {piqData?.data?.addressFieldsRequired
                  ? (
                    <Typography style={{ textTransform: 'capitalize' }}>
                      {piqData?.data?.addressFieldsRequired}
                    </Typography>
                  ) : <Typography fontWeight="bold">No Address Required</Typography>}
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Additional Comments Required</Typography>
                {piqData?.data?.additionalCommentsRequired
                  ? (
                    <Typography style={{ textTransform: 'capitalize' }}>
                      {piqData?.data?.additionalCommentsRequired}
                    </Typography>
                  ) : <Typography fontWeight="bold">No Additional Comments Required</Typography>}
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Qualifying Question 1</Typography>
                {displayNotRequiredField('Qualifying Question 1', piqData?.data?.qualifyingQuestionOne)}
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Qualifying Question 1 Options</Typography>
                {displayNotRequiredField('Qualifying Question 1 Options', piqData?.data?.qualifyingQuestionOneOptions)}
              </Box>
            </Box>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Qualifying Question 2</Typography>
                {displayNotRequiredField('Qualifying Question 2', piqData?.data?.qualifyingQuestionTwo)}
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Qualifying Question 2 Options</Typography>
                {displayNotRequiredField('Qualifying Question 2 Options', piqData?.data?.qualifyingQuestionTwoOptions)}
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Thank You Message</Typography>
                {displayNotRequiredField('Thank You Message', piqData?.data?.thankYouMessage)}
              </Box>
            </Box>
          </GridItem>

          {/* Tracking */}
          <Box sx={{ display: 'flex', margin: '10px 0' }}>
            {editButton(isEditable, () => navigate.to(
              Urls.PiQTracking,
              product.id,
            ))}
            <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Tracking</Typography>
          </Box>
          <GridItem sizes={[6, 6]}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">CallCap Tracking Phone Number</Typography>
                {displayNotRequiredField('CallCap Tracking Phone Number', piqData?.data?.callCapTrackingPhoneNumber)}
              </Box>
            </Box>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Form Fill Lead Email Address</Typography>
                {displayNotRequiredField('Form Fill Lead Email Address', piqData?.data?.formFillLeadEmailAddress)}
              </Box>
            </Box>
          </GridItem>
        </Box>
      </GridItem>

      {/* Design */}
      <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid #000000' }}>
        <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Design</Typography>
        <Box>
          {/* Background Image */}
          <Box sx={{ display: 'flex', marginBottom: '10px' }}>
            {editButton(isEditable, () => navigate.to(
              Urls.PiQBackgroundImage,
              product.id,
            ))}
            <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Background Image</Typography>
          </Box>
          <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Background Image Preference</Typography>
                {displayNotRequiredField('Background Image Preference', piqData?.data?.backgroundImagePreference)}
              </Box>
            </Box>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Background Image Asset</Typography>
                {displayAssets(backgroundImages, 'Background Image')}
              </Box>
            </Box>
          </GridItem>

          {/* Assets */}
          <Box sx={{ display: 'flex', margin: '10px 0' }}>
            {editButton(isEditable, () => navigate.to(
              Urls.PiQAssets,
              product.id,
            ))}
            <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Assets</Typography>
          </Box>
          <GridItem sizes={[6, 6]}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Logo</Typography>
                {displayAssets(logoAssets, 'Logo')}
              </Box>
            </Box>
            <Box />
          </GridItem>
        </Box>
      </GridItem>

      {/* Additional */}
      <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid #000000' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {editButton(isEditable, () => navigate.to(
            Urls.PiQAdditionalInfo,
            product.id,
          ))}
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Additional</Typography>
        </Box>
        <Box>
          <GridItem sizes={[6, 6]}>
            <Box>
              <Box mb={2} sx={{ marginTop: '10px' }}>
                <Typography sx={headingStyle} color="primary">Additional Notes</Typography>
                <Typography fontWeight="bold">
                  {displayNotRequiredField('Additional Notes', piqData?.data?.additionalInfo)}
                </Typography>
              </Box>
            </Box>
          </GridItem>
        </Box>
      </GridItem>
    </>
  );

  return (
    <Grid container sx={{ borderTop: '1px solid #716F6F' }}>
      {/* Status Tracker */}
      {showTracker && (
        <GridItem sizes={[12, 12]} marginBottom={8} sx={{
          padding: '40px 0 0', borderBottom: '1px solid #000000',
        }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {editButton(isEditable, () => {})}
            <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Order Status</Typography>
          </Box>
          <Box>
            <OrderTracker
              key={product?.id}
              status={status}
              productName={product?.pdOfferingType}
              product={product}
            />
          </Box>
        </GridItem>
      )}

      {/* General Questions */}
      {GeneralQuestionsSection(offeringType, product.offeringId, product.id, navigate, isEditable) }

      {!product.isRebuild && renderProductData()}

      {/* Actions items */}
      {showTracker && actionItems(false, product?.id)}
    </Grid>
  );
}
