import ContentLoader from 'components/contentLoader/ContentLoader';
import useProduct from 'hooks/useProduct';
import {Urls} from 'navigation/Urls';
import XmoTargetingPageForm from './XmoTargetingPageForm';
import {useParams} from "react-router";

export default function XmoTargeting() {
  const { page } = useParams();
  const {product, onSubmit, onBack} = useProduct(page as Urls);

  return (
      <ContentLoader status={product.status} message={product.message}>
        <XmoTargetingPageForm
            product={product?.content?.data?.solutionStrategy}
            onSubmit={onSubmit}
            onBack={onBack}
        />
      </ContentLoader>
  );
}