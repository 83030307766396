import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { Grid } from '@mui/material';

import { IQFileUploader, IQStockList } from '@gannettdigital/shared-react-components';
import {
  getFiles,
  getGettyAssets,
  selectFilesGetty,
  selectFilesUploaded,
  uploadFiles,
} from 'services/fileUploaderSlice';
import GettyImages from 'pages/websites/design/existing-assets/GettyImages';
import useUpdateAssets from 'hooks/useUpdateAssets';
import ContentLoader from '../contentLoader/ContentLoader';

import { AllUploaders as AllUploadersType, OnlyFileUploader, OnlyGetty } from './CoFileUploader';
import NeStockList from './NeStockList';

export const FileUploader: FC<OnlyFileUploader & { onDelete: (...args: any) => any,
  onSuccess?: (...args: any) => any,
  filesUploaded?: any,
  getRadioValue?: (file: any, value: string) => void }> = (props) => {
  const {
    offeringType, productId, onDelete, filesUploaded, getRadioValue,
  } = props;
  const {
    uploaderType, directory, showCheckboxes, showComments, showRadioButtons, ...fileUploaderProps
  } = props.fileUploaderOptions;
  const { content, status, message } = filesUploaded || useSelector(selectFilesUploaded);

  const dispatch = useDispatch();
  const { watch } = useFormContext();
  const { updateFileStore } = useUpdateAssets(
    watch,
    fileUploaderProps.name,
    undefined,
  );

  const onFileDelete = (args :unknown) => {
    updateFileStore();
    onDelete(args);
  };

  const onSuccessCallback = (newFiles: File[]) => {
    dispatch(uploadFiles({
      websiteId: productId, files: newFiles, uploaderType, offeringType,
    }));
  };

  useEffect(() => {
    dispatch(getFiles({ websiteId: productId, uploaderType, offeringType }));
  }, [productId, uploaderType]);

  return (
    <>
      <IQFileUploader {...fileUploaderProps} onSuccess={onSuccessCallback}
        currentUploadedFiles={content.length}
      />
      {!!content.length && (
        <ContentLoader message={message} status={status}>
          <NeStockList
            name={fileUploaderProps.name}
            files={content.filter(file => file.uploaderType === uploaderType)}
            onDelete={onFileDelete}
            showCheckboxes={showCheckboxes}
            showComments={showComments}
            showRadioButtons={showRadioButtons}
            radioGroupName={fileUploaderProps.radioGroupName}
            radioGroupDefaultValue={fileUploaderProps.radioGroupDefaultValue}
            returnRadioValue={getRadioValue}
          />
        </ContentLoader>
      )}
    </>
  );
};

export const GettyUploader: FC<OnlyGetty & { onDelete: (...args: any) => any }> = (props) => {
  const {
    name, displayOnly, showCheckboxes, showComments, productType,
  } = props.gettyImagesUploaderOptions;
  const { orderId, orderItemId, onDelete } = props;

  const { message, status, content } = useSelector(selectFilesGetty);

  const dispatch = useDispatch();
  const { watch } = useFormContext();
  const { updateGettyStore } = useUpdateAssets(
    watch,
    undefined,
    name,
  );

  const onFileDelete = (args: unknown) => {
    updateGettyStore();
    onDelete(args);
  };

  useEffect(() => {
    dispatch(getGettyAssets({ orderId, orderItemId, productType }));
  }, [orderId]);

  return (
    <>
      <GettyImages displayOnly={displayOnly} productType={productType} />
      {!!content.length && (
        <ContentLoader message={message} status={status}>
          <IQStockList
            name={name}
            files={content.map((img) => ({ ...img, tag: 'stock' })) as any}
            onDelete={onFileDelete}
            showCheckboxes={showCheckboxes}
            showComments={showComments}
          />
        </ContentLoader>
      )}
    </>
  );
};

export const AllUploaders: FC<
AllUploadersType & { onFileDelete: (...args: any) => any;
  onGettyDelete: (...args: any) => any, gettyDescription?: string }
> = (props) => {
  const {
    orderId, orderItemId, offeringType, productId, productType, onFileDelete, onGettyDelete, gettyDescription,
  } = props;
  const {
    uploaderType, directory, showCheckboxes, showComments, ...fileUploaderProps
  } = props.fileUploaderOptions;

  const dispatch = useDispatch();
  const { watch } = useFormContext();

  const { content: files, message: filesMessage, status: filesStatus } = useSelector(selectFilesUploaded);

  const {
    name: gettyName, displayOnly, showCheckboxes: showGettyCheckboxes, showComments: showGettyComments,
  } = props.gettyImagesUploaderOptions;

  const { message: gettyMessage, status: gettyStatus, content: gettyContent } = useSelector(selectFilesGetty);

  const { updateAllAssetsStore } = useUpdateAssets(
    watch,
    fileUploaderProps.name,
    gettyName,
  );

  const handleFileDelete = (args :unknown) => {
    updateAllAssetsStore();
    onFileDelete(args);
  };

  const handleGettyDelete = (args: unknown) => {
    updateAllAssetsStore();
    onGettyDelete(args);
  };

  const status = [filesStatus, gettyStatus];
  const message = [filesMessage, gettyMessage];

  const hasFiles = files.length || gettyContent.length;

  const onSuccess = (newFiles: File[]) => {
    dispatch(uploadFiles({
      websiteId: productId, files: newFiles, uploaderType, offeringType,
    }));
  };

  useEffect(() => {
    dispatch(getGettyAssets({ orderId, orderItemId, productType }));
  }, [orderId]);

  useEffect(() => {
    dispatch(getFiles({ websiteId: productId, uploaderType, offeringType }));
  }, [productId, uploaderType]);

  return (
    <>
      <Grid
        item
        sx={{
          display: 'flex',
          gap: '16px',
          button: { maxWidth: '218px', maxHeight: '152px' },
          '.dropzone': { maxWidth: '374px', height: '90px' },
        }}
      >
        <GettyImages displayOnly={displayOnly} productType={productType} gettyDescription={gettyDescription} />
        <IQFileUploader {...fileUploaderProps} onSuccess={onSuccess} currentUploadedFiles={files.length} />
      </Grid>
      {!!hasFiles && (
        <ContentLoader message={message} status={status}>
          {!!files.length && (
          <IQStockList name={fileUploaderProps.name} files={files.filter(file => file.uploaderType === uploaderType)}
            onDelete={handleFileDelete} showCheckboxes={showCheckboxes} showComments={showComments}
          />
          )}
          {!!gettyContent.length && (
            <IQStockList
              name={gettyName}
              files={gettyContent.map((img) => ({ ...img, tag: 'stock' })) as any}
              onDelete={handleGettyDelete}
              showCheckboxes={showGettyCheckboxes}
              showComments={showGettyComments}
            />
          )}
        </ContentLoader>
      )}
    </>
  );
};
