import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Box, FormHelperText, Grid, useTheme,
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import {
  IQCheckbox,
  IQFormTextArea,
  IQLabelTooltip,
  IQTheme,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import { t } from 'i18next';
import LandingPageType from 'models/LandingPageType';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import GridItem from 'components/grid/GridItem';
import NeSelectChip from 'components/chips/NeSelectChip';
import schema from './Audience.schema';

interface Props {
  product: LandingPageType;
  onSubmit: (data) => any;
  onBack: () => any;
}

export type AudienceFormProps = {
  searchEngineMarketing: boolean;
  socialAds: boolean;
  displayAds: boolean;
  otherChannels: boolean;
  otherChannelsDescription: string;
  campaignFocuses: string[];
  targetGeographies: string[];
  additionalAudienceNotes: string;
};

type Checkbox = {
  title: string;
  field: string;
  label: string;
};

const checkboxes :Checkbox[] = [
  {
    title: '',
    field: 'searchEngineMarketing',
    label: t('pages.landing.audience.searchEngineMarketing'),
  },
  {
    title: '',
    field: 'socialAds',
    label: t('pages.landing.audience.socialAds'),
  },
  {
    title: '',
    field: 'displayAds',
    label: t('pages.landing.audience.displayAds'),
  },
  {
    title: '',
    field: 'otherChannels',
    label: t('pages.landing.audience.other'),
  },
];

const ErrorText = ({ hasError, errorText }: { hasError: boolean; errorText: string }) => {
  const theme = useTheme();

  if (!hasError) {
    return null;
  }

  return (
    <FormHelperText role="alert" sx={{
      mt: '2px',
      color: theme.palette.error.main,
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    }}
    >
      <ErrorIcon fontSize="inherit" style={{ fontSize: theme.typography.fontSize }} />
      {errorText}
    </FormHelperText>
  );
};

export default function AudienceForm(props: Props) {
  const { product } = props;

  const [checkboxContainerError, setCheckboxContainerError] = useState(false);
  const theme = useTheme();

  const methods = useForm<AudienceFormProps>({
    mode: 'all',
    defaultValues: {
      searchEngineMarketing: product?.searchEngineMarketing || false,
      socialAds: product?.socialAds || false,
      displayAds: product?.displayAds || false,
      otherChannels: product?.otherChannels || false,
      otherChannelsDescription: product?.otherChannelsDescription || '',
      campaignFocuses: product?.campaignFocuses || [],
      targetGeographies: product?.targetGeographies || [],
      additionalAudienceNotes: product?.additionalAudienceNotes || '',
    },
  });
  const {
    handleSubmit,
    unregister,
    setValue,
    watch,
    formState: {
      isValid, isDirty, isSubmitting, isSubmitSuccessful, touchedFields, dirtyFields,
    },
  } = methods;

  const formCheckboxValues = {
    searchEngineMarketing: watch('searchEngineMarketing'),
    socialAds: watch('socialAds'),
    displayAds: watch('displayAds'),
    otherChannels: watch('otherChannels'),
  };

  const {
    searchEngineMarketing, socialAds, displayAds, otherChannels,
  } = formCheckboxValues;

  useEffect(() => {
    if (!otherChannels) {
      unregister('otherChannelsDescription');
      setValue('otherChannelsDescription', '');
    }
  }, [otherChannels]);

  const validCheckboxes = Object.values(formCheckboxValues).some(value => value);

  const onSubmit = (data: AudienceFormProps) => props.onSubmit(data);

  const ChannelCheckboxes = checkboxes.map(element => (
    <>
      <IQCheckbox {...element} />
      {element.field === 'otherChannels' && otherChannels
        && (
        <Grid item pl="33px" sx={{ '& > div > label': { pb: '4px' }, textarea: { width: 'initial' } }}>
          <IQFormTextArea
            id="otherChannelsDescription"
            name="otherChannelsDescription"
            fullWidth
            rowCount={2}
            required
            showError
          />
        </Grid>
        )}
    </>

  ));

  useEffect(() => {
    const allCheckboxesFalse = Object.values(formCheckboxValues).every(value => !value);

    const {
      searchEngineMarketing, socialAds, displayAds, otherChannels,
    } = touchedFields;

    const {
      searchEngineMarketing: sem, socialAds: social, displayAds: display, otherChannels: other,
    } = dirtyFields;

    const areCheckboxesDirty = [
      searchEngineMarketing, socialAds, displayAds, otherChannels, sem, social, display, other,
    ].some(field => field);

    if (allCheckboxesFalse && areCheckboxesDirty) {
      setCheckboxContainerError(true);
    }

    return () => setCheckboxContainerError(false);
  }, [searchEngineMarketing, socialAds, displayAds, otherChannels]);

  return (
    <DefaultPageLayout
      disableContinue={!isValid || !validCheckboxes}
      onBack={props.onBack}
      header="Audience"
      onContinue={handleSubmit(onSubmit)}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Box
            id="mainForm"
            component="form"
            sx={{ display: 'flex' }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid container xs={8}>
              <GridItem sx={{ '& > div:nth-child(2)': { mb: 0 } }}>
                <IQLabelTooltip
                  labelText={t('pages.landing.audience.channelsLabel')}
                  theme={IQTheme}
                  hasError={checkboxContainerError}
                  required={false}
                  tooltipText=""
                />
                {ChannelCheckboxes}
                <ErrorText hasError={checkboxContainerError} errorText={t('pages.landing.audience.checkboxError')} />
              </GridItem>
              <GridItem
                sx={{
                  '.MuiInputBase-root': { background: theme.palette.background.paper },
                  fieldset: { border: `1px solid ${theme.palette.text.primary}` },
                }}
              >
                <NeSelectChip
                  freeSolo
                  label
                  labelText={t('pages.landing.audience.campaignFocuses')}
                  items={[]}
                  name="campaignFocuses"
                  placeholder={t('pages.landing.audience.chipPlaceholder')}
                  values={product?.campaignFocuses}
                  maxItems={6}
                  labelTooltipText={t('pages.landing.audience.campaignFocusesTooltip')}
                  disableClear
                  schema={schema}
                  theme={IQTheme}
                />
              </GridItem>
              <GridItem
                sx={{
                  '.MuiInputBase-root': { background: theme.palette.background.paper },
                  fieldset: { border: `1px solid ${theme.palette.text.primary}` },
                }}
              >
                <NeSelectChip
                  freeSolo
                  label
                  labelText={t('pages.landing.audience.targetAudience')}
                  items={[]}
                  name="targetGeographies"
                  values={product?.targetGeographies}
                  maxItems={6}
                  disableClear
                  placeholder={t('pages.landing.audience.chipPlaceholder')}
                  schema={schema}
                  theme={IQTheme}
                />
              </GridItem>
              <GridItem sx={{ textarea: { width: 'initial' } }}>
                <IQFormTextArea
                  id="additionalAudienceNotes"
                  name="additionalAudienceNotes"
                  labelText={t('pages.landing.audience.additionalNotes')}
                  fontLabelWeight="600"
                  fullWidth
                  rowCount={2}
                />
              </GridItem>
            </Grid>
            {/* show the dialog if we changed the field and didn't submit the changes */}
            <CoNavigationConfirm
              showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
            />
          </Box>
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
