import i18n from 'i18n/i18n';
import {
  TrackingCustomValidate,
  TrackingSchema, TrackingUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/TrackingSchema";
import {
  TrackingNumberDetailsCustomValidate,
  TrackingNumberDetailsSchema, TrackingNumberDetailsUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/TrackingNumberDetails";
import {
  NotesAndInstructionsCustomValidate,
  NotesAndInstructionsSchema, NotesAndInstructionsUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/NotesAndInstructionsSchema";
import {
  DemandGenDetailsCustomValidate,
  DemandGenDetailsSchema, DemandGenDetailsUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/DemandGenDetailsSchema";
import {
  GDNDisplayCustomValidate,
  GDNDisplaySchema, GDNDisplayUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/GDNDisplaySchema";
import {
  CampaignAssetsCustomValidate,
  CampaignAssetsSchema,
  CampaignAssetsUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/CampaignAssets";
import {
  CallTrackingCustomValidate,
  CallTrackingSchema,
  CallTrackingUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/CallTrackingSchema";
import {
  WebEventsCustomValidate,
  WebEventsSchema,
  WebEventsUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/WebEventsSchema";
import {
  TargetingCustomValidate,
  TargetingSchema,
  TargetingUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/TargetingSchema";

const {t} = i18n;

export const AusDemandGenSchema = (data) => {
  const schema = [
    {
      type: 'object',
      title: t('pages.ausProduct.demandGenDetails.title'),
      schema: DemandGenDetailsSchema(data),
      uiSchema: DemandGenDetailsUISchema,
      customValidate: DemandGenDetailsCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.tracking.title'),
      schema: TrackingSchema(data),
      uiSchema: TrackingUISchema,
      customValidate: TrackingCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.gdnDisplay.title'),
      schema: GDNDisplaySchema(data),
      uiSchema: GDNDisplayUISchema,
      customValidate: GDNDisplayCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.targeting.title'),
      schema: TargetingSchema(data),
      uiSchema: TargetingUISchema,
      customValidate: TargetingCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.campaignAssets.title'),
      schema: CampaignAssetsSchema(data),
      uiSchema: CampaignAssetsUISchema,
      customValidate: CampaignAssetsCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.trackingNumber1Details.title'),
      schema: TrackingNumberDetailsSchema(data),
      uiSchema: TrackingNumberDetailsUISchema(data),
      customValidate: TrackingNumberDetailsCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.notesAndInstructions.title'),
      schema: NotesAndInstructionsSchema(data),
      uiSchema: NotesAndInstructionsUISchema,
      customValidate: NotesAndInstructionsCustomValidate,
    },
  ];

  const trackingRequirements = data.trackingRequirements;
  const indexToInsert = 5;

  if (trackingRequirements === "Call and Web Event Tracking") {
    schema.splice(indexToInsert, 0, {
      type: 'object',
      title: t('pages.ausProduct.webEvents.title'),
      schema: WebEventsSchema(data),
      uiSchema: WebEventsUISchema(data),
      customValidate: WebEventsCustomValidate,
    });
    schema.splice(indexToInsert + 1, 0, {
      type: 'object',
      title: t('pages.ausProduct.callTracking.title'),
      schema: CallTrackingSchema(data),
      uiSchema: CallTrackingUISchema,
      customValidate: CallTrackingCustomValidate,
    });
  } else if (trackingRequirements === "Web Event Tracking") {
    schema.splice(indexToInsert, 0, {
      type: 'object',
      title: t('pages.ausProduct.webEvents.title'),
      schema: WebEventsSchema(data),
      uiSchema: WebEventsUISchema(data),
      customValidate: WebEventsCustomValidate,
    });
  } else if (trackingRequirements === "Call Tracking") {
    schema.splice(indexToInsert, 0, {
      type: 'object',
      title: t('pages.ausProduct.callTracking.title'),
      schema: CallTrackingSchema(data),
      uiSchema: CallTrackingUISchema,
      customValidate: CallTrackingCustomValidate,
    });
  }

  return schema;
};


export const AusDemandGenUISchema = (data) => ({
  'ui:order': [
    ...DemandGenDetailsUISchema['ui:order'],
    ...TrackingUISchema['ui:order'],
    ...GDNDisplayUISchema['ui:order'],
    ...CampaignAssetsUISchema['ui:order'],
    ...TargetingUISchema['ui:order'],
    ...WebEventsUISchema(data)['ui:order'],
    ...CallTrackingUISchema['ui:order'],
    ...TrackingNumberDetailsUISchema(data)['ui:order'],
    ...NotesAndInstructionsUISchema['ui:order'],
  ],
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AusDemandGenCustomValidate = (formData: any, errors: any, uiSchema: any) => errors;
