/* eslint-disable import/no-extraneous-dependencies */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';
import AddWebEvents from 'pages/generic-product/details/custom-fields/AddWebEvents';
import { validateEmail } from 'pages/generic-product/details/custom-fields/EmailTextField';
import { validateUrl } from 'pages/generic-product/details/custom-fields/UrlTextField';

const { t } = i18n;

export const SiteRetargetingWithLookalikesGDNSchema: RJSFSchema | any = (data) => ({
  title: t('pages.siteRetargetingWithLookalikesGDN.accountInfo'),
  // eslint-disable-next-line max-len
  type: 'object',
  required: ['contactName', 'contactNumber', 'contactEmail'],
  properties: {
    generalQuestionsTitle: {
      type: 'string',
      title: t('pages.siteRetargetingWithLookalikesGDN.generalQuestions.generalQuestionsTitle'),
    },
    adCreativeLabel: {
      type: 'string',
      title: t('pages.siteRetargetingWithLookalikesGDN.generalQuestions.adCreativeLabel'),
    },
    targetingType: {
      type: 'string',
      title: t('pages.siteRetargetingWithLookalikesGDN.generalQuestions.targetingTypeLabel'),
      enum: [
        '',
        'City',
        'DMA',
        'National',
      ],
    },
    targetCity: {
      type: 'string',
      title: t('pages.siteRetargetingWithLookalikesGDN.generalQuestions.targetCityLabel'),
      default: '',
    },
    websitesTitle: {
      type: 'string',
      title: t('pages.siteRetargetingWithLookalikesGDN.websites.websitesTitle'),
    },
    isLocalIqWebsite: {
      type: 'string',
      title: t('pages.siteRetargetingWithLookalikesGDN.websites.isLocalIqWebsiteLabel'),
      enum: [
        '',
        'Yes',
        'No',
      ],
      default: '',
    },
    proxyProvisioningTitle: {
      type: 'string',
      title: t('pages.siteRetargetingWithLookalikesGDN.proxyProvisioning.proxyProvisioningTitle'),
    },
    localAreaCode1: {
      type: 'string',
      title: t('pages.siteRetargetingWithLookalikesGDN.proxyProvisioning.localAreaCode1Label'),
      default: '',
    },
    localAreaCode2: {
      type: 'string',
      title: t('pages.siteRetargetingWithLookalikesGDN.proxyProvisioning.localAreaCode2Label'),
      default: '',
    },
    primaryNumber: {
      type: 'string',
      title: t('pages.siteRetargetingWithLookalikesGDN.proxyProvisioning.primaryNumberLabel'),
      default: '',
    },
    isNumberForwardRequired: {
      type: 'string',
      title: t('pages.siteRetargetingWithLookalikesGDN.proxyProvisioning.isNumberForwardRequiredLabel'),
      enum: [
        '',
        'Yes',
        'No',
      ],
      default: '',
    },
    utmDetailsTitle: {
      type: 'string',
      title: t('pages.siteRetargetingWithLookalikesGDN.utmDetails.utmDetailsTitle'),
    },
    implementUtmStrings: {
      type: 'string',
      title: t('pages.siteRetargetingWithLookalikesGDN.utmDetails.implementUtmStringsLabel'),
      enum: [
        '',
        'Yes - Use my UTM strings only',
        'Yes - Use UTM strings Best Practices',
        'No - Do NOT use UTM strings',
      ],
      default: '',
    },
    isCaptureCodeCompatible: {
      type: 'string',
      title: t('pages.siteRetargetingWithLookalikesGDN.utmDetails.captureCodeCompatibleLabel'),
      enum: [
        '',
        'Yes',
        'No',
      ],
      default: '',
    },
    isClientPartOfFranchise: {
      type: 'string',
      title: t('pages.siteRetargetingWithLookalikesGDN.utmDetails.isClientPartOfFranchiseLabel'),
      enum: [
        '',
        'Yes',
        'No',
      ],
      default: '',
    },
    isCaptchaInstalled: {
      type: 'string',
      title: t('pages.siteRetargetingWithLookalikesGDN.utmDetails.isCaptchaInstalledLabel'),
      enum: [
        '',
        'Yes',
        'No',
      ],
      default: '',
    },
    hasAnotherAccount: {
      type: 'string',
      title: t('pages.siteRetargetingWithLookalikesGDN.utmDetails.hasAnotherAccountLabel'),
      description: t('pages.siteRetargetingWithLookalikesGDN.utmDetails.hasAnotherAccountHelpTextLabel'),
      enum: [
        '',
        'Yes, Please use the MMR script',
        'No',
      ],
      default: '',
    },
    doNotProxy: {
      type: 'string',
      title: t('pages.siteRetargetingWithLookalikesGDN.utmDetails.doNotProxyLabel'),
    },
    clientInformationTitle: {
      type: 'string',
      title: t('pages.siteRetargetingWithLookalikesGDN.clientInformation.clientInformationTitle'),
    },
    webEvents: {
      type: 'string',
      title: t('pages.siteRetargetingWithLookalikesGDN.utmDetails.webEventsLabel'),
      enum: [
        '',
        'Please adjust web events per best practices',
        'Use only my web events',
      ],
      default: '',
    },
    contactName: {
      type: 'string',
      title: t('pages.siteRetargetingWithLookalikesGDN.clientInformation.contactNameLabel'),
      default: '',
    },
    contactNumber: {
      type: 'string',
      title: t('pages.siteRetargetingWithLookalikesGDN.clientInformation.contactNumberLabel'),
      default: '',
    },
    contactEmail: {
      type: 'string',
      title: t('pages.siteRetargetingWithLookalikesGDN.clientInformation.contactEmailLabel'),
      description: t('pages.siteRetargetingWithLookalikesGDN.clientInformation.clientInformationHelpText'),
      default: '',
    },
  },
  dependencies: {
    isLocalIqWebsite: {
      oneOf: [
        {
          properties: {
            isLocalIqWebsite: {
              enum: ['Yes'],
            },
            isReachSiteLive: {
              type: 'string',
              title: t('pages.siteRetargetingWithLookalikesGDN.websites.isReachSiteLiveLabel'),
              default: data?.isReachSiteLive || '',
              enum: [
                '',
                'Yes',
                'No',
              ],
            },
          },
        },
      ],
    },
    isReachSiteLive: {
      oneOf: [
        {
          properties: {
            isReachSiteLive: {
              enum: ['No'],
            },
            temporaryLinkLabel: {
              type: 'string',
              title: t('pages.siteRetargetingWithLookalikesGDN.websites.temporaryLinkLabel'),
              description: t('pages.siteRetargetingWithLookalikesGDN.websites.temporaryLinkHelpTextLabel'),
              default: data?.temporaryLinkLabel || '',
            },
          },
        },
      ],
    },
    webEvents: {
      oneOf: [
        {
          properties: {
            webEvents: {
              enum: ['Use only my web events'],
            },
            webEventItems: {
              type: 'string',
              items: {
                type: 'object',
              },
              title: 'Web Events',
              default: '',
            },
          },
        },
      ],
    },
    isCaptchaInstalled: {
      oneOf: [
        {
          properties: {
            isCaptchaInstalled: {
              enum: ['Yes'],
            },
            canProvisioned: {
              type: 'string',
              title: t('pages.siteRetargetingWithLookalikesGDN.utmDetails.canProvisionedLabel'),
              default: data?.canProvisioned || '',
              enum: [
                '',
                'Yes',
                'No - please hold campaign until client is able to remove CAPTCHA',
              ],
            },
          },
        },
      ],
    },
  },
  allOf: [
    {
      if: {
        properties: {
          isCaptureCodeCompatible: {
            enum: ['No'],
          },
          isClientPartOfFranchise: {
            enum: ['Yes'],
          },
        },
      },
      then: {
        properties: {
          reachCodeDescription1: {
            type: 'string',
            title: t('pages.siteRetargetingWithLookalikesGDN.utmDetails.reachCodeDescriptionLabel1'),
          },
          reachCodeDescription2: {
            type: 'string',
            title: t('pages.siteRetargetingWithLookalikesGDN.utmDetails.reachCodeDescriptionLabel2'),
          },
          reachCodeDescription3: {
            type: 'string',
            title: t('pages.siteRetargetingWithLookalikesGDN.utmDetails.reachCodeDescriptionLabel3'),
          },
          reachCodeDescription4: {
            type: 'string',
            title: t('pages.siteRetargetingWithLookalikesGDN.utmDetails.reachCodeDescriptionLabel4'),
          },
          reachCodeDescription5: {
            type: 'string',
            title: t('pages.siteRetargetingWithLookalikesGDN.utmDetails.reachCodeDescriptionLabel5'),
          },
          reachCodeDescription6: {
            type: 'string',
            title: t('pages.siteRetargetingWithLookalikesGDN.utmDetails.reachCodeDescriptionLabel6'),
          },
          websiteTrackingCaptureCodeCompatibleNoClientPartOfFranchiseYes: {
            type: 'string',
            title: t('pages.siteRetargetingWithLookalikesGDN.utmDetails.websiteTrackingLabel'),
            default: 'No Tracking',
            enum: [
              '',
              'No Tracking',
            ],
          },
        },
      },
    },
    {
      if: {
        properties: {
          isCaptureCodeCompatible: {
            enum: ['No'],
          },
          isClientPartOfFranchise: {
            enum: ['No'],
          },
        },
      },
      then: {
        properties: {
          reachCodeDescription1: {
            type: 'string',
            title: t('pages.siteRetargetingWithLookalikesGDN.utmDetails.reachCodeDescriptionLabel1'),
          },
          reachCodeDescription2: {
            type: 'string',
            title: t('pages.siteRetargetingWithLookalikesGDN.utmDetails.reachCodeDescriptionLabel2'),
          },
          reachCodeDescription3: {
            type: 'string',
            title: t('pages.siteRetargetingWithLookalikesGDN.utmDetails.reachCodeDescriptionLabel3'),
          },
          reachCodeDescription4: {
            type: 'string',
            title: t('pages.siteRetargetingWithLookalikesGDN.utmDetails.reachCodeDescriptionLabel4'),
          },
          reachCodeDescription5: {
            type: 'string',
            title: t('pages.siteRetargetingWithLookalikesGDN.utmDetails.reachCodeDescriptionLabel5'),
          },
          reachCodeDescription6: {
            type: 'string',
            title: t('pages.siteRetargetingWithLookalikesGDN.utmDetails.reachCodeDescriptionLabel6'),
          },
          websiteTrackingCaptureCodeCompatibleNoClientPartOfFranchiseNo: {
            type: 'string',
            default: 'Vertical Specific Proxy Domains',
            title: t('pages.siteRetargetingWithLookalikesGDN.utmDetails.websiteTrackingLabel'),
            enum: [
              '',
              'Vertical Specific Proxy Domains',
              'No Tracking',
            ],
          },
        },
      },
    },
    {
      if: {
        properties: {
          isCaptureCodeCompatible: {
            enum: ['Yes'],
          },
          isClientPartOfFranchise: {
            enum: ['Yes'],
          },
        },
      },
      then: {
        properties: {
          websiteTrackingCaptureCodeCompatibleYesClientPartOfFranchiseYes: {
            type: 'string',
            title: t('pages.siteRetargetingWithLookalikesGDN.utmDetails.websiteTrackingLabel'),
            default: 'CaptureCode on Client\'s Site',
            enum: [
              '',
              'CaptureCode on Client\'s Site',
              'Client Center with Lead Engagement Add-On (Edge)',
              'Direct Web Link (DWL) - NO TRACKING',
            ],
          },
        },
        if: {
          properties: {
            websiteTrackingCaptureCodeCompatibleYesClientPartOfFranchiseYes: {
              enum: ['CaptureCode on Client\'s Site',
                'Client Center with Lead Engagement Add-On (Edge)'],
            },
          },
        },
        then: {
          properties: {
            startCampaignOn_3pp: {
              type: 'string',
              title: t('pages.siteRetargetingWithLookalikesGDN.utmDetails.canStartCampaignOn3PPLabel'),
              default: 'No - Hold Campaign until CaptureCode is implemented',
              enum: [
                '',
                'No - Hold Campaign until CaptureCode is implemented',
              ],
            },
          },
        },
      },
    },
    {
      if: {
        properties: {
          isCaptureCodeCompatible: {
            enum: ['Yes'],
          },
          isClientPartOfFranchise: {
            enum: ['No'],
          },
        },
      },
      then: {
        properties: {
          websiteTrackingCaptureCodeCompatibleYesClientPartOfFranchiseNo: {
            type: 'string',
            title: t('pages.siteRetargetingWithLookalikesGDN.utmDetails.websiteTrackingLabel'),
            default: 'CaptureCode on Client\'s Site',
            enum: [
              '',
              'CaptureCode on Client\'s Site',
              'CaptureCode + Third-Party Proxy (3PP)',
              'Client Center with Lead Engagement Add-On (Edge)',
              'Direct Web Link (DWL) - NO TRACKING',
            ],
          },
        },
        if: {
          properties: {
            websiteTrackingCaptureCodeCompatibleYesClientPartOfFranchiseNo: {
              enum: ['CaptureCode on Client\'s Site',
                'CaptureCode + Third-Party Proxy (3PP)',
                'Client Center with Lead Engagement Add-On (Edge)'],
            },
          },
        },
        then: {
          properties: {
            startCampaignOn_3pp: {
              type: 'string',
              title: t('pages.siteRetargetingWithLookalikesGDN.utmDetails.canStartCampaignOn3PPLabel'),
              default: data?.startCampaignOn_3pp || '',
              enum: [
                '',
                'Yes - Start Campaign On 3PP until CaptureCode is Implemented',
                'No - Hold Campaign until CaptureCode is implemented',
              ],
            },
          },
        },
      },
    },
  ],
});

const dropDownKeyFields = [
  'targetingType', 'isLocalIqWebsite', 'isReachSiteLive', 'isNumberForwardRequired', 'implementUtmStrings',
  'isCaptureCodeCompatible', 'isClientPartOfFranchise', 'websiteTrackingCaptureCodeCompatibleNoClientPartOfFranchiseNo',
  'websiteTrackingCaptureCodeCompatibleYesClientPartOfFranchiseNo',
  'websiteTrackingCaptureCodeCompatibleYesClientPartOfFranchiseYes',
  'websiteTrackingCaptureCodeCompatibleNoClientPartOfFranchiseYes',
  'startCampaignOn_3pp', 'isCaptchaInstalled', 'canProvisioned', 'hasAnotherAccount', 'webEvents',
];

const dropdownFields = dropDownKeyFields.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'dropdown' },
}), {});

const headingTextLabelKeyFields = [
  'generalQuestionsTitle', 'websitesTitle', 'proxyProvisioningTitle', 'utmDetailsTitle', 'clientInformationTitle',
];

const headingTextLabelFields = headingTextLabelKeyFields.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'headingTextLabel' },
}), {});

const textInputFieldKeyFields = [
  'targetCity', 'localAreaCode1', 'localAreaCode2', 'doNotProxy',
  'contactName',
];

const textInputFields = textInputFieldKeyFields.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textInputField' },
}), {});

const textLabelKeyFields = [
  'adCreativeLabel', 'reachCodeDescription1', 'reachCodeDescription2', 'reachCodeDescription3',
  'reachCodeDescription4', 'reachCodeDescription5', 'reachCodeDescription6',
];

const textLabelFields = textLabelKeyFields.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textLabel' },
}), {});

const phoneNumberTextKeyFields = [
  'primaryNumber', 'contactNumber',
];

const phoneNumberTextFields = phoneNumberTextKeyFields.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'phoneNumberTextField' },
}), {});

export const SiteRetargetingWithLookalikesGDNUISchema = (data) => ({
  'ui:order': ['generalQuestionsTitle', 'adCreativeLabel', 'targetingType', 'targetCity',
    'websitesTitle', 'isLocalIqWebsite', 'isReachSiteLive', 'temporaryLinkLabel', 'proxyProvisioningTitle',
    'localAreaCode1', 'localAreaCode2', 'primaryNumber', 'isNumberForwardRequired', 'utmDetailsTitle',
    'implementUtmStrings', 'isCaptureCodeCompatible', 'isClientPartOfFranchise',
    'websiteTrackingCaptureCodeCompatibleNoClientPartOfFranchiseNo',
    'websiteTrackingCaptureCodeCompatibleYesClientPartOfFranchiseNo',
    'websiteTrackingCaptureCodeCompatibleYesClientPartOfFranchiseYes',
    'websiteTrackingCaptureCodeCompatibleNoClientPartOfFranchiseYes',
    'startCampaignOn_3pp', 'reachCodeDescription1', 'reachCodeDescription2', 'reachCodeDescription3',
    'reachCodeDescription4', 'reachCodeDescription5', 'reachCodeDescription6', 'isCaptchaInstalled', 'canProvisioned',
    'hasAnotherAccount', 'doNotProxy', 'webEvents', 'webEventItems', 'value', 'webEventUrl',
    'webEventName', 'clientInformationTitle', 'contactName', 'contactNumber', 'contactEmail',
  ],

  temporaryLinkLabel: {
    'ui:widget': 'UrlTextWidget',
  },
  contactEmail: {
    'ui:widget': 'EmailTextWidget',
  },
  webEventItems: {
    'ui:widget': (props) => <AddWebEvents {...props} data={data} />,
  },

  ...dropdownFields,
  ...headingTextLabelFields,
  ...textInputFields,
  ...textLabelFields,
  ...phoneNumberTextFields,
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SiteRetargetingWithLookalikesGDNCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.contactName === '') {
    errors.contactName.addError(t('pages.siteRetargetingWithLookalikesGDN.clientInformation.contactNameErrorMessage'));
    errors.addError(true);
  }
  if (formData?.contactNumber === '') {
    errors.contactNumber
      .addError(t('pages.siteRetargetingWithLookalikesGDN.clientInformation.contactNumberErrorMessage'));
    errors.addError(true);
  }
  if (formData?.contactEmail === '') {
    errors.contactEmail
      .addError(t('pages.siteRetargetingWithLookalikesGDN.clientInformation.contactEmailErrorMessage'));
    errors.addError(true);
  }
  if (formData?.contactEmail !== '' && !validateEmail(formData?.contactEmail)) {
    errors.contactEmail.addError(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'));
    errors.addError(true);
  }
  if (formData?.isReachSiteLive === 'No') {
    if (formData?.temporaryLinkLabel !== '' && !validateUrl(formData?.temporaryLinkLabel)) {
      errors.temporaryLinkLabel.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
      errors.addError(true);
    }
  }
  return errors;
};
