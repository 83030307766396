import { AddonsOfferingTypes } from 'pages/business/campaignIds/CampaignIds';

export const getCIDStatus = (campaignStatuses, orderItemId) => {
  const item = campaignStatuses.filter(obj => Number(obj.orderItemId) === Number(orderItemId));
  if (item.length > 0 && item[0].status === 'CAMPAIGN_STATUS_FAILED') {
    return 'Failed';
  }
  if (item.length > 0 && item[0].status === 'CAMPAIGN_STATUS_CREATED') {
    return item[0].campaignId;
  }

  return 'Pending';
};

export const getOrderItemStatus = (orderDetails, campaignStatuses, orderItemId) => {
  const orderItem = orderDetails.filter(item => item.orderItem.id === orderItemId).at(0);
  const isMasterAddon = orderItem?.productName === 'MasterAddon';
  if (isMasterAddon) {
    const addonItems = orderDetails.filter(item => AddonsOfferingTypes.includes(item.productName));
    const addonItemStatuses = addonItems.map(obj => getCIDStatus(campaignStatuses, obj.orderItem.id));

    if (addonItemStatuses.includes('Failed')) {
      return 'Off Track';
    }
    if (addonItemStatuses.includes('Pending')) {
      return 'On Track';
    }
  }
  const status = getCIDStatus(campaignStatuses, orderItemId);
  if (status === 'Failed') {
    return 'Off Track';
  }
  if (status === 'Pending') {
    return 'On Track';
  }

  return orderDetails?.filter(details => details?.orderItem?.id === orderItemId)?.[0]?.orderStatus?.neonStatus;
};

export const setServiceAreaLabels = (values) => {
  if (values.length > 0) {
    const firstElement = values[0];
    if (typeof firstElement === 'string') {
      return values;
    }
    if (typeof firstElement === 'object' && firstElement !== null) {
      const areas = [...values];
      return areas.map(el => (el.label));
    }
  }
  return [];
};
