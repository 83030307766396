/* eslint-disable no-param-reassign */
import {DefaultPageLayout} from 'layouts/DefaultPageLayout';
import GridItem from 'components/grid/GridItem';
import GridContainer from 'components/grid/GridContainer';
import {
  IQButtonRadioContainer,
  IQFormInput, IQFormSelect, IQFormTextArea,
  IQTheme,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import {FormProvider, useForm} from 'react-hook-form';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import schema from './XmoSnapchat.schema';
import {FormControlLabel, Radio, RadioGroup, Typography} from "@mui/material";
import schemaValidate from "components/schemaValidate";
import {useTranslation} from "react-i18next";
import XmoStepType from "models/XmoStepType";
import {
  callToActionButtonItems, geoTargetingTypeItems
} from "pages/xmo/tactics/snapchat/XmoSnapchatService";

export type SnapchatFormProps = {
  disableThePublisher: string,
  advertiserLegalBusinessName: string,
  campaignObjective: string,
  geoTargetingType: string,
  geoTargetingDetails: string,
  isThisAHousingCreditOrEmploymentCampaign: string,
  isThisAPoliticalCampaign: string,
  callToActionButton: string,
}

interface Props {
  product: XmoStepType;
  onSubmit: (data: any) => void;
  onBack: () => void;
}

export default function XmoSnapchatForm({product, onSubmit, onBack}: Props) {
  const {t} = useTranslation();
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      disableThePublisher: product?.disableThePublisher || '',
      advertiserLegalBusinessName: product?.advertiserLegalBusinessName || '',
      campaignObjective: product?.campaignObjective || '',
      geoTargetingType: product?.geoTargetingType || '',
      geoTargetingDetails: product?.geoTargetingDetails || '',
      isThisAHousingCreditOrEmploymentCampaign: product?.isThisAHousingCreditOrEmploymentCampaign || '',
      isThisAPoliticalCampaign: product?.isThisAPoliticalCampaign || '',
      callToActionButton: product?.callToActionButton || '',
    },
  });

  const handleFormSubmit = (data: any) => {
    const pageFormData = {
      snapchat: data
    };
    onSubmit(pageFormData);
  };

  const {
    handleSubmit, register,
    formState: {
      isValid,
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
    },
  } = methods;

  return (
      <DefaultPageLayout
          disableContinue={!isValid}
          onBack={onBack}
          header="Snapchat"
          onContinue={handleSubmit(handleFormSubmit)}
      >
        <ValidationProvider schema={schema}>
          <FormProvider {...methods}>
            <GridContainer fullWidth>
              <GridItem sizes={[12]}>
                <Typography
                    variant="subtitle1"
                    fontWeight={600}
                >
                  {t('pages.xmo.snapchat.disableThisPublisher')}
                  <RadioGroup
                      id="disableThePublisher"
                      name="disableThePublisher"
                      defaultValue={product?.disableThePublisher || ''}
                      row
                  >
                    {['Yes', 'No'].map(opt => (
                        <FormControlLabel
                            key={opt}
                            control={<Radio value={opt}/>}
                            label={opt}
                            {...register('disableThePublisher', {
                              validate: (value) => schemaValidate(value, 'disableThePublisher', schema),
                            })}
                        />
                    ))}
                  </RadioGroup>
                </Typography>
              </GridItem>
              <GridItem sizes={[12]}>
                <IQFormInput
                    schema={schema}
                    theme={IQTheme}
                    fontLabelWeight="600"
                    id="advertiserLegalBusinessName"
                    name="advertiserLegalBusinessName"
                    fullWidth
                    showError
                    labelText={t('pages.xmo.snapchat.advertiserLegalBusinessName')}
                />
              </GridItem>
              <GridItem sizes={[12]}>
                <Typography
                    variant="subtitle1"
                    fontWeight={600}
                >
                  {t('pages.xmo.snapchat.campaignObjective')}
                  <RadioGroup
                      id="campaignObjective"
                      name="campaignObjective"
                      defaultValue={product?.campaignObjective || ''}
                      row
                  >
                    {['Website Traffic', 'Brand Awareness'].map(opt => (
                        <FormControlLabel
                            key={opt}
                            control={<Radio value={opt}/>}
                            label={opt}
                            {...register('campaignObjective', {
                              validate: (value) => schemaValidate(value, 'campaignObjective', schema),
                            })}
                        />
                    ))}
                  </RadioGroup>
                </Typography>
              </GridItem>
              <GridItem sizes={[12]}>
                <IQButtonRadioContainer
                    label={t('pages.xmo.snapchat.geoTargetingType')}
                    name="geoTargetingType"
                    optionsList={geoTargetingTypeItems}
                    required
                />
              </GridItem>
              <GridItem sizes={[12]}>
                <IQFormTextArea
                    fontLabelWeight="600"
                    id="geoTargetingDetails"
                    name="geoTargetingDetails"
                    fullWidth
                    showError
                    labelText={t('pages.xmo.snapchat.geoTargetingDetails')}
                />
              </GridItem>
              <GridItem sizes={[12]}>
                <Typography
                    variant="subtitle1"
                    fontWeight={600}
                >
                  {t('pages.xmo.snapchat.isThisAHousingCreditOrEmploymentCampaign')}
                  <RadioGroup
                      id="isThisAHousingCreditOrEmploymentCampaign"
                      name="isThisAHousingCreditOrEmploymentCampaign"
                      defaultValue={product?.isThisAHousingCreditOrEmploymentCampaign || ''}
                      row
                  >
                    {['Yes', 'No'].map(opt => (
                        <FormControlLabel
                            key={opt}
                            control={<Radio value={opt}/>}
                            label={opt}
                            {...register('isThisAHousingCreditOrEmploymentCampaign', {
                              validate: (value) => schemaValidate(value, 'isThisAHousingCreditOrEmploymentCampaign', schema),
                            })}
                        />
                    ))}
                  </RadioGroup>
                </Typography>
              </GridItem>
              <GridItem sizes={[12]}>
                <Typography
                    variant="subtitle1"
                    fontWeight={600}
                >
                  {t('pages.xmo.snapchat.isThisAPoliticalCampaign')}
                  <RadioGroup
                      id="isThisAPoliticalCampaign"
                      name="isThisAPoliticalCampaign"
                      defaultValue={product?.isThisAPoliticalCampaign || ''}
                      row
                  >
                    {['Yes', 'No'].map(opt => (
                        <FormControlLabel
                            key={opt}
                            control={<Radio value={opt}/>}
                            label={opt}
                            {...register('isThisAPoliticalCampaign', {
                              validate: (value) => schemaValidate(value, 'isThisAPoliticalCampaign', schema),
                            })}
                        />
                    ))}
                  </RadioGroup>
                </Typography>
              </GridItem>
              <GridItem sizes={[12]}>
                <IQFormSelect
                    id="callToActionButton"
                    name="callToActionButton"
                    labelText={t('pages.xmo.snapchat.callToActionButton')}
                    items={callToActionButtonItems}
                    defaultValue=""
                    theme={IQTheme}
                    fontLabelWeight="600"
                    required
                    fullWidth
                    showError
                    schema={schema}
                    placeholder={"Select CTA button"}
                />
              </GridItem>
            </GridContainer>
            <CoNavigationConfirm
                showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
            />
          </FormProvider>
        </ValidationProvider>
      </DefaultPageLayout>
  );
}
