import i18n from 'i18n/i18n';

import {
  VideoCreativeServiceCustomValidate,
  VideoCreativeServiceSchema,
  VideoCreativeServiceUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/VideoCreativeServiceSchema";
import {
  NotesAndInstructionsCustomValidate,
  NotesAndInstructionsSchema,
  NotesAndInstructionsUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/NotesAndInstructionsSchema";

const { t } = i18n;

export const AusVideoCreativeServiceSchema = (data) => {
  const schema = [
    {
      type: 'object',
      title: t('pages.ausProduct.ausVideoCreativeService.videoCreativeService.title'),
      schema: VideoCreativeServiceSchema(data),
      uiSchema: VideoCreativeServiceUISchema,
      customValidate: VideoCreativeServiceCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.notesAndInstructions.title'),
      schema: NotesAndInstructionsSchema(data),
      uiSchema: NotesAndInstructionsUISchema,
      customValidate: NotesAndInstructionsCustomValidate,
    }
  ];
  return schema;
};

export const AusVideoCreativeServicUISchema = (data) => ({
  'ui:order': [
    ...VideoCreativeServiceUISchema['ui:order'],
    ...NotesAndInstructionsUISchema['ui:order'],
  ],
});

export const AusVideoCreativeServicCustomValidate = (formData: any, errors, uiSchema) => errors;