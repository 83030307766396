/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import { useTranslation } from 'react-i18next';
import {
  FormControlLabel, Link, Radio, RadioGroup, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import GridItem from 'components/grid/GridItem';
import GridContainer from 'components/grid/GridContainer';
import {
  IQFormInput, IQFormSelect, IQFormTextArea,
  IQTheme, IQThemeV2,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import { FormProvider, useForm } from 'react-hook-form';
import schemaValidate from 'components/schemaValidate';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import NePhoneInput from 'components/NePhoneInputContainer/IQPhoneInputContainer';
import {
  ProxyProvisioningPageFormProps,
} from 'pages/youtube/proxy-provisioning/ProxyProvisioningPageForm';
import GenericCard from 'components/generic-card/GenericCard';
import WebEventFormDialog from 'pages/youtube/webevents/WebEvent';
import { updateOrAddDataBasedOnSourceId } from 'pages/youtube/YouTubeService';
import {
  updateOrAddDataBasedOnSourceIdTrackingLine,
} from 'pages/xmo/solutionStrategy/trackingLine/TrackingLineService';
import TrackingLineFormDialog from 'pages/xmo/solutionStrategy/trackingLine/TrackingLine';
import {
  baseWebsiteDropDown,
  yesNoDropDown,
  websiteTrackingDropDown,
  canWeStartYourCampaignOn3ppDropDown, advertiserYelpProfileRatingsDropDown,
} from 'pages/xmo/solutionStrategy/proxy/XmoProxyService';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import schema from './XmoProxy.schema';

interface Props {
  productData: any;
  onSubmit: (data: any) => void;
  onBack: () => void;
}

export type DetailsFormProps = {
  localAreaCodePreference1: string,
  localAreaCodePreference2: string,
  primaryProvisioningNumber: string,
  isPrimaryBusinessNumberNeedToForward: string,
  businessNameForCallVerification: string,
  clientWantPhoneTracking: string,
  shouldWeImplementUtmString: string,
  utmToUse: string,
  isCaptchaInstalled: string,
  captureCodeCompatible: string,
  isClientPartOfAFranchise: string,
  websiteTracking: string,
  canWeStartYourCampaignOn3pp: string,
  trackingForTheCaptchaPiece: string,
  clientHaveAnotherMaidAccount: string,
  proxyTheFollowingAreas: string,
  webEventType: string,
  forwardingNumber: string,
  advertiserYelpProfileRatings:string,
  yelpBusinessProfileUrl:string,
  restrictedCategories:string,
  webEventFields: {
    sourceId: string;
    webEventValue: string;
    webEventUrl: string;
    webEventName: string;
  }[],
  trackingLineFields: {
    sourceId: string;
    originalNumber: string,
    forwardToNumber: string,
    areaCodePreference: string,
  }[],
};

export default function XmoProxyPageForm({ productData, onSubmit, onBack }: Props) {
  const { t } = useTranslation();
  const prodXmoStepDetails = productData?.details;
  const product = productData?.solutionStrategy;
  const [businessNameForCallVerificationState, setBusinessNameForCallVerificationState] = useState(product?.businessNameForCallVerification || '');
  const [primaryBusinessNumberNeedToForward, setPrimaryBusinessNumberNeedToForward] = useState(product?.isPrimaryBusinessNumberNeedToForward || '');
  const [clientWantPhoneTrackingOrganic, setClientWantPhoneTrackingOrganic] = useState(product?.clientWantPhoneTracking || '');
  const [implementUtmStringValue, setImplementUtmStringValue] = useState(product?.shouldWeImplementUtmString || '');
  const [isCaptchaInstalledValue, setIsCaptchaInstalledValue] = useState(product?.isCaptchaInstalled || '');
  const [isCaptureCodeCompatibleValue, setCaptureCodeCompatibleValue] = useState(product?.captureCodeCompatible || '');
  const [isClientPartOfAFranchiseValue, setIsClientPartOfAFranchiseValue] = useState(product?.isClientPartOfAFranchise || '');
  const [isWebsiteTrackingValue, setIsWebsiteTrackingValue] = useState(product?.websiteTracking || '');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [canWeStartYourCampaignOn3pp, setCanWeStartYourCampaignOn3ppValue] = useState(product?.canWeStartYourCampaignOn3pp || '');
  const [isDisabled, setIsDisabled] = useState(false);
  const [websiteDropDown, setWebsiteDropDown] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [variant, setVariant] = useState<any>(productData?.variant || '');
  const [advertiserYelpProfileRatings, setAdvertiserYelpProfileRatings] = useState(product?.advertiserYelpProfileRatings || '');
  const [isYelpUrlToShow, setIsYelpUrlToShow] = useState(false);
  /** Web Events State Management Logic and Various Operations * */
  const [webEventFields, setWebEventFields] = useState<ProxyProvisioningPageFormProps['webEventFields']>(product.webEventFields || []);
  const [webEventsFieldsFormDialogOpen, setWebEventsFieldsFormDialogOpen] = useState(false);
  const [selectedWebEventFields, setSelectedWebEventEventFields] = useState<ProxyProvisioningPageFormProps['webEventFields']>([]);
  const [webEventsSelectedValue, setWebEventsSelectedValue] = useState(product?.webEventType || '');
  const webEventFieldLabels = ['webEventName', 'webEventValue', 'webEventUrl'];

  /** Tracking Line Fields State Management Logic and Various Operations * */
  const [trackingLineFields, setTrackingLineFields] = useState<any>(product.trackingLineFields || []);
  const [trackingLineFieldsFormDialogOpen, setTrackingLineFieldsFormDialogOpen] = useState(false);
  const [selectedTrackingLineFields, setSelectedTrackingLineFields] = useState<any>([]);
  const trackingLineFieldLabels = ['originalNumber', 'forwardToNumber', 'areaCodePreference'];

  const methods = useForm<DetailsFormProps>({
    mode: 'onChange',
    defaultValues: {
      localAreaCodePreference1: product?.localAreaCodePreference1 || '',
      localAreaCodePreference2: product?.localAreaCodePreference2 || '',
      primaryProvisioningNumber: product?.primaryProvisioningNumber || '',
      isPrimaryBusinessNumberNeedToForward: product?.isPrimaryBusinessNumberNeedToForward || '',
      businessNameForCallVerification: product?.businessNameForCallVerification || '',
      clientWantPhoneTracking: product?.clientWantPhoneTracking || '',
      shouldWeImplementUtmString: product?.shouldWeImplementUtmString || '',
      utmToUse: product?.utmToUse || '',
      isCaptchaInstalled: product?.isCaptchaInstalled || '',
      captureCodeCompatible: product?.captureCodeCompatible || '',
      isClientPartOfAFranchise: product?.isClientPartOfAFranchise || '',
      websiteTracking: product?.websiteTracking || '',
      canWeStartYourCampaignOn3pp: product?.canWeStartYourCampaignOn3pp || '',
      trackingForTheCaptchaPiece: product?.trackingForTheCaptchaPiece || '',
      clientHaveAnotherMaidAccount: product?.clientHaveAnotherMaidAccount || '',
      proxyTheFollowingAreas: product?.proxyTheFollowingAreas || '',
      webEventType: product?.webEventType || '',
      forwardingNumber: product?.forwardingNumber || '',
      advertiserYelpProfileRatings: product?.advertiserYelpProfileRatings || '',
      yelpBusinessProfileUrl: product?.yelpBusinessProfileUrl || '',
      webEventFields: Array.isArray(product?.webEventFields) && product.webEventFields.length > 0
        ? product.webEventFields
        : webEventFields,
      trackingLineFields,
    },
  });

  const {
    register, handleSubmit, setValue, watch,
    formState: {
      isValid, isDirty, isSubmitSuccessful, isSubmitting,
    },
  } = methods;

  const onDeleteWebEventFields = async (webEventField: any) => {
    const updatedWebEvents = webEventFields.filter(
      (webEvent) => webEvent.sourceId !== webEventField.sourceId,
    );
    setWebEventFields(updatedWebEvents);
  };

  const handleWebEventsFieldsFormDialogOpen = () => setWebEventsFieldsFormDialogOpen(true);

  const handleWebEventFieldsSelection = (selectedWebEventField) => {
    setSelectedWebEventEventFields(selectedWebEventField);
    handleWebEventsFieldsFormDialogOpen();
  };

  const handleWebEventsFieldsFormDialogClose = () => {
    setWebEventsFieldsFormDialogOpen(false);
    setSelectedWebEventEventFields([]);
  };

  const updateWebEvents = (newData) => {
    setWebEventFields(prevData => updateOrAddDataBasedOnSourceId(prevData, newData));
  };

  const handleIsCaptchaInstalledRadioChange = (event) => {
    setIsCaptchaInstalledValue(event.target.value);
    if (event.target.value === 'No') {
      setValue('trackingForTheCaptchaPiece', '');
    }
  };

  const onDeleteTrackingLineFields = async (trackingLineField: any) => {
    const updatedTrackingLines = trackingLineFields.filter(
      (trackingLine: any) => trackingLine.sourceId !== trackingLineField.sourceId,
    );
    setTrackingLineFields(updatedTrackingLines);
  };

  const handleTrackingLineFieldsFormDialogOpen = () => setTrackingLineFieldsFormDialogOpen(true);

  const handleTrackingLineFieldsSelection = (trackingLineField: any) => {
    setSelectedTrackingLineFields(trackingLineField);
    handleTrackingLineFieldsFormDialogOpen();
  };

  const handleTrackingLineFieldsFormDialogClose = () => {
    setTrackingLineFieldsFormDialogOpen(false);
    setSelectedTrackingLineFields([]);
  };

  const updateTrackingLineFields = (newData) => {
    setTrackingLineFields(prevData => updateOrAddDataBasedOnSourceIdTrackingLine(prevData, newData));
  };

  /** Generic Logic * */
  const handleFormSubmit = (data: DetailsFormProps) => {
    if (primaryBusinessNumberNeedToForward === 'No') {
      data.forwardingNumber = '';
    }
    if (clientWantPhoneTrackingOrganic === 'Yes') {
      data.trackingLineFields = trackingLineFields;
    } else {
      data.trackingLineFields = [];
    }
    if (implementUtmStringValue !== 'Yes - Use my UTM strings only') {
      data.utmToUse = '';
    }
    if (isCaptchaInstalledValue === 'No') {
      data.trackingForTheCaptchaPiece = '';
    }
    if (webEventsSelectedValue === 'Use only my web events') {
      data.webEventFields = webEventFields;
    } else {
      data.webEventFields = [];
    }
    if (!isYelpUrlToShow) {
      data.yelpBusinessProfileUrl = '';
    }

    const pageFormData = {
      solutionStrategy: {
        ...product, ...data,
      },
    };
    onSubmit(pageFormData);
  };

  const handleBusinessNameForCallVerification = (e) => {
    setBusinessNameForCallVerificationState(e.target.value);
  };

  const yelpBusinessProfileUrlData = watch('yelpBusinessProfileUrl');
  useEffect(() => {
    if (isClientPartOfAFranchiseValue === 'No' && isCaptureCodeCompatibleValue === 'Yes') {
      setWebsiteDropDown([
        baseWebsiteDropDown[0],
        {
          value: t('pages.xmo.solutionsStrategy.proxy.baseWebsiteDropDown3'),
          description: t('pages.xmo.solutionsStrategy.proxy.baseWebsiteDropDown3'),
        },
        ...baseWebsiteDropDown.slice(1),
      ]);
    } else {
      setWebsiteDropDown(baseWebsiteDropDown);
    }
  }, [isClientPartOfAFranchiseValue]);

  useEffect(() => {
    if (isClientPartOfAFranchiseValue === 'Yes' && isCaptureCodeCompatibleValue === 'No') {
      setValue('websiteTracking', 'No Tracking');
      setIsDisabled(true);
    } else if (isClientPartOfAFranchiseValue === 'Yes' && isCaptureCodeCompatibleValue === 'Yes') {
      setValue('canWeStartYourCampaignOn3pp', 'No - Hold Campaign until CaptureCode is Implemented');
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [isClientPartOfAFranchiseValue]);

  useEffect(() => {
    const advertiserYelpProfileRatingsData = watch('advertiserYelpProfileRatings');
    if (advertiserYelpProfileRatingsData === 'No Ratings' || advertiserYelpProfileRatingsData === '3+' || advertiserYelpProfileRatingsData === '4+') {
      setIsYelpUrlToShow(true);
    } else {
      setIsYelpUrlToShow(false);
    }
  }, [watch('advertiserYelpProfileRatings')]);

  return (
    <DefaultPageLayout
      disableContinue={!isValid || businessNameForCallVerificationState === '' || (variant === 1 && (advertiserYelpProfileRatings === '' || (isYelpUrlToShow && yelpBusinessProfileUrlData === '')))}
      onBack={onBack}
      header={t('pages.xmo.solutionsStrategy.proxy.proxyTitle')}
      onContinue={handleSubmit(handleFormSubmit)}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Box
            id="mainForm"
            component="form"
            sx={{ display: 'flex' }}
            onSubmit={() => {
              handleSubmit(handleFormSubmit);
            }}
          >
            <GridContainer fullWidth>

              {/* Proxy General Section */}

              <GridItem sizes={[12]}>
                <Typography
                  variant="h6"
                >
                  {t('pages.xmo.solutionsStrategy.general.generalTitle')}
                </Typography>
              </GridItem>
              <GridItem sizes={[12]}>
                <IQFormInput
                  schema={schema}
                  theme={IQTheme}
                  fontLabelWeight="600"
                  id="localAreaCodePreference1"
                  name="localAreaCodePreference1"
                  fullWidth
                  showError
                  labelText={t('pages.xmo.solutionsStrategy.proxy.localAreaCodePreference1')}
                />
              </GridItem>
              <GridItem sizes={[12]}>
                <IQFormInput
                  schema={schema}
                  theme={IQTheme}
                  fontLabelWeight="600"
                  id="localAreaCodePreference2"
                  name="localAreaCodePreference2"
                  fullWidth
                  showError
                  labelText={t('pages.xmo.solutionsStrategy.proxy.localAreaCodePreference2')}
                />
              </GridItem>
              <GridItem sizes={[12]}>
                <NePhoneInput
                  name="primaryProvisioningNumber"
                  defaultValue=""
                  country="US"
                  phoneLabelOptions={{
                    tooltipText: '',
                    labelText: t('pages.xmo.solutionsStrategy.proxy.primaryProvisioningNumber'),
                    hasError: true,
                    theme: IQThemeV2,
                    required: true,
                  }}
                  hasType={false}
                  countryCallingCodeEditable={false}
                  international
                />
              </GridItem>
              <GridItem sizes={[12]}>
                <Typography
                  fontWeight={600}
                  variant="subtitle1"
                >
                  {t('pages.xmo.solutionsStrategy.proxy.isPrimaryBusinessNumberNeedToForward')}
                  <RadioGroup
                    id="isPrimaryBusinessNumberNeedToForward"
                    name="isPrimaryBusinessNumberNeedToForward"
                    defaultValue={product?.isPrimaryBusinessNumberNeedToForward || ''}
                    onChange={(e: any) => setPrimaryBusinessNumberNeedToForward(e.target.value)}
                    row
                  >
                    {[t('pages.xmo.solutionsStrategy.inputValues.yesInputValue'),
                      t('pages.xmo.solutionsStrategy.inputValues.noInputValue')].map(opt => (
                        <FormControlLabel
                          key={opt}
                          control={<Radio value={opt} />}
                          label={opt}
                          {...register('isPrimaryBusinessNumberNeedToForward', {
                            validate: (value) => schemaValidate(value, 'isPrimaryBusinessNumberNeedToForward', schema),
                          })}
                        />
                    ))}
                  </RadioGroup>
                </Typography>
              </GridItem>
              {
                    primaryBusinessNumberNeedToForward === t('pages.xmo.solutionsStrategy.inputValues.yesInputValue')
                    && (
                    <GridItem sizes={[12]}>
                      <NePhoneInput
                        name="forwardingNumber"
                        defaultValue=""
                        country="US"
                        phoneLabelOptions={{
                          tooltipText: '',
                          labelText: t('pages.xmo.solutionsStrategy.proxy.forwardingNumber'),
                          hasError: true,
                          theme: IQThemeV2,
                          required: true,
                        }}
                        hasType={false}
                        countryCallingCodeEditable={false}
                        international
                      />
                    </GridItem>
                    )
                }
              <GridItem sizes={[12]}>
                <IQFormInput
                  schema={schema}
                  theme={IQTheme}
                  fontLabelWeight="600"
                  id="businessNameForCallVerification"
                  name="businessNameForCallVerification"
                  fullWidth
                  showError
                  labelText={t('pages.xmo.solutionsStrategy.proxy.businessNameForCallVerification')}
                  tooltipText={t('pages.xmo.solutionsStrategy.proxy.businessNameForCallVerificationTooltip', { businessName: businessNameForCallVerificationState })}
                  tooltipPlacement="right"
                  tooltipPaddingBottom={14}
                  onChange={handleBusinessNameForCallVerification}
                />
              </GridItem>

              {/* Proxy Organic Phone Tracking Section */}

              <GridItem sizes={[12]}>
                <Typography
                  variant="h6"
                >
                  {t('pages.xmo.solutionsStrategy.proxy.organicPhoneTrackingTitle')}
                </Typography>
              </GridItem>
              <GridItem sizes={[12]}>
                <Typography
                  fontWeight={600}
                  variant="subtitle1"
                >
                  {t('pages.xmo.solutionsStrategy.proxy.clientWantPhoneTracking')}
                  <RadioGroup
                    id="clientWantPhoneTracking"
                    name="clientWantPhoneTracking"
                    defaultValue={product?.clientWantPhoneTracking || ''}
                    onChange={(e: any) => setClientWantPhoneTrackingOrganic(e.target.value)}
                    row
                  >
                    {[t('pages.xmo.solutionsStrategy.inputValues.yesInputValue'),
                      t('pages.xmo.solutionsStrategy.inputValues.noInputValue')].map(opt => (
                        <FormControlLabel
                          key={opt}
                          control={<Radio value={opt} />}
                          label={opt}
                          {...register('clientWantPhoneTracking', {
                            validate: (value) => schemaValidate(value, 'clientWantPhoneTracking', schema),
                          })}
                        />
                    ))}
                  </RadioGroup>
                </Typography>
              </GridItem>
              {
                    clientWantPhoneTrackingOrganic === t('pages.xmo.solutionsStrategy.inputValues.yesInputValue') && (
                    <GridItem sizes={[12]}>
                      {Array.isArray(trackingLineFields) && trackingLineFields.map((trackingLineField) => (
                        <GenericCard
                          key={trackingLineField.sourceId}
                          labels={trackingLineFieldLabels}
                          data={trackingLineField}
                          handleDelete={() => onDeleteTrackingLineFields(trackingLineField)}
                          onEdit={() => handleTrackingLineFieldsSelection(trackingLineField)}
                          onSelect={() => {
                          }}
                        />
                      ))}
                      <TrackingLineFormDialog
                        open={trackingLineFieldsFormDialogOpen}
                        onOpen={handleTrackingLineFieldsFormDialogOpen}
                        onClose={handleTrackingLineFieldsFormDialogClose}
                        updateTrackingLine={updateTrackingLineFields}
                        selectedTrackingLine={selectedTrackingLineFields}
                      />
                    </GridItem>
                    )
}

              {/* Proxy UTM Details Section */}

              <GridItem sizes={[12]}>
                <Typography
                  variant="h6"
                >
                  {t('pages.xmo.solutionsStrategy.proxy.utmDetailsTitle')}
                </Typography>
              </GridItem>
              <GridItem sizes={[12]}>
                <Typography
                  fontWeight={600}
                  variant="subtitle1"
                >
                  {t('pages.xmo.solutionsStrategy.proxy.shouldWeImplementUtmString')}
                  <RadioGroup
                    id="shouldWeImplementUtmString"
                    name="shouldWeImplementUtmString"
                    defaultValue={product?.shouldWeImplementUtmString || ''}
                    onChange={(e: any) => setImplementUtmStringValue(e.target.value)}
                    row
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      {[t('pages.xmo.solutionsStrategy.inputValues.useMyUtmStringsOnly'),
                        t('pages.xmo.solutionsStrategy.inputValues.useUtmBestPractices'),
                        t('pages.xmo.solutionsStrategy.inputValues.doNotUseUtmStrings')].map(opt => (
                          <Box>
                            <FormControlLabel
                              key={opt}
                              control={<Radio value={opt} />}
                              label={opt}
                              {...register('shouldWeImplementUtmString', {
                                validate: (value) => schemaValidate(value, 'shouldWeImplementUtmString', schema),
                              })}
                            />
                          </Box>
                      ))}
                    </Box>
                  </RadioGroup>
                </Typography>
              </GridItem>

              {
                    implementUtmStringValue === t('pages.xmo.solutionsStrategy.inputValues.useMyUtmStringsOnly')
                    && (
                    <GridItem sizes={[12]}>
                      <IQFormInput
                        schema={schema}
                        theme={IQTheme}
                        fontLabelWeight="600"
                        id="utmToUse"
                        name="utmToUse"
                        fullWidth
                        showError
                        labelText={t('pages.xmo.solutionsStrategy.proxy.utmToUse')}
                        subDescription={t('pages.xmo.solutionsStrategy.proxy.utmToUseDescription')}
                      />
                    </GridItem>
                    )
                }

              {/* Capture Code Section */}

              {
                    prodXmoStepDetails.clientHaveLocalIqWebsite === 'No'
                    && (
                    <GridContainer fullWidth>
                      <GridItem sizes={[12]}>
                        <Typography
                          variant="h6"
                        >
                          {t('pages.xmo.solutionsStrategy.proxy.captureCodeLabel')}
                        </Typography>
                      </GridItem>

                      <GridItem sizes={[12]}>
                        <IQFormSelect
                          id="captureCodeCompatible"
                          name="captureCodeCompatible"
                          labelText={t('pages.xmo.solutionsStrategy.proxy.captureCodeCompatibleLabel')}
                          items={yesNoDropDown}
                          onChange={(e: any) => setCaptureCodeCompatibleValue(e.target.value)}
                          defaultValue={product?.captureCodeCompatible || ''}
                          theme={IQTheme}
                          fontLabelWeight="600"
                          fullWidth
                          schema={schema}
                        />
                      </GridItem>

                      <GridItem sizes={[12]}>
                        <IQFormSelect
                          id="isClientPartOfAFranchise"
                          name="isClientPartOfAFranchise"
                          labelText={t('pages.xmo.solutionsStrategy.proxy.isClientPartOfAFranchiseLabel')}
                          items={yesNoDropDown}
                          defaultValue={product?.isClientPartOfAFranchise || ''}
                          onChange={(e: any) => setIsClientPartOfAFranchiseValue(e.target.value)}
                          theme={IQTheme}
                          fontLabelWeight="600"
                          fullWidth
                          schema={schema}
                        />
                      </GridItem>

                      {
                          isCaptureCodeCompatibleValue === 'Yes'
                          && (
                          <GridContainer>
                            <GridItem sizes={[12]}>
                              <Typography fontSize={17}>
                                <a href="https://example.com/" target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {t('pages.xmo.solutionsStrategy.proxy.Link1Description')}
                                </a>
                              </Typography>
                              <Typography fontSize={17}>
                                <a href="https://thesource.reachlocal.com/resources/capcom-live-how-guide"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {t('pages.xmo.solutionsStrategy.proxy.Link2Description')}
                                </a>
                              </Typography>
                            </GridItem>
                          </GridContainer>
                          )
                      }

                      {isCaptureCodeCompatibleValue === 'Yes' ? (
                        <GridItem sizes={[12]}>
                          <IQFormSelect
                            id="websiteTracking"
                            name="websiteTracking"
                            labelText={t('pages.xmo.solutionsStrategy.proxy.websiteTrackingLabel')}
                            items={websiteDropDown}
                            defaultValue={product?.websiteTracking || ''}
                            onChange={(e: any) => setIsWebsiteTrackingValue(e.target.value)}
                            theme={IQTheme}
                            fontLabelWeight="600"
                            fullWidth
                            schema={schema}
                          />
                        </GridItem>
                      ) : isCaptureCodeCompatibleValue === 'No' && (
                      <GridItem sizes={[12]}>
                        <IQFormSelect
                          id="websiteTracking"
                          name="websiteTracking"
                          labelText={t('pages.xmo.solutionsStrategy.proxy.websiteTrackingLabel')}
                          items={websiteTrackingDropDown}
                          defaultValue={product?.websiteTracking || ''}
                          disabled={isDisabled}
                          onChange={(e: any) => setIsWebsiteTrackingValue(e.target.value)}
                          theme={IQTheme}
                          fontLabelWeight="600"
                          fullWidth
                          schema={schema}
                        />
                      </GridItem>
                      )}

                      {(isCaptureCodeCompatibleValue === 'Yes')
                          && (isWebsiteTrackingValue === 'CaptureCode on Client\'s Site'
                              || isWebsiteTrackingValue === 'CaptureCode + Third-Party Proxy (3PP)'
                              || isWebsiteTrackingValue === 'Client Center with Lead Engagement Add-On (Edge')
                          && (
                          <GridItem sizes={[12]}>
                            <IQFormSelect
                              id="canWeStartYourCampaignOn3pp"
                              name="canWeStartYourCampaignOn3pp"
                              labelText={t('pages.xmo.solutionsStrategy.proxy.canWeStartYourCampaignOn3ppLabel')}
                              items={canWeStartYourCampaignOn3ppDropDown}
                              defaultValue={product?.canWeStartYourCampaignOn3pp || ''}
                              onChange={(e: any) => setCanWeStartYourCampaignOn3ppValue(e.target.value)}
                              disabled={isDisabled}
                              theme={IQTheme}
                              fontLabelWeight="600"
                              subDescription={t('pages.xmo.solutionsStrategy.proxy.canWeStartYourCampaignOn3ppHelper')}
                              fullWidth
                              schema={schema}
                            />
                            <Typography fontSize={17}>
                              {t('pages.xmo.solutionsStrategy.proxy.canWeStartYourCampaignOn3ppDescription1')}
                            </Typography>
                            <div>
                              <Typography>
                                {t('pages.xmo.solutionsStrategy.proxy.canWeStartYourCampaignOn3ppDescription2')}
                              </Typography>
                              <Typography>
                                {t('pages.xmo.solutionsStrategy.proxy.canWeStartYourCampaignOn3ppDescription3')}
                              </Typography>
                            </div>
                            <Typography fontSize={17}>
                              {t('pages.xmo.solutionsStrategy.proxy.canWeStartYourCampaignOn3ppDescription4')}
                            </Typography>
                            <div>
                              <Typography>
                                {t('pages.xmo.solutionsStrategy.proxy.canWeStartYourCampaignOn3ppDescription5')}
                              </Typography>
                              <Typography>
                                {t('pages.xmo.solutionsStrategy.proxy.canWeStartYourCampaignOn3ppDescription6')}
                              </Typography>
                            </div>
                            <Typography fontSize={17}>
                              {t('pages.xmo.solutionsStrategy.proxy.canWeStartYourCampaignOn3ppDescription7')}
                            </Typography>
                            <div>
                              <Typography>
                                {t('pages.xmo.solutionsStrategy.proxy.canWeStartYourCampaignOn3ppDescription8')}
                              </Typography>
                              <Typography>
                                {t('pages.xmo.solutionsStrategy.proxy.canWeStartYourCampaignOn3ppDescription9')}
                              </Typography>
                            </div>
                          </GridItem>
                          )}
                    </GridContainer>
                    )
                }

              {/* Proxy Captcha Section */}

              <GridItem sizes={[12]}>
                <Typography
                  variant="h6"
                >
                  {t('pages.xmo.solutionsStrategy.proxy.captchaTitle')}
                </Typography>
              </GridItem>
              <GridItem sizes={[12]}>
                <Typography
                  fontWeight={600}
                  variant="subtitle1"
                >
                  {t('pages.xmo.solutionsStrategy.proxy.isCaptchaInstalled')}
                  <RadioGroup
                    id="isCaptchaInstalled"
                    name="isCaptchaInstalled"
                    defaultValue={watch('isCaptchaInstalled') || ''}
                    onChange={handleIsCaptchaInstalledRadioChange}
                    row
                  >
                    {[t('pages.xmo.solutionsStrategy.inputValues.yesInputValue'),
                      t('pages.xmo.solutionsStrategy.inputValues.noInputValue')].map(opt => (
                        <FormControlLabel
                          key={opt}
                          control={<Radio value={opt} />}
                          label={opt}
                          {...register('isCaptchaInstalled', {
                            validate: (value) => schemaValidate(value, 'isCaptchaInstalled', schema),
                          })}
                        />
                    ))}
                  </RadioGroup>
                </Typography>
              </GridItem>

              {isCaptchaInstalledValue === t('pages.xmo.solutionsStrategy.inputValues.yesInputValue')
                    && (
                    <GridItem sizes={[12]}>
                      <Typography
                        fontWeight={600}
                        variant="subtitle1"
                      >
                        {t('pages.xmo.solutionsStrategy.proxy.trackingForTheCaptchaPiece')}
                        <RadioGroup
                          id="trackingForTheCaptchaPiece"
                          name="trackingForTheCaptchaPiece"
                          defaultValue={product?.trackingForTheCaptchaPiece || ''}
                          row
                        >
                          {[t('pages.xmo.solutionsStrategy.inputValues.yesInputValue'),
                            t('pages.xmo.solutionsStrategy.inputValues.holdCampaignForCaptcha')].map(opt => (
                              <FormControlLabel
                                key={opt}
                                control={<Radio value={opt} />}
                                label={opt}
                                {...register('trackingForTheCaptchaPiece', {
                                  validate: (value) => schemaValidate(value, 'trackingForTheCaptchaPiece', schema),
                                })}
                              />
                          ))}
                        </RadioGroup>
                      </Typography>
                    </GridItem>
                    )}

              <GridItem sizes={[12]}>
                <Typography
                  fontWeight={600}
                  variant="subtitle1"
                >
                  {t('pages.xmo.solutionsStrategy.proxy.clientHaveAnotherMaidAccount')}
                  <RadioGroup
                    id="clientHaveAnotherMaidAccount"
                    name="clientHaveAnotherMaidAccount"
                    defaultValue={product?.clientHaveAnotherMaidAccount || ''}
                    row
                  >
                    {[t('pages.xmo.solutionsStrategy.inputValues.useMmrScript'),
                      t('pages.xmo.solutionsStrategy.inputValues.noInputValue')].map(opt => (
                        <FormControlLabel
                          key={opt}
                          control={<Radio value={opt} />}
                          label={opt}
                          {...register('clientHaveAnotherMaidAccount', {
                            validate: (value) => schemaValidate(value, 'clientHaveAnotherMaidAccount', schema),
                          })}
                        />
                    ))}
                  </RadioGroup>
                </Typography>
              </GridItem>
              <GridItem sizes={[12]}>
                <IQFormTextArea
                  fontLabelWeight="600"
                  id="proxyTheFollowingAreas"
                  name="proxyTheFollowingAreas"
                  fullWidth
                  showError
                  labelText={t('pages.xmo.solutionsStrategy.proxy.proxyTheFollowingAreas')}
                />
              </GridItem>

              {/* Proxy Web Events Section */}

              <GridItem sizes={[12]}>
                <Typography
                  variant="h6"
                >
                  {t('pages.xmo.solutionsStrategy.proxy.webEventsTitle')}
                </Typography>
              </GridItem>
              <GridItem sizes={[12]}>
                <Typography
                  fontWeight={600}
                  variant="subtitle1"
                >
                  {t('pages.xmo.solutionsStrategy.proxy.webEventType')}
                  <RadioGroup
                    id="webEventType"
                    name="webEventType"
                    defaultValue={product?.webEventType || ''}
                    onChange={(e: any) => setWebEventsSelectedValue(e.target.value)}
                    row
                  >
                    {[t('pages.xmo.solutionsStrategy.inputValues.webEventsBestPractices'),
                      t('pages.xmo.solutionsStrategy.inputValues.useOnlyMyWebEvents')].map(opt => (
                        <FormControlLabel
                          key={opt}
                          control={<Radio value={opt} />}
                          label={opt}
                          {...register('webEventType', {
                            validate: (value) => schemaValidate(value, 'webEventType', schema),
                          })}
                        />
                    ))}
                  </RadioGroup>
                </Typography>
              </GridItem>
              {webEventsSelectedValue === t('pages.xmo.solutionsStrategy.inputValues.useOnlyMyWebEvents') && (
                <GridItem sizes={[12]}>
                  {Array.isArray(webEventFields) && webEventFields.map((webEventField) => (
                    <GenericCard
                      key={webEventField.sourceId}
                      labels={webEventFieldLabels}
                      data={webEventField}
                      handleDelete={() => onDeleteWebEventFields(webEventField)}
                      onEdit={() => handleWebEventFieldsSelection(webEventField)}
                      onSelect={() => {
                      }}
                    />
                  ))}
                  <WebEventFormDialog
                    open={webEventsFieldsFormDialogOpen}
                    onOpen={handleWebEventsFieldsFormDialogOpen}
                    onClose={handleWebEventsFieldsFormDialogClose}
                    updateWebEvents={updateWebEvents}
                    selectedWebEvents={selectedWebEventFields}
                  />
                </GridItem>
              )}
              {/* Yelp section for SEM */}
              {variant === 1 && (
                <>
                  <GridItem sizes={[12]}>
                    <IQFormSelect
                      id="advertiserYelpProfileRatings"
                      name="advertiserYelpProfileRatings"
                      labelText={t('pages.xmo.solutionsStrategy.proxy.advertiserYelpProfileStarRatingLabel')}
                      items={advertiserYelpProfileRatingsDropDown}
                      onChange={(e: any) => setAdvertiserYelpProfileRatings(e.target.value)}
                      defaultValue={product?.advertiserYelpProfileRatings || ''}
                      theme={IQTheme}
                      fontLabelWeight="600"
                      fullWidth
                      schema={schema}
                      subDescription={t('pages.xmo.solutionsStrategy.proxy.advertiserYelpProfileStarRatingHelpText')}
                    />
                  </GridItem>
                  { isYelpUrlToShow && (
                  <GridItem sizes={[12]}>
                    <IQFormInput
                      id="yelpBusinessProfileUrl"
                      name="yelpBusinessProfileUrl"
                      labelText={t('pages.xmo.solutionsStrategy.proxy.yelpBusinessProfileUrlLabel')}
                      fullWidth
                      theme={IQTheme}
                      fontLabelWeight="600"
                      schema={schema}
                      adornmentIcon={<InsertLinkIcon />}
                      showError
                    />
                  </GridItem>
                  )}
                  <GridItem sizes={[12]}>
                    <Typography>
                      <Link mr={1}
                        href={t('pages.xmo.solutionsStrategy.proxy.restrictedCategoriesLink')}
                        target="_blank" rel="noopener noreferrer" sx={{ marginLeft: '5px' }}
                      >
                        {t('pages.xmo.solutionsStrategy.proxy.restrictedCategories')}
                      </Link>
                    </Typography>
                  </GridItem>
                </>
              )}
            </GridContainer>
          </Box>
          <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
          />
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
