import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Urls } from 'navigation/Urls';
import { SaveAndCloseContext } from 'context/SaveAndCloseContext';
import { OrderFlow } from 'shared/constants';
// import { updateCompletedStep } from 'services/websitesSlice';
import { selectActiveOfferingType, selectActiveProductId } from 'services/navigationSlice';
import { selectOrdersContent } from 'services/ordersSlice';

interface Navigate extends Window {
  navigate: string;
}

declare let window: Navigate;

function assembleUrl(orderId, orderItemId, location) {
  const params = location.includes('businesses')
    ? [orderId, location]
    : [orderId, 'product', orderItemId, location];
  const resolvedUrl = params
    .filter(Boolean)
    .join('/');
  return `/order/${resolvedUrl}`;
}

interface DefaultMethods {
  to: (url, orderItemId?: string, banner?: boolean) => void;
  back: () => void;
  saveAndClose: () => void;
  overrideRegularNavigation: (url) => void;
  clearDefaultNavigation: () => void;
}

export function useNavigationHandler(): DefaultMethods {
  const { orderId, orderItemId, clientId } = useParams();
  const offeringType = useSelector(selectActiveOfferingType);
  const websiteId = useSelector(selectActiveProductId);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const saveAndCloseContext = useContext(SaveAndCloseContext);
  const ordersContent = useSelector(selectOrdersContent);
  const { orderFlow } = ordersContent;
  const [isPunchlist, setIsPunchlist] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    orderFlow === OrderFlow.PUNCHLIST ? setIsPunchlist(true) : setIsPunchlist(false);
  }, [orderFlow]);

  const clearDefaultNavigation = useCallback(() => {
    window.navigate = undefined;
  }, []);

  const overrideRegularNavigation = useCallback((url) => {
    window.navigate = url;
  }, []);

  const to = useCallback(
    (urlParam, orderItemIdParam?: string, banner = true) => {
      // fix relative url
      const url = urlParam;
      if (url.startsWith('/')) {
        const oldPath = window.location.pathname;
        const newPath = oldPath.slice(0, oldPath.lastIndexOf('/')) + urlParam;
        navigate(newPath, { replace: true, state: { previousPath: pathname } });
        return;
      }
      // the window.navigate can override the default navigation logic
      if (window.navigate) {
        window.location.href = window.navigate;
        clearDefaultNavigation();
      } else if (url === Urls.Home || url === Urls.ShoppingCart) {
        navigate(`/${url}`);
      } else if (url === Urls.Orders) {
        clearDefaultNavigation();
        navigate(`/${Urls.Orders}`);
      } else if (url === Urls.SalesRep || url === Urls.Overview || url === Urls.PlHealthcareLocations) {
        clearDefaultNavigation();
        const previousPath = url === Urls.SalesRep ? '' : pathname;
        navigate(`/order/${orderId}/${url}`, { state: { previousPath, banner } });
      } else if (url.includes(Urls.SalesRep)) {
        clearDefaultNavigation();
        const previousPath = url === Urls.SalesRep ? '' : pathname;
        navigate(`/order/${url}`, { state: { previousPath, banner } });
      } else if (url === 'orderSummary') {
        clearDefaultNavigation();
        navigate(`/order/${orderId}`);
      } else if (url === 'punchlistSaved') {
        clearDefaultNavigation();
        navigate('/saved/punchlistSaved');
      } else {
        const oiId = orderItemIdParam || orderItemId;
        const resolvedUrl = assembleUrl(
          orderId,
          oiId,
          url,
        );
        navigate(resolvedUrl, { state: { previousPath: pathname } });
      }
    },
    [orderId, websiteId, offeringType, orderItemId, isPunchlist],
  );

  const back = () => navigate(-1);
  const saveAndClose = useCallback(() => {
    if (clientId !== undefined) {
      saveAndCloseContext.setFormValid(true);
    } else if (isPunchlist) to(Urls.PunchlistSaved);
    else to(Urls.Orders);
  }, [isPunchlist]);

  return {
    to,
    back,
    overrideRegularNavigation,
    clearDefaultNavigation,
    saveAndClose,
  };
}
