import i18n from 'i18n/i18n';
import {
  TrackingCustomValidate,
  TrackingSchema,
  TrackingUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/TrackingSchema";
import {
  TrackingNumberDetailsCustomValidate,
  TrackingNumberDetailsSchema,
  TrackingNumberDetailsUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/TrackingNumberDetails";
import {
  NotesAndInstructionsCustomValidate,
  NotesAndInstructionsSchema,
  NotesAndInstructionsUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/NotesAndInstructionsSchema";
import {
  WebEventsCustomValidate,
  WebEventsSchema,
  WebEventsUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/WebEventsSchema";
import {
  CallTrackingCustomValidate,
  CallTrackingSchema,
  CallTrackingUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/CallTrackingSchema";
import {
  TargetingSchema,
  TargetingCustomValidate,
  TargetingUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/TargetingSchema";
import {
  GDNDisplayCustomValidate,
  GDNDisplaySchema, GDNDisplayUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/GDNDisplaySchema";

const {t} = i18n;

export const AusGoogleDisplayNetworkSchema = (data) => {
  return [
    {
      type: 'object',
      title: t('pages.ausProduct.tracking.title'),
      schema: TrackingSchema(data),
      uiSchema: TrackingUISchema,
      customValidate: TrackingCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.gdnDisplay.title'),
      schema: GDNDisplaySchema(data),
      uiSchema: GDNDisplayUISchema,
      customValidate: GDNDisplayCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.targeting.title'),
      schema: TargetingSchema(data),
      uiSchema: TargetingUISchema,
      customValidate: TargetingCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.webEvents.title'),
      schema: WebEventsSchema(data),
      uiSchema: WebEventsUISchema(data),
      customValidate: WebEventsCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.callTracking.title'),
      schema: CallTrackingSchema(data),
      uiSchema: CallTrackingUISchema,
      customValidate: CallTrackingCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.trackingNumber1Details.title'),
      schema: TrackingNumberDetailsSchema(data),
      uiSchema: TrackingNumberDetailsUISchema(data),
      customValidate: TrackingNumberDetailsCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.notesAndInstructions.title'),
      schema: NotesAndInstructionsSchema(data),
      uiSchema: NotesAndInstructionsUISchema,
      customValidate: NotesAndInstructionsCustomValidate,
    },
  ]

}

export const AusGoogleDisplayNetworkUISchema = (data) => ({
  'ui:order': [
    ...TrackingUISchema['ui:order'],
    ...GDNDisplayUISchema['ui:order'],
    ...TargetingUISchema['ui:order'],
    ...WebEventsUISchema(data)['ui:order'],
    ...CallTrackingUISchema['ui:order'],
    ...TrackingNumberDetailsUISchema(data)['ui:order'],
    ...NotesAndInstructionsUISchema['ui:order'],
  ]
})

export const AusGoogleDisplayNetworkCustomValidate = (formData: any, errors: any, uiSchema: any) => errors;