import { useEffect, useState } from 'react';
import IosShareIcon from '@mui/icons-material/IosShare';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Typography, Button, Box } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import GridItem from 'components/grid/GridItem';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrderDetails, getOrderDetails } from 'services/myOrdersSlice';
import { IQLoadingSpinner } from '@gannettdigital/shared-react-components';

export const actionItems = (clientInvite, itemId, disableEntryForm = false, openBusinessAndSalesAgent = () => {}) => {
  const dispatch = useDispatch();
  const { orderId, productId } = useParams();
  const orderDetails = useSelector(selectOrderDetails);
  const [pdfLoading, setPdfLoading] = useState(false);

  useEffect(() => {
    dispatch(getOrderDetails(orderId));
  }, [orderId]);

  const handleEntryForm = async () => {
    setPdfLoading(true);
    await openBusinessAndSalesAgent();
    setTimeout(() => {
      window.print();
      setPdfLoading(false);
    }, 2000);
  };

  return (
    <GridItem sizes={[12, 12]} marginBottom={4} sx={{ padding: '40px 0 0', borderTop: '1px solid #000000' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Action items</Typography>
      </Box>
      <Box>
        {clientInvite && (
          <Link to={`/public_emails/${orderId}/item/${productId || orderDetails?.[0]?.orderItem?.id}`} replace
            target="_blank" rel="noopener noreferrer"
          >
            <Button variant="outlined" startIcon={<IosShareIcon />}
              sx={{ marginRight: '10px', background: '#ffffff' }}
            >
              Client-Asset Invite
            </Button>
          </Link>
        )}
        <Button variant="outlined"
          startIcon={<FileDownloadOutlinedIcon />}
          sx={{ background: '#ffffff' }} disabled={disableEntryForm || pdfLoading}
          onClick={handleEntryForm}
        >
          Entry Form
          {pdfLoading && <Box ml={1}><IQLoadingSpinner size={18} /></Box>}
        </Button>
      </Box>
    </GridItem>
  );
};
