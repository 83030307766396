/* eslint-disable no-param-reassign */
import {DefaultPageLayout} from 'layouts/DefaultPageLayout';
import GridItem from 'components/grid/GridItem';
import GridContainer from 'components/grid/GridContainer';
import {
  IQDatePicker,
  IQFormInput, IQFormTextArea,
  IQTheme,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import schema from './Sem.schema';
import {FormControlLabel, Radio, RadioGroup, Typography} from "@mui/material";
import {t} from "i18next";
import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import schemaValidate from "components/schemaValidate";
import {
  descriptionGuidelinesDetailItems,
  headLineGuidelinesDetailItems,
  keywordListCreationItems, locationExtensionSettingsItems, multipleLocationsItems,
  selectSitelinkItems,
  textAdPreferenceItems,
  yesOrNoItems
} from "pages/xmo/tactics/sem/SemService";
import GenericCard from "components/generic-card/GenericCard";
import {updateOrAddDataBasedOnSitelinkSourceId} from "pages/xmo/tactics/sem/sitelink/SitelinkService";
import SitelinkFormDialog from "pages/xmo/tactics/sem/sitelink/Sitelink";
import {updateOrAddDataBasedOnAdGroupSourceId} from "pages/xmo/tactics/sem/adGroups/AdGroupService";
import AdGroupsFormDialog from "pages/xmo/tactics/sem/adGroups/AdGroups";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import ErrorIcon from "@mui/icons-material/Error";

interface Props {
  product: any;
  onSubmit: (data) => void;
  onBack: () => void;
}

export type SemFormProps = {
  enableRemarketingLists: string,
  keywordListCreation: string,
  doNotAddFollowingKeywords: string,
  includeFollowingNegativeKeywords: string,
  keywordsToPauseToAvoidDoubleServing: string,
  additionalGeoKeywords: string,
  doNotUseFollowingGeoKeywords: string,
  selectSitelink: string,
  textAdPreference: string,
  primaryAdGroupFocus: string,
  primaryAdGroupLandingPage: string,
  multipleLocations: string,
  locationExtensionSettings: string,
  flightedCampaign: string,
  flightStartDate: string,
  flightEndDate: string,
  previousAdwordsAccountOrCampaign: string,
  googleCustomerId: string,
  sitelinkFields: {
    sourceId: string,
    sitelinkUrl: string,
    sitelinkName: string,
  }[],
  adGroupFields: {
    sourceId: string,
    adGroupFocus: string,
    adGroupLandingPage: string,
  }[],
};

export default function SemForm({product, onSubmit, onBack}: Props) {

  const [sitelinkFields, setSitelinkFields] = useState<SemFormProps['sitelinkFields']>(product?.sitelinkFields || []);
  const [sitelinkFieldsFormDialogOpen, setSitelinkFieldsFormDialogOpen] = useState(false);
  const [selectedSitelinkFields, setSelectedSitelinkFields] = useState<SemFormProps['sitelinkFields']>([]);
  const sitelinkFieldLabels = ['sitelinkName', 'sitelinkUrl'];

  const [adGroupFields, setAdGroupFields] = useState<SemFormProps['adGroupFields']>(product?.adGroupFields || []);
  const [adGroupFieldsFormDialogOpen, setAdGroupFieldsFormDialogOpen] = useState(false);
  const [selectedAdGroupFields, setSelectedAdGroupFields] = useState<SemFormProps['adGroupFields']>([]);
  const adGroupFieldLabels = ['adGroupFocus', 'adGroupLandingPage'];

  const [isFlightedCampaign, setIsFlightedCampaign] = useState(product?.flightedCampaign);
  const [isPreviousAdwordsAccountOrCampaign, setIsPreviousAdwordsAccountOrCampaign] = useState(product?.previousAdwordsAccountOrCampaign);
  const [isSelectSitelinkOn, setIsSelectSitelinkOn] = useState(product?.selectSitelink);

  const [dateError, setDateError] = useState<boolean>(false);
  const [startDateData, setStartDateData] = useState(product?.flightStartDate || '');
  const [endDateData, setEndDateData] = useState(product?.flightEndDate || '');
  const [startDateError, setStartDateError] = useState<boolean>(false);
  const [endDateError, setEndDateError] = useState<boolean>(false);
  const [keywordCountError, setKeywordCountError] = useState<boolean>(false);
  const handleSitelinkFieldsFormDialogOpen = () => setSitelinkFieldsFormDialogOpen(true);
  const handleAdGroupFieldsFormDialogOpen = () => setAdGroupFieldsFormDialogOpen(true);

  const handleSitelinkFieldsFormDialogClose = () => {
    setSitelinkFieldsFormDialogOpen(false);
    setSelectedSitelinkFields([]);
  }
  const handleAdGroupFieldsFormDialogClose = () => {
    setAdGroupFieldsFormDialogOpen(false);
    setSelectedAdGroupFields([]);
  }

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      enableRemarketingLists: product?.enableRemarketingLists || '',
      keywordListCreation: product?.keywordListCreation || '',
      doNotAddFollowingKeywords: product?.doNotAddFollowingKeywords || '',
      includeFollowingNegativeKeywords: product?.includeFollowingNegativeKeywords || '',
      keywordsToPauseToAvoidDoubleServing: product?.keywordsToPauseToAvoidDoubleServing || '',
      additionalGeoKeywords: product?.additionalGeoKeywords || '',
      doNotUseFollowingGeoKeywords: product?.doNotUseFollowingGeoKeywords || '',
      selectSitelink: product?.selectSitelink || '',
      textAdPreference: product?.textAdPreference || '',
      primaryAdGroupFocus: product?.primaryAdGroupFocus || '',
      primaryAdGroupLandingPage: product?.primaryAdGroupLandingPage || '',
      multipleLocations: product?.multipleLocations || '',
      locationExtensionSettings: product?.locationExtensionSettings || '',
      flightedCampaign: product?.flightedCampaign || '',
      flightStartDate: product?.flightStartDate || '',
      flightEndDate: product?.flightEndDate || '',
      previousAdwordsAccountOrCampaign: product?.previousAdwordsAccountOrCampaign || '',
      googleCustomerId: product?.googleCustomerId || '',
      sitelinkFields: Array.isArray(product?.sitelinkFields) && product.sitelinkFields.length > 0
          ? product.sitelinkFields
          : [{sourceId: '', sitelinkName: '', sitelinkUrl: ''}],
      adGroupFields: Array.isArray(product?.adGroupFields) && product.adGroupFields.length > 0
          ? product.adGroupFields
          : [{sourceId: '', adGroupFocus: '', adGroupLandingPage: ''}]
    },
  });

  const handleFormSubmit = (data: SemFormProps) => {
    if (isFlightedCampaign === 'No' && isFlightedCampaign !== undefined) {
      data.flightStartDate = '';
      data.flightEndDate = '';
    }
    if (isPreviousAdwordsAccountOrCampaign === 'No' && isPreviousAdwordsAccountOrCampaign !== undefined) {
      data.googleCustomerId = '';
    }
    if (isSelectSitelinkOn === 'On - Use Below' && isSelectSitelinkOn !== undefined) {
      data.sitelinkFields = sitelinkFields;
    } else {
      data.sitelinkFields = [];
    }
    data.adGroupFields = adGroupFields;
    const formData = {
      sem: data
    };
    onSubmit(formData);
  };

  const {
    handleSubmit,
    register,
      watch,
    formState: {
      isValid,
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
    },
  } = methods;

  const handleSitelinkFieldsSelection = (selectedSitelinkField) => {
    setSelectedSitelinkFields(selectedSitelinkField);
    handleSitelinkFieldsFormDialogOpen();
  };
  const handleAdGroupFieldsSelection = (selectedAdGroupField) => {
    setSelectedAdGroupFields(selectedAdGroupField);
    handleAdGroupFieldsFormDialogOpen();
  };

  const updateSitelinks = (newData) => {
    setSitelinkFields(prevData => {
      return updateOrAddDataBasedOnSitelinkSourceId(prevData, newData);
    });
  };
  const updateAdGroups = (newData) => {
    setAdGroupFields(prevData => {
      return updateOrAddDataBasedOnAdGroupSourceId(prevData, newData);
    });
  };

  const onDeleteSitelinkFields = async (sitelinkField: any) => {
    const updatedSitelinks = sitelinkFields.filter(
        (sitelink) => sitelink.sourceId !== sitelinkField.sourceId
    );
    setSitelinkFields(updatedSitelinks);
  }
  const onDeleteAdGroupFields = async (AdGroupField: any) => {
    const updatedAdGroups = adGroupFields.filter(
        (adGroup) => adGroup.sourceId !== AdGroupField.sourceId
    );
    setAdGroupFields(updatedAdGroups);
  }

  useEffect(() => {
    if (startDateData !== '' && endDateData !== '') {
      if (new Date(startDateData).getTime() > new Date(endDateData).getTime()) {
        setDateError(true);
      } else {
        setDateError(false);
      }
    }

    function dateErrorCheck(dateToCompare: any) {
      const currentDate = new Date();
      const formattedDate = (currentDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
          currentDate.getDate().toString().padStart(2, '0') + '/' +
          currentDate.getFullYear();
      return new Date(dateToCompare).getTime() < new Date(formattedDate).getTime()
    }

    if (startDateData !== '') {
      setStartDateError(dateErrorCheck(startDateData))
    }
    if (endDateData !== '') {
      setEndDateError(dateErrorCheck(endDateData))
    }
  }, [startDateData, endDateData]);

  const additionalGeoKeywordsData = watch('additionalGeoKeywords')
  useEffect(() => {
    if(additionalGeoKeywordsData !== undefined && additionalGeoKeywordsData!== '')
    {
      const additionalGeoKeywordsArr = additionalGeoKeywordsData.split(',');
      if(additionalGeoKeywordsArr.length>10)
      {
        setKeywordCountError(true);
      }else{setKeywordCountError(false);}
    }else{setKeywordCountError(false);}
  }, [additionalGeoKeywordsData]);

  return (
      <DefaultPageLayout
          disableContinue={!isValid || dateError || keywordCountError}
          onBack={onBack}
          header="SEM Campaign Set Up"
          onContinue={handleSubmit(handleFormSubmit)}
      >
        <ValidationProvider schema={schema}>
          <FormProvider {...methods}>
            <Box
                id="mainForm"
                component="form"
                sx={{display: 'flex'}}
                onSubmit={handleSubmit(handleFormSubmit)}
            >
              <GridContainer fullWidth>
                <GridItem sizes={[12]}>
                  <Typography variant="h6"> {t('pages.xmo.sem.general')}</Typography>
                </GridItem>
                <GridItem sizes={[12]} marginBottom={0}>
                  <Typography variant="subtitle2" component="h3" fontSize={16}>
                    {t('pages.xmo.sem.enableRemarketingLists')}
                    <RadioGroup
                        id="enableRemarketingLists"
                        name="enableRemarketingLists"
                        aria-labelledby="topic-location"
                        defaultValue={product?.enableRemarketingLists}
                        row
                    >
                      {yesOrNoItems.map(option => (
                          <FormControlLabel
                              key={option.value}
                              value={option.value}
                              control={<Radio/>}
                              label={option.description}
                              {...register('enableRemarketingLists', {
                                validate: (value) => schemaValidate(value, 'enableRemarketingLists', schema),
                              })}
                          />
                      ))}
                    </RadioGroup>
                  </Typography>
                  <Typography variant="caption"> {t('pages.xmo.sem.enableRemarketingListsNote')}</Typography>
                </GridItem>
                <GridItem sizes={[12]} marginTop={3}>
                  <Typography variant="h6"> {t('pages.xmo.sem.keywordTargeting')}</Typography>
                </GridItem>
                <GridItem sizes={[12]} marginBottom={0}>
                  <Typography variant="subtitle2" component="h3" fontSize={16}>
                    {t('pages.xmo.sem.keywordListCreation')}
                    <RadioGroup
                        id="keywordListCreation"
                        name="keywordListCreation"
                        aria-labelledby="topic-location"
                        defaultValue={product?.keywordListCreation}
                        row
                    >
                      {keywordListCreationItems.map(option => (
                          <FormControlLabel
                              key={option.value}
                              value={option.value}
                              control={<Radio/>}
                              label={option.description}
                              {...register('keywordListCreation', {
                                validate: (value) => schemaValidate(value, 'keywordListCreation', schema),
                              })}
                          />
                      ))}
                    </RadioGroup>
                  </Typography>
                  <Typography variant="caption"> {t('pages.xmo.sem.keywordListCreationHelpText')}</Typography>
                </GridItem>
                <GridItem sizes={[12]} marginTop={3}>
                  <IQFormTextArea
                      id="doNotAddFollowingKeywords"
                      labelText={t('pages.xmo.sem.doNotAddFollowingKeywords')}
                      name="doNotAddFollowingKeywords"
                      rowCount={2}
                      fullWidth
                      fontLabelWeight="600"
                      subDescription={t('pages.xmo.sem.doNotAddFollowingKeywordsHelpText')}
                      showError
                  />
                </GridItem>
                <GridItem sizes={[12]}>
                  <IQFormTextArea
                      id="includeFollowingNegativeKeywords"
                      labelText={t('pages.xmo.sem.includeFollowingNegativeKeywords')}
                      name="includeFollowingNegativeKeywords"
                      rowCount={2}
                      fullWidth
                      fontLabelWeight="600"
                      subDescription={t('pages.xmo.sem.includeFollowingNegativeKeywordsHelpText')}
                      showError
                  />
                </GridItem>
                <GridItem sizes={[12]}>
                  <IQFormTextArea
                      id="keywordsToPauseToAvoidDoubleServing"
                      labelText={t('pages.xmo.sem.keywordsToPauseToAvoidDoubleServing')}
                      name="keywordsToPauseToAvoidDoubleServing"
                      rowCount={2}
                      fullWidth
                      fontLabelWeight="600"
                      showError
                  />
                </GridItem>
                <GridItem sizes={[12]}>
                  <IQFormTextArea
                      id="additionalGeoKeywords"
                      labelText={t('pages.xmo.sem.additionalGeoKeywords')}
                      name="additionalGeoKeywords"
                      rowCount={2}
                      fullWidth
                      fontLabelWeight="600"
                      subDescription={t('pages.xmo.sem.additionalGeoKeywordsHelpText')}
                      showError
                  />
                    <Typography sx={{color: '#C20F1E', fontSize: '14px'}}
                                variant="subtitle1">
                      {keywordCountError && <ErrorIcon sx={{
                        fontSize: '16px',
                      }}/>} {keywordCountError ? t('pages.xmo.sem.additionalGeoKeywordsErrorMessage') : ''}
                    </Typography>
                </GridItem>
                <GridItem sizes={[12]}>
                  <IQFormTextArea
                      id="doNotUseFollowingGeoKeywords"
                      labelText={t('pages.xmo.sem.doNotUseFollowingGeoKeywords')}
                      name="doNotUseFollowingGeoKeywords"
                      rowCount={2}
                      fullWidth
                      fontLabelWeight="600"
                      subDescription={t('pages.xmo.sem.doNotUseFollowingGeoKeywordsHelpText')}
                      showError
                  />
                </GridItem>
                <GridItem sizes={[12]}>
                  <Typography variant="h6"> {t('pages.xmo.sem.sitelinks')}</Typography>
                </GridItem>
                <GridItem sizes={[12]}>
                  <Typography variant="subtitle2" component="h3" fontSize={16}>
                    {t('pages.xmo.sem.selectSitelink')}
                    <RadioGroup
                        id="selectSitelink"
                        name="selectSitelink"
                        aria-labelledby="topic-location"
                        defaultValue={product?.selectSitelink}
                        onChange={(event) => setIsSelectSitelinkOn(event.target.value)}
                        row
                    >
                      {selectSitelinkItems.map(option => (
                          <FormControlLabel
                              key={option.value}
                              value={option.value}
                              control={<Radio/>}
                              label={option.description}
                              {...register('selectSitelink', {
                                validate: (value) => schemaValidate(value, 'selectSitelink', schema),
                              })}
                          />
                      ))}
                    </RadioGroup>
                  </Typography>
                </GridItem>
                {isSelectSitelinkOn === 'On - Use Below' && (
                    <GridItem sizes={[12]}>
                      {Array.isArray(sitelinkFields) && sitelinkFields.map((sitelinkField, index) => (
                          <GenericCard
                              key={sitelinkField.sourceId}
                              labels={sitelinkFieldLabels}
                              data={sitelinkField}
                              handleDelete={() => onDeleteSitelinkFields(sitelinkField)}
                              onEdit={() => handleSitelinkFieldsSelection(sitelinkField)}
                              onSelect={() => {
                              }}
                              heading = {`${t('pages.xmo.sem.sitelinkTitle')} ${++index}`}
                          />
                      ))}
                          <SitelinkFormDialog
                            open={sitelinkFieldsFormDialogOpen}
                            onOpen={handleSitelinkFieldsFormDialogOpen}
                            onClose={handleSitelinkFieldsFormDialogClose}
                            updateSitelink={updateSitelinks}
                            selectedSitelink={selectedSitelinkFields}
                            siteLinkLength ={sitelinkFields.length}
                        />
                    </GridItem>
                )}
                <GridItem sizes={[12]}>
                  <Typography variant="h6">{t('pages.xmo.sem.rsaTextAdPreference')}</Typography>
                </GridItem>
                <GridItem sizes={[12]} marginBottom={1}>
                  <Typography variant="subtitle2" component="h3" fontSize={16}>
                    {t('pages.xmo.sem.textAdPreference')}
                    <RadioGroup
                        id="textAdPreference"
                        name="textAdPreference"
                        aria-labelledby="topic-location"
                        defaultValue={product?.textAdPreference}
                        row
                    >
                      {textAdPreferenceItems.map(option => (
                          <FormControlLabel
                              key={option.value}
                              value={option.value}
                              control={<Radio/>}
                              label={option.description}
                              {...register('textAdPreference', {
                                validate: (value) => schemaValidate(value, 'textAdPreference', schema),
                              })}
                          />
                      ))}
                    </RadioGroup>
                  </Typography>
                </GridItem>
                <GridItem sizes={[12]} marginBottom={1}>
                  <Typography variant="caption"> {t('pages.xmo.sem.noteUploadToSalesforce')}</Typography>
                </GridItem>
                <GridItem sizes={[12]} marginBottom={0}>
                  <Typography variant="subtitle2"> {t('pages.xmo.sem.headLineGuidelines')}</Typography>
                </GridItem>
                <GridItem sizes={[12]} marginBottom={1}>
                  <Typography variant="caption" component="ul">
                    {headLineGuidelinesDetailItems.map((item, index) => (
                        <Typography key={index} variant="caption" component="li">{item}</Typography>
                    ))}
                  </Typography>
                </GridItem>
                <GridItem sizes={[12]} marginBottom={0}>
                  <Typography variant="subtitle2"> {t('pages.xmo.sem.descriptionGuidelines')}</Typography>
                </GridItem>
                <GridItem sizes={[12]} marginBottom={0}>
                  <Typography variant="caption" component="ul">
                    {descriptionGuidelinesDetailItems.map((item, index) => (
                        <Typography key={index} variant="caption" component="li">{item}</Typography>
                    ))}
                  </Typography>
                </GridItem>
                <GridItem sizes={[12]} marginBottom={0}>
                  <Typography variant="caption"> {t('pages.xmo.sem.googleRecommendsDescription')}</Typography>
                </GridItem>
                <GridItem marginTop={3}>
                  <IQFormInput
                      id="primaryAdGroupFocus"
                      name="primaryAdGroupFocus"
                      fullWidth
                      labelText={t('pages.xmo.sem.primaryAdGroupFocus')}
                      theme={IQTheme}
                      fontLabelWeight="600"
                      schema={schema}
                  />
                </GridItem>
                <GridItem>
                  <IQFormInput
                      id='primaryAdGroupLandingPage'
                      name='primaryAdGroupLandingPage'
                      labelText={t('pages.xmo.sem.primaryAdGroupLandingPage')}
                      fullWidth
                      theme={IQTheme}
                      fontLabelWeight='600'
                      schema={schema}
                      adornmentIcon={<InsertLinkIcon/>}
                      showError
                  />
                </GridItem>
                <GridItem sizes={[12]}>
                  {Array.isArray(adGroupFields) && adGroupFields.map((adGroupField, index) => (
                      <GenericCard
                          key={adGroupField.sourceId}
                          labels={adGroupFieldLabels}
                          data={adGroupField}
                          handleDelete={() => onDeleteAdGroupFields(adGroupField)}
                          onEdit={() => handleAdGroupFieldsSelection(adGroupField)}
                          onSelect={() => {
                          }}
                          heading = {`${t('pages.xmo.sem.adGroupFocusTitle')} ${++index}`}
                      />
                  ))}
                  <AdGroupsFormDialog
                      open={adGroupFieldsFormDialogOpen}
                      onOpen={handleAdGroupFieldsFormDialogOpen}
                      onClose={handleAdGroupFieldsFormDialogClose}
                      updateAdGroup={updateAdGroups}
                      selectedAdGroup={selectedAdGroupFields}
                      adGroupLength = {adGroupFields.length}
                  />
                </GridItem>
                <GridItem sizes={[12]}>
                  <Typography variant="h6"> {t('pages.xmo.sem.locationSettings')} </Typography>
                </GridItem>
                <GridItem sizes={[12]}>
                  <Typography variant="subtitle2" component="h3" fontSize={16}>
                    {t('pages.xmo.sem.multipleLocations')}
                    <RadioGroup
                        id="multipleLocations"
                        name="multipleLocations"
                        aria-labelledby="topic-location"
                        defaultValue={product?.multipleLocations}
                        row
                    >
                      {multipleLocationsItems.map(option => (
                          <FormControlLabel
                              key={option.value}
                              value={option.value}
                              control={<Radio/>}
                              label={option.description}
                              {...register('multipleLocations', {
                                validate: (value) => schemaValidate(value, 'multipleLocations', schema),
                              })}
                          />
                      ))}
                    </RadioGroup>
                  </Typography>
                </GridItem>
                <GridItem sizes={[12]}>
                  <Typography variant="subtitle2" component="h3" fontSize={16}>
                    {t('pages.xmo.sem.locationExtensionSettings')}
                    <RadioGroup
                        id="locationExtensionSettings"
                        name="locationExtensionSettings"
                        defaultValue={product?.locationExtensionSettings}
                        aria-labelledby="topic-location"
                        row
                    >
                      {locationExtensionSettingsItems.map(option => (
                          <FormControlLabel
                              key={option.value}
                              value={option.value}
                              control={<Radio/>}
                              label={option.description}
                              {...register('locationExtensionSettings', {
                                validate: (value) => schemaValidate(value, 'locationExtensionSettings', schema),
                              })}
                          />
                      ))}
                    </RadioGroup>
                  </Typography>
                </GridItem>
                <GridItem sizes={[12]}>
                  <Typography variant="subtitle2" component="h3" fontSize={16}>
                    {t('pages.xmo.sem.flightedCampaign')}
                    <RadioGroup
                        id="flightedCampaign"
                        name="flightedCampaign"
                        aria-labelledby="topic-location"
                        defaultValue={product?.flightedCampaign}
                        onChange={(event) =>  setIsFlightedCampaign(event.target.value)}
                        row
                    >
                      {yesOrNoItems.map(option => (
                          <FormControlLabel
                              key={option.value}
                              value={option.value}
                              control={<Radio/>}
                              label={option.description}
                              {...register('flightedCampaign', {
                                validate: (value) => schemaValidate(value, 'flightedCampaign', schema),
                              })}
                          />
                      ))}
                    </RadioGroup>
                  </Typography>
                </GridItem>
                {isFlightedCampaign === 'Yes' && (
                    <>
                    <GridItem sizes={[6, 6]} columnSpacing={2}>
                      <Controller
                          name="flightStartDate"
                          control={methods.control}
                          render={({field}) => (
                              <>
                                <IQDatePicker
                                    id={'flightStartDate'}
                                    {...field}
                                    schema={schema}
                                    theme={IQTheme}
                                    fontLabelWeight="600"
                                    labelText={t('pages.xmo.sem.flightStartDate')}
                                    required
                                    fullWidth
                                    onChange={(e: any) => setStartDateData(e)}
                                />
                                <Typography sx={{color: '#C20F1E', fontSize: '14px'}}
                                            variant="subtitle1">
                                  {startDateError && <ErrorIcon sx={{
                                    fontSize: '16px',
                                    marginTop: '6px'
                                  }}/>} {startDateError ? t('pages.xmo.solutionsStrategy.general.dateGreaterThanCurrentDateError') : ''}
                                </Typography>
                              </>
                          )}
                      />
                      <Controller
                          name="flightEndDate"
                          control={methods.control}
                          render={({field}) => (
                              <>
                                <IQDatePicker
                                    id={'flightEndDate'}
                                    {...field}
                                    schema={schema}
                                    theme={IQTheme}
                                    fontLabelWeight="600"
                                    labelText={t('pages.xmo.sem.flightEndDate')}
                                    required
                                    fullWidth
                                    onChange={(e: any) => setEndDateData(e)}
                                />
                                <Typography sx={{color: '#C20F1E', fontSize: '14px'}}
                                            variant="subtitle1">
                                  {endDateError && <ErrorIcon sx={{
                                    fontSize: '16px',
                                    marginTop: '6px'
                                  }}/>} {endDateError ? t('pages.xmo.solutionsStrategy.general.dateGreaterThanCurrentDateError') : ''}
                                </Typography>
                              </>
                          )}
                      />
                    </GridItem>
                    <GridItem sizes={[12]} marginTop={-2}>
                      <Typography sx={{color: '#C20F1E', fontSize: '14px'}} variant="subtitle1">
                        {dateError && <ErrorIcon sx={{
                          fontSize: '16px',
                          marginTop: '6px'
                        }}/>} {dateError ? t('pages.xmo.solutionsStrategy.general.genericStartAndEndDateError') : ''}
                      </Typography>
                    </GridItem>
                    </>
                )}
                <GridItem sizes={[12]}>
                  <Typography variant="subtitle2" component="h3" fontSize={16}>
                    {t('pages.xmo.sem.previousAdwordsAccountOrCampaign')}
                    <RadioGroup
                        id="previousAdwordsAccountOrCampaign"
                        name="previousAdwordsAccountOrCampaign"
                        aria-labelledby="topic-location"
                        defaultValue={product?.previousAdwordsAccountOrCampaign}
                        onChange={(event) => setIsPreviousAdwordsAccountOrCampaign(event.target.value)}
                        row
                    >
                      {yesOrNoItems.map(option => (
                          <FormControlLabel
                              key={option.value}
                              value={option.value}
                              control={<Radio/>}
                              label={option.description}
                              {...register('previousAdwordsAccountOrCampaign', {
                                validate: (value) => schemaValidate(value, 'previousAdwordsAccountOrCampaign', schema),
                              })}
                          />
                      ))}
                    </RadioGroup>
                  </Typography>
                </GridItem>
                {isPreviousAdwordsAccountOrCampaign === 'Yes' && (
                    <GridItem sizes={[12]}>
                      <IQFormInput
                          id="googleCustomerId"
                          name="googleCustomerId"
                          fullWidth
                          labelText={t('pages.xmo.sem.googleCustomerId')}
                          subDescription={t('pages.xmo.sem.googleCustomerIdHelpText')}
                          theme={IQTheme}
                          fontLabelWeight="600"
                          tooltipText={t('pages.xmo.sem.googleCustomerIdTooltipText')}
                          tooltipPlacement={"top"}
                          tooltipPaddingBottom={18}
                          schema={schema}
                      />
                    </GridItem>
                )}
              </GridContainer>
            </Box>
          </FormProvider>
        </ValidationProvider>
        <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
        />
      </DefaultPageLayout>
  );
}