import { RJSFSchema } from "@rjsf/utils";
import { FormHelperText, Typography } from "@mui/material";
import i18n from "i18n/i18n";
import AddSitelink from "../../custom-fields/AddSitelink";
import AddAdGroup from "../../custom-fields/AddAdGroup";
const { t } = i18n;

const multipleLocationOptions = {
  yes: t(
    "pages.socialAds.sem-ads.multipleLocations.multipleLocationsOptions.yes-MultipleLocations"
  ),
  no: t(
    "pages.socialAds.sem-ads.multipleLocations.multipleLocationsOptions.no-SingleLocation"
  ),
};

const localExtSettingOptions = {
  text: t(
    "pages.socialAds.sem-ads.multipleLocations.localExtensionSettingsOptions.textAdOnly"
  ),
  textLocationCall: t(
    "pages.socialAds.sem-ads.multipleLocations.localExtensionSettingsOptions.textAdLocationAndCallExt"
  ),
  textCall: t(
    "pages.socialAds.sem-ads.multipleLocations.localExtensionSettingsOptions.textAdCallExt"
  ),
};

const siteLinksOpts = {
  onBest: t("pages.socialAds.sem-ads.siteLinks.siteLinkOptions.onBest"),
  onUse: t("pages.socialAds.sem-ads.siteLinks.siteLinkOptions.onUse"),
  off: t("pages.socialAds.sem-ads.siteLinks.siteLinkOptions.off"),
};

export const SEMAdsSchema: RJSFSchema | any = (data) => ({
  type: "object",
  title: t("pages.socialAds.sem-ads.title"),
  required: ["rsaTextAdPreference", "primaryAdGroupLandingPage","multipleLocations","localExtensionSettings"],
  properties: {
    sitelinksHeading: {
      type: "string",
      title: t("pages.socialAds.sem-ads.siteLinks.headingLabel"),
    },
    siteLinks: {
      type: "string",
      title: t("pages.socialAds.sem-ads.siteLinks.siteLinksLabel"),
      default: data?.siteLinks || "",
      items: {
        enum: [siteLinksOpts.onBest, siteLinksOpts.onUse, siteLinksOpts.off],
      }
    },
    rsaHeading: {
      type: "string",
      title: t("pages.socialAds.sem-ads.rsa.headingLabel"),
    },
    rsaTextAdPreference: {
      type: "string",
      title: t("pages.socialAds.sem-ads.rsa.rsaTextAdPreferenceLabel"),
      description: t(
        "pages.socialAds.sem-ads.rsa.rsaTextAdPreferenceHelperText"
      ),
      default: data?.rsaTextAdPreference || "",
      items: {
        enum: [
          t("pages.socialAds.sem-ads.rsa.rsaTextAdPreferenceOptions.useMyText"),
          t("pages.socialAds.sem-ads.rsa.rsaTextAdPreferenceOptions.useBest"),
          t("pages.socialAds.sem-ads.rsa.rsaTextAdPreferenceOptions.createText"),
        ],
      }
    },
    headlinesGuidlinesInfo: {
      type: "string",
      title: t("pages.socialAds.sem-ads.rsa.headlinesGuidlinesInfoText.title"),
      rawDescription: (
        <FormHelperText error={false}>
          <ul>
            <li>{t("pages.socialAds.sem-ads.rsa.headlinesGuidlinesInfoText.max")}</li>
            <li>{t("pages.socialAds.sem-ads.rsa.headlinesGuidlinesInfoText.atLeastDense")}</li>
            <li>{t("pages.socialAds.sem-ads.rsa.headlinesGuidlinesInfoText.atLeast")}</li>
          </ul>
        </FormHelperText>
      ),
    },
    descriptionGuidlinesInfo: {
      type: "string",
      title: t(
        "pages.socialAds.sem-ads.rsa.descriptionGuidlinesInfoText.title"
      ),
      rawDescription: (
        <FormHelperText error={false}>
          <ul>
            <li>{t("pages.socialAds.sem-ads.rsa.descriptionGuidlinesInfoText.maxChars")}</li>
            <li>{t("pages.socialAds.sem-ads.rsa.descriptionGuidlinesInfoText.uniqueDescription")}</li>
          </ul>
          
          {t(
            "pages.socialAds.sem-ads.rsa.descriptionGuidlinesInfoText.googleRecommends"
          )}
        </FormHelperText>
      ),
    },
    primaryAdGroupFocus: {
      type: "string",
      title: t("pages.socialAds.sem-ads.primaryAdGroupFocusLabel"),
      value: data?.primaryAdGroupFocus || "",
    },
    primaryAdGroupLandingPage: {
      type: "string",
      title: t("pages.socialAds.sem-ads.primaryAdGroupLandingPageLabel"),
      value: data?.primaryAdGroupLandingPage || "",
    },
    adGroups: {
      type: "array",
      default: [],
      items: {
        type: "object",
        properties: {
          adGroupFocus: { type: "string" },
          adGroupLandingPage: { type: "string" },
        },
      },
    },
    additionalNotes: {
      title: t("pages.socialAds.sem-ads.additionalNotesLabel"),
      value: data?.additionalNotes || "",
      type: "string",
    },
    multipleLocationsHeading: {
      type: "string",
      title: t("pages.socialAds.sem-ads.multipleLocations.headingLabel"),
    },
    multipleLocations: {
      type: "string",
      title: t(
        "pages.socialAds.sem-ads.multipleLocations.multipleLocationsLabel"
      ),
      default: data?.multipleLocations || "",
      items: {
        enum: [multipleLocationOptions.yes, multipleLocationOptions.no],
      }
    },
  },
  dependencies: {
    siteLinks: {
      oneOf: [
        {
          properties: {
            siteLinks: {
              enum: [siteLinksOpts.onUse],
            },
            sitelinks: {
              title: t("pages.customTracking.solutionStrategy.webEventsLabel"),
              type: "array",
              default: data?.sitelinks || [],
              items: {
                type: "object",
                properties: {
                  sitelinkName: { type: "string" },
                  sitelinkUrl: { type: "string" },
                },
              },
            },
          },
        },
      ],
    },
    multipleLocations: {
      oneOf: [
        {
          properties: {
            multipleLocations: {
              enum: ["", multipleLocationOptions.no],
            },
            localExtensionSettings: {
              type: "string",
              title: t(
                "pages.socialAds.sem-ads.multipleLocations.localExtensionSettingsLabel"
              ),
              default: data?.localExtensionSettings || "",
              items: {
                enum: [
                  localExtSettingOptions.text,
                  localExtSettingOptions.textLocationCall,
                  localExtSettingOptions.textCall,
                ],
              }
            },
          },
        },
        {
          properties: {
            multipleLocations: {
              enum: [multipleLocationOptions.yes],
            },
            localExtensionSettings: {
              type: "string",
              title: t(
                "pages.socialAds.sem-ads.multipleLocations.localExtensionSettingsLabel"
              ),
              default: data?.localExtensionSettings || "",
              items: {
                enum: [
                  localExtSettingOptions.text,
                  localExtSettingOptions.textCall,
                ],
              }
            },
          },
        },
      ],
    },
  },
});

export const SEMAdsUISchema = {
  "ui:order": [
    "sitelinksHeading",
    "siteLinks",
    "sitelinks",
    "rsaHeading",
    "rsaTextAdPreference",
    "headlinesGuidlinesInfo",
    "descriptionGuidlinesInfo",
    "primaryAdGroupFocus",
    "primaryAdGroupLandingPage",
    "adGroups",
    "multipleLocationsHeading",
    "multipleLocations",
    "localExtensionSettings",
    "additionalNotes",
    "sitelinkName",
    "sitelinkUrl",
    "adGroupFocus",
    "adGroupLandingPage"
  ],
  sitelinksHeading: { "ui:widget": "headingTextLabel" },
  siteLinks: { "ui:widget": "radioButton" },
  rsaHeading: { "ui:widget": "headingTextLabel" },
  rsaTextAdPreference: { "ui:widget": "radioButton" },
  headlinesGuidlinesInfo: { "ui:widget": "textLabel" },
  descriptionGuidlinesInfo: { "ui:widget": "textLabel" },
  primaryAdGroupFocus: { "ui:widget": "textInputField" },
  primaryAdGroupLandingPage: { "ui:widget": "UrlTextWidget" },
  sitelinks: { "ui:widget": AddSitelink },
  adGroups: { "ui:widget": AddAdGroup },
  multipleLocationsHeading: { "ui:widget": "headingTextLabel" },
  multipleLocations: { "ui:widget": "radioButton" },
  localExtensionSettings: { "ui:widget": "radioButton" },
  additionalNotes: { "ui:widget": "TextareaWidget" },
};

export const SEMAdsCustomValidate = (formData, errors, uiSchema) => {
  if (formData?.siteLinks === '') {
    errors.siteLinks.addError(t('pages.generic-translation.mandatoryRadioButtonErrorMessage'));
    errors.addError(true);
  }
  if (formData?.rsaTextAdPreference === '') {
    errors.rsaTextAdPreference.addError(t('pages.generic-translation.mandatoryRadioButtonErrorMessage'));
    errors.addError(true);
  }
  if (formData?.multipleLocations === '') {
    errors.multipleLocations.addError(t('pages.generic-translation.mandatoryRadioButtonErrorMessage'));
    errors.addError(true);
  }
  if (formData?.localExtensionSettings === '') {
    errors.localExtensionSettings.addError(t('pages.generic-translation.mandatoryRadioButtonErrorMessage'));
    errors.addError(true);
  }
  if (formData?.primaryAdGroupLandingPage === '') {
    errors.primaryAdGroupLandingPage.addError(t('pages.generic-translation.mandatoryRadioButtonErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
