import { Button, Paper, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useSelector } from 'react-redux';
import { selectOpportunity } from 'services/cefSlice';
import { useParams } from 'react-router';

export default function ProductUnavailable({ solutionId } : { solutionId: number | string }) {
  const { opportunityId } = useParams();
  const opportunity = useSelector(selectOpportunity);

  const accountId = opportunity?.account?.id;

  const handleClick = () => {
    // eslint-disable-next-line max-len
    const url = `https://app.localiq.tools/prod/usa/api?AccountID=${accountId}&OpportunityId=${opportunityId}&SolutionId=${solutionId}`;
    window.open(url, '_blank');
  };

  return (
    <Paper
      elevation={3}
      sx={{
        width: '100%',
        padding: '40px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography variant="h4" mb={2}>Product Unavailable</Typography>
      <Typography mb={4}>
        This product is not included in Presence Builder, please complete standard CEF.
      </Typography>
      <Button variant="contained" sx={{ padding: '8px 22px' }} onClick={handleClick}>
        <Typography>
          Go to Standard CEF
        </Typography>
        <OpenInNewIcon sx={{ fontSize: '16px', marginLeft: '10px' }} />
      </Button>
    </Paper>
  );
}
