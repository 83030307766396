import {
    IQFormInput,
    IQFormSelect,
    IQFormTextArea,
    IQTheme, IQThemeV2,
    ValidationProvider,
} from '@gannettdigital/shared-react-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import GridItem from 'components/grid/GridItem';
import GridContainer from 'components/grid/GridContainer';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import TargetedDisplayLegactType from "models/TargetedDisplayLegacyType";
import { FormProvider, useForm } from "react-hook-form";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import { Box } from "@mui/system";
import schema from './ClientInformation.schema';
import NePhoneInput from "components/NePhoneInputContainer/IQPhoneInputContainer";
import { FormHelperText, Grid, Typography } from '@mui/material';
import EmailIcon from "@mui/icons-material/Email";
import { yesNoDropdownItems } from '../TargetedDisplayLegacyService';
import { doesClientWantLeadNotificationPerformanceReportsDropdownItems } from './ClientInformationService';

interface Props {
    product: TargetedDisplayLegactType;
    onSubmit: (data: ClientInformationPageFormProps) => void;
    onBack: () => void;
}

export type ClientInformationPageFormProps = {
    clientContactName: string;
    clientContactPhone: string;
    clientContactEmail: string;
    bestNumberToReachYou: string;
    clientHaveGoogleAnalyticsAndWantToGaugePerformanceOfThisCampaign: string;
    bestPersonForGoogleAnalyticsAccess: string;
    bestClientContactName: string;
    bestClientContactPhone: string;
    bestClientContactEmail: string;
    clientWantLeadNotificationAndOrWeeklyPerformanceReport: string;
    weeklyReportEmail: string;
};

export default function ProxyProvisioningPageForm({ product, onSubmit, onBack }: Props) {
    const { t } = useTranslation();
    const methods = useForm<ClientInformationPageFormProps>({
        mode: 'onChange',
        defaultValues: {
            clientContactName: product?.clientContactName || '',
            clientContactPhone: product?.clientContactPhone || '',
            clientContactEmail: product?.clientContactEmail || '',
            bestNumberToReachYou: product?.bestNumberToReachYou || '',
            clientHaveGoogleAnalyticsAndWantToGaugePerformanceOfThisCampaign: product?.clientHaveGoogleAnalyticsAndWantToGaugePerformanceOfThisCampaign || '',
            bestPersonForGoogleAnalyticsAccess: product?.bestPersonForGoogleAnalyticsAccess || '',
            clientWantLeadNotificationAndOrWeeklyPerformanceReport: product?.clientWantLeadNotificationAndOrWeeklyPerformanceReport || '',
            weeklyReportEmail: product?.weeklyReportEmail || '',
            bestClientContactName: product?.bestClientContactName || '',
            bestClientContactPhone: product?.bestClientContactPhone || '',
            bestClientContactEmail: product?.bestClientContactEmail || '',
        }
    });

    const handleFormSubmit = (data: ClientInformationPageFormProps) => {
        const formData = { ...data };
        if (formData.clientHaveGoogleAnalyticsAndWantToGaugePerformanceOfThisCampaign === 'No') {
            formData.bestPersonForGoogleAnalyticsAccess = '';
            formData.bestClientContactName = '';
            formData.bestClientContactPhone = '';
            formData.bestClientContactEmail = '';
        } else {
            if (formData.bestPersonForGoogleAnalyticsAccess === 'Yes') {
                formData.bestClientContactName = '';
                formData.bestClientContactPhone = '';
                formData.bestClientContactEmail = '';
            }
        }
        if (formData.clientWantLeadNotificationAndOrWeeklyPerformanceReport === 'None') {
            formData.weeklyReportEmail = '';
        }
        onSubmit(formData);
    }

    const {
        handleSubmit,
        formState: {
            isValid,
            isDirty,
            isSubmitSuccessful,
            isSubmitting,
        },
    } = methods;
    const [ClientHaveGoogleAnalyticsSelectedValue, setClientHaveGoogleAnalyticsSelectedValue] = useState(product?.clientHaveGoogleAnalyticsAndWantToGaugePerformanceOfThisCampaign || '')
    const [bestPersonForGoogleAnalyticsAccessSelectedValue, setbestPersonForGoogleAnalyticsAccessSelectedValue] = useState(product?.bestPersonForGoogleAnalyticsAccess)
    const [clientWantLeadNotificationAndOrWeeklyPerformanceReportSelectedValue, setclientWantLeadNotificationAndOrWeeklyPerformanceReportSelectedValue] = useState(product?.clientWantLeadNotificationAndOrWeeklyPerformanceReport)

    return (
        <DefaultPageLayout
            disableContinue={!isValid}
            onBack={onBack}
            header={t('pages.targetedDisplayLegacy.clientInformation.header')}
            onContinue={handleSubmit(handleFormSubmit)}
        >
            <ValidationProvider schema={schema}>
                <FormProvider {...methods}>
                    <Box
                        id="mainForm"
                        component="form"
                        sx={{ display: 'flex' }}
                        onSubmit={handleSubmit(handleFormSubmit)}
                    >
                        <GridContainer fullWidth>
                            <GridItem sizes={[12]}>
                                <IQFormInput
                                    id="clientContactName"
                                    theme={IQTheme}
                                    labelText={t('pages.targetedDisplayLegacy.clientInformation.clientContactName')}
                                    fontLabelWeight="600"
                                    name="clientContactName"
                                    fullWidth
                                />
                            </GridItem>
                            <GridItem sizes={[12]}>
                                <NePhoneInput
                                    name="clientContactPhone"
                                    defaultValue=""
                                    country="US"
                                    hasExtension={false}
                                    phoneLabelOptions={{
                                        labelText: t('pages.targetedDisplayLegacy.clientInformation.clientContactPhone'),
                                        hasError: true,
                                        theme: IQThemeV2,
                                        required: true,
                                    }}
                                    hasType={false}
                                    countryCallingCodeEditable={false}
                                    international
                                />
                            </GridItem>
                            <GridItem sizes={[12]}>
                                <IQFormInput
                                    id="clientContactEmail"
                                    name="clientContactEmail"
                                    labelText={t('pages.targetedDisplayLegacy.clientInformation.clientContactEmail')}
                                    theme={IQTheme}
                                    fontLabelWeight="600"
                                    fullWidth
                                    schema={schema}
                                    adornmentIcon={<EmailIcon />}
                                />
                                <Grid mt={-3.5} fontSize='0.9rem' fontWeight={600}>
                                    <FormHelperText
                                        variant={"filled"}>{t('pages.targetedDisplayLegacy.clientInformation.clientContactEmailDescription')}</FormHelperText>
                                </Grid>
                            </GridItem>
                            <GridItem sizes={[12]}>
                                <NePhoneInput
                                    name="bestNumberToReachYou"
                                    defaultValue=""
                                    country="US"
                                    hasExtension={false}
                                    phoneLabelOptions={{
                                        labelText: t('pages.targetedDisplayLegacy.clientInformation.bestNumberToReachYou'),
                                        hasError: true,
                                        theme: IQThemeV2,
                                        required: true,
                                    }}
                                    hasType={false}
                                    countryCallingCodeEditable={false}
                                    international
                                />
                            </GridItem>
                            <GridItem sizes={[12]}>
                                <IQFormSelect
                                    id="clientHaveGoogleAnalyticsAndWantToGaugePerformanceOfThisCampaign"
                                    name="clientHaveGoogleAnalyticsAndWantToGaugePerformanceOfThisCampaign"
                                    labelText={t('pages.targetedDisplayLegacy.clientInformation.doesClientHaveGoogleAnylytics')}
                                    items={yesNoDropdownItems}
                                    defaultValue=""
                                    theme={IQTheme}
                                    onChange={(event)=>setClientHaveGoogleAnalyticsSelectedValue(event.target.value as string)}
                                    fontLabelWeight="600"
                                    fullWidth
                                    showError
                                    schema={schema}
                                />
                            </GridItem>
                            {ClientHaveGoogleAnalyticsSelectedValue === 'Yes' && (
                                <GridItem sizes={[12]}>
                                    <IQFormSelect
                                        id="bestPersonForGoogleAnalyticsAccess"
                                        name="bestPersonForGoogleAnalyticsAccess"
                                        labelText={t('pages.targetedDisplayLegacy.clientInformation.bestPersonForGoogleAnalyticsAccess')}
                                        items={yesNoDropdownItems}
                                        defaultValue=""
                                        theme={IQTheme}
                                        onChange={(event)=>setbestPersonForGoogleAnalyticsAccessSelectedValue(event.target.value as string)}
                                        fontLabelWeight="600"
                                        fullWidth
                                        showError
                                        schema={schema}
                                    />
                                </GridItem>
                            )}
                            {(bestPersonForGoogleAnalyticsAccessSelectedValue === 'No') && (ClientHaveGoogleAnalyticsSelectedValue === 'Yes') && (
                                <>
                                    <Grid mt={-1.5} fontSize='0.9rem' fontWeight={600}>
                                        <FormHelperText
                                            variant={"filled"}>{t('pages.targetedDisplayLegacy.clientInformation.bestFormHelperText')}
                                        </FormHelperText>
                                    </Grid>
                                    <GridItem sizes={[12]}>
                                        <IQFormInput
                                            id="bestClientContactName"
                                            theme={IQTheme}
                                            labelText={t('pages.targetedDisplayLegacy.clientInformation.clientContactName')}
                                            fontLabelWeight="600"
                                            name="bestClientContactName"
                                            fullWidth
                                        />
                                    </GridItem>
                                    <GridItem sizes={[12]}>
                                        <NePhoneInput
                                            name="bestClientContactPhone"
                                            defaultValue=""
                                            country="US"
                                            hasExtension={false}
                                            phoneLabelOptions={{
                                                labelText: t('pages.targetedDisplayLegacy.clientInformation.bestClientContactPhone'),
                                                hasError: true,
                                                theme: IQThemeV2,
                                                required: false,
                                            }}
                                            hasType={false}
                                            countryCallingCodeEditable={false}
                                            international
                                        />
                                    </GridItem>
                                    <GridItem sizes={[12]}>
                                        <IQFormInput
                                            id="bestClientContactEmail"
                                            name="bestClientContactEmail"
                                            labelText={t('pages.targetedDisplayLegacy.clientInformation.clientContactEmail')}
                                            theme={IQTheme}
                                            fontLabelWeight="600"
                                            fullWidth
                                            schema={schema}
                                            adornmentIcon={<EmailIcon />}
                                        />
                                    </GridItem>
                                </>
                            )}
                            <GridItem sizes={[12]}>
                                <IQFormSelect
                                    id="clientWantLeadNotificationAndOrWeeklyPerformanceReport"
                                    name="clientWantLeadNotificationAndOrWeeklyPerformanceReport"
                                    labelText={t('pages.targetedDisplayLegacy.clientInformation.clientWantLeadNotificationAndOrWeeklyPerformanceReport')}
                                    items={doesClientWantLeadNotificationPerformanceReportsDropdownItems}
                                    defaultValue=""
                                    theme={IQTheme}
                                    onChange={(event)=>setclientWantLeadNotificationAndOrWeeklyPerformanceReportSelectedValue(event.target.value as string)}
                                    fontLabelWeight="600"
                                    fullWidth
                                    showError
                                    schema={schema}
                                />
                            </GridItem>
                            {(clientWantLeadNotificationAndOrWeeklyPerformanceReportSelectedValue === 'Weekly Performance Report' || clientWantLeadNotificationAndOrWeeklyPerformanceReportSelectedValue === 'Lead Notification' || clientWantLeadNotificationAndOrWeeklyPerformanceReportSelectedValue === 'Weekly Performance Report + Lead Notifications') && (
                                <GridItem sizes={[12]}>
                                    <IQFormInput
                                        id="weeklyReportEmail"
                                        name="weeklyReportEmail"
                                        labelText={t('pages.targetedDisplayLegacy.clientInformation.WeeklyReportEmail')}
                                        theme={IQTheme}
                                        fontLabelWeight="600"
                                        fullWidth
                                        schema={schema}
                                        adornmentIcon={<EmailIcon />}
                                    />
                                </GridItem>
                            )}
                        </GridContainer>
                    </Box>
                </FormProvider>
            </ValidationProvider>
            <CoNavigationConfirm
                showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
            />
        </DefaultPageLayout>
    );
}
