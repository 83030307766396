import { useTheme } from '@emotion/react';
import { IQDateRangePicker, ValidationProvider } from '@gannettdigital/shared-react-components';
import {
  Autocomplete, Box, Checkbox, Chip, Grid, TextField, Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectAllProductTypes } from 'services/offerMappingSlice';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ClearIcon from '@mui/icons-material/Clear';

interface DateFilters {
  initialDate: string
  finalDate: string
}

export interface OrderFilterValue {
  dateFilters: DateFilters
  productFilters: string[]
}

interface Props {
  value: OrderFilterValue
  onFilterUpdated: (value: OrderFilterValue) => void
}

export default function NewOrderFilter({ value, onFilterUpdated }: Props) {
  const theme = useTheme();
  const allProductTypes = useSelector(selectAllProductTypes);
  const [currentValue, setCurrentValue] = useState<OrderFilterValue>({} as OrderFilterValue);
  const [productTypeOptions, setProductTypeOptions] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const minPastDate = new Date('1/1/2017');
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      startDate: null,
      endDate: null,
      productTypes: [],
    },
  });

  const {
    watch, getValues, setValue, formState: { errors },
  } = methods;

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const adjustedMonth = month < 9 ? `0${month + 1}` : month + 1;
    const day = date.getDate();
    const adjustedDay = day < 10 ? `0${day}` : day;

    return `${year}-${adjustedMonth}-${adjustedDay}`;
  };

  const parseDate = (date: string) => {
    const parts = date.split('-').map(part => parseInt(part, 10));
    return new Date(parts[0], parts[1] - 1, parts[2]);
  };

  useMemo(() => {
    const updatedOptions = allProductTypes.filter(p => p.active)
      .map(el => ({ label: el.name, value: el.id }));
    setProductTypeOptions(updatedOptions);
  }, [allProductTypes]);

  const schema = {
    yupValidations: {},
    customValidations: {},
  };

  useEffect(() => {
    const filterValues = getValues();

    const paramMap = { productFilters: [], dateFilters: {} as DateFilters };
    // eslint-disable-next-line no-restricted-globals
    if (filterValues.startDate && filterValues.endDate && !isNaN(filterValues.endDate)) {
      paramMap.dateFilters = {
        initialDate: formatDate(filterValues.startDate as Date),
        finalDate: formatDate(filterValues.endDate as Date),
      };
    }

    if (filterValues.productTypes?.length > 0) {
      paramMap.productFilters = filterValues.productTypes;
    }

    if (JSON.stringify(currentValue) !== JSON.stringify(paramMap)) {
      setCurrentValue(paramMap);
      onFilterUpdated(paramMap);
    }
  }, [watch()]);

  const validateDateFormat = (dateStr) => (dateStr && !dateStr.includes('NaN') && dateStr.length === 10);

  useEffect(() => {
    if (Object.keys(value).length > 0) {
      const { productFilters } = value;
      if (productFilters?.length > 0) {
        setValue('productTypes', productFilters);
      }
      const { initialDate, finalDate } = value.dateFilters;
      if (validateDateFormat(initialDate) && validateDateFormat(finalDate)
        && new Date(finalDate) > new Date(minPastDate)
      ) {
        setValue('startDate', parseDate(initialDate));
        setValue('endDate', parseDate(finalDate));
      }
    }
  }, [value]);

  return (
    <Box sx={{
      backgroundColor: 'white', borderRadius: 2, padding: 3,
    }}
    >
      <FormProvider {...methods}>
        <ValidationProvider schema={schema}>
          <Grid container>
            <Grid item xs={8}>
              <Grid container>
                <Grid item xs={10}>
                  <Typography variant="subtitle2" mb={1}>Filter By Product Type</Typography>
                  <Autocomplete
                    sx={{ border: '1px solid #292928', '& .MuiOutlinedInput-notchedOutline': { border: 0 } }}
                    multiple
                    options={productTypeOptions}
                    getOptionLabel={(option) => option.label}
                    autoHighlight
                    disableClearable
                    disableCloseOnSelect
                    value={selectedTypes}
                    onChange={(e, valueObjArray) => {
                      const values = valueObjArray.map(e => e.value);
                      setSelectedTypes(valueObjArray);
                      setValue('productTypes', values);
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        hiddenLabel
                        placeholder={getValues('productTypes').length === 0
                          ? 'Type here for searching with Product Types' : ''}
                        error={!!errors.productTypes}
                      />
                    )}
                    renderTags={(value: any[], getTagProps) => value.map((option: any, index: number) => (
                      <Chip
                        deleteIcon={<ClearIcon />}
                        variant="outlined"
                        sx={{
                          borderColor: 'primary.main',
                          color: 'primary.main',
                          '& .MuiChip-deleteIcon': {
                            color: 'primary.main',
                          },
                        }}
                        label={option.label}
                        {...getTagProps({ index })}
                      />
                    ))}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <IQDateRangePicker
                id={{ start: 'datepicker-start', end: 'datepicker-end' }}
                name={{ start: 'startDate', end: 'endDate' }}
                labelText={{
                  start: 'From',
                  end: 'To',
                  separator: '',
                }}
                theme={theme}
                fontLabelWeight="600"
                showError
                disablePast={false}
                minDate={minPastDate}
              />
            </Grid>
          </Grid>
        </ValidationProvider>
      </FormProvider>
    </Box>
  );
}
