/* eslint-disable import/no-extraneous-dependencies */
import {RJSFSchema} from '@rjsf/utils';
import i18n from 'i18n/i18n';
import Dropdown from 'pages/generic-product/details/custom-fields/Dropdown';
import {validateUrl} from "pages/generic-product/details/custom-fields/UrlTextField";

const {t} = i18n;
const urlValidationParameter = ['https://tiktok.com', 'https://www.tiktok.com'];

export const AusTikTokSchema: RJSFSchema | any = (data) => ({
  title: t('pages.smm.solutionStrategy.title'),
  // eslint-disable-next-line max-len
  type: 'object',
  required: ['campaignObjectives', 'trackingCampaigns', 'geographicTargeting', 'whoAreWeLookingToTarget',
    'age', 'additionalInformation'],
  properties: {
    helpText1: {
      type: 'string',
      title: "",
      description: t('pages.ausProduct.ausTiktok.helpText1Label'),
      margin: 0
    },
    helpText2: {
      type: 'string',
      title: "",
      description: t('pages.ausProduct.ausTiktok.helpText2Label'),
      margin: 0
    },
    helpText3: {
      type: 'string',
      title: "",
      description: t('pages.ausProduct.ausTiktok.helpText3Label'),
      margin: 0
    },
    helpText4: {
      type: 'string',
      title: "",
      description: t('pages.ausProduct.ausTiktok.helpText4Label'),
      margin: 0
    },
    helpText5: {
      type: 'string',
      title: "",
      description: t('pages.ausProduct.ausTiktok.helpText5Label'),
      margin: 0
    },
    campaignObjectives: {
      type: 'string',
      title: t('pages.ausProduct.ausTiktok.campaignObjectivesLabel'),
      default: data?.campaignObjectives || '',
      items: {
        enum: [
          `${t('pages.ausProduct.ausTiktok.reach')}`,
          `${t('pages.ausProduct.ausTiktok.clickOrTraffic')}`,
          `${t('pages.ausProduct.ausTiktok.videoViews')}`,
          `${t('pages.ausProduct.ausTiktok.communityInteractions')}`,
        ],
      },
    },
    tracking: {
      type: 'string',
      title: t('pages.ausProduct.ausTiktok.tracking'),
      description: t('pages.ausProduct.ausTiktok.trackingHelperText'),
    },
    trackingCampaigns: {
      type: 'string',
      title: t('pages.ausProduct.tracking.trackingCampaignsLabel'),
      default: data?.trackingCampaigns || '',
      enum: [
        `${t('pages.ausProduct.tracking.reachCode')}`,
        `${t('pages.ausProduct.tracking.3pp')}`,
        `${t('pages.ausProduct.tracking.noTracking')}`,
      ],
    },
    doesClientWantToRunAdsFromTheirTiktokPage: {
      type: 'string',
      title: t('pages.ausProduct.ausTiktok.doesClientWantToRunAdsFromTheirTiktokPageLabel'),
      enum: [
        `${t('pages.ausProduct.tracking.yes')}`,
        `${t('pages.ausProduct.tracking.no')}`,
      ],
    },
    doesClientHaveSpecialMarginOnBid: {
      type: 'string',
      title: t('pages.ausProduct.ausTiktok.doesClientHaveSpecialMarginOnBidLabel'),
      enum: [
        `${t('pages.ausProduct.tracking.yes')}`,
        `${t('pages.ausProduct.tracking.no')}`,
      ],
    },
    demographicTargeting: {
      type: 'string',
      title: t('pages.ausProduct.ausTiktok.demographicTargetingLabel'),
    },
    geographicTargeting: {
      type: 'string',
      title: t('pages.ausProduct.ausTiktok.geographicTargetingLabel'),
      default: data?.geographicTargeting || '',
      description: t('pages.ausProduct.ausTiktok.geographicTargetingHelperText'),
    },
    whoAreWeLookingToTarget: {
      type: 'string',
      title: t('pages.ausProduct.ausTiktok.whoAreWeLookingToTargetLabel'),
      default: data?.whoAreWeLookingToTarget || '',
    },
    age: {
      type: 'number',
      title: t('pages.ausProduct.ausTiktok.ageLabel'),
      description: t('pages.ausProduct.ausTiktok.ageHelpText'),
      default: data?.age || '',
    },
    additionalInformation: {
      type: 'string',
      title: t('pages.ausProduct.ausTiktok.additionalInformationLabel'),
      default: data?.additionalInformation || '',
    },
  },
  dependencies: {
    campaignObjectives: {
      oneOf: [
        {
          properties: {
            campaignObjectives: {
              enum: [`${t('pages.ausProduct.ausTiktok.clickOrTraffic')}`],
            },
            clientsWebsiteUrl: {
              type: 'string',
              title: t('pages.ausProduct.ausTiktok.clientsWebsiteUrlLabel'),
              default: data?.clientsWebsiteUrl || '',
            },
          },
        },
      ],
    },
    doesClientWantToRunAdsFromTheirTiktokPage: {
      oneOf: [
        {
          properties: {
            doesClientWantToRunAdsFromTheirTiktokPage: {
              enum: [t('pages.ausProduct.tracking.yes')],
            },
            tiktokPageUrl: {
              type: 'string',
              title: t('pages.ausProduct.ausTiktok.tiktokPageUrlLabel'),
              default: data?.tiktokPageUrl || '',
              customUrlValidation: true,
              urlValidationParameter,
              urlValidationErrorText: t('pages.ausProduct.ausTiktok.tiktokPageUrlErrorMessage'),
              description: t('pages.ausProduct.ausTiktok.tiktokPageUrlHelperText'),
            },
          },
          required: ['tiktokPageUrl'],
        },
      ],
    },
    doesClientHaveSpecialMarginOnBid: {
      oneOf: [
        {
          properties: {
            doesClientHaveSpecialMarginOnBid: {
              enum: [t('pages.ausProduct.tracking.yes')],
            },
            margin: {
              type: 'string',
              title: t('pages.ausProduct.ausTiktok.marginLabel'),
              default: data?.margin || '',
            },
          },
        },
      ],
    },
  },
});

const textFieldKeys = [
  'margin', 'geographicTargeting', 'whoAreWeLookingToTarget',
  'age', 'additionalInformation',
];

const radioFieldKeys = [
  'campaignObjectives',
];

const dropdownFieldKeys = [
  'trackingCampaigns', 'doesClientWantToRunAdsFromTheirTiktokPage', 'doesClientHaveSpecialMarginOnBid'
];

const headingKeys = [
  'tracking', 'demographicTargeting'
]
const textLabelKeys = [
  'helpText1', 'helpText2', 'helpText3', 'helpText4', 'helpText5'
];

const urlTextFieldKeys = [
  'clientsWebsiteUrl', 'tiktokPageUrl',
]

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o, [key]: {'ui:widget': 'textInputField'},
}), {});

const radioFields = radioFieldKeys.reduce((o, key) => ({
  ...o, [key]: {'ui:widget': 'radioButton'},
}), {});

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {
    'ui:widget': (props) => (
        <Dropdown
            {...props}
            errorMessage={t('pages.ausProduct.tracking.requiredFieldErrorMessage')}
        />
    ),
  },
}), {});

const headingFields = headingKeys.reduce((o, key) => ({
  ...o, [key]: {'ui:widget': 'headingTextLabel',},
}), {});

const textLabelFields = textLabelKeys.reduce((o, key) => ({
  ...o, [key]: {'ui:widget': 'textLabel'},
}), {});

const urlTextFields = urlTextFieldKeys.reduce((o, key) => ({
  ...o, [key]: {'ui:widget': 'UrlTextWidget'},
}), {});

export const AusTikTokUISchema = {
  'ui:order': ['helpText1', 'helpText2', 'helpText3', 'helpText4', 'helpText5', 'campaignObjectives',
    'clientsWebsiteUrl', 'tracking', 'trackingCampaigns', 'doesClientWantToRunAdsFromTheirTiktokPage',
    'tiktokPageUrl', 'doesClientHaveSpecialMarginOnBid', 'margin', 'demographicTargeting',
    'geographicTargeting', 'whoAreWeLookingToTarget', 'age', 'additionalInformation'],
  ...textFields,
  ...radioFields,
  ...dropdownFields,
  ...headingFields,
  ...textLabelFields,
  ...urlTextFields
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AusTikTokCustomValidate = (formData: any, errors, uiSchema: any) => {
  if (formData?.campaignObjectives === `${t('pages.ausProduct.ausTiktok.clickOrTraffic')}`) {
    if (formData?.clientsWebsiteUrl !== undefined && formData?.clientsWebsiteUrl !== ''
        && !validateUrl(formData?.clientsWebsiteUrl)) {
      errors.clientsWebsiteUrl.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
      errors.addError(true);
    }
  }
  if (formData?.campaignObjectives === '') {
    errors.campaignObjectives.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.trackingCampaigns === '') {
    errors.trackingCampaigns.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.doesClientWantToRunAdsFromTheirTiktokPage === t('pages.ausProduct.tracking.yes')) {
    if (formData?.tiktokPageUrl === '' || !urlValidationParameter.some(url => formData?.tiktokPageUrl?.startsWith(url))) {
      errors.tiktokPageUrl.addError(t('pages.ausProduct.ausTiktok.tiktokPageUrlErrorMessage'));
      errors.addError(true);
    }
  }
  if (formData?.geographicTargeting === '') {
    errors.geographicTargeting.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.whoAreWeLookingToTarget === '') {
    errors.whoAreWeLookingToTarget.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.age === '') {
    errors.age.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.additionalInformation === '') {
    errors.additionalInformation.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
