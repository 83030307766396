/* eslint-disable no-param-reassign */
import {
  Alert,
  AlertTitle,
  Box, Grid, Tab, Tabs, Typography,
} from '@mui/material';
import {
  SyntheticEvent, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { getOrdersList, selectOrdersStatus } from 'services/myOrdersSlice';
import { getUserInfo, selectUserAccess } from 'services/userSlice';
import NewOrderFilter, { OrderFilterValue } from 'pages/orders/NewOrderFilter';
import { getAllProductTypes } from 'services/offerMappingSlice';
import NameAndStatusFilter, { NameStatusFilterValue } from './NameAndStatusFilter';
import OrdersTable from './OrdersTable';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = ({ children, index, value }: TabPanelProps) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
  >
    {value === index && (
      <Box paddingTop={4}>
        <Typography>{children}</Typography>
      </Box>
    )}
  </div>
);

export default function MyOrders() {
  const userAccess = useSelector(selectUserAccess);
  let notAuthorized = false;
  const currentUrl = window.location.href;
  const ordersStatus = useSelector(selectOrdersStatus);

  if (currentUrl.includes('not-authorized')) {
    notAuthorized = true;
  }

  const [currentTab, setCurrentTab] = useState(0);
  const [filterValue, setFilterValue] = useState<OrderFilterValue>({} as OrderFilterValue);
  const [nameStatusFilterValue, setNameStatusFilterValue] = useState<NameStatusFilterValue>(
    {} as NameStatusFilterValue,
  );
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [mailSuccessNotifier, setMailSuccessNotifier] = useState(false);
  const [mailFailureNotifier, setMailFailureNotifier] = useState(false);
  const [postFinalizationBanner, setPostFinalizationBanner] = useState(false);
  const [canSeePunchlist, setCanSeePunchlist] = useState(false);

  const debounceDuration = 300;

  const setOrderType = (tab) => {
    switch (tab) {
      case 1:
        return 'NEON_CUSTOM_ORDERS';
      case 2:
        return 'PUNCHLIST_ORDERS';
      default:
        return 'NEON_ORDERS';
    }
  };

  useMemo(() => {
    if (userAccess.isAdmin || userAccess.canDoFulfillment) {
      setCanSeePunchlist(true);
    }
  }, [userAccess]);

  useEffect(() => {
    getUserInfo('me');
    const currentUrl = window.location.href;
    if (currentUrl.includes('success')) {
      setMailSuccessNotifier(true);
      navigate('/orders');
    }

    if (currentUrl.includes('fail')) {
      setMailFailureNotifier(true);
      navigate('/orders');
    }

    if (currentUrl.includes('finalized')) {
      setPostFinalizationBanner(true);
      navigate('/orders');
    }
  }, [window.location.href]);

  const setBanner = (mailSuccessNotifier || mailFailureNotifier || postFinalizationBanner) && (
    <Grid item xs={4}
      marginTop={4}
      marginBottom={4}
      sx={{ textAlign: 'center', margin: '0 auto' }}
    >
      {mailSuccessNotifier
      && (
      <Alert
        severity="success"
        sx={{
          border: '1px solid #DFF0D8',
          background: '#DFF0D8',
          borderRadius: '4px',
          textAlign: 'center',
          color: '#3C763D',
          '& .MuiAlert-icon': { color: '#3C763D' },
        }}
      >
        <AlertTitle>Success</AlertTitle>
        Image Selection Email sent to user successfully!
      </Alert>
      )}
      {mailFailureNotifier
      && (
      <Alert
        severity="error"
        sx={{
          border: '1px solid #F5D5D7',
          background: '#F5D5D7',
          borderRadius: '4px',
          textAlign: 'center',
          color: '#C20F1E',
          '& .MuiAlert-icon': { color: '#C20F1E' },
        }}
      >
        <AlertTitle>Error</AlertTitle>
        Unable to send Selection Email to user!
      </Alert>
      )}
      {postFinalizationBanner
      && (
        <Alert
          severity="success"
          sx={{
            border: '1px solid #DFF0D8',
            background: '#DFF0D8',
            borderRadius: '4px',
            textAlign: 'center',
            color: '#3C763D',
            '& .MuiAlert-icon': { color: '#3C763D' },
          }}
        >
          <AlertTitle>Order Fulfilled</AlertTitle>
          There may be a delay between order submission and account creation / listings added to Uberall.
        </Alert>
      )}
    </Grid>
  );

  useEffect(() => {
    dispatch(getAllProductTypes({}));
  }, []);

  useEffect(() => {
    if (location.pathname.endsWith('quoteRequests')) {
      setCurrentTab(1);
    } else if (location.pathname.endsWith('punchList')) {
      setCurrentTab(2);
    }
  }, [location]);

  useEffect(() => {
    const dateFilters = { initialDate: null, finalDate: null };

    const initial = searchParams.get('dateFilters[initialDate]');
    const final = searchParams.get('dateFilters[finalDate]');
    if (initial && final) {
      dateFilters.initialDate = initial;
      dateFilters.finalDate = final;
    }
    // setFilterValue({ productFilters, dateFilters });

    if (searchParams.get('searchTerm')) {
      setNameStatusFilterValue({ searchTerm: searchParams.get('searchTerm') });
    } else if (searchParams.get('status')) {
      setNameStatusFilterValue({ status: searchParams.get('status') });
    }
  }, [searchParams]);

  const resetParams = () => {
    setSearchParams({});
    setFilterValue({} as OrderFilterValue);
    setNameStatusFilterValue({} as NameStatusFilterValue);
  };

  const handleChange = (event: SyntheticEvent, tabIndex: number) => {
    switch (tabIndex) {
      case 1:
        setCurrentTab(1);
        resetParams();
        navigate('/orders/quoteRequests');
        break;
      case 2:
        setCurrentTab(2);
        resetParams();
        navigate('/orders/punchList');
        break;
      default:
        resetParams();
        navigate('/orders');
        setCurrentTab(0);
        break;
    }
  };

  const buildParams = () => {
    const { productFilters, dateFilters } = filterValue;
    const { initialDate, finalDate } = (dateFilters || {});
    const params: any = {};
    if (searchParams.get('page')) {
      params.page = searchParams.get('page');
    }

    if (searchParams.get('size')) {
      params.size = searchParams.get('size');
    }

    if (searchParams.get('cron')) {
      params.cron = searchParams.get('cron');
    }

    if (searchParams.get('biz')) {
      params.cron = searchParams.get('biz');
    }

    if (productFilters?.length > 0) {
      productFilters.map(type => `typeFilters[${type}]`).forEach(type => {
        params[type] = 'on';
      });
    }

    if (initialDate && finalDate) {
      params['dateFilters[initialDate]'] = initialDate;
      params['dateFilters[finalDate]'] = finalDate;
    }

    const { searchTerm, status } = nameStatusFilterValue;
    if (searchTerm) {
      params.searchTerm = searchTerm;
    } else if (status) {
      params.status = status;
    }

    return params;
  };

  const handleFilterUpdated = (value: OrderFilterValue) => {
    setFilterValue(value);
  };

  const handleNameFilterUpdated = (value: NameStatusFilterValue) => {
    setNameStatusFilterValue(value);
  };

  const debouncedNameSearchHandler = useCallback(
    debounce(handleNameFilterUpdated, debounceDuration),
    [],
  );

  const isValidDate = (date) => {
    const parsedDate = new Date(date);
    // eslint-disable-next-line no-restricted-globals
    return !isNaN(parsedDate.getTime());
  };

  const buildQueryParams = (type, value) => {
    if (value === null) return undefined;
    switch (type) {
      case 'page':
        return `&page=${value}`;
      case 'size':
        return `&size=${value}`;
      case 'dateStart':
        return isValidDate(value) ? `&dateStart=${new Date(value).toISOString()}` : '';
      case 'dateEnd':
        return isValidDate(value) ? `&dateEnd=${new Date(value).toISOString()}` : '';
      case 'search':
        return `&search=%25${value}%25`;
      case 'product':
        return value.length > 0 ? value.map(e => `&selectedProductIds=${e}`).join('') : undefined;
      case 'cron':
        return `&sort=created_at%2C${value}`;
      case 'biz':
        return `&sort=businessName%2C${value}`;
      case 'status':
        return `&neonOrderStatus=${value}`;
      default:
        return value;
    }
  };

  const formatQueryObjIntoString = (queryObj) => {
    const selectedParams = Object.keys(queryObj).reduce((obj, key) => {
      if (queryObj[key] !== undefined) {
        obj[key] = queryObj[key];
      }
      return obj;
    }, {});

    return Object.values(selectedParams).join('');
  };
  const buildQuery = (searchParams) => {
    const queryObj = {
      page: buildQueryParams('page', searchParams.get('page') || 0),
      size: buildQueryParams('size', searchParams.get('size') || 10),
      selectedProductIds: filterValue?.productFilters ? buildQueryParams(
        'product',
        filterValue?.productFilters,
      ) : '',
      search: buildQueryParams('search', searchParams.get('searchTerm')),
      dateStart: buildQueryParams('dateStart', searchParams.get('dateFilters[initialDate]')),
      dateEnd: buildQueryParams('dateEnd', searchParams.get('dateFilters[finalDate]')),
      created_at: buildQueryParams('cron', searchParams.get('cron')),
      businessName: buildQueryParams('biz', searchParams.get('biz')),
      neonOrderStatus: buildQueryParams('status', searchParams.get('status')),
    };

    return formatQueryObjIntoString(queryObj);
  };

  const buildCustomQuoteQuery = (searchParams) => {
    const queryObj = {
      page: buildQueryParams('page', searchParams.get('page') || 0),
      size: buildQueryParams('size', searchParams.get('size') || 10),
      created_at: buildQueryParams('cron', searchParams.get('cron')),
      businessName: buildQueryParams('biz', searchParams.get('biz')),
    };

    return formatQueryObjIntoString(queryObj);
  };

  useMemo(() => {
    setSearchParams(buildParams());
  }, [filterValue, nameStatusFilterValue]);

  useEffect(() => {
    const queryConstruct = buildQuery(searchParams);
    const customQuoteConstruct = buildCustomQuoteQuery(searchParams);
    if (ordersStatus !== 'loading') {
      if (currentTab !== 1) {
        dispatch(getOrdersList({ orderType: setOrderType(currentTab), query: queryConstruct }));
      } else {
        dispatch(getOrdersList({ orderType: setOrderType(1), query: customQuoteConstruct }));
      }
    }
  }, [searchParams, currentTab]);

  return (
    <Grid container sx={{ width: '1400px', margin: 'auto' }}>
      {setBanner}
      <Grid item xs={12}>
        {(notAuthorized && (
          <Alert severity="error" sx={{ textAlign: 'left', fontSize: '1.0em', border: '1' }}>
            <AlertTitle>Access Denied</AlertTitle>
            You are not authorized to access that page.
          </Alert>
        ))}
        <Typography variant="h4">My Orders</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={currentTab}
            onChange={handleChange}
            sx={{
              '.MuiTab-root': {
                textTransform: 'none',
                alignItems: 'flex-start',
                paddingLeft: 0,
                paddingRight: 0,
                minWidth: 0,
                marginRight: 4,
              },
            }}
          >
            <Tab label="Orders" />
            <Tab label="Quote Requests" />
            { canSeePunchlist && (
            <Tab label="Punchlist" />
            ) }
          </Tabs>
        </Box>
        <TabPanel value={currentTab} index={0}>
          <NewOrderFilter value={filterValue} onFilterUpdated={handleFilterUpdated} />
          <Grid container marginTop={4}>
            <Grid item xs={10}>
              <NameAndStatusFilter value={nameStatusFilterValue} onFilterUpdated={debouncedNameSearchHandler} />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={currentTab} index={1} />
        <TabPanel value={currentTab} index={2}>
          <NewOrderFilter value={filterValue} onFilterUpdated={handleFilterUpdated} />
          <Grid container marginTop={4}>
            <Grid item xs={10}>
              <NameAndStatusFilter value={nameStatusFilterValue} onFilterUpdated={debouncedNameSearchHandler} />
            </Grid>
          </Grid>
        </TabPanel>
      </Grid>
      <Grid item xs={12} marginTop={4}>
        <OrdersTable />
      </Grid>
    </Grid>
  );
}
