import { Urls } from 'navigation/Urls';
import ExistingAssets from 'pages/websites/design/existing-assets/ExistingAssets';
import GeneralQuestions from 'pages/general-questions/GeneralQuestions';
import DomainsPage from 'pages/websites/domains/DomainsPage';
import Examples from 'pages/websites/design/examples/Examples';
import AdditionalFeaturesDesign
  from 'pages/websites/design/additional-features-design/AdditionalFeaturesDesign';
import ContentWriting from 'pages/websites/content/content-writing/ContentWriting';
import ExistingContent from 'pages/websites/content/existing-content/ExistingContent';
import WebsiteAgeGate from 'pages/websites/content/age-gate/AgeGate';
import AdditionalFeaturesContent
  from 'pages/websites/content/additional-features-content/AdditionalFeaturesContent';
import SitePages from 'pages/websites/content/site-pages/SitePages';
import BusinessDetails from 'pages/business/details/BusinessDetails';
import BusinessSearch from 'pages/business/search/BusinessSearch';
import ClientDetails from 'pages/business/client/ClientDetails';
import CampaignIds from 'pages/business/campaignIds/CampaignIds';
import LocationsPage from 'pages/locations/LocationsPage';
import SalesAgentDetails from 'pages/sales_agent/SalesAgentDetails';
import Overview from 'pages/overview/Overview';
import DomainPage from 'pages/ecommerce/domain/DomainPage';
import StockAssets from 'pages/ecommerce/design/stock-assets/StockAssets';
import Branding from 'pages/ecommerce/design/branding/Branding';
import ProductsDetails from 'pages/ecommerce/products/details/ProductsDetails';
import ProductCatalog from 'pages/ecommerce/products/product-catalog/ProductCatalog';
import Returns from 'pages/ecommerce/store-policies/returns/Returns';
import AdditionalStoreDetails
  from 'pages/ecommerce/store-policies/additional/AdditionalStoreDetails';
import AgeGate from 'pages/ecommerce/store-policies/age-gate/AgeGate';
import ShippingPolicy from 'pages/ecommerce/store-policies/shipping-policy/ShippingPolicy';
import Contact from 'pages/ecommerce/store-policies/contact/Contact';
import OrderType from 'pages/landing-pages/campaign/order-type/OrderType';
import Timeline from 'pages/landing-pages/campaign/timeline/Timeline';
import Audience from 'pages/landing-pages/campaign/audience/Audience';
import Goals from 'pages/landing-pages/campaign/goals/Goals';
import Tracking from 'pages/landing-pages/campaign/tracking/Tracking';
import Domain from 'pages/landing-pages/domain/Domain';
import LandingContentWriting
  from 'pages/landing-pages/content/content-writing/LandingContentWriting';
import LandingPageInfo from 'pages/landing-pages/content/info/LandingPageInfo';
import LandingExistingContent
  from 'pages/landing-pages/content/existing-content/LandingExistingContent';
import LandingStockAssets from 'pages/landing-pages/design/stock-assets/LandingStockAssets';
import BusinessPage from 'pages/chat/business/BusinessPage';
import ScriptDetails from 'pages/chat/script-details/ScriptDetails';
import ChatLocations from 'pages/chat/locations/ChatLocations';
import AdditionalPage from 'pages/chat/additional/AdditionalPage';
import ChatPlatinumBusinessPage from 'pages/chat-platinum/business/BusinessPage';
import ChatPlatinumScriptDetails from 'pages/chat-platinum/script-details/ScriptDetails';
import ChatPlatinumChatLocations from 'pages/chat-platinum/locations/ChatLocations';
import ChatPlatinumAdditionalPage from 'pages/chat-platinum/additional/AdditionalPage';
import CustomLandingPageDetails from 'pages/landing-pages/custom/details/CustomLandingPageDetails';
import CustomLandingPageDesign from 'pages/landing-pages/custom/design/CustomLandingPageDesign';
import CustomLandingPageDevelopment
  from 'pages/landing-pages/custom/development/CustomLandingPageDevelopment';
import CustomLandingPageAdditionalInfo
  from 'pages/landing-pages/custom/additional-info/CustomLandingPageAdditionalInfo';
import CustomWebsiteDetails from 'pages/websites/custom/details/CustomWebsiteDetails';
import CustomWebsiteDesignItems from 'pages/websites/custom/design-items/CustomWebsiteDesignItems';
import CustomWebsiteDesignStepper from 'pages/websites/custom/CustomWebsiteDesignStepper';
import CustomWebsiteFeaturesList from 'pages/websites/custom/features/CustomWebsitesFeatureList';
import CustomWebsiteFeaturesStepper from 'pages/websites/custom/CustomWebsiteFeaturesStepper';
import CustomWebsiteDevelopmentStepper from 'pages/websites/custom/CustomWebsiteDevelopmentStepper';
import CustomWebsiteAdditionalInfo
  from 'pages/websites/custom/additional-info/CustomWebsiteAdditionalInfo';
import DetailsPage from 'pages/seo/details/DetailsPage';
import SolutionStrategy from 'pages/seo/solutionStrategy/SolutionStrategy';
import CustomSolutionStrategy from 'pages/customSeo/solutionStrategy/CustomSolutionStrategy';
import BlogSolutionStrategy from 'pages/seo-blog/solution-strategy/BlogSolutionStrategy';
import SMMDetailsPage from 'pages/smm/details/SMMDetailsPage';
import SMMStrategyPage from 'pages/smm/solutionStrategy/SMMStrategyPage';
import ProductMoveBackward from 'pages/redirect/ProductMoveBackward';
import ProductMoveForward from 'pages/redirect/ProductMoveForward';
import AddonsHome from 'pages/addons/addons-home/AddonsHome';
import HeroVideoPage from 'pages/addons/heroVideo/HeroVideoPage';
import EmailHosting from 'pages/addons/emailHosting/EmailHosting';
import ExpandableMenu from 'pages/addons/expandableMenu/ExpandableMenu';
import PaymentForms from 'pages/addons/paymentForms/PaymentForms';
import OnlineBooking from 'pages/addons/online-booking/OnlineBooking';
import PopUpPage from 'pages/addons/popUp/PopUpPage';
import HipaaForms from 'pages/addons/hipaaForms/HipaaForms';
import CCByDashDetails from 'pages/dms-next/cc-by-dash/details/CCByDashDetails';
import CCByDashStrategy from 'pages/dms-next/cc-by-dash/solutionStrategy/CCByDashStrategy';
import CustomerCenterStrategy
  from 'pages/dms-next/customer-center/solutionStrategy/CustomerCenterStrategy';
import Targeting from 'pages/youtube/targeting/Targeting';
import Websites from 'pages/youtube/websites/Websites';
import ProxyProvisioning from 'pages/youtube/proxy-provisioning/ProxyProvisioning';
import Advertising from 'pages/youtube/advertising/Advertising';
import XmoStepDetails from 'pages/xmo/details/XmoStepDetails';
import XmoTargeting from 'pages/xmo/solutionStrategy/targeting/XmoTargeting';
import LeadAds from 'pages/xmo/tactics/leadAds/LeadAds';
import XmoYoutube from 'pages/xmo/tactics/youtube/XmoYoutube';
import TargetedDisplay from 'pages/targeted-display/TargetedDisplay';
import PlHealthcareLocations from './premium-listings/healthcare/PlHealthcareLocations';
import PiQWebsite from './piq/landing-pages/website/PiQWebsite';
import PiQPageInfo from './piq/landing-pages/pageInfo/PiQPageInfo';
import PiQFormFields from './piq/landing-pages/formFields/PiQFormFields';
import PiQTracking from './piq/landing-pages/tracking/PiQTracking';
import PiQBackgroundImage from './piq/landing-pages/backgroundImage/PiQBackgroundImage';
import PiQAssets from './piq/landing-pages/assets/PiQAssets';
import PiQAdditionalInfo from './piq/landing-pages/additionalInfo/PiQAdditionalInfo';
import GenericProductDetailsPage from './generic-product/details/GenericProductDetailsPage';
import ThemeSelection from './punchlist/themeSelection/ThemeSelection';
import SemDetails from './sem/details/Details';
import ProxyProvisioningTargetedDisplayLeagacy from './target-display-legacy/proxy-provisioning/ProxyProvisioning';
import ClientInformationTargetedDisplayLeagacy from './target-display-legacy/client-information/ClientInformation';
import XmoGeneral from './xmo/solutionStrategy/general/XmoGeneral';
import XmoGoal from './xmo/solutionStrategy/goal/XmoGoal';
import XmoProxy from './xmo/solutionStrategy/proxy/XmoProxy';
import SocialSmartOptimization from './xmo/tactics/socialSmartOptimization/SocialSmartOptimization';
import Sem from './xmo/tactics/sem/Sem';
import WebsiteClicks from './xmo/tactics/websiteClicks/WebsiteClicks';
import Retargeting from './xmo/tactics/retargeting/Retargeting';
import XmoChat from './xmo/tactics/chat/XmoChat';
import XmoSnapchat from './xmo/tactics/snapchat/XmoSnapchat';
import XmoReach from './xmo/tactics/reach/XmoReach';
import XmoBrandAmplifier from './xmo/solutionStrategy/brandAmplifier/XmoBrandAmplifier';
import PiQDomain from './piq/landing-pages/domain/PiQDomain';

export const ProductPageRouteMapping = {
  [Urls.ProductMoveBackward]: ProductMoveBackward,
  [Urls.ProductMoveForward]: ProductMoveForward,
  [Urls.BusinessSearch]: BusinessSearch,
  [Urls.BusinessDetail]: BusinessDetails,
  [Urls.BusinessAddNew]: BusinessDetails,
  [Urls.BusinessClient]: ClientDetails,
  [Urls.BusinessCampaign]: CampaignIds,
  [Urls.BusinessLocation]: LocationsPage,
  [Urls.PlHealthcareLocations]: PlHealthcareLocations,
  [Urls.SalesRep]: SalesAgentDetails,
  [Urls.Overview]: Overview,
  [Urls.GeneralQuestions]: GeneralQuestions,
  [Urls.Domains]: DomainsPage,
  [Urls.Theme]: ThemeSelection,
  [Urls.StockAssets]: ExistingAssets,
  [Urls.Examples]: Examples,
  [Urls.AdditionalFeaturesDesign]: AdditionalFeaturesDesign,
  [Urls.ContentWriting]: ContentWriting,
  [Urls.ExistingContent]: ExistingContent,
  [Urls.WebsiteAgeGate]: WebsiteAgeGate,
  [Urls.AdditionalFeaturesContent]: AdditionalFeaturesContent,
  [Urls.SitePages]: SitePages,
  [Urls.Domain]: DomainPage,
  [Urls.Assets]: StockAssets,
  [Urls.Branding]: Branding,
  [Urls.ProductsDetails]: ProductsDetails,
  [Urls.ProductCatalog]: ProductCatalog,
  [Urls.Returns]: Returns,
  [Urls.AdditionalStoreDetails]: AdditionalStoreDetails,
  [Urls.AgeGate]: AgeGate,
  [Urls.Shipping]: ShippingPolicy,
  [Urls.Contact]: Contact,
  [Urls.OrderType]: OrderType,
  [Urls.Timeline]: Timeline,
  [Urls.Audience]: Audience,
  [Urls.Goals]: Goals,
  [Urls.Tracking]: Tracking,
  [Urls.LandingDomain]: Domain,
  [Urls.LandingContentWriting]: LandingContentWriting,
  [Urls.Info]: LandingPageInfo,
  [Urls.LandingExistingContent]: LandingExistingContent,
  [Urls.LandingStockAssets]: LandingStockAssets,
  [Urls.BusinessChat]: BusinessPage,
  [Urls.ScriptDetails]: ScriptDetails,
  [Urls.ChatLocations]: ChatLocations,
  [Urls.ChatAdditional]: AdditionalPage,
  [Urls.PlatinumBusinessChat]: ChatPlatinumBusinessPage,
  [Urls.PlatinumScriptDetails]: ChatPlatinumScriptDetails,
  [Urls.PlatinumChatLocations]: ChatPlatinumChatLocations,
  [Urls.PlatinumChatAdditional]: ChatPlatinumAdditionalPage,
  [Urls.LandingCustomDetails]: CustomLandingPageDetails,
  [Urls.LandingCustomDesign]: CustomLandingPageDesign,
  [Urls.LandingCustomDevelopment]: CustomLandingPageDevelopment,
  [Urls.LandingCustomAdditionalInfo]: CustomLandingPageAdditionalInfo,
  [Urls.CustomWebsiteDetails]: CustomWebsiteDetails,
  [Urls.CustomWebsiteDesign]: CustomWebsiteDesignItems,
  [Urls.CustomWebsiteDesignItems]: CustomWebsiteDesignStepper,
  [Urls.CustomWebsiteFeatures]: CustomWebsiteFeaturesList,
  [Urls.CustomWebsiteFeatureList]: CustomWebsiteFeaturesStepper,
  [Urls.CustomWebsiteDevelopment]: CustomWebsiteDevelopmentStepper,
  [Urls.CustomWebsiteAdditionalInfo]: CustomWebsiteAdditionalInfo,
  [Urls.SeoDetails]: DetailsPage,
  [Urls.SolutionStrategy]: SolutionStrategy,
  [Urls.CustomSolutionStrategy]: CustomSolutionStrategy,
  [Urls.BlogSolutionStrategy]: BlogSolutionStrategy,
  [Urls.SMMDetails]: SMMDetailsPage,
  [Urls.SMMSolutionStrategy]: SMMStrategyPage,
  [Urls.WebsiteUrl]: AddonsHome,
  [Urls.HeroVideoGeneralQuestions]: GeneralQuestions,
  [Urls.HeroVideo]: HeroVideoPage,
  [Urls.EmailHostingGeneralQuestions]: GeneralQuestions,
  [Urls.EmailHosting]: EmailHosting,
  [Urls.ExpandableMenusGeneralQuestions]: GeneralQuestions,
  [Urls.ExpandableMenus]: ExpandableMenu,
  [Urls.PaymentFormsGeneralQuestions]: GeneralQuestions,
  [Urls.PaymentForms]: PaymentForms,
  [Urls.OnlineBookingGeneralQuestions]: GeneralQuestions,
  [Urls.OnlineBooking]: OnlineBooking,
  [Urls.PopupsGeneralQuestions]: GeneralQuestions,
  [Urls.Popups]: PopUpPage,
  [Urls.HipaaFormsGeneralQuestions]: GeneralQuestions,
  [Urls.HipaaForms]: HipaaForms,
  [Urls.CCByDashDetails]: CCByDashDetails,
  [Urls.CCByDashStrategy]: CCByDashStrategy,
  [Urls.CustomerCenterStrategy]: CustomerCenterStrategy,
  [Urls.PiQDomain]: PiQDomain,
  [Urls.PiQWebsite]: PiQWebsite,
  [Urls.PiQPageInfo]: PiQPageInfo,
  [Urls.PiQFormFields]: PiQFormFields,
  [Urls.PiQTracking]: PiQTracking,
  [Urls.PiQBackgroundImage]: PiQBackgroundImage,
  [Urls.PiQAssets]: PiQAssets,
  [Urls.PiQAdditionalInfo]: PiQAdditionalInfo,
  [Urls.GenericProductDetails]: GenericProductDetailsPage,
  [Urls.YouTubeTargeting]: Targeting,
  [Urls.YouTubeWebsites]: Websites,
  [Urls.YouTubeProxyProvisioning]: ProxyProvisioning,
  [Urls.YouTubeAdvertising]: Advertising,
  [Urls.SemDetails]: SemDetails,
  [Urls.TargetedDisplay]: TargetedDisplay,
  [Urls.TargetedDisplayLeagacyProxyProvisioning]: ProxyProvisioningTargetedDisplayLeagacy,
  [Urls.TargetedDisplayLeagacyClientInformation]: ClientInformationTargetedDisplayLeagacy,
  [Urls.XmoDetails]: XmoStepDetails,
  [Urls.XmoBrandAmplifier]: XmoBrandAmplifier,
  [Urls.XmoStrategyGeneral]: XmoGeneral,
  [Urls.XmoStrategyGoal]: XmoGoal,
  [Urls.XmoStrategyTargeting]: XmoTargeting,
  [Urls.XmoStrategyProxy]: XmoProxy,
  [Urls.XmoSocialSmartOptimization]: SocialSmartOptimization,
  [Urls.XmoSem]: Sem,
  [Urls.XmoWebsiteClicks]: WebsiteClicks,
  [Urls.XmoLeadAds]: LeadAds,
  [Urls.XmoRetargeting]: Retargeting,
  [Urls.XmoChat]: XmoChat,
  [Urls.XmoTargetedDisplay]: TargetedDisplay,
  [Urls.XmoSnapchat]: XmoSnapchat,
  [Urls.XmoYouTube]: XmoYoutube,
  [Urls.XmoReach]: XmoReach,
  [Urls.PmmDetails]: XmoStepDetails,
  [Urls.PmmStrategyGeneral]: XmoGeneral,
  [Urls.PmmStrategyTargeting]: XmoTargeting,
  [Urls.PmmStrategyProxy]: XmoProxy,
  [Urls.PmmSem]: Sem,
  [Urls.PmmSocialSmartOptimization]: SocialSmartOptimization,
  [Urls.PmmWebsiteClicks]: WebsiteClicks,
  [Urls.PmmRetargeting]: Retargeting,
  [Urls.PmmTargetedDisplay]: TargetedDisplay,
};
