import { Children } from 'react';
import {
  Box, Button, Divider, Grid, Stack, Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Product } from 'services/adminSlice';
import { setImageUrl } from 'services/themesSlice';
import OrderTracker from './OrderTracker';
import { renderStatusPill } from './OrdersTable';

interface Props {
  orderDetails: any
}

export default function OrderDetailsRow({ orderDetails }: Props) {
  const formatDate = (date: Date) =>
    date && `${date.toLocaleString('default', { month: 'long' })} ${date.getDate()}`;

  const isPlProduct = (product: Product) => product.productName === 'PremiumListing'
  || product.productName === 'Premium Listings Healthcare';

  const defaultBorder = '1px solid rgba(224, 224, 224, 1)';

  const displayReviewButton = (product) => isPlProduct(product) && (
  <Link
    to={`/order/${product.orderItem.orderId}/review?reviewId=true`}
    style={{ pointerEvents: product.orderStatus?.neonStatus === 'Payment Pending' ? 'none' : 'auto' }}
  >
    <Button
      variant={(product.productName === 'PremiumListing'
                && product.orderStatus?.neonStatus === 'Completed') ? 'outlined' : 'contained'}
      sx={{ width: '150px', paddingY: 1, marginTop: 4 }}
      disabled={product.orderStatus?.neonStatus === 'Payment Pending'}
    >
      <Typography fontWeight="bold" fontSize="17px">
        Review
      </Typography>
    </Button>
  </Link>
  );

  const displayViewProductButton = (product) => (
    <Link to={`/order/${product.orderItem.orderId}/product/${product.orderItem.id}`}>
      <Button
        variant="contained"
        sx={{
          width: '150px', paddingY: 1, marginTop: 4, marginLeft: 1,
        }}
      >
        <Typography fontWeight="bold" fontSize="17px">View Product</Typography>
      </Button>
    </Link>
  );

  const getCidStatus = (product) => {
    if (product.orderItem.campaignId) {
      return product.orderItem.campaignId;
    }
    return product.orderItem.status;
  };

  const checkTrackerStatus = (product) => {
    const { lastCompletedStep } = product.orderStatus ?? { lastCompletedStep: null };
    if (lastCompletedStep === 'Order Submitted' || lastCompletedStep === null) {
      if (isPlProduct(product)
            || product.orderStatus?.neonStatus.includes('Payment')
            || product.orderStatus?.neonStatus.includes('Assignments')) {
        return product.orderStatus?.neonStatus;
      }
    }

    return lastCompletedStep;
  };

  const getProductStatus = (product) => {
    if (product.orderStatus?.neonStatus === 'Incomplete' || product.orderStatus?.wrikeStatus === 'Cancelled') {
      return 'Off Track';
    }
    return product.orderStatus?.neonStatus;
  };

  return (
    <Box
      sx={{
        padding: 3,
        borderLeft: defaultBorder,
        borderRight: defaultBorder,
        borderBottom: defaultBorder,
        background: 'white',
      }}
    >
      {Children.toArray(orderDetails.map(product => (
        <>
          <Divider sx={{ marginBottom: 6 }} />
          <Grid container paddingLeft={12} paddingRight={3}>
            <Grid item xs={9}>
              <Stack direction="row">
                <img
                  style={{ maxWidth: '170px', maxHeight: '170px', marginRight: '30px' }}
                  src={setImageUrl(product.themeId, product.screenshotImage, 'screenshot_image')}
                  alt={product.productName}
                />
                <Grid container>
                  <Grid item xs={12}>
                    <Typography fontSize="22px" fontWeight="bold">{product.productName}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {renderStatusPill(getProductStatus(product))}
                  </Grid>
                  <Grid item xs={4}>
                    <Typography fontSize="16px">Delivery Estimate</Typography>
                  </Grid>
                  <Grid item xs={4} paddingLeft={3}>
                    <Typography fontWeight="bold">
                      {formatDate(product.deliveryEstimate) || 'No estimate yet'}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} />
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" flexDirection="column" alignItems="flex-end">
                {product.productName !== 'MasterAddon'
                  && (
                  <Typography textAlign="right" fontWeight="bold">
                    Campaign ID&nbsp;&nbsp;
                    {getCidStatus(product)}
                  </Typography>
                  )}

                <Box display="flex" flexDirection="row">
                  {displayReviewButton(product)}
                  {displayViewProductButton(product)}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                marginTop={8}
                marginBottom={7}
              >
                <OrderTracker
                  key={product.orderItem.id}
                  status={checkTrackerStatus(product)}
                  productName={product.productName}
                  product={product}
                />
              </Box>
            </Grid>
          </Grid>
        </>
      )))}
    </Box>
  );
}
