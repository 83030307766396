import * as yup from 'yup';

const dateStart = new Date();
dateStart.setDate(dateStart.getDate() + 1);

const date = new Date();
date.setDate(date.getDate() - 1);

const schema = {
  campaignName: yup.string().required(),
  budget: yup.number().min(0).required('This is a required field.'),
  initialCycles: yup.number().min(0).required(),
  budgetReallocationCid: yup.number().required(),
  rebuildCid: yup.number().required(),
  runningMcid: yup.number().required(),
  adminStartDate: yup.string().required(),
  requestedStart: yup.date().typeError('Please select or enter a valid date.')
    .min(dateStart, 'Please select or enter a future date.')
    .when(['adminStartDate'], {
      is: (adminStartDate) => adminStartDate === 'ASAP',
      then: yup.date()
        .typeError('Please select or enter a valid date.')
        .min(dateStart, 'Please select or enter a future date.')
        .required('Please select or enter a valid date.'),
    }),
  delayedStart: yup.date().min(date, 'Please select or enter a date that is either today or in the future.')
    .when(['adminStartDate'], {
      is: (adminStartDate) => adminStartDate === 'Delay' || adminStartDate === 'Pending Site Launch',
      then: yup.date().min(date, 'Please select or enter a date that is either today or in the future.').required(),
    }),
  paymentDate: yup.string().required()
    .when(['adminStartDate'], {
      is: (adminStartDate) => adminStartDate === 'Delay' || adminStartDate === 'Pending Site Launch',
      then: yup.string().required(),
    }),
  requestedPayDate: yup.date().min(date, 'Please select or enter a date that is either today or in the future.')
    .when(['paymentDate'], {
      is: (paymentDate) => paymentDate === 'ASAP',
      then: yup.date().min(date, 'Please select or enter a date that is either today or in the future.').required(),
    }),
  delayedRequestedPayDate: yup.date().min(date, 'Please select or enter a date that is either today or in the future.')
    .when(['paymentDate'], {
      is: (paymentDate) => paymentDate === 'Delay',
      then: yup.date().min(date, 'Please select or enter a date that is either today or in the future.').required(),
    }),
  paymentMethod: yup.array().min(1).required(),
  servicesProcessPayment: yup.string().required(),
  emailPreference: yup.string().required(),
  trackingMethod: yup.string().required(),
  averageTransactionValue: yup.number().transform((value) => (Number.isNaN(value) ? null : value)).nullable()
    .min(1, 'Please enter a value greater than zero')
    .required('Please enter average transaction value.'),
  costPerLead: yup.number().transform((value) => (Number.isNaN(value) ? null : value)).nullable()
    .min(0, 'Please enter a positive value')
    .required('Please enter CPL goal.'),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
