import {
  IQFormInput,
  IQFormSelect,
  IQFormTextArea,
  IQTheme, IQThemeV2,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import GridItem from 'components/grid/GridItem';
import GridContainer from 'components/grid/GridContainer';
import {
  doesClientHaveAnotherAccountDropdownItems,
  provisioningProceedWithBreakingTrackingForCaptchaItems,
  proxyDetailsItems,
  shouldWeImplementUtmStringItems,
  webEventsDropdownItems,
} from "./ProxyProvisioningService";
import { updateOrAddDataBasedOnSourceId, yesNoDropdownItems } from "pages/target-display-legacy/TargetedDisplayLegacyService";
import GenericCard from "components/generic-card/GenericCard";
import WebEventFormDialog from "pages/target-display-legacy/webevents/WebEvent";
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import TargetedDisplayLegactType from "models/TargetedDisplayLegacyType";
import { FormProvider, useForm } from "react-hook-form";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import { Box } from "@mui/system";
import schema from './ProxyProvisioning.schema';
import NePhoneInput from "components/NePhoneInputContainer/IQPhoneInputContainer";
import { Grid, Typography } from '@mui/material';

interface Props {
  product: TargetedDisplayLegactType;
  onSubmit: (data: ProxyProvisioningPageFormProps) => void;
  onBack: () => void;
}

export type ProxyProvisioningPageFormProps = {
  localAreaCodePreference1: string;
  localAreaCodePreference2: string;
  primaryNumberToBeProvisioned: string;
  doesPrimaryBusinessNumberNeedToForward: string;
  alternativeForwardingNumber: string;
  shouldWeImplementUtmString: string;
  useThisUtmString: string;
  doesWebsiteHaveCaptchaInstalled: string;
  ProvisioningProceedWithTrackingCaptchaPiece: string;
  doesClientHaveAnotherAccount: string;
  proxyDetails: string;
  doNotProxyWebsite: string;
  webEvents: string;
  webEventFields: {
    sourceId: string;
    webEventValue: string;
    webEventUrl: string;
    webEventName: string;
  }[];
};

export default function ProxyProvisioningPageForm({ product, onSubmit, onBack }: Props) {
  const { t } = useTranslation();
  const [doesPrimaryBusinessNumberNeedToForwardSelectedValue, setDoesPrimaryBusinessNumberNeedToForwardSelectedValue] = useState(product.doesPrimaryBusinessNumberNeedToForward || '');
  const [ShouldWeImplementUtmStringSelectedValue, setShouldWeImplementUtmStringSelectedValue] = useState(product.shouldWeImplementUtmString || '');
  const [ThisWebsiteHaveCaptchaInstalledSelectedValue, setThisWebsiteHaveCaptchaInstalledSelectedValue] = useState(product.doesWebsiteHaveCaptchaInstalled || '');
  const [webEventFields, setWebEventFields] = useState<ProxyProvisioningPageFormProps['webEventFields']>(product.webEventFields || []);

  /** Web Events **/
  const [webEventsFieldsFormDialogOpen, setWebEventsFieldsFormDialogOpen] = useState(false);

  const [selectedWebEventFields, setSelectedWebEventEventFields] = useState<ProxyProvisioningPageFormProps['webEventFields']>([]);

  const [webEventsSelectedValue, setWebEventsSelectedValue] = useState(product?.webEvents || '');

  const webEventFieldLabels = ['webEventName', 'webEventValue'];
  const handleWebEventsFieldsFormDialogOpen = () => setWebEventsFieldsFormDialogOpen(true);

  const handleWebEventsFieldsFormDialogClose = () => {
    setWebEventsFieldsFormDialogOpen(false);
    setSelectedWebEventEventFields([]);
  }

  const methods = useForm<ProxyProvisioningPageFormProps>({
    mode: 'onChange',
    defaultValues: {
      localAreaCodePreference1: product?.localAreaCodePreference1 || '',
      localAreaCodePreference2: product?.localAreaCodePreference2 || '',
      primaryNumberToBeProvisioned: product?.primaryNumberToBeProvisioned || '',
      doesPrimaryBusinessNumberNeedToForward: product?.doesPrimaryBusinessNumberNeedToForward || '',
      alternativeForwardingNumber: product?.alternativeForwardingNumber || '',
      shouldWeImplementUtmString: product?.shouldWeImplementUtmString || '',
      useThisUtmString: product?.useThisUtmString || '',
      doesWebsiteHaveCaptchaInstalled: product?.doesWebsiteHaveCaptchaInstalled || '',
      ProvisioningProceedWithTrackingCaptchaPiece: product?.ProvisioningProceedWithTrackingCaptchaPiece || '',
      doesClientHaveAnotherAccount: product?.doesClientHaveAnotherAccount || '',
      proxyDetails: product?.proxyDetails || '',
      doNotProxyWebsite: product?.doNotProxyWebsite || '',
      webEvents: product?.webEvents || '',
      webEventFields: Array.isArray(product?.webEventFields) && product.webEventFields.length > 0
        ? product.webEventFields
        : [{ sourceId: '', webEventValue: '', webEventUrl: '', webEventName: '' }]
    },
  });

  const handleFormSubmit = (data: ProxyProvisioningPageFormProps) => {
    const formData = { ...data, webEventFields };
    if (formData.doesPrimaryBusinessNumberNeedToForward === 'No') {
      formData.alternativeForwardingNumber = '';
    }
    if (formData.shouldWeImplementUtmString !== 'Yes - Use my UTM strings only') {
      formData.useThisUtmString = '';
    }
    if (formData.doesWebsiteHaveCaptchaInstalled === 'No') {
      formData.ProvisioningProceedWithTrackingCaptchaPiece = '';
    }
    if (formData.webEvents === 'Please adjust web events per best practices') {
      formData.webEventFields = [];
    }
    onSubmit(formData);
  }

  const {
    handleSubmit,
    formState: {
      isValid,
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
    },
  } = methods;

  const handleWebEventFieldsSelection = (selectedWebEventField) => {
    setSelectedWebEventEventFields(selectedWebEventField);
    handleWebEventsFieldsFormDialogOpen();
  };

  const updateWebEvents = (newData) => {
    setWebEventFields(prevData => {
      return updateOrAddDataBasedOnSourceId(prevData, newData);
    });
  };

  const onDeleteWebEventFields = async (webEventField: any) => {
    const updatedWebEvents = webEventFields.filter(
      (webEvent) => webEvent.sourceId !== webEventField.sourceId
    );
    setWebEventFields(updatedWebEvents);
  }

  return (
    <DefaultPageLayout
      disableContinue={!isValid}
      onBack={onBack}
      header={t('pages.targetedDisplayLegacy.proxyProvisioning.header')}
      onContinue={handleSubmit(handleFormSubmit)}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Box
            id="mainForm"
            component="form"
            sx={{ display: 'flex' }}
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <GridContainer fullWidth>
              <GridItem sizes={[12]}>
                <IQFormInput
                  id="localAreaCodePreference1"
                  theme={IQTheme}
                  labelText={t('pages.targetedDisplayLegacy.proxyProvisioning.localAreaCodePreference1')}
                  fontLabelWeight="600"
                  name="localAreaCodePreference1"
                  fullWidth
                />
              </GridItem>
              <GridItem sizes={[12]}>
                <IQFormInput
                  id="localAreaCodePreference2"
                  theme={IQTheme}
                  labelText={t('pages.targetedDisplayLegacy.proxyProvisioning.localAreaCodePreference2')}
                  fontLabelWeight="600"
                  name="localAreaCodePreference2"
                  fullWidth
                />
              </GridItem>
              <GridItem sizes={[12]}>
                <NePhoneInput
                  name="primaryNumberToBeProvisioned"
                  defaultValue=""
                  country="US"
                  hasExtension={false}
                  phoneLabelOptions={{
                    labelText: t('pages.targetedDisplayLegacy.proxyProvisioning.primaryNumberToBeProvisioned'),
                    hasError: true,
                    theme: IQThemeV2,
                    required: true,
                  }}
                  hasType={false}
                  countryCallingCodeEditable={false}
                  international
                />
              </GridItem>
              <GridItem sizes={[12]}>
                <IQFormSelect
                  id="doesPrimaryBusinessNumberNeedToForward"
                  name="doesPrimaryBusinessNumberNeedToForward"
                  labelText={t('pages.targetedDisplayLegacy.proxyProvisioning.doesPrimaryBusinessNumberNeedToForward')}
                  items={yesNoDropdownItems}
                  defaultValue=""
                  theme={IQTheme}
                  onChange={(event)=>setDoesPrimaryBusinessNumberNeedToForwardSelectedValue(event.target.value as string)}
                  fontLabelWeight="600"
                  fullWidth
                  showError
                  schema={schema}
                />
              </GridItem>
              {doesPrimaryBusinessNumberNeedToForwardSelectedValue === 'Yes' && (
                <GridItem sizes={[12]}>
                  <NePhoneInput
                    name="alternativeForwardingNumber"
                    defaultValue=""
                    country="US"
                    hasExtension={false}
                    phoneLabelOptions={{
                      labelText: t('pages.targetedDisplayLegacy.proxyProvisioning.alternativeForwardingNumber'),
                      hasError: true,
                      theme: IQThemeV2,
                      required: false,
                    }}
                    hasType={false}
                    countryCallingCodeEditable={false}
                    international
                  />
                </GridItem>
              )}
              <Grid item>
                <Typography variant="h6">{t('pages.targetedDisplayLegacy.proxyProvisioning.utmDetails')}</Typography>
              </Grid>
              <GridItem sizes={[12]}>
                <IQFormSelect
                  id="shouldWeImplementUtmString"
                  name="shouldWeImplementUtmString"
                  labelText={t('pages.targetedDisplayLegacy.proxyProvisioning.shouldWeImplementUtmString')}
                  items={shouldWeImplementUtmStringItems}
                  defaultValue=""
                  theme={IQTheme}
                  onChange={(event)=>setShouldWeImplementUtmStringSelectedValue(event.target.value as string)}
                  fontLabelWeight="600"
                  fullWidth
                  showError
                  schema={schema}
                />
              </GridItem>
              {ShouldWeImplementUtmStringSelectedValue === 'Yes - Use my UTM strings only' && (
                <GridItem sizes={[12]}>
                  <IQFormInput
                    id="useThisUtmString"
                    theme={IQTheme}
                    labelText={t('pages.targetedDisplayLegacy.proxyProvisioning.useThisUtmString')}
                    fontLabelWeight="600"
                    name="useThisUtmString"
                    fullWidth
                    subDescription={t('pages.targetedDisplayLegacy.proxyProvisioning.useThisUtmStringdescription')}
                  />
                </GridItem>
              )}
              <GridItem sizes={[12]}>
                <IQFormSelect
                  id="doesWebsiteHaveCaptchaInstalled"
                  name="doesWebsiteHaveCaptchaInstalled"
                  labelText={t('pages.targetedDisplayLegacy.proxyProvisioning.doesWebsiteHaveCaptchaInstalled')}
                  items={yesNoDropdownItems}
                  defaultValue=""
                  onChange={(event)=>setThisWebsiteHaveCaptchaInstalledSelectedValue(event.target.value as string)}
                  theme={IQTheme}
                  fontLabelWeight="600"
                  fullWidth
                  showError
                  schema={schema}
                />
              </GridItem>
              {ThisWebsiteHaveCaptchaInstalledSelectedValue === 'Yes' && (
                <GridItem sizes={[12]}>
                  <IQFormSelect
                    id="ProvisioningProceedWithTrackingCaptchaPiece"
                    name="ProvisioningProceedWithTrackingCaptchaPiece"
                    labelText={t('pages.targetedDisplayLegacy.proxyProvisioning.ProvisioningProceedWithTrackingCaptchaPiece')}
                    items={provisioningProceedWithBreakingTrackingForCaptchaItems}
                    defaultValue=""
                    theme={IQTheme}
                    fontLabelWeight="600"
                    fullWidth
                    showError
                    schema={schema}
                  />
                </GridItem>
              )}
              <GridItem sizes={[12]}>
                <IQFormSelect
                  id="doesClientHaveAnotherAccount"
                  name="doesClientHaveAnotherAccount"
                  labelText={t('pages.targetedDisplayLegacy.proxyProvisioning.doesClientHaveAnotherAccount')}
                  items={doesClientHaveAnotherAccountDropdownItems}
                  defaultValue=""
                  theme={IQTheme}
                  fontLabelWeight="600"
                  fullWidth
                  showError
                  schema={schema}
                />
              </GridItem>
              <GridItem sizes={[12]}>
                <IQFormSelect
                  id="proxyDetails"
                  name="proxyDetails"
                  labelText={t('pages.targetedDisplayLegacy.proxyProvisioning.proxyDetails')}
                  items={proxyDetailsItems}
                  defaultValue=""
                  theme={IQTheme}
                  fontLabelWeight="600"
                  fullWidth
                  showError
                  schema={schema}
                />
              </GridItem>
              <GridItem sizes={[12]}>
                <IQFormTextArea
                  id="doNotProxyWebsite"
                  labelText={t('pages.targetedDisplayLegacy.proxyProvisioning.doNotProxyWebsite')}
                  name="doNotProxyWebsite"
                  rowCount={2}
                  fullWidth
                  fontLabelWeight="600"
                  showError
                />
              </GridItem>
              <GridItem sizes={[12]}>
                <IQFormSelect
                  id="webEvents"
                  name="webEvents"
                  labelText={t('pages.targetedDisplayLegacy.proxyProvisioning.webEvents')}
                  items={webEventsDropdownItems}
                  defaultValue=""
                  theme={IQTheme}
                  fontLabelWeight="600"
                  fullWidth
                  schema={schema}
                  onChange={(event)=>setWebEventsSelectedValue(event.target.value as string)}
                />
              </GridItem>
              {webEventsSelectedValue === 'Use only my web events' && (
                <GridItem sizes={[12]}>
                  {Array.isArray(webEventFields) && webEventFields.map((webEventField) => (
                    <GenericCard
                      key={webEventField.sourceId}
                      labels={webEventFieldLabels}
                      data={webEventField}
                      handleDelete={() => onDeleteWebEventFields(webEventField)}
                      onEdit={() => handleWebEventFieldsSelection(webEventField)}
                      onSelect={() => { }}
                    />
                  ))}
                  <WebEventFormDialog
                    open={webEventsFieldsFormDialogOpen}
                    onOpen={handleWebEventsFieldsFormDialogOpen}
                    onClose={handleWebEventsFieldsFormDialogClose}
                    updateWebEvents={updateWebEvents}
                    selectedWebEvents={selectedWebEventFields}
                  />
                </GridItem>
              )}
            </GridContainer>
          </Box>
        </FormProvider>
      </ValidationProvider>
      <CoNavigationConfirm
        showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
      />
    </DefaultPageLayout>
  );
}
