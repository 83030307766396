/* eslint-disable max-len */
import i18n from 'i18n/i18n';
import { RJSFSchema } from '@rjsf/utils';
import Dropdown from 'pages/generic-product/details/custom-fields/Dropdown';
import AddWebEvents from 'pages/generic-product/details/custom-fields/AddWebEvents';
import { validateEmail } from 'pages/generic-product/details/custom-fields/EmailTextField';
import { validateUrl } from 'pages/generic-product/details/custom-fields/UrlTextField';

export function validateAgeRange(formData, minAgeField, maxAgeField, errorField, errors, t) {
  const parseAge = (age) => (age === '65 Plus' ? 66 : parseInt(age, 10));

  const maximumAge = parseAge(formData?.[maxAgeField]);
  const minimumAge = parseAge(formData?.[minAgeField]);

  if (maximumAge < minimumAge) {
    errors[errorField].addError(t('pages.socialAds.solutionStrategy.specialAdCategories.maximumAgeErrorMessage'));
    errors.addError(true);
  }
}
export function validateCharacterLimit(formData, fieldName, maxCharacterLimit, errors, t) {
  if (formData?.[fieldName]
    && formData?.[fieldName].trim() !== ''
    && formData?.[fieldName].trim().length > maxCharacterLimit) {
    const actualLength = formData?.[fieldName].trim().length;
    errors[fieldName]
      .addError(t(`Cannot contain more than ${maxCharacterLimit} characters (currently ${actualLength}).`));
    errors.addError(true);
  }
}

const ageEnum = Array.from({ length: 66 }, (_, i) => (i === 65 ? '65 Plus' : (i + 1).toString()));
const radiusMileOrKilometerDistanceEnum = Array.from({ length: 10 }, (_, i) => ((i + 1) * 5).toString());

const { t } = i18n;
const urlValidationParameter = ['https://www.facebook.com'];

export const SocialAdsWithSmartOptimizationTechnologyChildSchema: RJSFSchema | any = (data) => ({
  type: 'object',
  required: ['facebookSpecialAdCategories','webEvents', 'targetingType', 'localiqWebsite', 'primaryNumberToBeProvisioned',
    'utmImplementString', 'doesWebsiteHaveCaptchaInstalled', 'doesClientHaveAnotherAccount', 'campaignFocus',
    'clientContactName', 'clientContactNumber', 'clientContactEmail', 'isThisTheSameEmailForCreativeApproval',
    'isThisTheBestPersonToGrantUsFacebookAdminAccess', 'focusOnTimedEvent', 'flightedCampaign',
    'clientFirmStartDate', 'clientFirmEndDate',
  ],

  properties: {
    specialAdCategoriesTitle: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.specialAdCategories.title'),
    },
    specialAdCategoriesDescription: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.specialAdCategories.description'),
    },
    facebookSpecialAdCategories: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.specialAdCategories.facebookSpecialAdCategories'),
      default: data?.facebookSpecialAdCategories || '',
      enum: [
        'Yes - Career & Employment',
        'Yes - Auto, Finance, Credit',
        'Yes - Housing, Real Estate',
        'No - None of the Above',
      ],
    },
    targetingLabel: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.targeting.title'),
    },
    targetingType: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.targeting.targetingTypeLabel'),
      default: data?.targetingType || '',
      enum: [
        'Country',
        'Radius',
        'City',
        'DMA',
        'ZIP Code',
      ],
    },
    audienceTargetingDetails: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.targeting.audienceTargetingDetailsLabel'),
    },
    websitesTitle: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.websites.title'),
    },
    localiqWebsite: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.websites.localiqWebsiteTitle'),
      default: data?.localiqWebsite || '',
      enum: [
        t('pages.socialAds.solutionStrategy.websites.yesOption'),
        t('pages.socialAds.solutionStrategy.websites.noOption'),
      ],
    },
    proxyOrProvisioningTitle: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.proxyProvisioning.title'),
    },
    localAreaCodePreference1: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.proxyProvisioning.localAreaCodePreference1'),
      default: data?.localAreaCodePreference1 || '',
    },
    localAreaCodePreference2: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.proxyProvisioning.localAreaCodePreference2'),
      default: data?.localAreaCodePreference2 || '',
    },
    primaryNumberToBeProvisioned: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.proxyProvisioning.primaryNumberToBeProvisioned'),
      default: data?.primaryNumberToBeProvisioned || '',
    },
    doesPrimaryBusinessNumberNeedToForward: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.proxyProvisioning.doesPrimaryBusinessNumberNeedToForward'),
      default: data?.doesPrimaryBusinessNumberNeedToForward || '',
      enum: [
        '',
        t('pages.socialAds.solutionStrategy.proxyProvisioning.doesPrimaryBusinessNumberNeedOptions.yes'),
        t('pages.socialAds.solutionStrategy.proxyProvisioning.doesPrimaryBusinessNumberNeedOptions.no'),
      ],
    },
    utmDetailsHeading: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.proxyProvisioning.utmDetailsHeading'),
    },
    utmImplementString: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.proxyProvisioning.utmImplementString'),
      default: data?.utmImplementString || '',
      enum: [
        t('pages.socialAds.solutionStrategy.proxyProvisioning.utmImplementStringOptions.yesUseUtmStringOnly'),
        t('pages.socialAds.solutionStrategy.proxyProvisioning.utmImplementStringOptions.yesUseUtmStringBestPractices'),
        t('pages.socialAds.solutionStrategy.proxyProvisioning.utmImplementStringOptions.noDoNotUseUtmString'),
      ],
    },
    doesWebsiteHaveCaptchaInstalled: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.proxyProvisioning.doesWebsiteHaveCaptchaInstalled'),
      default: data?.doesWebsiteHaveCaptchaInstalled || '',
      enum: [
        t('pages.socialAds.solutionStrategy.proxyProvisioning.doesWebsiteHaveCaptchaInstalledOptions.yes'),
        t('pages.socialAds.solutionStrategy.proxyProvisioning.doesWebsiteHaveCaptchaInstalledOptions.no'),
      ],
    },
    doesClientHaveAnotherAccount: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.proxyProvisioning.doesClientHaveAnotherAccount'),
      default: data?.doesClientHaveAnotherAccount || '',
      enum: [
        t('pages.socialAds.solutionStrategy.proxyProvisioning.doesThisClientHaveAnotherAccount(MAID)Options.yes'),
        t('pages.socialAds.solutionStrategy.proxyProvisioning.doesThisClientHaveAnotherAccount(MAID)Options.no'),
      ],
    },
    doNotProxyWebsite: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.proxyProvisioning.doNotProxyWebsite'),
      description: t('pages.socialAds.solutionStrategy.proxyProvisioning.webEventsHelperText'),
      default: data?.doNotProxyWebsite || '',
    },
    webEvents: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.proxyProvisioning.webEvents'),
      default: data?.webEvents || '',
      enum: [
        '',
        t('pages.socialAds.solutionStrategy.proxyProvisioning.webEventsOptions.adjustWebEvents'),
        t('pages.socialAds.solutionStrategy.proxyProvisioning.webEventsOptions.useMyWebEventsOnly'),
      ],
    },
    contactInformationTitle: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.contactInformation.title'),
    },
    clientContactName: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.contactInformation.clientContactNameLabel'),
      default: data?.clientContactName || '',
    },
    clientContactNumber: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.contactInformation.clientContactNumberLabel'),
      default: data?.clientContactNumber || '',
    },
    clientContactEmail: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.contactInformation.clientContactEmailLabel'),
      default: data?.clientContactEmail || '',
    },
    bestNumberToReachYou: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.contactInformation.bestNumberToReachYouLabel'),
      default: data?.bestNumberToReachYou || '',
    },
    isThisTheSameEmailForCreativeApproval: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.contactInformation.isThisTheSameEmailForCreativeApprovalLabel'),
      default: data?.isThisTheSameEmailForCreativeApproval || '',
      enum: [
        'Yes',
        'No',
      ],
    },
    isThisTheBestPersonToGrantUsFacebookAdminAccess: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.contactInformation.isThisTheBestPersonToGrantUsFacebookAdminAccessLabel'),
      default: data?.isThisTheBestPersonToGrantUsFacebookAdminAccess || '',
      enum: [
        'Yes',
        'No',
      ],
    },
    socialAdsSmartOptimizationTitle: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.pageTitle'),
    },
    campaignFocus: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.campaignFocus'),
      default: data?.campaignFocus || '',
      enum: [
        '',
        t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.contactLabel'),
        t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.reachLabel'),
        t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.engagementLabel'),
      ],
    },
    timedEventsAndFlights: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.timedEventsAndFlights'),
    },
    focusOnTimedEvent: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.clientFocus'),
      enum: [
        t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.yesOption'),
        t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.noOption'),
      ],
    },
    flightedCampaign: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.flightedCampaign'),
      enum: [
        t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.yesOption'),
        t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.noOption'),
      ],
    },
    clientFirmStartDate: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.clientFirmStartDate'),
      default: data?.clientFirmStartDate || '',
      enum: [
        '',
        t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.yesOption'),
        t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.noOption'),
      ],
    },
    clientFirmEndDate: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.clientFirmEndDate'),
      default: data?.clientFirmEndDate || '',
      enum: [
        '',
        t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.yesOption'),
        t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.noOption'),
      ],
    },
    smartWebsiteClicks: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.smartWebsiteClick.SmartWebsiteLabel'),
    },
    automaticOrOptimizedPlacement: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.smartWebsiteClick.automaticOrOptimizedPlacement'),
      default: data?.automaticOrOptimizedPlacement || '',
      enum: [
        '',
        t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.yesOption'),
        t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.noOption'),
      ],
    },
    callToAction: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.smartWebsiteClick.callToActionDropdown'),
      default: data?.callToAction || '',
      enum: [
        '',
        t('pages.socialAds.solutionStrategy.smartWebsiteClick.noButtonOption'),
        t('pages.socialAds.solutionStrategy.smartWebsiteClick.applyNowOption'),
        t('pages.socialAds.solutionStrategy.smartWebsiteClick.bookNowOption'),
        t('pages.socialAds.solutionStrategy.smartWebsiteClick.contactUsOption'),
        t('pages.socialAds.solutionStrategy.smartWebsiteClick.donateNowOption'),
        t('pages.socialAds.solutionStrategy.smartWebsiteClick.downloadOption'),
        t('pages.socialAds.solutionStrategy.smartWebsiteClick.getShowtimeOption'),
        t('pages.socialAds.solutionStrategy.smartWebsiteClick.learnMoreOption'),
        t('pages.socialAds.solutionStrategy.smartWebsiteClick.listenNowOption'),
        t('pages.socialAds.solutionStrategy.smartWebsiteClick.requestTimeOption'),
        t('pages.socialAds.solutionStrategy.smartWebsiteClick.seeMenuOption'),
        t('pages.socialAds.solutionStrategy.smartWebsiteClick.sendMessageOption'),
        t('pages.socialAds.solutionStrategy.smartWebsiteClick.shopNowOption'),
        t('pages.socialAds.solutionStrategy.smartWebsiteClick.signUpOption'),
        t('pages.socialAds.solutionStrategy.smartWebsiteClick.watchNowOption'),
      ],
    },
    smartRetargetingTitle: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.smartRetargeting.SmartRetargetingLabel'),
    },
    automaticOrOptimizedPlacementForRetargeting: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.smartWebsiteClick.automaticOrOptimizedPlacement'),
      default: data?.automaticOrOptimizedPlacementForRetargeting || '',
      enum: [
        '',
        t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.yesOption'),
        t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.noOption'),
      ],
    },
    callToActionForRetargeting: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.smartRetargeting.callToActionButton'),
      default: data?.callToActionForRetargeting || '',
      enum: [
        '',
        t('pages.socialAds.solutionStrategy.smartWebsiteClick.noButtonOption'),
        t('pages.socialAds.solutionStrategy.smartWebsiteClick.applyNowOption'),
        t('pages.socialAds.solutionStrategy.smartWebsiteClick.bookNowOption'),
        t('pages.socialAds.solutionStrategy.smartWebsiteClick.contactUsOption'),
        t('pages.socialAds.solutionStrategy.smartWebsiteClick.donateNowOption'),
        t('pages.socialAds.solutionStrategy.smartWebsiteClick.downloadOption'),
        t('pages.socialAds.solutionStrategy.smartWebsiteClick.getShowtimeOption'),
        t('pages.socialAds.solutionStrategy.smartWebsiteClick.learnMoreOption'),
        t('pages.socialAds.solutionStrategy.smartWebsiteClick.listenNowOption'),
        t('pages.socialAds.solutionStrategy.smartWebsiteClick.requestTimeOption'),
        t('pages.socialAds.solutionStrategy.smartWebsiteClick.seeMenuOption'),
        t('pages.socialAds.solutionStrategy.smartWebsiteClick.sendMessageOption'),
        t('pages.socialAds.solutionStrategy.smartWebsiteClick.shopNowOption'),
        t('pages.socialAds.solutionStrategy.smartWebsiteClick.signUpOption'),
        t('pages.socialAds.solutionStrategy.smartWebsiteClick.watchNowOption'),
      ],
    },
    facebookRetargetingPreference: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.smartRetargeting.facebookRetargetingPreferenceLevel'),
      default: data?.facebookRetargetingPreference || '',
      enum: [
        '',
        t('pages.socialAds.solutionStrategy.smartRetargeting.entireWebsiteOptions'),
        t('pages.socialAds.solutionStrategy.smartRetargeting.selectPagesOptions'),
      ],
    },
    enableTargetingPeople: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.smartRetargeting.enableTargetingPeopleTitle'),
      default: data?.enableTargetingPeople || '',
      enum: [
        '',
        t('pages.socialAds.solutionStrategy.smartRetargeting.yesUseLookAlikeOptions'),
        t('pages.socialAds.solutionStrategy.smartRetargeting.noOptions'),
      ],
    },
    daysOfRetargetVisitorsOnFacebook: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.smartRetargeting.daysOfRetargetVisitorsOnFacebookTitle'),
      default: data?.daysOfRetargetVisitorsOnFacebook || '',
      enum: [
        '',
        t('pages.socialAds.solutionStrategy.smartRetargeting.days15'),
        t('pages.socialAds.solutionStrategy.smartRetargeting.days30'),
        t('pages.socialAds.solutionStrategy.smartRetargeting.days60'),
        t('pages.socialAds.solutionStrategy.smartRetargeting.days90'),
        t('pages.socialAds.solutionStrategy.smartRetargeting.days120'),
        t('pages.socialAds.solutionStrategy.smartRetargeting.days150'),
        t('pages.socialAds.solutionStrategy.smartRetargeting.days180'),
      ],
    },
    websiteRetargetingLink: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.smartRetargeting.websiteRetargetingLinkTitle'),
      default: data?.websiteRetargetingLink || '',
    },
    enableTargetingPeopleLikedFacebookPage: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.smartRetargeting.enableTargetingPeopleLikedFacebookPageTitle'),
      default: data?.enableTargetingPeopleLikedFacebookPage || '',
      enum: [
        '',
        t('pages.socialAds.solutionStrategy.smartRetargeting.yesUseLookAlikeOptions'),
        t('pages.socialAds.solutionStrategy.smartRetargeting.noOptions'),
      ],
    },
    searchCampaignFacebookRetargeting: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.smartRetargeting.searchCampaignFacebookRetargetingTitle'),
      default: data?.searchCampaignFacebookRetargeting || '',
      enum: [
        '',
        t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.yesOption'),
        t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.noOption'),
      ],
    },
    searchLeadsLookalikeAudience: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.smartRetargeting.searchLeadsLookalikeAudienceTitle'),
      default: data?.searchLeadsLookalikeAudience || '',
      enum: [
        '',
        t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.yesOption'),
        t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.noOption'),
      ],
    },
    multiUrlLabel: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.smartRetargeting.multiUrlLabel'),
    },
    creativeLandingPage: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.smartRetargeting.creativeLandingPageTitle'),
      default: data?.creativeLandingPage || '',
      enum: [
        '',
        t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.yesOption'),
        t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.noOption'),
      ],
    },
    socialCreativeInstructionsTitle: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.socialCreativeInstructions.socialCreativeInstructionsTitle'),
    },
    whatTypeOfVisualStrategyDoesYourClientWantToEmploy: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.whatTypeOfVisualStrategyDoesYourClientWantToEmployLabel'),
      description: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.whatTypeOfVisualStrategyDoesYourClientWantToEmployHelperText'),
      default: data?.whatTypeOfVisualStrategyDoesYourClientWantToEmploy || '',
      enum: [
        '',
        'Single Image',
        'Single Video',
        'Multiple Images',
        'Multiple Videos',
        'Image and Video Combination',
      ],
    },
    willCreativesNeedToLandToUniqueLandingPagesWithinTheCampaignDomain: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.willCreativesNeedToLandToUniqueLandingPagesWithinTheCampaignDomainLabel'),
      default: data?.willCreativesNeedToLandToUniqueLandingPagesWithinTheCampaignDomain || '',
      enum: [
        '',
        'Yes',
        'No',
      ],
    },
    adCopyProvidedTitle: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.adCopyProvidedHeading'),
    },
    adCopyProvidedDescription: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.adCopyProvidedHelperText'),
    },
    willTheClientBeProvidingTheirOwnTextCreative: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.willTheClientBeProvidingTheirOwnTextCreativeLabel'),
      default: data?.willTheClientBeProvidingTheirOwnTextCreative || '',
      enum: [
        '',
        'Yes',
        'No',
      ],
    },
    allowEmojis: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.allowEmojisLabel'),
      default: data?.allowEmojis || '',
      enum: [
        '',
        'Yes',
        'No',
      ],
    },
  },

  allOf: [
    {
      if: {
        properties: {
          captureCodeCompatible: {
            enum: [
              'Yes',
            ]
          },
          isClientPartOfAFranchise: {
            enum: [
              '',
            ]
          },
        },
        required: [
          'captureCodeCompatible',
          'isClientPartOfAFranchise',
        ]
      },
      then: {
        properties: {
          reachCodeCompatibilityReportLink: {
            type: 'string',
            title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.Link1Description'),
            enum: [
              t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.exampleUrl'),
            ],
          },
          howToUseTheCapComToolIsAvailableHereLink: {
            type: 'string',
            title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.Link2Description'),
            enum: [
              t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.theSourceUrl'),
            ],
          },
          websiteTracking: {
            type: 'string',
            title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.websiteTrackingLabel'),
            default: data?.websiteTracking || '',
            enum: [
              '',
              t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.baseWebsiteDropDown1'),
              t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.baseWebsiteDropDown2'),
              t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.baseWebsiteDropDown4'),
            ],
          },
        },
        dependencies: {
          websiteTracking: {
            oneOf: [
              {
                properties: {
                  websiteTracking: {
                    enum: ['CaptureCode on Client\'s Site',
                      'Client Center with Lead Engagement Add-On (Edge',
                    ],
                  },
                  canWeStartYourCampaignOn3pp: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppLabel'),
                    description: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppHelper'),
                    default: data?.canWeStartYourCampaignOn3pp || '',
                    enum: [
                      '',
                      t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDropDownOpt1'),
                      t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDropDownOpt2'),
                    ],
                  },
                  canWeStartYourCampaignOn3ppDescription1Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription1')
                  },
                  canWeStartYourCampaignOn3ppDescription2Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription2')
                  },
                  canWeStartYourCampaignOn3ppDescription3Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription3')
                  },
                  canWeStartYourCampaignOn3ppDescription4Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription4')
                  },
                  canWeStartYourCampaignOn3ppDescription5Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription5')
                  },
                  canWeStartYourCampaignOn3ppDescription6Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription6')
                  },
                  canWeStartYourCampaignOn3ppDescription7Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription7')
                  },
                  canWeStartYourCampaignOn3ppDescription8Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription8')
                  },
                  canWeStartYourCampaignOn3ppDescription9Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription9')
                  },
                },
              },
            ],
          },
        }
      }
    },
    {
      if: {
        properties: {
          captureCodeCompatible: {
            enum: [
              'Yes',
            ]
          },
          isClientPartOfAFranchise: {
            enum: [
              'Yes',
            ]
          },
        },
        required: [
          'captureCodeCompatible',
          'isClientPartOfAFranchise',
        ]
      },
      then: {
        properties: {
          reachCodeCompatibilityReportLink: {
            type: 'string',
            title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.Link1Description'),
            enum: [
              t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.exampleUrl'),
            ],
          },
          howToUseTheCapComToolIsAvailableHereLink: {
            type: 'string',
            title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.Link2Description'),
            enum: [
              t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.theSourceUrl'),
            ],
          },
          websiteTracking: {
            type: 'string',
            title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.websiteTrackingLabel'),
            default: data?.websiteTracking || '',
            enum: [
              '',
              t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.baseWebsiteDropDown1'),
              t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.baseWebsiteDropDown2'),
              t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.baseWebsiteDropDown4'),
            ],
          },
        },
        dependencies: {
          websiteTracking: {
            oneOf: [
              {
                properties: {
                  websiteTracking: {
                    enum: ['CaptureCode on Client\'s Site',
                      'Client Center with Lead Engagement Add-On (Edge',
                    ],
                  },
                  canWeStartYourCampaignOn3ppForFranchiseYes: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppLabel'),
                    description: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppHelper'),
                    default: 'No - Hold Campaign until CaptureCode is Implemented',
                    enum: [
                      '',
                      t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDropDownOpt2'),
                    ],
                  },
                  canWeStartYourCampaignOn3ppDescription1Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription1')
                  },
                  canWeStartYourCampaignOn3ppDescription2Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription2')
                  },
                  canWeStartYourCampaignOn3ppDescription3Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription3')
                  },
                  canWeStartYourCampaignOn3ppDescription4Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription4')
                  },
                  canWeStartYourCampaignOn3ppDescription5Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription5')
                  },
                  canWeStartYourCampaignOn3ppDescription6Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription6')
                  },
                  canWeStartYourCampaignOn3ppDescription7Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription7')
                  },
                  canWeStartYourCampaignOn3ppDescription8Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription8')
                  },
                  canWeStartYourCampaignOn3ppDescription9Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription9')
                  },
                },
              },
            ],
          },
        }
      }
    },
    {
      if: {
        properties: {
          captureCodeCompatible: {
            enum: [
              'Yes',
            ]
          },
          isClientPartOfAFranchise: {
            enum: [
              'No',
            ]
          },
        },
        required: [
          'captureCodeCompatible',
          'isClientPartOfAFranchise',
        ]
      },
      then: {
        properties: {
          reachCodeCompatibilityReportLink: {
            type: 'string',
            title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.Link1Description'),
            enum: [
              t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.exampleUrl'),
            ],
          },
          howToUseTheCapComToolIsAvailableHereLink: {
            type: 'string',
            title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.Link2Description'),
            enum: [
              t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.theSourceUrl'),
            ],
          },
          websiteTracking: {
            type: 'string',
            title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.websiteTrackingLabel'),
            default: data?.websiteTracking || '',
            enum: [
              '',
              t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.baseWebsiteDropDown1'),
              t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.baseWebsiteDropDown3'),
              t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.baseWebsiteDropDown2'),
              t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.baseWebsiteDropDown4'),
            ],
          },
        },
        dependencies: {
          websiteTracking: {
            oneOf: [
              {
                properties: {
                  websiteTracking: {
                    enum: [
                      'CaptureCode on Client\'s Site',
                      'CaptureCode + Third-Party Proxy (3PP)',
                      'Client Center with Lead Engagement Add-On (Edge',
                    ],
                  },
                  canWeStartYourCampaignOn3pp: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppLabel'),
                    description: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppHelper'),
                    default: data?.canWeStartYourCampaignOn3pp || '',
                    enum: [
                      '',
                      t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDropDownOpt1'),
                      t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDropDownOpt2'),
                    ],
                  },
                  canWeStartYourCampaignOn3ppDescription1Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription1')
                  },
                  canWeStartYourCampaignOn3ppDescription2Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription2')
                  },
                  canWeStartYourCampaignOn3ppDescription3Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription3')
                  },
                  canWeStartYourCampaignOn3ppDescription4Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription4')
                  },
                  canWeStartYourCampaignOn3ppDescription5Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription5')
                  },
                  canWeStartYourCampaignOn3ppDescription6Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription6')
                  },
                  canWeStartYourCampaignOn3ppDescription7Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription7')
                  },
                  canWeStartYourCampaignOn3ppDescription8Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription8')
                  },
                  canWeStartYourCampaignOn3ppDescription9Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription9')
                  },
                },
              },
            ],
          },
        }
      }
    },
    {
      if: {
        properties: {
          captureCodeCompatible: {
            enum: [
              'No',
            ]
          },
          isClientPartOfAFranchise: {
            enum: [
              '',
              'No',
            ]
          },
        },
        required: [
          'captureCodeCompatible',
          'isClientPartOfAFranchise',
        ]
      },
      then: {
        properties: {
          websiteTracking: {
            type: 'string',
            title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.websiteTrackingLabel'),
            default: data?.websiteTracking || '',
            enum: [
              '',
              t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.websiteTracking1stDropDown'),
              t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.websiteTracking2stDropDown'),
            ],
          },
        },
      }
    },
    {
      if: {
        properties: {
          captureCodeCompatible: {
            enum: [
              'No',
            ]
          },
          isClientPartOfAFranchise: {
            enum: [
              'Yes',
            ]
          },
        },
        required: [
          'captureCodeCompatible',
          'isClientPartOfAFranchise',
        ]
      },
      then: {
        properties: {
          websiteTrackingForFranchiseYes: {
            type: 'string',
            title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.websiteTrackingLabel'),
            default: 'No Tracking',
            enum: [
              '',
              t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.websiteTracking2stDropDown'),
            ],
          },
        },
      }
    },
    {
      if: {
        properties: {
          campaignFocus: {
            enum: ['Reach'],
          },
          facebookSpecialAdCategories: {
            enum: ['No - None of the Above'],
          },
        },
        required: [
          'campaignFocus',
          'facebookSpecialAdCategories',
        ],
      },
      then: {
        properties: {
          minimumAgeForSmartReach: {
            type: 'string',
            title: t('pages.socialAds.solutionStrategy.targeting.minimumAgeLabel'),
            default: data?.minimumAgeForSmartReach || '',
            enum: ['', ...ageEnum],
          },
          maximumAgeForSmartReach: {
            type: 'string',
            title: t('pages.socialAds.solutionStrategy.targeting.maximumAgeLabel'),
            default: data?.maximumAgeForSmartReach || '',
            enum: ['', ...ageEnum],
          },
          genderForSmartReach: {
            type: 'string',
            title: t('pages.socialAds.solutionStrategy.targeting.genderLabel'),
            default: data?.genderForSmartReach || '',
            enum: ['', 'Male', 'Female', 'Both'],
          },
        },
      },
    },
  ],

  dependencies: {
    targetingType: {
      oneOf: [
        {
          properties: {
            targetingType: {
              enum: ['', 'Country', 'City', 'DMA', 'ZIP Code'],
            },
            geoTargetingDetails: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.targeting.geoTargetingDetailsNoRequireLabel'),
              default: data?.geoTargetingDetails || '',
            },
          },
        },
        {
          properties: {
            targetingType: {
              enum: ['Radius'],
            },
            advertiserBusinessAddress: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.targeting.advertiserBusinessAddressNoRequireLabel'),
              enum: [
                'Yes',
                'No',
              ],
            },
            radiusMileOrKilometerDistance: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.targeting.radiusMileOrKilometerDistanceLabel'),
              enum: [...radiusMileOrKilometerDistanceEnum],
            },
          },
        },
      ],
    },
    advertiserBusinessAddress: {
      oneOf: [
        {
          properties: {
            advertiserBusinessAddress: {
              enum: ['No'],
            },
            addressToUse: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.targeting.listTheAddressToUse'),
              default: data?.addressToUse,
            },
            areaToExcludeFromTargeting: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.targeting.areaToExcludeFromTargeting'),
              default: data?.areaToExcludeFromTargeting || '',
            },
          },
          required: ["addressToUse"],
        },
      ],
    },
    facebookSpecialAdCategories: {
      oneOf: [
        {
          properties: {
            facebookSpecialAdCategories: {
              enum: ['No - None of the Above'],
            },
            minimumAge: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.targeting.minimumAgeLabel'),
              default: data?.minimumAge || '',
              enum: ['', ...ageEnum],
            },
            maximumAge: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.targeting.maximumAgeLabel'),
              default: data?.maximumAge || '',
              enum: ['', ...ageEnum],
            },
            gender: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.targeting.genderLabel'),
              default: data?.gender || '',
              enum: ['', 'Male', 'Female', 'Both'],
            },
            minimumAgeForSmartWebsite: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.targeting.minimumAgeLabel'),
              default: data?.minimumAgeForSmartWebsite || '',
              enum: ['', ...ageEnum],
            },
            maximumAgeForSmartWebsite: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.targeting.maximumAgeLabel'),
              default: data?.maximumAgeForSmartWebsite || '',
              enum: ['', ...ageEnum],
            },
            genderForSmartWebsite: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.targeting.genderLabel'),
              default: data?.genderForSmartWebsite || '',
              enum: ['', 'Male', 'Female', 'Both'],
            },
            minimumAgeForSmartRetargeting: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.targeting.minimumAgeLabel'),
              default: data?.minimumAgeForSmartRetargeting || '',
              enum: ['', ...ageEnum],
            },
            maximumAgeForSmartRetargeting: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.targeting.maximumAgeLabel'),
              default: data?.maximumAgeForSmartRetargeting || '',
              enum: ['', ...ageEnum],
            },
            genderForSmartRetargeting: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.targeting.genderLabel'),
              default: data?.genderForSmartRetargeting || '',
              enum: ['', 'Male', 'Female', 'Both'],
            },
          },
        },
      ],
    },
    localiqWebsite: {
      oneOf: [
        {
          properties: {
            localiqWebsite: {
              enum: ['Yes'],
            },
            websiteLive: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.websites.websiteLiveTitle'),
              default: data?.websiteLive || '',
              enum: [
                '',
                t('pages.socialAds.solutionStrategy.websites.websiteLiveYesOptions'),
                t('pages.socialAds.solutionStrategy.websites.websiteLiveNoOptions'),
              ],
            },
          },
        },
        {
          properties: {
            localiqWebsite: {
              enum: ['No'],
            },
            captureCodeCompatible: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.captureCodeCompatibleLabel'),
              default: data?.captureCodeCompatible || '',
              enum: [
                '',
                t('pages.socialAds.solutionStrategy.proxyProvisioning.doesWebsiteHaveCaptchaInstalledOptions.yes'),
                t('pages.socialAds.solutionStrategy.proxyProvisioning.doesWebsiteHaveCaptchaInstalledOptions.no'),
              ],
            },
            isClientPartOfAFranchise: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.isClientPartOfAFranchiseLabel'),
              default: data?.isClientPartOfAFranchise || '',
              enum: [
                '',
                t('pages.socialAds.solutionStrategy.proxyProvisioning.doesWebsiteHaveCaptchaInstalledOptions.yes'),
                t('pages.socialAds.solutionStrategy.proxyProvisioning.doesWebsiteHaveCaptchaInstalledOptions.no'),
              ],
            },
          },
        },
      ],
    },
    websiteLive: {
      oneOf: [
        {
          properties: {
            websiteLive: {
              enum: ['No, the ReachSite is Not live.'],
            },
            temporaryLink: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.websites.temporaryLinkTitle'),
              default: data?.temporaryLink || '',
            },
          },
        },
        {
          properties: {
            websiteLive: {
              enum: ['Yes, the ReachSite is live.'],
            },
          },
        },
      ],
    },
    doesPrimaryBusinessNumberNeedToForward: {
      oneOf: [
        {
          properties: {
            doesPrimaryBusinessNumberNeedToForward: {
              enum: ['Yes'],
            },
            alternativeForwardingNumber: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.proxyProvisioning.alternativeForwardingNumber'),
              default: data?.alternativeForwardingNumber || '',
            },
          },
        },
      ],
    },
    utmImplementString: {
      oneOf: [
        {
          properties: {
            utmImplementString: {
              enum: ['Yes - Use my UTM strings only'],
            },
            useThisUtm: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.proxyProvisioning.useThisUtm'),
              description: t('pages.socialAds.solutionStrategy.proxyProvisioning.helperTextForUseThisUtm'),
              default: data?.useThisUtm || '',
            },
          },
        },
        {
          properties: {
            utmImplementString: {
              enum: ['Yes - Use UTM strings Best Practices', 'No - Do NOT use UTM strings'],
            },
          },
        },
      ],
    },
    doesWebsiteHaveCaptchaInstalled: {
      oneOf: [
        {
          properties: {
            doesWebsiteHaveCaptchaInstalled: {
              enum: ['Yes'],
            },
            captchaPiece: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captchaPiece'),
              default: data?.captchaPiece || '',
              enum: [
                '',
                t('pages.socialAds.solutionStrategy.proxyProvisioning.provisioningProceedWithBreakingTrackingOptions.yes'),
                t('pages.socialAds.solutionStrategy.proxyProvisioning.provisioningProceedWithBreakingTrackingOptions.no'),
              ],
            },
          },
        },
        {
          properties: {
            doesWebsiteHaveCaptchaInstalled: {
              enum: ['No'],
            },
          },
        },
      ],
    },
    webEvents: {
      oneOf: [
        {
          properties: {
            webEvents: {
              enum: ['Use only my web events'],
            },
            webEventItems: {
              type: 'string',
              items: {
                type: 'object',
              },
              title: 'Web Events',
              default: '',
            },
          },
        },
      ],
    },
    isThisTheSameEmailForCreativeApproval: {
      oneOf: [
        {
          properties: {
            isThisTheSameEmailForCreativeApproval: {
              enum: ['No'],
            },
            clientEmailForCreativeApproval: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.contactInformation.clientEmailForCreativeApprovalLabel'),
              default: data?.clientEmailForCreativeApproval || '',
            },
          },
        },
      ],
    },
    isThisTheBestPersonToGrantUsFacebookAdminAccess: {
      oneOf: [
        {
          properties: {
            isThisTheBestPersonToGrantUsFacebookAdminAccess: {
              enum: ['No'],
            },
            clientEmailForFacebookAdminAccess: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.contactInformation.clientEmailForFacebookAdminAccessLabel'),
              default: data?.clientEmailForFacebookAdminAccess || '',
            },
          },
        },
      ],
    },
    campaignFocus: {
      oneOf: [
        {
          properties: {
            campaignFocus: {
              enum: ['Contacts'],
            },
            targetingDetail: {
              type: 'string',
              default: data?.targetingDetail || '',
              title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.detailedTargetingLabel'),
              enum: [
                '',
                t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.detailedTargetingYesLabel'),
                t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.detailedTargetingNoLabel'),
              ],
            },
            businessWebsiteUrl: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.businessWebsiteURL'),
              default: data?.businessWebsiteUrl || '',
            },
            facebookPageUrl: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.facebookPageURL'),
              default: data?.facebookPageUrl || '',
              customUrlValidation: true,
              urlValidationParameter,
              urlValidationErrorText: t('pages.socialAds.solutionStrategy.socialAdsLeadAds.facebookPageUrlErrorMessage'),
            },
            landingPageUrl: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.landingPageURL'),
              default: data?.landingPageUrl || '',
            },
            advertiserEmail: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.advertiserEmail'),
              default: data?.advertiserEmail || '',
            },
            socialAdsLeadsAdsTitle: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAds.title'),
            },
            minAge: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAds.minAgeTitle'),
              default: data?.minAge || '',
              enum: ['', ...ageEnum],
            },
            maxAge: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAds.maxAgeTitle'),
              default: data?.maxAge || '',
              enum: ['', ...ageEnum],
            },
            genderForSocialAdsLeadsAds: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartWebsiteClick.gender'),
              default: data?.genderForSocialAdsLeadsAds || '',
              enum: ['',
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.maleOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.femaleOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.bothOption'),
              ],
            },
            automaticOrOptimizedPlacementForSocialAdsLeadsAds: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartWebsiteClick.automaticOrOptimizedPlacement'),
              default: data?.automaticOrOptimizedPlacementForSocialAdsLeadsAds || '',
              enum: [
                '',
                t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.yesOption'),
                t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.noOption'),
              ],
            },
            callToActionForSocialAdsLeadsAds: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAds.callToActionDropdown'),
              default: data?.callToActionForSocialAdsLeadsAds || '',
              enum: [
                '',
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.noButtonOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.applyNowOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.bookNowOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.contactUsOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.donateNowOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.downloadOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.getShowtimeOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.learnMoreOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.listenNowOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.requestTimeOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.seeMenuOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.sendMessageOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.shopNowOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.signUpOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.watchNowOption'),
              ],
            },
            contactFieldsTitle: {
              type: 'array',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAds.contactFieldsTitle'),
              items: {
                type: 'string',
                enum: [
                  t('pages.socialAds.solutionStrategy.socialAdsLeadAds.phoneNumberOptions'),
                  t('pages.socialAds.solutionStrategy.socialAdsLeadAds.emailAddressOptions'),
                  t('pages.socialAds.solutionStrategy.socialAdsLeadAds.zipCodeOptions'),
                ],
              },
              uniqueItems: true,
            },
            zipCodeNotAvailable: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAds.zipCodeNotAvailableLabel'),
            },
            note: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAds.noteLabel'),
            },
            leadEnhancementTitle: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.title'),
            },
            wouldYouPreferTheLeadFormInTheLeadAdsObjectiveOptimizeFor: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.wouldYouPreferTheLeadFormInTheLeadAdsObjectiveOptimizeForNonRequireLabel'),
              default: data?.wouldYouPreferTheLeadFormInTheLeadAdsObjectiveOptimizeFor || '',
              enum: [
                '',
                'More Volume of Leads',
                'Higher Intent Leads',
              ],
            },
            introSectionOnLeadFormHeading: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.introSectionOnLeadFormHeading'),
            },
            doYouWantToIncludeAnIntroSectionOnYourLeadsForm: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.doYouWantToIncludeAnIntroSectionOnYourLeadsFormNonRequireLabel'),
              default: data?.doYouWantToIncludeAnIntroSectionOnYourLeadsForm || '',
              enum: [
                '',
                'Yes - Include intro card',
                'No',
              ],
            },
            customConfigurableQuestionsOptionalHeading: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.customConfigurableQuestionsOptionalHeading'),
            },
            question1: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.question1Label'),
              description: t('pages.socialAds.solutionStrategy.leadEnhancement.questionHelperText'),
              default: data?.question1 || '',
            },
            answerToQuestion1: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.answerToQuestion1Label'),
              description: t('pages.socialAds.solutionStrategy.leadEnhancement.answerToQuestion1HelperText'),
              default: data?.answerToQuestion1 || '',
            },
            question2: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.question2Label'),
              description: t('pages.socialAds.solutionStrategy.leadEnhancement.questionHelperText'),
              default: data?.question2 || '',
            },
            answerToQuestion2: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.answerToQuestion2Label'),
              description: t('pages.socialAds.solutionStrategy.leadEnhancement.answerToQuestion1HelperText'),
              default: data?.answerToQuestion2 || '',
            },
            question3: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.question3Label'),
              description: t('pages.socialAds.solutionStrategy.leadEnhancement.questionHelperText'),
              default: data?.question3 || '',
            },
            answerToQuestion3: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.answerToQuestion3Label'),
              default: data?.answerToQuestion3 || '',
            },
            thankYouSectionOnLeadFormHeading: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.thankYouSectionOnLeadFormHeading'),
            },
            forTheTextFieldsOnTheThankYouScreen: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.forTheTextFieldsOnTheThankYouScreenNonRequireLabel'),
              default: data?.forTheTextFieldsOnTheThankYouScreen || '',
              enum: [
                '',
                'Write my text for me',
                'Use boilerplate message: "Thank you for your form submission. A representative will be in touch with you soon."',
                'I will provide the text',
              ],
            },
            callToActionPostLeadSubmissionDoesTheAdvertiserPreferToDirectClientsTo: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.callToActionPostLeadSubmissionDoesTheAdvertiserPreferToDirectClientsToLabel'),
              default: data?.callToActionPostLeadSubmissionDoesTheAdvertiserPreferToDirectClientsTo || '',
              enum: [
                '',
                'Landing Page (Website)',
                'Call Now',
              ],
            },
            privacyPolicyUrl: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.privacyPolicyURLTitle'),
              default: data?.privacyPolicyUrl || '',
            },
            samplePrivacyPolice: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.samplePrivacyPolice'),
              enum: ['https://example.com/', true],
            },
            advertiserDisclaimerUrl: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.advertiserDisclaimerURLTitle'),
              default: data?.advertiserDisclaimerUrl || '',
            },
          },
          required: [
            'targetingDetail',
            'businessWebsiteUrl',
            'facebookPageUrl',
            'landingPageUrl',
            'advertiserEmail',
            'callToActionForSocialAdsLeadsAds'
          ],
        },
        {
          properties: {
            campaignFocus: {
              enum: ['Reach'],
            },
            businessWebsiteUrl: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.businessWebsiteURL'),
              default: data?.businessWebsiteUrl || '',
            },
            facebookPageUrl: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.facebookPageURL'),
              default: data?.facebookPageUrl || '',
              customUrlValidation: true,
              urlValidationParameter,
              urlValidationErrorText: t('pages.socialAds.solutionStrategy.socialAdsLeadAds.facebookPageUrlErrorMessage'),
            },
            snapchatEnable: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.snapchatEnable'),
              default: data?.snapchatEnable || '',
              enum: [
                '',
                t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.yesOption'),
                t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.noOption'),
              ],
            },
            landingPageUrl: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.landingPageURL'),
              default: data?.landingPageUrl || '',
            },
            advertiserEmail: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.advertiserEmail'),
              default: data?.advertiserEmail || '',
            },
            socialAdsSmartReachTitle: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsReach.socialAdsReachTitle'),
            },
            automaticOrOptimizedPlacementForSmartReach: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartWebsiteClick.automaticOrOptimizedPlacement'),
              default: data?.automaticOrOptimizedPlacementForSmartReach || '',
              enum: [
                '',
                t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.yesOption'),
                t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.noOption'),
              ],
            },
            callToActionForSmartReach: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartRetargeting.callToActionButton'),
              default: data?.callToActionForSmartReach || '',
              enum: [
                '',
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.noButtonOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.applyNowOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.bookNowOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.contactUsOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.donateNowOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.downloadOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.getShowtimeOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.learnMoreOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.listenNowOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.requestTimeOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.seeMenuOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.sendMessageOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.shopNowOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.signUpOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.watchNowOption'),
              ],
            },
          },
          required: [
            'businessWebsiteUrl',
            'facebookPageUrl',
            'landingPageUrl',
            'advertiserEmail',
          ],
        },
        {
          properties: {
            campaignFocus: {
              enum: ['Engagement'],
            },
            targetingDetail: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.detailedTargetingLabel'),
              enum: [
                t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.detailedTargetingYesLabel'),
                t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.detailedTargetingNoLabel'),
              ],
            },
            businessWebsiteUrl: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.businessWebsiteURL'),
              default: data?.businessWebsiteUrl || '',
            },
            facebookPageUrl: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.facebookPageURL'),
              default: data?.facebookPageUrl || '',
              customUrlValidation: true,
              urlValidationParameter,
              urlValidationErrorText: t('pages.socialAds.solutionStrategy.socialAdsLeadAds.facebookPageUrlErrorMessage'),
            },
            snapchatEnable: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.snapchatEnable'),
              default: data?.snapchatEnable || '',
              enum: [
                '',
                t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.yesOption'),
                t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.noOption'),
              ],
            },
            landingPageUrl: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.landingPageURL'),
              default: data?.landingPageUrl || '',
            },
            advertiserEmail: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.advertiserEmail'),
              default: data?.advertiserEmail || '',
            },
            socialAdsLeadsAdsTitle: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAds.title'),
            },
            minAge: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAds.minAgeTitle'),
              default: data?.minAge || '',
              enum: ['', ...ageEnum],
            },
            maxAge: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAds.maxAgeTitle'),
              default: data?.maxAge || '',
              enum: ['', ...ageEnum],
            },
            genderForSocialAdsLeadsAds: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartWebsiteClick.gender'),
              default: data?.genderForSocialAdsLeadsAds || '',
              enum: ['',
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.maleOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.femaleOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.bothOption'),
              ],
            },
            automaticOrOptimizedPlacementForSocialAdsLeadsAds: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartWebsiteClick.automaticOrOptimizedPlacement'),
              default: data?.automaticOrOptimizedPlacementForSocialAdsLeadsAds || '',
              enum: [
                '',
                t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.yesOption'),
                t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.noOption'),
              ],
            },
            callToActionForSocialAdsLeadsAds: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAds.callToActionDropdown'),
              default: data?.callToActionForSocialAdsLeadsAds || '',
              enum: [
                '',
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.noButtonOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.applyNowOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.bookNowOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.contactUsOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.donateNowOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.downloadOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.getShowtimeOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.learnMoreOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.listenNowOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.requestTimeOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.seeMenuOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.sendMessageOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.shopNowOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.signUpOption'),
                t('pages.socialAds.solutionStrategy.smartWebsiteClick.watchNowOption'),
              ],
            },
            contactFieldsTitle: {
              type: 'array',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAds.contactFieldsTitle'),
              items: {
                type: 'string',
                enum: [
                  t('pages.socialAds.solutionStrategy.socialAdsLeadAds.phoneNumberOptions'),
                  t('pages.socialAds.solutionStrategy.socialAdsLeadAds.emailAddressOptions'),
                  t('pages.socialAds.solutionStrategy.socialAdsLeadAds.zipCodeOptions'),
                ],
              },
              uniqueItems: true,
            },
            zipCodeNotAvailable: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAds.zipCodeNotAvailableLabel'),
            },
            note: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAds.noteLabel'),
            },
            leadEnhancementTitle: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.title'),
            },
            wouldYouPreferTheLeadFormInTheLeadAdsObjectiveOptimizeFor: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.wouldYouPreferTheLeadFormInTheLeadAdsObjectiveOptimizeForNonRequireLabel'),
              default: data?.wouldYouPreferTheLeadFormInTheLeadAdsObjectiveOptimizeFor || '',
              enum: [
                '',
                'More Volume of Leads',
                'Higher Intent Leads',
              ],
            },
            introSectionOnLeadFormHeading: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.introSectionOnLeadFormHeading'),
            },
            doYouWantToIncludeAnIntroSectionOnYourLeadsForm: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.doYouWantToIncludeAnIntroSectionOnYourLeadsFormNonRequireLabel'),
              default: data?.doYouWantToIncludeAnIntroSectionOnYourLeadsForm || '',
              enum: [
                '',
                'Yes - Include intro card',
                'No',
              ],
            },
            customConfigurableQuestionsOptionalHeading: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.customConfigurableQuestionsOptionalHeading'),
            },
            question1: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.question1Label'),
              description: t('pages.socialAds.solutionStrategy.leadEnhancement.questionHelperText'),
              default: data?.question1 || '',
            },
            answerToQuestion1: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.answerToQuestion1Label'),
              description: t('pages.socialAds.solutionStrategy.leadEnhancement.answerToQuestion1HelperText'),
              default: data?.answerToQuestion1 || '',
            },
            question2: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.question2Label'),
              description: t('pages.socialAds.solutionStrategy.leadEnhancement.questionHelperText'),
              default: data?.question2 || '',
            },
            answerToQuestion2: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.answerToQuestion2Label'),
              description: t('pages.socialAds.solutionStrategy.leadEnhancement.answerToQuestion1HelperText'),
              default: data?.answerToQuestion2 || '',
            },
            question3: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.question3Label'),
              description: t('pages.socialAds.solutionStrategy.leadEnhancement.questionHelperText'),
              default: data?.question3 || '',
            },
            answerToQuestion3: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.answerToQuestion3Label'),
              default: data?.answerToQuestion3 || '',
            },
            thankYouSectionOnLeadFormHeading: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.thankYouSectionOnLeadFormHeading'),
            },
            forTheTextFieldsOnTheThankYouScreen: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.forTheTextFieldsOnTheThankYouScreenNonRequireLabel'),
              default: data?.forTheTextFieldsOnTheThankYouScreen || '',
              enum: [
                '',
                'Write my text for me',
                'Use boilerplate message: "Thank you for your form submission. A representative will be in touch with you soon."',
                'I will provide the text',
              ],
            },
            callToActionPostLeadSubmissionDoesTheAdvertiserPreferToDirectClientsTo: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.callToActionPostLeadSubmissionDoesTheAdvertiserPreferToDirectClientsToLabel'),
              default: data?.callToActionPostLeadSubmissionDoesTheAdvertiserPreferToDirectClientsTo || '',
              enum: [
                '',
                'Landing Page (Website)',
                'Call Now',
              ],
            },
            privacyPolicyUrl: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.privacyPolicyURLTitle'),
              default: data?.privacyPolicyUrl || '',
            },
            samplePrivacyPolice: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.samplePrivacyPolice'),
              enum: ['https://example.com/', true],
            },
            advertiserDisclaimerUrl: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.advertiserDisclaimerURLTitle'),
              default: data?.advertiserDisclaimerUrl || '',
            },
          },
          required: [
            'targetingDetail',
            'businessWebsiteUrl',
            'facebookPageUrl',
            'landingPageUrl',
            'advertiserEmail',
            'callToActionForSocialAdsLeadsAds'
          ],
        },
      ],
    },
    snapchatEnable: {
      oneOf: [
        {
          properties: {
            snapchatEnable: {
              enum: ['Yes'],
            },
            businessProfile: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.businessProfile'),
              default: data?.businessProfile || '',
              enum: [
                '',
                t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.yesOption'),
                t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.noOption'),
              ],
            },
            socialAdsSnapchatTitle: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsSnapchat.socialAdsSnapchatTitle'),
            },
            headline: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsSnapchat.headlineTitle'),
              default: data?.headline || '',
            },
            brandAwareness: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsSnapchat.brandAwarenessTitle'),
              default: data?.brandAwareness || '',
              enum: [
                '',
                t('pages.socialAds.solutionStrategy.socialAdsSnapchat.showAdOptions'),
                t('pages.socialAds.solutionStrategy.socialAdsSnapchat.showDestinationURLOptions'),
              ],
            },
            whatTypeOfVisualStrategyDoesYourClientWantToEmploySnapchat: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialCreativeInstructions.employSnapchatTitle'),
              default: data?.whatTypeOfVisualStrategyDoesYourClientWantToEmploySnapchat || '',
              enum: [
                '',
                'Single Image',
                'Single Video',
                'Image and Video Combination',
              ],
            },
          },
        },
        {
          properties: {
            snapchatEnable: {
              enum: ['No'],
            },
          },
        },
      ],
    },
    businessProfile: {
      oneOf: [
        {
          properties: {
            businessProfile: {
              enum: ['Yes'],
            },
            snapchatProfileId: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.snapchatProfileID'),
            },
          },
        },
        {
          properties: {
            businessProfile: {
              enum: ['No'],
            },
          },
        },
      ],
    },
    focusOnTimedEvent: {
      oneOf: [
        {
          properties: {
            focusOnTimedEvent: {
              enum: ['Yes'],
            },
            detailsOfEvent: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.eventDetails'),
            },
            startDate: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.startDateLabel'),
            },
            endDate: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.endDateLabel'),
            },
          },
        },
        {
          properties: {
            focusOnTimedEvent: {
              enum: ['No'],
            },
          },
        },
      ],
    },
    flightedCampaign: {
      oneOf: [
        {
          properties: {
            flightedCampaign: {
              enum: ['Yes'],
            },
            dateForFlightedCampaign: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.flightedCampaignDateLabel'),
            },
            flightedCampaignStartDate: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.startDateLabel'),
            },
            flightedCampaignEndDate: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.endDateLabel'),
            },
          },
        },
        {
          properties: {
            flightedCampaign: {
              enum: ['No'],
            },
          },
        },
      ],
    },
    clientFirmStartDate: {
      oneOf: [
        {
          properties: {
            clientFirmStartDate: {
              enum: ['Yes'],
            },
            firmStartDate: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.firmStartDateLabel'),
              default: data?.firmStartDate || '',
            },
          },
        },
        {
          properties: {
            clientFirmStartDate: {
              enum: ['No'],
            },
          },
        },
      ],
    },
    clientFirmEndDate: {
      oneOf: [
        {
          properties: {
            clientFirmEndDate: {
              enum: ['Yes'],
            },
            firmEndDate: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.firmEndDateLabel'),
              default: data?.firmEndDate || '',
            },
          },
        },
        {
          properties: {
            clientFirmEndDate: {
              enum: ['No'],
            },
          },
        },
      ],
    },
    automaticOrOptimizedPlacement: {
      oneOf: [
        {
          properties: {
            automaticOrOptimizedPlacement: {
              enum: ['No'],
            },
            adPlacements: {
              type: 'array',
              title: t('pages.socialAds.solutionStrategy.smartWebsiteClick.clientAdsCheckbox'),
              items: {
                type: 'string',
                enum: [
                  t('pages.socialAds.solutionStrategy.smartWebsiteClick.mobileNewsFeedLabel'),
                  t('pages.socialAds.solutionStrategy.smartWebsiteClick.desktopNewsFeedLabel'),
                  t('pages.socialAds.solutionStrategy.smartWebsiteClick.instagramLabel'),
                  t('pages.socialAds.solutionStrategy.smartWebsiteClick.facebookAudienceNetworkLabel'),
                  t('pages.socialAds.solutionStrategy.smartWebsiteClick.marketplaceLabel'),
                  t('pages.socialAds.solutionStrategy.smartWebsiteClick.storyAdsLabel'),
                ],
              },
              uniqueItems: true,
            },
          },
        },
        {
          properties: {
            automaticOrOptimizedPlacement: {
              enum: ['Yes'],
            },
          },
        },
      ],
    },
    facebookRetargetingPreference: {
      oneOf: [
        {
          properties: {
            facebookRetargetingPreference: {
              enum: ['Select Pages'],
            },
            selectPageUrl: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartRetargeting.selectPageURLTitle'),
              default: data?.selectPageUrl || '',
            },
          },
        },
        {
          properties: {
            facebookRetargetingPreference: {
              enum: ['Entire Website'],
            },
          },
        },
      ],
    },
    enableTargetingPeople: {
      oneOf: [
        {
          properties: {
            enableTargetingPeople: {
              enum: ['Yes - Use LookAlikes'],
            },
            daysOfRetarget: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartRetargeting.daysOfRetargetTitle'),
              enum: [
                t('pages.socialAds.solutionStrategy.smartRetargeting.days15'),
                t('pages.socialAds.solutionStrategy.smartRetargeting.days30'),
                t('pages.socialAds.solutionStrategy.smartRetargeting.days60'),
                t('pages.socialAds.solutionStrategy.smartRetargeting.days90'),
                t('pages.socialAds.solutionStrategy.smartRetargeting.days120'),
                t('pages.socialAds.solutionStrategy.smartRetargeting.days150'),
                t('pages.socialAds.solutionStrategy.smartRetargeting.days180'),
              ],
            },
          },
        },
        {
          properties: {
            facebookRetargetingPreference: {
              enum: ['Entire Website'],
            },
          },
        },
      ],
    },
    creativeLandingPage: {
      oneOf: [
        {
          properties: {
            creativeLandingPage: {
              enum: ['Yes'],
            },
            creativeLandUnique: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.smartRetargeting.uniqueURLsTitle'),
              default: data?.creativeLandUnique || '',
            },
          },
        },
        {
          properties: {
            creativeLandingPage: {
              enum: ['No'],
            },
          },
        },
      ],
    },
    automaticOrOptimizedPlacementForSocialAdsLeadsAds: {
      oneOf: [
        {
          properties: {
            automaticOrOptimizedPlacementForSocialAdsLeadsAds: {
              enum: ['No'],
            },
            clientAdsForSocialAdsLeadsAds: {
              type: 'array',
              title: t('pages.socialAds.solutionStrategy.smartWebsiteClick.clientAdsCheckbox'),
              items: {
                type: 'string',
                enum: [
                  t('pages.socialAds.solutionStrategy.smartWebsiteClick.mobileNewsFeedLabel'),
                  t('pages.socialAds.solutionStrategy.smartWebsiteClick.desktopNewsFeedLabel'),
                  t('pages.socialAds.solutionStrategy.smartWebsiteClick.instagramLabel'),
                  t('pages.socialAds.solutionStrategy.smartWebsiteClick.facebookAudienceNetworkLabel'),
                  t('pages.socialAds.solutionStrategy.smartWebsiteClick.marketplaceLabel'),
                  t('pages.socialAds.solutionStrategy.smartWebsiteClick.storyAdsLabel'),
                ],
              },
              uniqueItems: true,
            },
          },
        },
        {
          properties: {
            automaticOrOptimizedPlacementForSocialAdsLeadsAds: {
              enum: ['Yes'],
            },
          },
        },
      ],
    },
    forTheTextFieldsOnTheThankYouScreen: {
      oneOf: [
        {
          properties: {
            forTheTextFieldsOnTheThankYouScreen: {
              enum: ['I will provide the text'],
            },
            textAdFields: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.textAdFieldsLabel'),
              default: data?.textAdFields || '',
            },
            thankYouScreenHeadline: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.thankYouScreenHeadlineTitle'),
              default: data?.thankYouScreenHeadline || '',
            },
            thankYouScreenDescription: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.leadEnhancement.thankYouScreenDescriptionTitle'),
              default: data?.thankYouScreenDescription || '',
            },
          },
        },
      ],
    },
    willCreativesNeedToLandToUniqueLandingPagesWithinTheCampaignDomain: {
      oneOf: [
        {
          properties: {
            willCreativesNeedToLandToUniqueLandingPagesWithinTheCampaignDomain: {
              enum: ['Yes'],
            },
            whatUniqueUrlsShouldTheCreativesLandTo: {
              type: 'string',
              description: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.whatUniqueUrlsShouldTheCreativesLandToHelperText'),
              title: t('pages.socialAds.solutionStrategy.socialCreativeInstructions.whatUniqueUrlsShouldTheCreativesLandToLabel'),
              default: data?.whatUniqueUrlsShouldTheCreativesLandTo || '',
            },
          },
        },
      ],
    },
    willTheClientBeProvidingTheirOwnTextCreative: {
      oneOf: [
        {
          properties: {
            willTheClientBeProvidingTheirOwnTextCreative: {
              enum: ['Yes'],
            },
            fbMockupHeading: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.fbMockupHeading'),
            },
            pleaseEnterTheAdLinkDescription: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.pleaseEnterTheAdLinkDescriptionLabel'),
              description: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.pleaseEnterTheAdLinkDescriptionHelperText'),
              default: data?.pleaseEnterTheAdLinkDescription || '',
            },
            pleaseEnterTheAdHeadline: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.pleaseEnterTheAdHeadlineLabel'),
              description: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.pleaseEnterTheAdHeadlineHelperText'),
              default: data?.pleaseEnterTheAdHeadline || '',
            },
            pleaseEnterTheAdText: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.pleaseEnterTheAdTextLabel'),
              description: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.pleaseEnterTheAdTextHelperText'),
              default: data?.pleaseEnterTheAdText || '',
            },
            whatProductServiceOfferShouldBePromotedInThisCampaign: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.whatProductServiceOfferShouldBePromotedInThisCampaignLabel'),
              default: data?.whatProductServiceOfferShouldBePromotedInThisCampaign || '',
            },
          },
        },
      ],
    },
    targetingDetail: {
      oneOf: [
        {
          properties: {
            targetingDetail: {
              enum: ['Yes - Include detailed targeting (Run Reach, Retargeting, Leads with Detailed Tarageting, Website Clicks With Detailed Targeting)'],
            },
            socialAdsLeadAdsWithDetailedTargetingTitle: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.headerLabel'),
            },
            guide: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.guideLabel'),
            },
            detailedTargetingPreferences: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.detailedTargetingPreferencesLabel'),
            },
            demographicTargeting: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.demographicTargetingDropDownLabel'),
              enum: [
                t('form.big-box-labels.yes'),
                t('pages.custom.solutions.common.noOption'),
              ],
            },
            interestTargeting: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.interestTargetingDropDownLabel'),
              enum: [
                t('form.big-box-labels.yes'),
                t('pages.custom.solutions.common.noOption'),
              ],
            },
            behavioralTargeting: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.behavioralTargetingDropDownLabel'),
              enum: [
                t('form.big-box-labels.yes'),
                t('pages.custom.solutions.common.noOption'),
              ],
            },
            selectAll: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.selectAllLabel'),
            },
          },
        },
      ],
    },
    demographicTargeting: {
      oneOf: [
        {
          properties: {
            demographicTargeting: {
              enum: ['Yes'],
            },
            demographicTargetingHeaderLabel: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.demographicHeaderLabel'),
            },
            info: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.demographicHelp'),
            },
            info1: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.demographicHelp1'),
            },
            info2: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.demographicHelp2'),
            },
            info3: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.demographicHelp3'),
            },
            info4: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.demographicHelp4'),
            },
            info5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.demographicHelp5'),
            },
            info6: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.demographicHelp6'),
            },
            info7: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.demographicHelp7'),
            },
            desiredDetailsDemographicTargeting: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.desiredDetailedLabel'),
              default: data?.desiredDetailsDemographicTargeting || '',
            },
          },
        },
        {
          properties: {
            demographicTargeting: {
              enum: ['No'],
            },
          },
        },
      ],
    },
    interestTargeting: {
      oneOf: [
        {
          properties: {
            interestTargeting: {
              enum: ['Yes'],
            },
            interestTargetingHeaderLabel: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.interestHeaderLabel'),
            },
            advice: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.interestHelp'),
            },
            advice1: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.interestHelp1'),
            },
            advice2: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.interestHelp2'),
            },
            advice3: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.interestHelp3'),
            },
            advice4: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.interestHelp4'),
            },
            advice5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.interestHelp5'),
            },
            advice6: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.interestHelp6'),
            },
            advice7: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.interestHelp7'),
            },
            advice8: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.interestHelp8'),
            },
            desiredDetailsInterestTargeting: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.desiredDetailedLabel'),
              default: data?.desiredDetailsInterestTargeting || '',
            },
          },
        },
        {
          properties: {
            interestTargeting: {
              enum: ['No'],
            },
          },
        },
      ],
    },
    behavioralTargeting: {
      oneOf: [
        {
          properties: {
            behavioralTargeting: {
              enum: ['Yes'],
            },
            behavioralTargetingHeaderLabel: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.behavioralTargetingHeaderLabel'),
            },
            information: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.behavioralHelp'),
            },
            information1: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.behavioralHelp1'),
            },
            information2: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.behavioralHelp2'),
            },
            information3: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.behavioralHelp3'),
            },
            information4: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.behavioralHelp4'),
            },
            information5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.behavioralHelp5'),
            },
            information6: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.behavioralHelp6'),
            },
            information7: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.behavioralHelp7'),
            },
            information8: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.behavioralHelp8'),
            },
            desiredDetailsBehavioralTargeting: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsLeadAdsWithDetailedTargeting.desiredDetailedLabel'),
              default: data?.desiredDetailsBehavioralTargeting || '',
            },
          },
        },
        {
          properties: {
            behavioralTargeting: {
              enum: ['No'],
            },
          },
        },
      ],
    },
    brandAwareness: {
      oneOf: [
        {
          properties: {
            brandAwareness: {
              enum: ['Show Destination URL'],
            },
            destinationUrl: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsSnapchat.destinationUrlText'),
              default: data?.destinationUrl || '',
            },
            callToActionForSnapchat: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsSnapchat.callToActionText'),
              default: data?.callToActionForSnapchat || '',
            },
          },
        },
      ],
    },
  },
});

const dropDownKeys = [
  'facebookSpecialAdCategories',
  'minimumAge',
  'gender',
  'minimumAgeForSmartWebsite',
  'genderForSmartWebsite',
  'minimumAgeForSmartRetargeting',
  'genderForSmartRetargeting',
  'minimumAgeForSmartReach',
  'genderForSmartReach',
  'targetingType',
  'advertiserBusinessAddress',
  'radiusMileOrKilometerDistance',
  'localiqWebsite',
  'websiteLive',
  'doesPrimaryBusinessNumberNeedToForward',
  'utmImplementString',
  'doesWebsiteHaveCaptchaInstalled',
  'captchaPiece',
  'doesClientHaveAnotherAccount',
  'webEvents',
  'campaignFocus',
  'targetingDetail',
  'snapchatEnable',
  'businessProfile',
  'focusOnTimedEvent',
  'flightedCampaign',
  'clientFirmStartDate',
  'clientFirmEndDate',
  'automaticOrOptimizedPlacement',
  'callToAction',
  'automaticOrOptimizedPlacementForRetargeting',
  'callToActionForRetargeting',
  'facebookRetargetingPreference',
  'daysOfRetarget',
  'daysOfRetargetVisitorsOnFacebook',
  'searchCampaignFacebookRetargeting',
  'searchLeadsLookalikeAudience',
  'creativeLandingPage',
  'automaticOrOptimizedPlacementForSocialAdsLeadsAds',
  'callToActionForSocialAdsLeadsAds',
  'minAge',
  'genderForSocialAdsLeadsAds',
  'automaticOrOptimizedPlacementForSmartReach',
  'callToActionForSmartReach',
  'demographicTargeting',
  'interestTargeting',
  'behavioralTargeting',
  'brandAwareness',
  'captureCodeCompatible',
  'isClientPartOfAFranchise',
  'websiteTracking',
  'websiteTrackingForFranchiseYes',
  'websiteTrackingForCaptureCodeCompatibleNo',
  'canWeStartYourCampaignOn3pp',
  'canWeStartYourCampaignOn3ppForFranchiseYes',
];

const dropDownFields = dropDownKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'dropdown' },
}), {});

const textFieldKeys = [
  'geoTargetingDetails',
  'localAreaCodePreference1',
  'localAreaCodePreference2',
  'useThisUtm',
  'clientContactName',
  'areaToExcludeFromTargeting',
  'snapchatProfileId',
  'detailsOfEvent',
  'question1',
  'answerToQuestion1',
  'question2',
  'answerToQuestion2',
  'question3',
  'whatProductServiceOfferShouldBePromotedInThisCampaign',
  'pleaseEnterTheAdLinkDescription',
  'pleaseEnterTheAdHeadline',
  'pleaseEnterTheAdText',
  'headline',
  'callToActionForSnapchat',
];

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textInputField' },
}), {});

const textAreaFieldKeys = [
  'doNotProxyWebsite',
  'whatUniqueUrlsShouldTheCreativesLandTo',
  'desiredDetailsDemographicTargeting',
  'desiredDetailsInterestTargeting',
  'desiredDetailsBehavioralTargeting',
];

const textAreaFields = textAreaFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'TextareaWidget' },
}), {});

const dateKeys = [
  'startDate',
  'endDate',
  'flightedCampaignStartDate',
  'flightedCampaignEndDate',
  'firmStartDate',
  'firmEndDate',
];

const dateFields = dateKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'datePickerField' },
}), {});

const checkBoxesFieldKeys = [
  'adPlacements',
  'clientAdsForSocialAdsLeadsAds',
  'contactFieldsTitle',
];

const checkBoxesFields = checkBoxesFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'checkBoxField' },
}), {});

const headingFieldKeys = [
  'specialAdCategoriesTitle',
  'targetingLabel',
  'websitesTitle',
  'proxyOrProvisioningTitle',
  'utmDetailsHeading',
  'contactInformationTitle',
  'socialAdsSmartOptimizationTitle',
  'timedEventsAndFlights',
  'smartWebsiteClicks',
  'smartRetargetingTitle',
  'socialAdsLeadsAdsTitle',
  'leadEnhancementTitle',
  'socialAdsLeadAdsWithDetailedTargetingTitle',
  'socialCreativeInstructionsTitle',
  'socialAdsSmartReachTitle',
  'socialAdsSnapchatTitle',
  'demographicTargetingHeaderLabel',
  'interestTargetingHeaderLabel',
  'behavioralTargetingHeaderLabel',
];

const headingFields = headingFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'headingTextLabel' },
}), {});

const headingTextLabelKeys = [
  'introSectionOnLeadFormHeading',
  'customConfigurableQuestionsOptionalHeading',
  'thankYouSectionOnLeadFormHeading',
  'adCopyProvidedTitle',
  'adCopyProvidedDescription',
  'fbMockupHeading',
  'guide',
  'detailedTargetingPreferences',
  'selectAll',
  'info', 'info1', 'info2', 'info3',
  'info4', 'info5', 'info6', 'info7',
  'advice', 'advice1', 'advice2', 'advice3',
  'advice4', 'advice5', 'advice6', 'advice7', 'advice8',
  'information', 'information1', 'information2', 'information3',
  'information4', 'information5', 'information6', 'information7', 'information8',
  'canWeStartYourCampaignOn3ppDescription1Label',
  'canWeStartYourCampaignOn3ppDescription3Label',
  'canWeStartYourCampaignOn3ppDescription4Label',
  'canWeStartYourCampaignOn3ppDescription6Label',
  'canWeStartYourCampaignOn3ppDescription7Label',
  'canWeStartYourCampaignOn3ppDescription9Label',
];

const headingTextLabels = headingTextLabelKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textLabel' },
}), {});
const phoneNumberFieldsKeys = [
  'primaryNumberToBeProvisioned',
  'alternativeForwardingNumber',
  'clientContactNumber',
  'bestNumberToReachYou',
];
const phoneNumberFields = phoneNumberFieldsKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'phoneNumberTextField' },
}), {});
const emailTextFieldKeys = [
  'clientContactEmail',
  'clientEmailForCreativeApproval',
  'clientEmailForFacebookAdminAccess',
];

const emailTextFields = emailTextFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'EmailTextWidget' },
}), {});
export const SocialAdsWithSmartOptimizationTechnologyChildUISchema = (data) => ({
  'ui:order': [
    'specialAdCategoriesTitle', 'specialAdCategoriesDescription', 'facebookSpecialAdCategories',
    'targetingLabel', 'targetingType', 'geoTargetingDetails', 'advertiserBusinessAddress', 'radiusMileOrKilometerDistance',
    'addressToUse', 'areaToExcludeFromTargeting', 'audienceTargetingDetails', 'minimumAge', 'maximumAge', 'gender',
    'websitesTitle', 'localiqWebsite', 'websiteLive', 'temporaryLink',
    'proxyOrProvisioningTitle', 'localAreaCodePreference1', 'localAreaCodePreference2', 'primaryNumberToBeProvisioned',
    'doesPrimaryBusinessNumberNeedToForward', 'alternativeForwardingNumber', 'utmDetailsHeading', 'utmImplementString', 'useThisUtm',

    'captureCodeCompatible', 'isClientPartOfAFranchise', 'reachCodeCompatibilityReportLink', 'howToUseTheCapComToolIsAvailableHereLink',
    'websiteTracking', 'websiteTrackingForFranchiseYes', 'canWeStartYourCampaignOn3pp', 'canWeStartYourCampaignOn3ppForFranchiseYes',
    'canWeStartYourCampaignOn3ppDescription1Label', 'canWeStartYourCampaignOn3ppDescription2Label', 'canWeStartYourCampaignOn3ppDescription3Label',
    'canWeStartYourCampaignOn3ppDescription4Label', 'canWeStartYourCampaignOn3ppDescription5Label', 'canWeStartYourCampaignOn3ppDescription6Label',
    'canWeStartYourCampaignOn3ppDescription7Label', 'canWeStartYourCampaignOn3ppDescription8Label', 'canWeStartYourCampaignOn3ppDescription9Label',

    'doesWebsiteHaveCaptchaInstalled', 'captchaPiece', 'doesClientHaveAnotherAccount', 'doNotProxyWebsite', 'helperText', 'webEvents', 'webEventItems', 'webEventUrl', 'webEventName', 'value',

    'contactInformationTitle',
    'clientContactName',
    'clientContactNumber',
    'clientContactEmail',
    'bestNumberToReachYou',
    'isThisTheSameEmailForCreativeApproval',
    'clientEmailForCreativeApproval',
    'isThisTheBestPersonToGrantUsFacebookAdminAccess',
    'clientEmailForFacebookAdminAccess',

    'socialAdsSmartOptimizationTitle',
    'campaignFocus', 'targetingDetail', 'businessWebsiteUrl', 'facebookPageUrl', 'snapchatEnable', 'businessProfile', 'snapchatProfileId', 'landingPageUrl', 'advertiserEmail',
    'timedEventsAndFlights', 'focusOnTimedEvent', 'detailsOfEvent', 'startDate', 'endDate', 'flightedCampaign', 'dateForFlightedCampaign', 'flightedCampaignStartDate', 'flightedCampaignEndDate',
    'clientFirmStartDate', 'firmStartDate', 'clientFirmEndDate', 'firmEndDate', 'smartWebsiteClicks',
    'minimumAgeForSmartWebsite',
    'maximumAgeForSmartWebsite',
    'genderForSmartWebsite',
    'automaticOrOptimizedPlacement', 'adPlacements', 'callToAction', 'smartRetargetingTitle',
    'minimumAgeForSmartRetargeting',
    'maximumAgeForSmartRetargeting',
    'genderForSmartRetargeting',
    'automaticOrOptimizedPlacementForRetargeting', 'callToActionForRetargeting',
    'facebookRetargetingPreference', 'selectPageUrl', 'enableTargetingPeople', 'daysOfRetargetVisitorsOnFacebook', 'websiteRetargetingLink',
    'enableTargetingPeopleLikedFacebookPage', 'searchCampaignFacebookRetargeting',
    'daysOfRetarget', 'searchLeadsLookalikeAudience', 'multiUrlLabel',
    'creativeLandingPage', 'creativeLandUnique',
    'socialAdsSmartReachTitle',
    'minimumAgeForSmartReach',
    'maximumAgeForSmartReach',
    'genderForSmartReach',
    'automaticOrOptimizedPlacementForSmartReach',
    'callToActionForSmartReach',
    'socialAdsSnapchatTitle', 'headline', 'brandAwareness', 'destinationUrl', 'callToActionForSnapchat',
    'socialAdsLeadsAdsTitle', 'minAge', 'maxAge', 'genderForSocialAdsLeadsAds', 'automaticOrOptimizedPlacementForSocialAdsLeadsAds', 'clientAdsForSocialAdsLeadsAds', 'callToActionForSocialAdsLeadsAds',
    'contactFieldsTitle', 'zipCodeNotAvailable', 'note', 'leadEnhancementTitle',
    'wouldYouPreferTheLeadFormInTheLeadAdsObjectiveOptimizeFor',
    'introSectionOnLeadFormHeading',
    'doYouWantToIncludeAnIntroSectionOnYourLeadsForm',
    'customConfigurableQuestionsOptionalHeading',
    'question1',
    'answerToQuestion1',
    'question2',
    'answerToQuestion2',
    'question3',
    'answerToQuestion3',
    'thankYouSectionOnLeadFormHeading',
    'forTheTextFieldsOnTheThankYouScreen',
    'textAdFields',
    'thankYouScreenHeadline',
    'thankYouScreenDescription',
    'callToActionPostLeadSubmissionDoesTheAdvertiserPreferToDirectClientsTo',
    'privacyPolicyUrl',
    'samplePrivacyPolice',
    'advertiserDisclaimerUrl',
    'socialAdsLeadAdsWithDetailedTargetingTitle',
    'guide', 'detailedTargetingPreferences', 'demographicTargeting', 'interestTargeting', 'behavioralTargeting', 'selectAll',
    'demographicTargetingHeaderLabel', 'info', 'info1', 'info2', 'info3', 'info4',
    'info5', 'info6', 'info7',
    'desiredDetailsDemographicTargeting',
    'interestTargetingHeaderLabel', 'advice', 'advice1', 'advice2', 'advice3', 'advice4',
    'advice5', 'advice6', 'advice7', 'advice8',
    'desiredDetailsInterestTargeting',
    'behavioralTargetingHeaderLabel', 'information', 'information1', 'information2', 'information3', 'information4',
    'information5', 'information6', 'information7', 'information8',
    'desiredDetailsBehavioralTargeting',
    'socialCreativeInstructionsTitle',
    'whatTypeOfVisualStrategyDoesYourClientWantToEmploy',
    'whatTypeOfVisualStrategyDoesYourClientWantToEmploySnapchat',
    'willCreativesNeedToLandToUniqueLandingPagesWithinTheCampaignDomain',
    'whatUniqueUrlsShouldTheCreativesLandTo',
    'adCopyProvidedTitle',
    'adCopyProvidedDescription',
    'willTheClientBeProvidingTheirOwnTextCreative',
    'allowEmojis',
    'fbMockupHeading',
    'pleaseEnterTheAdLinkDescription',
    'pleaseEnterTheAdHeadline',
    'pleaseEnterTheAdText',
    'whatProductServiceOfferShouldBePromotedInThisCampaign',
  ],

  canWeStartYourCampaignOn3ppDescription2Label:{
    'ui:widget': 'textLabel',
    'ui:style': {
      marginBottom: '-11px',
    },
  },
  canWeStartYourCampaignOn3ppDescription5Label:{
    'ui:widget': 'textLabel',
    'ui:style': {
      marginBottom: '-11px',
    },
  },
  canWeStartYourCampaignOn3ppDescription8Label:{
    'ui:widget': 'textLabel',
    'ui:style': {
      marginBottom: '-11px',
    },
  },
  reachCodeCompatibilityReportLink: {
    'ui:widget': 'anchorTextLabel',
  },
  howToUseTheCapComToolIsAvailableHereLink: {
    'ui:widget': 'anchorTextLabel',
  },
  isThisTheSameEmailForCreativeApproval: {
    'ui:widget': (props) => (
      <Dropdown {...props}
        errorMessage={t('pages.socialAds.solutionStrategy.contactInformation.isThisTheSameEmailForCreativeApprovalErrorMessage')}
      />
    ),
  },
  isThisTheBestPersonToGrantUsFacebookAdminAccess: {
    'ui:widget': (props) => (
      <Dropdown {...props}
        errorMessage={t('pages.socialAds.solutionStrategy.contactInformation.isThisTheBestPersonToGrantUsFacebookAdminAccessErrorMessage')}
      />
    ),
  },
  temporaryLink: {
    'ui:widget': 'UrlTextWidget',
    'ui:description': t('pages.socialAds.solutionStrategy.websites.temporaryLinkHelper'),
  },
  specialAdCategoriesDescription: { 'ui:widget': 'textLabel' },
  audienceTargetingDetails: { 'ui:widget': 'textLabel' },
  destinationUrl: {
    'ui:widget': 'UrlTextWidget',
  },
  whatTypeOfVisualStrategyDoesYourClientWantToEmploy: {
    'ui:widget': (props) => (
      <Dropdown {...props}
        errorMessage={t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.wouldYouPreferTheLeadFormInTheLeadAdsObjectiveOptimizeForErrorMessage')}
      />
    ),
  },
  whatTypeOfVisualStrategyDoesYourClientWantToEmploySnapchat: {
    'ui:widget': 'dropdown',
  },
  willCreativesNeedToLandToUniqueLandingPagesWithinTheCampaignDomain: {
    'ui:widget': (props) => (
      <Dropdown {...props}
        errorMessage={t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.doYouWantToIncludeAnIntroSectionOnYourLeadsFormErrorMessage')}
      />
    ),
  },
  willTheClientBeProvidingTheirOwnTextCreative: {
    'ui:widget': (props) => (
      <Dropdown {...props}
        errorMessage={t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.forTheTextFieldsOnTheThankYouScreenErrorMessage')}
      />
    ),
  },
  allowEmojis: {
    'ui:widget': (props) => <Dropdown {...props} />,
  },
  samplePrivacyPolice: {
    'ui:widget': 'anchorTextLabel',
    'ui:description': 'for your client\'s reference.\t',
    'ui:style': {
      marginTop: '-15px',
    },
  },
  textAdFields: {
    'ui:widget': 'textLabel',
    'ui:style': {
      marginTop: '10px',
      marginBottom: '-18px',
    },
  },
  answerToQuestion3: {
    'ui:widget': 'textInputField',
    'ui:placeholder': t('pages.socialAds.solutionStrategy.leadEnhancement.answerToQuestion1HelperText'),
    'ui:description': t('pages.socialAds.solutionStrategy.leadEnhancement.helperText'),
  },
  thankYouScreenHeadline: {
    'ui:widget': 'textInputField',
    'ui:description': t('pages.socialAds.solutionStrategy.leadEnhancement.thankYouScreenHeadlineHelper'),
  },
  thankYouScreenDescription: {
    'ui:widget': 'textInputField',
    'ui:description': t('pages.socialAds.solutionStrategy.leadEnhancement.thankYouScreenDescriptionHelper'),
  },
  privacyPolicyUrl: {
    'ui:widget': 'UrlTextWidget',
    'ui:description': t('pages.socialAds.solutionStrategy.leadEnhancement.privacyPolicyURLHelper'),
  },
  advertiserDisclaimerUrl: {
    'ui:widget': 'UrlTextWidget',
    'ui:description': t('pages.socialAds.solutionStrategy.leadEnhancement.advertiserDisclaimerURLHelper'),
  },
  wouldYouPreferTheLeadFormInTheLeadAdsObjectiveOptimizeFor: {
    'ui:widget': 'dropdown',
  },
  doYouWantToIncludeAnIntroSectionOnYourLeadsForm: {
    'ui:widget': 'dropdown',
  },
  forTheTextFieldsOnTheThankYouScreen: {
    'ui:widget': 'dropdown',
  },
  callToActionPostLeadSubmissionDoesTheAdvertiserPreferToDirectClientsTo: {
    'ui:widget': 'dropdown',
  },
  zipCodeNotAvailable: {
    'ui:widget': 'textLabel',
    'ui:help': t('pages.socialAds.solutionStrategy.socialAdsLeadAds.zipCodeNotAvailableHelper'),
  },
  note: {
    'ui:widget': 'textLabel',
  },
  selectPageUrl: {
    'ui:widget': 'textInputField',
    'ui:description': t('pages.socialAds.solutionStrategy.smartRetargeting.selectPageURLHelper'),
  },
  enableTargetingPeople: {
    'ui:widget': 'dropdown',
    'ui:description': t('pages.socialAds.solutionStrategy.smartRetargeting.enableTargetingPeopleNoteHelper'),
  },
  websiteRetargetingLink: {
    'ui:widget': 'UrlTextWidget',
    'ui:description': t('pages.socialAds.solutionStrategy.smartRetargeting.websiteRetargetingLinkHelper'),
  },
  enableTargetingPeopleLikedFacebookPage: {
    'ui:widget': 'dropdown',
    'ui:description': t('pages.socialAds.solutionStrategy.smartRetargeting.enableTargetingPeopleLikedFacebookPageHelper'),
  },
  multiUrlLabel: {
    'ui:widget': 'textLabel',
  },
  creativeLandUnique: {
    'ui:widget': 'textInputField',
    'ui:description': t('pages.socialAds.solutionStrategy.smartRetargeting.uniqueURLHelper'),
  },
  webEventItems: {
    'ui:widget': (props) => <AddWebEvents {...props} data={data} />,
  },
  helperText: { 'ui:widget': 'textLabel' },
  businessWebsiteUrl: {
    'ui:widget': 'UrlTextWidget',
    'ui:description': t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.businessWebsiteURLHelperLabel'),
  },
  facebookPageUrl: {
    'ui:widget': 'UrlTextWidget',
    'ui:description': t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.facebookPageURLHelperLabel'),
  },
  landingPageUrl: {
    'ui:widget': 'UrlTextWidget',
    'ui:description': t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.landingPageURLHelperLabel'),
  },
  advertiserEmail: {
    'ui:widget': 'EmailTextWidget',
    'ui:description': t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.advertiserEmailHelperLabel'),
  },
  dateForFlightedCampaign: {
    'ui:widget': 'textLabel',
    'ui:style': {
      marginTop: '12px',
    },
  },
  maxAge: {
    'ui:widget': (props) => (
      <Dropdown {...props}
        errorMessage={t('pages.socialAds.solutionStrategy.specialAdCategories.maximumAgeErrorMessage')}
      />
    ),
  },
  maximumAge: {
    'ui:widget': (props) => (
      <Dropdown {...props}
        errorMessage={t('pages.socialAds.solutionStrategy.specialAdCategories.maximumAgeErrorMessage')}
      />
    ),
  },
  maximumAgeForSmartWebsite: {
    'ui:widget': (props) => (
      <Dropdown {...props}
        errorMessage={t('pages.socialAds.solutionStrategy.specialAdCategories.maximumAgeErrorMessage')}
      />
    ),
  },
  maximumAgeForSmartRetargeting: {
    'ui:widget': (props) => (
      <Dropdown {...props}
        errorMessage={t('pages.socialAds.solutionStrategy.specialAdCategories.maximumAgeErrorMessage')}
      />
    ),
  },
  maximumAgeForSmartReach: {
    'ui:widget': (props) => (
      <Dropdown {...props}
        errorMessage={t('pages.socialAds.solutionStrategy.specialAdCategories.maximumAgeErrorMessage')}
      />
    ),
  },
  addressToUse: { "ui:widget": "addressField" },
  ...dropDownFields,
  ...textFields,
  ...dateFields,
  ...checkBoxesFields,
  ...headingFields,
  ...headingTextLabels,
  ...textAreaFields,
  ...phoneNumberFields,
  ...emailTextFields,
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SocialAdsWithSmartOptimizationTechnologyChildValidate = (formData: any, errors: any, uiSchema: any) => {
  validateCharacterLimit(formData, 'thankYouScreenHeadline', 55, errors, t);
  validateCharacterLimit(formData, 'thankYouScreenDescription', 300, errors, t);
  validateCharacterLimit(formData, 'question1', 300, errors, t);
  validateCharacterLimit(formData, 'question2', 300, errors, t);
  validateCharacterLimit(formData, 'question3', 300, errors, t);
  validateCharacterLimit(formData, 'pleaseEnterTheAdLinkDescription', 250, errors, t);
  validateCharacterLimit(formData, 'pleaseEnterTheAdHeadline', 75, errors, t);
  validateCharacterLimit(formData, 'pleaseEnterTheAdText', 250, errors, t);
  if (formData?.localiqWebsite === '') {
    errors.localiqWebsite.addError(t('pages.socialAds.solutionStrategy.websites.localiqWebsiteError'));
    errors.addError(true);
  }
  if (formData?.primaryNumberToBeProvisioned === '') {
    errors.primaryNumberToBeProvisioned.addError(t('pages.socialAds.solutionStrategy.proxyProvisioning.primaryNumberToBeProvisionedErrorMsg'));
    errors.addError(true);
  }
  if (formData?.utmImplementString === '') {
    errors.utmImplementString.addError(t('pages.socialAds.solutionStrategy.proxyProvisioning.inputError.implementUtmString'));
    errors.addError(true);
  }
  if (formData?.doesWebsiteHaveCaptchaInstalled === '') {
    errors.doesWebsiteHaveCaptchaInstalled.addError(t('pages.socialAds.solutionStrategy.proxyProvisioning.inputError.captchaInstalled'));
    errors.addError(true);
  }
  if (formData?.doesClientHaveAnotherAccount === '') {
    errors.doesClientHaveAnotherAccount.addError(t('pages.socialAds.solutionStrategy.proxyProvisioning.inputError.anotherAccountMAIDError'));
    errors.addError(true);
  }
  if (formData?.clientContactName === '') {
    errors.clientContactName.addError(t('pages.socialAds.solutionStrategy.contactInformation.clientContactNameErrorMessage'));
    errors.addError(true);
  }
  if (formData?.clientContactEmail === '') {
    errors.clientContactEmail.addError(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'));
    errors.addError(true);
  }
  if (formData?.webEvents === '') {
    errors.webEvents.addError(t('pages.shoppingAds.solutionStrategy.webEventErrorMessage'));
    errors.addError(true);
  }
  if (formData?.campaignFocus === '') {
    errors.campaignFocus.addError(t('pages.shoppingAds.solutionStrategy.campaignFocusErrorMessage'));
    errors.addError(true);
  }
  if (formData?.advertiserEmail === '') {
    errors.advertiserEmail.addError(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'));
    errors.addError(true);
  }
  if (formData?.clientContactEmail !== '' && !validateEmail(formData?.clientContactEmail)) {
    errors.clientContactEmail.addError(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'));
    errors.addError(true);
  }
  if (formData?.clientEmailForCreativeApproval !== undefined && formData?.clientEmailForCreativeApproval !== '' && !validateEmail(formData?.clientEmailForCreativeApproval)) {
    errors.clientEmailForCreativeApproval.addError(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'));
    errors.addError(true);
  }
  if (formData?.clientEmailForFacebookAdminAccess !== undefined && formData?.clientEmailForFacebookAdminAccess !== '' && !validateEmail(formData?.clientEmailForFacebookAdminAccess)) {
    errors.clientEmailForFacebookAdminAccess.addError(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'));
    errors.addError(true);
  }
  if (formData?.advertiserEmail !== undefined && formData?.advertiserEmail !== '' && !validateEmail(formData?.advertiserEmail)) {
    errors.advertiserEmail.addError(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'));
    errors.addError(true);
  }
  if (formData?.websiteLive === 'No, the ReachSite is Not live.') {
    if (formData?.temporaryLink !== '' && !validateUrl(formData?.temporaryLink)) {
      errors.temporaryLink.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
      errors.addError(true);
    }
  }
  if (formData?.brandAwareness === 'Show Destination URL') {
    if (formData?.destinationUrl !== '' && !validateUrl(formData?.destinationUrl)) {
      errors.destinationUrl.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
      errors.addError(true);
    }
  }
  if (formData?.campaignFocus === 'Contacts' || formData?.campaignFocus === 'Engagement') {
    if (formData?.privacyPolicyUrl !== '' && !validateUrl(formData?.privacyPolicyUrl)) {
      errors.privacyPolicyUrl.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
      errors.addError(true);
    }
    if (formData?.advertiserDisclaimerUrl !== '' && !validateUrl(formData?.advertiserDisclaimerUrl)) {
      errors.advertiserDisclaimerUrl.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
      errors.addError(true);
    }
  }
  if (formData?.websiteRetargetingLink !== '' && !validateUrl(formData?.websiteRetargetingLink)) {
    errors.websiteRetargetingLink.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
    errors.addError(true);
  }
  if (formData?.campaignFocus === 'Contacts' || formData?.campaignFocus === 'Reach' || formData?.campaignFocus === 'Engagement') {
    if (formData?.businessWebsiteUrl && (formData?.businessWebsiteUrl === '' || !validateUrl(formData?.businessWebsiteUrl))) {
      errors.businessWebsiteUrl.addError(t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.businessWebsiteUrlError'));
      errors.addError(true);
    }
    if (formData?.facebookPageUrl && (formData?.facebookPageUrl === '' || !urlValidationParameter.some(url => formData?.facebookPageUrl?.startsWith(url)))) {
      errors.facebookPageUrl.addError(t('pages.socialAds.solutionStrategy.socialAdsLeadAds.facebookPageUrlErrorMessage'));
      errors.addError(true);
    }
    if (formData?.landingPageUrl && (formData?.landingPageUrl === '' || !validateUrl(formData?.landingPageUrl))) {
      errors.landingPageUrl.addError(t('pages.custom.solutions.common.landingPageURLError'));
      errors.addError(true);
    }
  }
  if (new Date(formData?.endDate) < new Date(formData?.startDate)) {
    errors.endDate.addError(t('pages.socialAds.socialAdsWebClicksSection.inputError.endDateErrorMessage'));
    errors.addError(true);
  }
  if (new Date(formData?.flightedCampaignEndDate) < new Date(formData?.flightedCampaignStartDate)) {
    errors.flightedCampaignEndDate.addError(t('pages.socialAds.socialAdsWebClicksSection.inputError.endDateErrorMessage'));
    errors.addError(true);
  }
  if (new Date(formData?.firmEndDate) < new Date(formData?.firmStartDate)) {
    errors.firmEndDate.addError(t('pages.socialAds.socialAdsWebClicksSection.inputError.endDateErrorMessage'));
    errors.addError(true);
  }
  if (formData?.advertiserBusinessAddress == "No") {
    if (formData?.addressToUse?.trim().length < 1) {
      errors.addError(true);
      errors.addressToUse.addError(
          t("pages.socialAds.solutionStrategy.targeting.businessAddressError")
      );
    }
  }
  validateAgeRange(formData, 'minimumAge', 'maximumAge', 'maximumAge', errors, t);
  validateAgeRange(formData, 'minimumAgeForSmartWebsite', 'maximumAgeForSmartWebsite', 'maximumAgeForSmartWebsite', errors, t);
  validateAgeRange(formData, 'minimumAgeForSmartRetargeting', 'maximumAgeForSmartRetargeting', 'maximumAgeForSmartRetargeting', errors, t);
  validateAgeRange(formData, 'minimumAgeForSmartReach', 'maximumAgeForSmartReach', 'maximumAgeForSmartReach', errors, t);
  validateAgeRange(formData, 'minAge', 'maxAge', 'maxAge', errors, t);
  return errors;
};
