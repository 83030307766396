import { Urls } from 'navigation/Urls';

export const xmoVariantsConstants = {
  1: {
    label: 'Solution Strategy (Lead Gen)',
    items: [
      Urls.GeneralQuestions,
      Urls.XmoDetails,
      '',
      Urls.XmoSem,
      Urls.XmoSocialSmartOptimization,
      Urls.XmoWebsiteClicks,
      Urls.XmoLeadAds,
      Urls.XmoRetargeting,
      Urls.XmoChat,
    ],
  },
  2: {
    label: 'Solution Strategy (First Party)',
    items: [
      Urls.GeneralQuestions,
      Urls.XmoDetails,
      '',
      Urls.XmoSocialSmartOptimization,
      Urls.XmoWebsiteClicks,
      Urls.XmoRetargeting,
      Urls.XmoTargetedDisplay,
      Urls.XmoSnapchat,
    ],
  },
  3: {
    label: 'Solution Strategy (Brand Amplifier)',
    items: [
      Urls.GeneralQuestions,
      Urls.XmoDetails,
      Urls.XmoBrandAmplifier,
      '',
      Urls.XmoSocialSmartOptimization,
      Urls.XmoWebsiteClicks,
      Urls.XmoRetargeting,
      Urls.XmoYouTube,
      Urls.XmoReach,
      Urls.XmoTargetedDisplay,
    ],
  },
};

const returnXmoVariantSubSteps = (item, xmoVariants) => {
  const variant = xmoVariants.filter(variant => variant.id === item?.builtFor)[0];
  const itemCopy = item?.subSteps?.filter(step => xmoVariantsConstants[variant.variant].items
    .includes(step?.url as any));

  if (variant.variant === 3) {
    const brandAmplifierItem = itemCopy?.filter(step => step.label !== 'Solution Strategy');
    return brandAmplifierItem;
  }
  return itemCopy;
};

const returnXmoVariantLabel = (item, xmoVariants) => {
  const variant = xmoVariants.filter(variant => variant.id === item?.builtFor)[0];
  return xmoVariantsConstants[variant.variant].label;
};

export const setXmoVariantMenu = (menu, xmoVariants) => {
  const menuCopy = menu?.map(item => ({
    ...item,
    subSteps: item?.offeringType === 'XMO'
      ? returnXmoVariantSubSteps(item, xmoVariants)
      : item?.subSteps,
  }));

  return menuCopy.map(item => ({
    ...item,
    subSteps: Array.isArray(item.subSteps) ? (item.subSteps.map(step => ({
      ...step,
      label: (item.offeringType === 'XMO' && step.label === 'Solution Strategy')
        ? returnXmoVariantLabel(item, xmoVariants)
        : step.label,
    }))) : null,
  }));
};
