import React, {useEffect, useMemo, useState} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import i18n from 'i18n/i18n';

const {t} = i18n;

type Tracking = {
  phoneNumberOnWebsiteToBeMasked: string;
  destinationOrTerminationNumber: string;
  trackingNumberType: string;
  tollFreeNumberFeesBeenCharged: string;
  regionForTrackingNumber: string;
};

const initTrackingState = (): Tracking => ({
  phoneNumberOnWebsiteToBeMasked: '',
  destinationOrTerminationNumber: '',
  trackingNumberType: '',
  tollFreeNumberFeesBeenCharged: '',
  regionForTrackingNumber: '',
});

const trackingNumberTypes = [
  t('pages.ausProduct.trackingNumber1Details.localGeo'),
  t('pages.ausProduct.trackingNumber1Details.mobile'),
  t('pages.ausProduct.trackingNumber1Details.phone'),
  t('pages.ausProduct.trackingNumber1Details.1300Au'),
  t('pages.ausProduct.trackingNumber1Details.1800Au'),
  t('pages.ausProduct.trackingNumber1Details.0800Nz'),
];

const tollFreeNumberFees = [
  t('pages.ausProduct.tracking.yes'),
  t('pages.ausProduct.tracking.no'),
];

export default function TrackingNumberDetails({data, ...props}: any) {
  const number = data?.totalTrackingNumbersRequired === '6+' ? 6 : data?.totalTrackingNumbersRequired || 1;
  const theme = useTheme();
  const [trackings, setTrackings] = useState<Tracking[]>(() => {
    return props.value?.length ? props.value : Array.from({length: number}, initTrackingState);
  });
  const [open, setOpen] = useState(false);
  const [tracking, setTracking] = useState<Tracking>(initTrackingState());
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const currentWindowCount = useMemo(() => (editIndex !== null ? editIndex + 1 : trackings.length + 1), [editIndex, trackings]);

  useEffect(() => {
    if (validatePhoneNumber(tracking.phoneNumberOnWebsiteToBeMasked) ||
        !tracking.destinationOrTerminationNumber ||
        !tracking.trackingNumberType ||
        !tracking.tollFreeNumberFeesBeenCharged ||
        !tracking.regionForTrackingNumber) {
      setIsSubmit(false)
    } else {
      setIsSubmit(true)
    }
  }, [errors, tracking]);

  const handleClickOpen = (index: number | null = null) => {
    if (index !== null) {
      setTracking(trackings[index]);
      setEditIndex(index);
    } else {
      setTracking(initTrackingState());
      setEditIndex(null);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setErrors({});
  };

  const validateField = (name: string, value: string) => {
    if (name === 'phoneNumberOnWebsiteToBeMasked') {
      return validatePhoneNumber(value) ? t('pages.ausProduct.trackingNumber1Details.phoneNumberErrorMessage') : null;
    }
    if (value === "") {
      return t('pages.ausProduct.tracking.requiredFieldErrorMessage');
    } else {
      return null
    }
  };

  const handleChange = (e: any) => {
    const {name, value} = e.target;
    let validationError: any;
    if (name === 'phoneNumberOnWebsiteToBeMasked') {
      const formattedInput = value.replace(/[^\d\s()-]/g, '');
      setTracking((prev) => ({...prev, [name]: formattedInput}));
      validationError = validateField(name, formattedInput);
    } else {
      setTracking((prev) => ({...prev, [name]: value}));
      validationError = validateField(name, value);
    }
    setErrors((prevErrors) => ({...prevErrors, [name]: validationError}));
  };

  const handleDropdownChangeTrackingNumberType = (event: any) => {
    const value = event.target.value;
    setTracking((prev) => ({...prev, trackingNumberType: value}));
    const validationError = validateField('trackingNumberType', value);
    setErrors((prevErrors) => ({...prevErrors, trackingNumberType: validationError}));
  };

  const handleDropdownChangeTollFreeNumberFeesBeenCharged = (event: any) => {
    const value = event.target.value;
    setTracking((prev) => ({...prev, tollFreeNumberFeesBeenCharged: value}));
    const validationError = validateField('tollFreeNumberFeesBeenCharged', value);
    setErrors((prevErrors) => ({...prevErrors, tollFreeNumberFeesBeenCharged: validationError}));
  };

  const validatePhoneNumber = (number) => {
    const phoneRegex = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
    const filteredNumber = number?.startsWith('+61') ? number?.replace('+61', '')
        : number
    if (filteredNumber === "") {
      return true;
    }
    return !phoneRegex.test(filteredNumber);
  };

  const handleSubmit = () => {
    let items = [...trackings];
    const newItem = {...tracking};
    const idx = editIndex !== null ? editIndex : items.length;
    items.splice(idx, 1, newItem);
    const addedItems = items.map((item) => {
      return {
        ...item,
        phoneNumberOnWebsiteToBeMasked: !item?.phoneNumberOnWebsiteToBeMasked.startsWith('+61') ?
            `+61${item?.phoneNumberOnWebsiteToBeMasked}` : item?.phoneNumberOnWebsiteToBeMasked
      }
    })
    setTrackings(addedItems);
    setTracking(initTrackingState());
    handleClose();
  };

  const handleDelete = (index: number) => {
    setTrackings((prevItems) => prevItems.filter((_, i) => i !== index));
  };

  useEffect(() => {
    props.onChange(trackings.map((item) => ({...item})));
  }, [trackings]);


  useEffect(() => {
    const currentTrackings = [...trackings];
    if (currentTrackings.length < number) {
      const emptyStatesToAdd = Array.from({length: number - currentTrackings.length}, initTrackingState);
      setTrackings([...currentTrackings, ...emptyStatesToAdd]);
    }
  }, [number]);

  return (
      <Grid item xs={9} my={3}>
        <Grid>
          <Box>
            {trackings.map((item, index) => (
                <Grid key={index}>
                  <Box
                      mb={2}
                      sx={{
                        padding: '10px',
                        display: 'flex',
                        fontSize: '15px',
                        alignItems: 'center',
                        backgroundColor: '#ffffff',
                        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
                      }}
                  >
                    <Box
                        mb={1}
                        sx={{
                          flexGrow: 1,
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginRight: '20%',
                        }}
                    >
                      <Box>
                        <Typography variant="h6" sx={{marginBottom: '10px', fontWeight: 'bold'}}>
                          {t('pages.ausProduct.trackingNumber1Details.trackingNumber', {count: index + 1})}
                        </Typography>
                        <div style={{marginBottom: '10px'}}>
                          <span style={{fontWeight: 'bold'}}>
                            {t('pages.ausProduct.trackingNumber1Details.phoneNumberOnWebsiteToBeMaskedLabel')}:
                          </span>
                          <span style={{marginLeft: '5px', wordBreak: 'break-word'}}>
                            {trackings[index]?.phoneNumberOnWebsiteToBeMasked || ''}
                          </span>
                        </div>
                        <div style={{marginBottom: '10px'}}>
                          <span style={{fontWeight: 'bold'}}>
                            {t('pages.ausProduct.trackingNumber1Details.destinationOrTerminationNumberLabel')}:
                          </span>
                          <span style={{marginLeft: '5px', wordBreak: 'break-word'}}>
                            {trackings[index]?.destinationOrTerminationNumber || ''}
                          </span>
                        </div>
                        <div style={{marginBottom: '10px'}}>
                          <span style={{fontWeight: 'bold'}}>
                            {t('pages.ausProduct.trackingNumber1Details.trackingNumberTypeLabel')}:
                          </span>
                          <span style={{marginLeft: '5px', wordBreak: 'break-word'}}>
                            {trackings[index]?.trackingNumberType || ''}
                          </span>
                        </div>
                        <div style={{marginBottom: '10px'}}>
                          <span style={{fontWeight: 'bold'}}>
                            {t('pages.ausProduct.trackingNumber1Details.tollFreeNumberFeesBeenChargedLabel')}:
                          </span>
                          <span style={{marginLeft: '5px', wordBreak: 'break-word'}}>
                            {trackings[index]?.tollFreeNumberFeesBeenCharged || ''}
                          </span>
                        </div>
                        <div style={{marginBottom: '10px'}}>
                          <span style={{fontWeight: 'bold'}}>
                            {t('pages.ausProduct.trackingNumber1Details.regionForTrackingNumberLabel')}:
                          </span>
                          <span style={{marginLeft: '5px', wordBreak: 'break-word'}}>
                            {trackings[index]?.regionForTrackingNumber || ''}
                          </span>
                        </div>
                      </Box>
                    </Box>
                    <IconButton onClick={() => handleClickOpen(index)}>
                      <EditIcon/>
                    </IconButton>
                    {
                        index >= number && <IconButton onClick={() => handleDelete(index)}>
                          <DeleteIcon/>
                        </IconButton>
                    }
                  </Box>
                </Grid>
            ))}
            {
                number >= 6 && <Box
                    sx={{
                      padding: '20px 20px 20px 0',
                      backgroundColor: theme.palette.primary.light,
                    }}
                >
                  <Button startIcon={<AddIcon/>} onClick={() => handleClickOpen()}>
                    {t('pages.ausProduct.trackingNumber1Details.addTrackingNumberDetails')}
                  </Button>
                </Box>
            }
            <Dialog
                open={open}
                onClose={handleClose}
                sx={{
                  '& .MuiDialog-paper': {
                    height: '70vh',
                    minWidth: 'lg',
                    margin: 'auto',
                  }
                }}
            >
              <DialogTitle>{t('pages.ausProduct.trackingNumber1Details.trackingNumber', {count: currentWindowCount})}</DialogTitle>
              <DialogContent>
                <Typography variant="subtitle1" my={1} sx={{fontWeight: 'bold'}}>
                  {t('pages.ausProduct.trackingNumber1Details.phoneNumberOnWebsiteToBeMaskedLabel')}
                </Typography>
                <TextField
                    fullWidth
                    variant="outlined"
                    type="tel"
                    name="phoneNumberOnWebsiteToBeMasked"
                    value={tracking?.phoneNumberOnWebsiteToBeMasked.startsWith('+61') ?
                        tracking?.phoneNumberOnWebsiteToBeMasked.replace('+61', '')
                        : tracking?.phoneNumberOnWebsiteToBeMasked}
                    onChange={handleChange}
                    onBlur={handleChange}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+61</InputAdornment>,
                    }}
                    inputProps={{
                      maxLength: 15,
                    }}
                    error={!!errors.phoneNumberOnWebsiteToBeMasked}
                    helperText={errors.phoneNumberOnWebsiteToBeMasked}
                />
                <Typography variant="subtitle1" mt={2} sx={{fontWeight: 'bold'}}>
                  {t('pages.ausProduct.trackingNumber1Details.destinationOrTerminationNumberLabel')}
                </Typography>
                <TextField
                    margin="dense"
                    name="destinationOrTerminationNumber"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={tracking.destinationOrTerminationNumber}
                    onChange={handleChange}
                    onBlur={handleChange}
                    error={!!errors.destinationOrTerminationNumber}
                    helperText={errors.destinationOrTerminationNumber}
                />
                <Typography sx={{fontFamily: 'Unify Sans', color: '#716F6F'}} variant="subtitle2">
                  {t('pages.ausProduct.trackingNumber1Details.destinationOrTerminationNumberDescription')}
                </Typography>
                <FormControl
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    error={!!errors.trackingNumberType}
                >
                  <Typography variant="subtitle1" mb={1} sx={{fontWeight: 'bold'}}>
                    {t('pages.ausProduct.trackingNumber1Details.trackingNumberTypeLabel')}
                  </Typography>

                  <Select
                      value={tracking.trackingNumberType}
                      onChange={handleDropdownChangeTrackingNumberType}
                      onBlur={handleDropdownChangeTrackingNumberType}
                      displayEmpty
                  >
                    {trackingNumberTypes.map((type, idx) => (
                        <MenuItem key={idx} value={type}>
                          {type}
                        </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {errors.trackingNumberType
                        ? errors.trackingNumberType
                        : ''
                    }
                  </FormHelperText>
                </FormControl>
                <Typography sx={{fontFamily: 'Unify Sans', color: '#716F6F'}} variant="subtitle2">
                  {t('pages.ausProduct.trackingNumber1Details.trackingNumberTypeDescription')}
                </Typography>
                <FormControl
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    error={!!errors.tollFreeNumberFeesBeenCharged}
                >
                  <Typography variant="subtitle1" mb={1} sx={{fontWeight: 'bold'}}>
                    {t('pages.ausProduct.trackingNumber1Details.tollFreeNumberFeesBeenChargedLabel')}
                  </Typography>

                  <Select
                      value={tracking.tollFreeNumberFeesBeenCharged}
                      onChange={handleDropdownChangeTollFreeNumberFeesBeenCharged}
                      onBlur={handleDropdownChangeTollFreeNumberFeesBeenCharged}
                      displayEmpty
                  >
                    {tollFreeNumberFees.map((type, idx) => (
                        <MenuItem key={idx} value={type}>
                          {type}
                        </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {errors.tollFreeNumberFeesBeenCharged
                        ? errors.tollFreeNumberFeesBeenCharged
                        : ''
                    }
                  </FormHelperText>
                </FormControl>
                <Typography variant="subtitle1" mt={2} sx={{fontWeight: 'bold'}}>
                  {t('pages.ausProduct.trackingNumber1Details.regionForTrackingNumberLabel')}
                </Typography>
                <TextField
                    margin="dense"
                    name="regionForTrackingNumber"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={tracking.regionForTrackingNumber}
                    onChange={handleChange}
                    onBlur={handleChange}
                    error={!!errors.regionForTrackingNumber}
                    helperText={errors.regionForTrackingNumber}
                />
                <Typography sx={{fontFamily: 'Unify Sans', color: '#716F6F'}} variant="subtitle2">
                  {t('pages.ausProduct.trackingNumber1Details.regionForTrackingNumberDescription')}
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                    style={{border: "1px solid #0046FF"}}
                    onClick={handleClose}
                    color="primary"
                >
                  {t('pages.ausProduct.trackingNumber1Details.cancel')}
                </Button>
                <Button
                    style={{
                      backgroundColor: isSubmit ? "#0046FF" : "#A8A8A8",
                      color: "white",
                      cursor: isSubmit ? "pointer" : "not-allowed",
                    }}
                    onClick={handleSubmit}
                    disabled={!isSubmit}
                >
                  {t('pages.ausProduct.trackingNumber1Details.save')}
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Grid>
      </Grid>
  );
}