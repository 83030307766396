import { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import GridItem from 'components/grid/GridItem';
import { useParams } from 'react-router';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { Urls } from 'navigation/Urls';
import { useAppDispatch } from 'services/redux/store';
import { getEmailHosting, getEmailHostingEmails, selectEmailHostingOrderItemId } from 'services/addons/addonsSlice';
import { useSelector } from 'react-redux';
import { renderStatusPill } from 'pages/orders/OrdersTable';
import { editButton, displayNotRequiredField } from 'pages/overview/Overview';
import { selectOrderItems } from 'services/ordersSlice';
import { headingStyle } from '../Business/BusinessSection';
import { actionItems } from '../common/actionItems';
import { findAddonId } from './masterAddonSection';

const mapHostingOption = {
  new_hosting: 'New Hosting',
  migrate_hosting: 'Migrate Hosting',
};

export default function EmailHostingSection({
  product, isEditable, showActionItems, openBusinessAndSalesAgent,
}: any) {
  const appDispatch = useAppDispatch();
  const navigate = useNavigationHandler();
  const { orderId } = useParams();
  const orderItems = useSelector(selectOrderItems);
  const emailHostingOrderId = useSelector(selectEmailHostingOrderItemId);
  const [emailHostingData, setEmailHostingData] = useState({} as any);
  const [emailHostingEmails, setEmailHostingEmails] = useState([]);
  const [addonStatus, setAddonStatus] = useState('');
  const addonName = 'emailHosting';

  useEffect(() => {
    if (orderId) {
      appDispatch(getEmailHostingEmails({ orderId })).then((response) => setEmailHostingEmails(response.payload));
    }
  }, [orderId]);

  useEffect(() => {
    if (emailHostingOrderId) {
      appDispatch(getEmailHosting({ orderItemId: emailHostingOrderId }))
        .then((response) => setEmailHostingData(response.payload));
    }
  }, [emailHostingOrderId]);

  useEffect(() => {
    if (orderItems?.content?.length && addonName) {
      const currentAddon = orderItems.content
        .filter((item) => item.offeringType?.toLowerCase() === addonName?.toLowerCase());
      if (currentAddon.length > 0) setAddonStatus(currentAddon[0].status);
    }
  }, [orderItems]);

  return (
    <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid #000000' }}>
      <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
        Email Hosting
        {!isEditable && renderStatusPill(addonStatus)}
      </Typography>

      <Box>

        {/* Email Hosting */}
        <Box sx={{ display: 'flex', margin: '10px 0' }}>
          {editButton(isEditable, () =>
            navigate.to(Urls.EmailHosting, product.id))}
          <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Email Hosting Details</Typography>
        </Box>
        <GridItem sizes={[6, 6]}>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Hosting Option</Typography>
              <Typography>{mapHostingOption[emailHostingData?.hostingChoice]}</Typography>
            </Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Email Archiving Required</Typography>
              <Typography sx={{ textTransform: 'capitalize' }}>{emailHostingData?.emailArchiving}</Typography>
            </Box>
          </Box>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Emails</Typography>
              {emailHostingEmails?.map(email => (
                <Typography key={email.email}>
                  {`${email.name} - ${email.email}`}
                </Typography>
              ))}
            </Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Additional Notes/Instructions</Typography>
              {displayNotRequiredField('additional notes/instructions', emailHostingData?.additionalNotes)}
            </Box>
          </Box>
        </GridItem>

        {/* Actions items */}
        {showActionItems && actionItems(false, findAddonId(orderItems, addonName), false, openBusinessAndSalesAgent)}
      </Box>
    </GridItem>
  );
}
