import i18n from "i18n/i18n";

const {t} = i18n;

export function targetingTypeRadiusDistanceDropdownItems() {
  let options = [];
  for (let i = 5; i <= 100; i += 5) {
    options.push({value: i, description: i});
  }
  return options;
}

export const targetingTypeDropdownItems = [
  {value: 'Radius', description: t('pages.xmo.solutionsStrategy.inputValues.radius')},
  {value: 'City', description: t('pages.xmo.solutionsStrategy.inputValues.city')},
  {value: 'DMA', description: t('pages.xmo.solutionsStrategy.inputValues.dma')},
  {value: 'National', description: t('pages.xmo.solutionsStrategy.inputValues.national')},
  {value: 'Multi-Radius', description: t('pages.xmo.solutionsStrategy.inputValues.multiRadius')},
  {value: 'Zip/Postal Code', description: t('pages.xmo.solutionsStrategy.inputValues.zipPostalCode')},
  {value: 'State', description: t('pages.xmo.solutionsStrategy.inputValues.state')},
];