import { Grid, Typography, Box } from '@mui/material';
import GridItem from 'components/grid/GridItem';
import { getAddons, getMasterAddonDetails } from 'services/addons/addonsSlice';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'services/redux/store';
import { editButton } from 'pages/overview/Overview';
import OrderTracker from 'pages/orders/OrderTracker';
import { useParams } from 'react-router';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { Urls } from 'navigation/Urls';
import { headingStyle } from '../Business/BusinessSection';
import VideoBackgroundSection from './videoBackgroundSection';
import EmailHostingSection from './emailHostingSection';
import ExpandableMenuSection from './expandableMenuSection';
import OnlineBookingSection from './onlineBookingSection';
import PaymentFormsSection from './paymentFormsSection';
import HipaaFormsSection from './hipaaFormsSection';
import PopUpSection from './popUpSection';

export const findAddonId = (orderItems, addonName) => {
  if (orderItems?.content?.length && addonName) {
    const currentAddon = orderItems.content
      .filter((item) => item.offeringType?.toLowerCase() === addonName?.toLowerCase());

    if (currentAddon.length > 0) return currentAddon[0].id;
  }

  return 0;
};

export default function MasterAddonSection({
  product, isEditable, showTracker, openBusinessAndSalesAgent,
}: any) {
  const { orderId } = useParams();
  const appDispatch = useAppDispatch();
  const navigate = useNavigationHandler();
  const [masterAddonDetails, setMasterAddonDetails] = useState({} as any);
  const [addonDetailsContent, setAddonDetailsContent] = useState({} as any);

  useEffect(() => {
    if (product?.id) {
      appDispatch(getAddons(orderId)).then((response) => setMasterAddonDetails(response.payload));
      appDispatch(getMasterAddonDetails(product.id)).then((response) =>
        setAddonDetailsContent(response.payload?.at(0)));
    }
  }, [product]);

  const addonProps = { isEditable, showActionItems: showTracker, openBusinessAndSalesAgent };

  return (
    <Grid container sx={{ borderTop: '1px solid #716F6F' }}>
      {/* Status Tracker */}
      {showTracker && (
      <GridItem sizes={[12, 12]} marginBottom={8} sx={{ padding: '40px 0 0', borderBottom: '1px solid #000000' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {editButton(isEditable, () => {})}
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Order Status</Typography>
        </Box>
        <Box>
          <OrderTracker
            key={product?.id}
            status={product?.trackerStatus}
            productName={product?.offeringType}
            product={product}
          />
        </Box>
      </GridItem>
      )}

      {/* Details */}
      <GridItem sizes={[12, 6, 6]} marginBottom={3} sx={{ padding: '20px 0 0' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {editButton(isEditable, () =>
            navigate.to(Urls.WebsiteUrl, product.id))}
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Details</Typography>
        </Box>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Localiq site</Typography>
            <Typography>{addonDetailsContent?.localiqWebsite}</Typography>
          </Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Localiq website live</Typography>
            <Typography>{addonDetailsContent?.localiqWebsiteLive}</Typography>
          </Box>
        </Box>
        <Box>
          {(addonDetailsContent?.localiqWebsiteLive === 'Yes' && addonDetailsContent?.businessWebsiteUrl !== 'manual')
           && (
           <Box mb={2}>
             <Typography sx={headingStyle} color="primary">Website URL</Typography>
             <Typography>{addonDetailsContent?.businessWebsiteUrl}</Typography>
           </Box>
           )}
          {(addonDetailsContent?.businessWebsiteUrl === 'manual') && (
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Website URL</Typography>
            <Typography>{addonDetailsContent?.manualInputUrl}</Typography>
          </Box>
          )}
          {(addonDetailsContent?.localiqWebsiteLive === 'No') && (
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Temporaty Website URL</Typography>
            <Typography>{addonDetailsContent?.temporaryWebsiteUrl}</Typography>
          </Box>
          )}
        </Box>
      </GridItem>

      {/* Video Background */}
      {masterAddonDetails?.videoBackground && <VideoBackgroundSection product={product} {...addonProps} />}

      {/* Email Hosting */}
      {masterAddonDetails?.emailHosting && <EmailHostingSection product={product} {...addonProps} />}

      {/* Expandable Menus */}
      {masterAddonDetails?.expandableMenus && <ExpandableMenuSection product={product} {...addonProps} />}

      {/* Online Booking */}
      {masterAddonDetails?.onlineBooking && <OnlineBookingSection product={product} {...addonProps} />}

      {/* Payment Forms */}
      {masterAddonDetails?.paymentForms && <PaymentFormsSection product={product} {...addonProps} />}

      {/* Hipaa Forms */}
      {masterAddonDetails?.hipaaForms && <HipaaFormsSection product={product} {...addonProps} />}

      {/* Pop-up */}
      {masterAddonDetails?.popups && <PopUpSection product={product} {...addonProps} />}

    </Grid>
  );
}
