import { useState } from 'react';
import { IQAssetsModal, IQImageButtonLink } from '@gannettdigital/shared-react-components';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  gettyImages,
  gettyVideos,
  loadGettyAssets,
  ProductTypes,
  selectGettyImages,
} from 'services/fileUploaderSlice';
import { selectSharedUrl } from 'services/clientStockAssetsSlice';

interface Props {
  onClick?: () => any;
  displayOnly?: 'images' | 'videos';
  productType: ProductTypes;
  gettyDescription?: string;
}

export default function GettyImages(props: Props) {
  const [modalOpen, setModalOpen] = useState(false);
  const { orderId, orderItemId } = useParams();
  const sharedUrlContent = useSelector(selectSharedUrl);
  const gettyImagesData = useSelector(selectGettyImages);
  const dispatch = useDispatch();

  const {
    productType, onClick, displayOnly, gettyDescription,
  } = props;

  const gettyFunction = (filterTerm: string) => (filterTerm === 'videos' ? gettyVideos : gettyImages);

  const onClickButton = () => {
    // eslint-disable-next-line no-unused-expressions,@typescript-eslint/no-unused-expressions
    onClick && onClick();
    setModalOpen(true);
  };

  const search = (data) => {
    dispatch(gettyFunction(data.filter)({ page: 1, size: 30, phrase: data.keyword }));
  };

  const changeFilter = (data) => {
    if (data.keyword && data.keyword.length > 2) {
      dispatch(gettyFunction(data.filter)({ page: 1, size: 30, phrase: data.keyword }));
    }
  };

  const changePage = (data, page) => {
    dispatch(gettyFunction(data.filter)({ page, size: 30, phrase: data.keyword }));
  };

  const submit = (data) => {
    dispatch(loadGettyAssets({
      orderId: orderId || sharedUrlContent.orderId,
      orderItemId: orderItemId || sharedUrlContent.sourceId,
      data,
      productType,
    }));
    setModalOpen(false);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  if (gettyImagesData.content) {
    return (
      <>
        <IQImageButtonLink buttonText="Find Stock Assets" onClick={onClickButton} />
        <IQAssetsModal displayOnly={displayOnly} data={gettyImagesData} onSubmit={submit}
          defaultSelectedItems={[]}
          isLoading={gettyImagesData.loading} onPageChange={changePage} onSearch={search}
          onChangeFilter={changeFilter} open={modalOpen} handleClose={handleClose}
          resetSelectionOnClose
          showFilterDropdown
          descriptionText={gettyDescription}
        />
      </>
    );
  }
  return null;
}
