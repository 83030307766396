import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";
import Dropdown from "pages/generic-product/details/custom-fields/Dropdown";

const {t} = i18n;

export const AudiencesIndentedSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.audiencesIndented.title'),
  type: 'object',
  required: [
    'specifyTheGenderTargeting',
    'minimumAge',
    'maximumAge',
    'wantRetargetingOrLookalikeAudience',
    'relevantInterestCategories',
    'requireAdScheduling'
  ],
  properties: {
    specifyTheGenderTargeting: {
      type: 'string',
      title: t('pages.ausProduct.audiencesIndented.specifyTheGenderTargeting'),
      default: '',
      enum: [
        t('pages.ausProduct.audiencesIndented.targetBothMalesAndFemales'),
        t('pages.ausProduct.audiencesIndented.targetMales'),
        t('pages.ausProduct.audiencesIndented.targetFemales'),
      ],
    },
    targetAgeRange: {
      type: 'string',
      title: '',
      description: t('pages.ausProduct.audiencesIndented.targetAgeRange'),
    },
    minimumAge: {
      type: 'integer',
      title: t('pages.ausProduct.audiencesIndented.minimumAge'),
    },
    maximumAge: {
      type: 'integer',
      title: t('pages.ausProduct.audiencesIndented.maximumAge'),
    },
    wantRetargetingOrLookalikeAudience: {
      type: 'string',
      title: t('pages.ausProduct.audiencesIndented.wantRetargetingOrLookalikeAudience'),
      default: '',
      enum: [
        t('pages.ausProduct.tracking.yes'),
        t('pages.ausProduct.tracking.no'),
      ],
    },
    relevantInterestCategories: {
      type: 'string',
      title: t('pages.ausProduct.audiencesIndented.relevantInterestCategories'),
      default: ""
    },
    requireAdScheduling: {
      type: 'string',
      title: t('pages.ausProduct.audiencesIndented.requireAdScheduling'),
      default: '',
      enum: [
        '',
        t('pages.ausProduct.tracking.yes'),
        t('pages.ausProduct.tracking.no'),
      ],
    },
  },
  dependencies: {
    wantRetargetingOrLookalikeAudience: {
      oneOf: [
        {
          properties: {
            wantRetargetingOrLookalikeAudience: {
              enum: [t('pages.ausProduct.tracking.yes')],
            },
            chooseOne: {
              type: 'string',
              title: t('pages.ausProduct.audiencesIndented.chooseOne'),
              default: '',
              enum: [
                '',
                t('pages.ausProduct.audiencesIndented.retargeting'),
                t('pages.ausProduct.audiencesIndented.lookalike'),
                t('pages.ausProduct.audiencesIndented.retargetingAndLookalike'),
              ],
            },
          },
        },
      ],
    },
  },
});

const textFieldKeys = [
  'relevantInterestCategories'
];

const dropdownFieldKeys = [
  'specifyTheGenderTargeting',
  'wantRetargetingOrLookalikeAudience',
  'chooseOne',
  'requireAdScheduling'
];

const textLabelKeys = [
  'targetAgeRange'
];

const integerInputFieldKeys = [
  'minimumAge',
  'maximumAge',
];

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o, [key]: {'ui:widget': 'textInputField'},
}), {});

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {
    'ui:widget': (props) => (
        <Dropdown
            {...props}
            errorMessage={t('pages.ausProduct.tracking.requiredFieldErrorMessage')}
        />
    ),
  },
}), {});

const textLabelFields = textLabelKeys.reduce((o, key) => ({
  ...o, [key]: {
    'ui:widget': 'textLabel',
    'ui:style': {
      marginBottom: '-10px',
    },
  },
}), {});

const integerInputField = integerInputFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'integerInputField'},
}), {});

export const AudiencesIndentedUISchema = {
  'ui:order': [
    'specifyTheGenderTargeting',
    'targetAgeRange',
    'minimumAge',
    'maximumAge',
    'wantRetargetingOrLookalikeAudience',
    'chooseOne',
    'relevantInterestCategories',
    'requireAdScheduling'
  ],
  ...textFields,
  ...dropdownFields,
  ...textLabelFields,
  ...integerInputField
};

export const AudiencesIndentedCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.specifyTheGenderTargeting === '') {
    errors.specifyTheGenderTargeting.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.wantRetargetingOrLookalikeAudience === '') {
    errors.wantRetargetingOrLookalikeAudience.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.relevantInterestCategories === '') {
    errors.relevantInterestCategories.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.requireAdScheduling === '') {
    errors.requireAdScheduling.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (!formData?.minimumAge) {
    errors.minimumAge.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.minimumAge > 100) {
    errors.minimumAge.addError(t('pages.ausProduct.audiencesIndented.ageErrorMessage'));
    errors.addError(true);
  }
  if (!formData?.maximumAge) {
    errors.maximumAge.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.maximumAge > 100) {
    errors.maximumAge.addError(t('pages.ausProduct.audiencesIndented.ageErrorMessage'));
    errors.addError(true);
  }
  if (formData?.maximumAge < formData?.minimumAge) {
    errors.maximumAge.addError(t('pages.ausProduct.audiencesIndented.maximumAgeErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
