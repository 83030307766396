/* eslint-disable no-param-reassign */
import {DefaultPageLayout} from 'layouts/DefaultPageLayout';
import GridItem from 'components/grid/GridItem';
import GridContainer from 'components/grid/GridContainer';
import {
  IQButtonRadioContainer,
  IQFormInput,
  IQFormSelect,
  IQInputNumericField,
  IQTheme,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import {FormProvider, useForm, useFormContext} from 'react-hook-form';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import schema from './XmoReach.schema';
import {
  ageDropDown,
  callToActionDropDown,
  genderOptionRadioItems,
  socialPlatformListOptions,
  yesNoRadioOptions
} from './XmoSocialReachService';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tooltip,
  Typography
} from '@mui/material';
import {t} from 'i18next';
import {useMemo, useState} from 'react';
import schemaValidate from "components/schemaValidate";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {yupResolver} from "@hookform/resolvers/yup";
import React from 'react';

interface Props {
  product: any;
  onSubmit: (data: any) => void;
  onBack: () => void;
}

export type socialReachProp = {
  minimumAge: number;
  maximumAge: number;
  gender: string,
  callToActionButton: string,
  useAutomaticOrOptimizedPlacement: string
  clientWantTheirAdsToAppear: string,
};

export default function XmoReachForm({product, onSubmit, onBack}: Props) {
  const methods = useForm<socialReachProp>({
    resolver: yupResolver(schema.yupValidations),
    mode: 'all',
    defaultValues: {
      minimumAge: product?.minimumAge === '65 plus' ? '65' : product?.minimumAge || '',
      maximumAge: product?.maximumAge === '65 plus' ? '65' : product?.maximumAge || '',
      gender: product?.gender || '',
      callToActionButton: product?.callToActionButton || '',
      useAutomaticOrOptimizedPlacement: product?.useAutomaticOrOptimizedPlacement || '',
      clientWantTheirAdsToAppear: product?.clientWantTheirAdsToAppear || '',
    },
  });

  const {
    handleSubmit,
    watch,
    getValues,
    register,
    trigger,
    formState: {
      isValid,
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
    },
  } = methods;

  useMemo(() => {
    const { minimumAge, maximumAge } = getValues();
    if (minimumAge && maximumAge) { trigger('maximumAge'); }
  }, [watch('minimumAge')]);

  const handleFormSubmit = (data: any) => {
    data.clientWantTheirAdsToAppear = selectedOptions
    if (data.useAutomaticOrOptimizedPlacement === 'Yes') {
      data.clientWantTheirAdsToAppear = [];
    }

    data.minimumAge = data?.minimumAge === 65 ? '65 plus' : data?.minimumAge;
    data.maximumAge = data?.maximumAge === 65 ? '65 plus' : data?.maximumAge;

    const pageFormData = {
      reach: data
    };
    onSubmit(pageFormData);
  };

  const [isAutomaticOrOptimizedPlacement, setIsAutomaticOrOptimizedPlacement] = useState(product?.useAutomaticOrOptimizedPlacement);
  const handleClientHavingWebsiteRadioChange = (event) => {
    setIsAutomaticOrOptimizedPlacement(event.target.value);
  };
  const [selectedOptions, setSelectedOptions] = useState(product?.clientWantTheirAdsToAppear || []);
  const handleCheckboxChange = (opt) => {
    if (selectedOptions.includes(opt)) {
      setSelectedOptions(selectedOptions.filter(item => item !== opt));
    } else {
      setSelectedOptions([...selectedOptions, opt]);
    }
  };
  const validateForm = () => {
    if (isAutomaticOrOptimizedPlacement === 'No') {
      return selectedOptions.length > 0;
    } else {
      return true;
    }
  };
  return (
      <DefaultPageLayout
          disableContinue={!isValid || !validateForm()}
          onBack={onBack}
          header={t('pages.xmo.reach.title')}
          onContinue={handleSubmit(handleFormSubmit)}
      >
        <ValidationProvider schema={schema}>
          <FormProvider {...methods}>
            <GridContainer fullWidth>
              <GridItem sizes={[12]}>
                <Typography variant="h6">{t('pages.xmo.reach.audience')}</Typography>
              </GridItem>
              <GridItem sizes={[6, 6]}>
                <IQFormSelect
                    id="minimumAge"
                    name="minimumAge"
                    labelText={t('pages.xmo.reach.minimumAgeTitle')}
                    items={ageDropDown}
                    defaultValue=""
                    theme={IQTheme}
                    fontLabelWeight="600"
                    fullWidth
                    required
                    schema={schema}
                />
                <IQFormSelect
                    id="maximumAge"
                    name="maximumAge"
                    labelText={t('pages.xmo.reach.maximumAgeTitle')}
                    items={ageDropDown}
                    defaultValue=""
                    theme={IQTheme}
                    fontLabelWeight="600"
                    fullWidth
                    required
                    schema={schema}
                />
              </GridItem>
              <GridItem sizes={[12]}>
                <IQButtonRadioContainer
                    label={t('pages.xmo.reach.gender')}
                    name="gender"
                    optionsList={genderOptionRadioItems}
                    inline
                />
              </GridItem>
              <GridItem sizes={[12]}>
                <Typography variant="h6">{t('pages.xmo.reach.strategy')}</Typography>
              </GridItem>
              <GridItem sizes={[12]}>
                <IQFormSelect
                    id="callToActionButton"
                    name="callToActionButton"
                    labelText={t('pages.xmo.reach.callToActionButton')}
                    items={callToActionDropDown}
                    defaultValue=""
                    theme={IQTheme}
                    fontLabelWeight="600"
                    fullWidth
                    schema={schema}
                />
              </GridItem>
              <GridItem sizes={[12]}>
                <Typography
                    sx={{fontWeight: 'bold'}}>{t('pages.xmo.reach.useAutomaticPlacementOnAds')}
                  <RadioGroup
                      id='useAutomaticOrOptimizedPlacement'
                      name='useAutomaticOrOptimizedPlacement'
                      onChange={handleClientHavingWebsiteRadioChange}
                      defaultValue={product?.useAutomaticOrOptimizedPlacement || ''}
                      row
                  >
                    {yesNoRadioOptions.map(opt => (
                        <FormControlLabel
                            key={opt.label}
                            control={<Radio value={opt.label}/>}
                            label={opt.label}
                            {...register('useAutomaticOrOptimizedPlacement', {
                              validate: (value) => schemaValidate(value, 'useAutomaticOrOptimizedPlacement', schema),
                            })}
                        />
                    ))}
                  </RadioGroup>
                </Typography>
              </GridItem>
              {isAutomaticOrOptimizedPlacement === 'No' && (
                  <GridItem sizes={[12]}>
                    <Typography sx={{
                      marginBottom: '-18px',
                      fontWeight: 'bold'
                    }}>{t('pages.xmo.reach.whereDoesTheClientWantTheirAdsToAppear')}
                    </Typography>
                    {socialPlatformListOptions.map((opt) => (
                        <Grid key={opt.label} item xs={12}>
                          <FormControlLabel
                              control={
                                <Checkbox
                                    id={`clientWantTheirAdsToAppear_${opt.label}`}
                                    name={`clientWantTheirAdsToAppear_${opt.label}`}
                                    checked={selectedOptions.includes(opt.label)}
                                    onChange={() => handleCheckboxChange(opt.label)}
                                    color="primary"
                                    inputProps={{'aria-label': opt.label}}
                                />
                              }
                              label={
                                <>
                                  {opt.label}
                                  {opt.label === 'MarketPlace' && (
                                      <Tooltip
                                          title={t('pages.xmo.reach.mobileNewsFeedEnabledTooltip')}
                                          placement="top">
                                        <InfoOutlinedIcon color="primary"
                                                          sx={{marginLeft: 1, fontSize: '15px'}}/>
                                      </Tooltip>
                                  )}
                                </>
                              }
                          />
                        </Grid>
                    ))}
                  </GridItem>
              )}
            </GridContainer>
            <CoNavigationConfirm
                showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
            />
          </FormProvider>
        </ValidationProvider>
      </DefaultPageLayout>
  );
}
