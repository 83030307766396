import React, {useEffect, useState} from 'react';
import DefaultPageLayout from 'layouts/DefaultPageLayout';
import {useTranslation} from "react-i18next";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Tooltip,
  Typography
} from '@mui/material';
import GridItem from 'components/grid/GridItem';
import GridContainer from 'components/grid/GridContainer';
import {
  IQButtonRadioContainer,
  IQFormInput,
  IQFormSelect,
  IQFormTextArea,
  IQInputNumericField,
  IQTheme,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';

import {Controller, FormProvider, useForm} from 'react-hook-form';
import schema from "pages/xmo/tactics/leadAds/LeadAds.schema";
import {
  behavioralTargetingList,
  callToActionDropDown,
  clientBeProvidingImagesToUseList,
  clientWantTheirAdsToAppearList,
  demographicTargetingList,
  detailTargetingPreferencesList,
  genderOptionRadioItems,
  interestTargetingList,
  leadAdsFormContactFieldsList,
  noneCustomList,
  visualStrategyClientWantToEmployList,
  yesNoList
} from "pages/xmo/tactics/leadAds/LeadAdsService";
import schemaValidate from "components/schemaValidate";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import NeSelectChip from "components/chips/NeSelectChip";
import ErrorIcon from "@mui/icons-material/Error";

interface Props {
  product: any,
  onSubmit: (data: any) => void,
  onBack: () => void,
}

export type LeadAdsProps = {
  minimumAge: string,
  maximumAge: string,
  gender: string,
  callToActionButton: string,
  useAutomaticOrOptimizedPlacement: string,
  clientWantTheirAdsToAppear: any,
  selectContactFieldsThatApply: any,
  leadAdsQuestionOne: string,
  customQuestionOne: string,
  customAnswerOne: string,
  leadAdsQuestionTwo: string,
  customQuestionTwo: string,
  customAnswerTwo: string,
  leadAdsQuestionThree: string,
  customQuestionThree: string,
  customAnswerThree: string,
  detailedTargetingPreferencesThatApply: any,
  demographicTargetDetail: string,
  behavioralTargetDetail: string,
  interestTargetDetail: string,
  privacyPolicyUrl: string,
  advertiserDisclaimerUrl: string,
  visualStrategyClientWantToEmploy: string,
  clientBeProvidingImagesToUse: string,
  campaignUtilizeTheCarouselFormat: string,
  clientBeProvidingTextCreative: string,
  adHeadline: string,
  adBodyText: string,
  newsfeedLinkDescription: string,
  services: any
};

export default function SocialAdsSmartLeadAdsPage({product, onSubmit, onBack}: Props) {
  const {t} = useTranslation();
  const [ageError, setAgeError] = useState<boolean>(false);

  const handleFormSubmit = (data: LeadAdsProps) => {
    if (data?.useAutomaticOrOptimizedPlacement === 'Yes') {
      data.clientWantTheirAdsToAppear = []
    }
    if (data?.leadAdsQuestionOne === 'None') {
      data.customQuestionOne = ""
      data.customAnswerOne = ""
    }
    if (data?.leadAdsQuestionTwo === 'None') {
      data.customQuestionTwo = ""
      data.customAnswerTwo = ""
    }
    if (data?.leadAdsQuestionThree === 'None') {
      data.customQuestionThree = ""
      data.customAnswerThree = ""
    }
    if (!data.detailedTargetingPreferencesThatApply.includes("Demographic Targeting")) {
      data.demographicTargetDetail = ""
    }
    if (!data.detailedTargetingPreferencesThatApply.includes("Behavioral Targeting")) {
      data.behavioralTargetDetail = ""
    }
    if (!data.detailedTargetingPreferencesThatApply.includes("Interest Targeting")) {
      data.interestTargetDetail = ""
    }
    if (data?.clientBeProvidingTextCreative === 'No') {
      data.adHeadline = ""
      data.adBodyText = ""
      data.newsfeedLinkDescription = ""
    }

    const pageFormData = {
      leadAds: data
    };
    onSubmit(pageFormData);
  };
  const methods = useForm<LeadAdsProps>({
    mode: 'all',
    defaultValues: {
      minimumAge: product?.minimumAge || '',
      maximumAge: product?.maximumAge || '',
      gender: product?.gender || '',
      callToActionButton: product?.callToActionButton || '',
      useAutomaticOrOptimizedPlacement: product?.useAutomaticOrOptimizedPlacement || '',
      clientWantTheirAdsToAppear: product?.clientWantTheirAdsToAppear || [],
      selectContactFieldsThatApply: product?.selectContactFieldsThatApply || [],
      leadAdsQuestionOne: product?.leadAdsQuestionOne || '',
      customQuestionOne: product?.customQuestionOne || '',
      customAnswerOne: product?.customAnswerOne || '',
      leadAdsQuestionTwo: product?.leadAdsQuestionTwo || '',
      customQuestionTwo: product?.customQuestionTwo || '',
      customAnswerTwo: product?.customAnswerTwo || '',
      leadAdsQuestionThree: product?.leadAdsQuestionThree || '',
      customQuestionThree: product?.customQuestionThree || '',
      customAnswerThree: product?.customAnswerThree || '',
      detailedTargetingPreferencesThatApply: product?.detailedTargetingPreferencesThatApply || [],
      demographicTargetDetail: product?.demographicTargetDetail || '',
      behavioralTargetDetail: product?.behavioralTargetDetail || '',
      interestTargetDetail: product?.interestTargetDetail || '',
      privacyPolicyUrl: product?.privacyPolicyUrl || '',
      advertiserDisclaimerUrl: product?.advertiserDisclaimerUrl || '',
      visualStrategyClientWantToEmploy: product?.visualStrategyClientWantToEmploy || '',
      clientBeProvidingImagesToUse: product?.clientBeProvidingImagesToUse || '',
      campaignUtilizeTheCarouselFormat: product?.campaignUtilizeTheCarouselFormat || '',
      clientBeProvidingTextCreative: product?.clientBeProvidingTextCreative || '',
      adHeadline: product?.adHeadline || '',
      adBodyText: product?.adBodyText || '',
      newsfeedLinkDescription: product?.newsfeedLinkDescription || '',
      services: product?.services || [],
    },
  });
  const {
    handleSubmit, register, control,
    formState: {isValid, isDirty, isSubmitSuccessful, isSubmitting},
    watch, trigger, getValues
  } = methods;

  const [adHeadlineValueError, setAdHeadlineValueError] = useState<boolean>(false);
  const [newsfeedLinkDescriptionValueError, setNewsfeedLinkDescriptionValueError] = useState<boolean>(false);
  const adHeadlineValue = watch('adHeadline');
  const newsfeedLinkDescriptionValue = watch('newsfeedLinkDescription');
  const useAutomaticOrOptimizedPlacementNo = watch('useAutomaticOrOptimizedPlacement') === 'No'
  const detailedTargetingPreferencesThatApplyDemographicTargeting = watch('detailedTargetingPreferencesThatApply').includes("Demographic Targeting")
  const detailedTargetingPreferencesThatApplyBehavioralTargeting = watch('detailedTargetingPreferencesThatApply').includes("Behavioral Targeting")
  const detailedTargetingPreferencesThatApplyInterestTargeting = watch('detailedTargetingPreferencesThatApply').includes("Interest Targeting")
  const clientBeProvidingTextCreativeYes = watch('clientBeProvidingTextCreative') === "Yes"
  const campaignUtilizeTheCarouselFormatYes = watch('campaignUtilizeTheCarouselFormat') === "Yes"
  const campaignUtilizeTheCarouselFormatNo = watch('campaignUtilizeTheCarouselFormat') === "No"
  const minimumAge = watch('minimumAge');
  const maximumAge = watch('maximumAge');

  useEffect(() => {
    if (campaignUtilizeTheCarouselFormatYes) {
      setAdHeadlineValueError(adHeadlineValue.length > 60)
      setNewsfeedLinkDescriptionValueError(newsfeedLinkDescriptionValue.length > 60)
    } else if (campaignUtilizeTheCarouselFormatNo) {
      setAdHeadlineValueError(adHeadlineValue.length > 75);
      setNewsfeedLinkDescriptionValueError(newsfeedLinkDescriptionValue.length > 250);
    }
  }, [campaignUtilizeTheCarouselFormatYes, campaignUtilizeTheCarouselFormatNo, adHeadlineValue, newsfeedLinkDescriptionValue]);

  useEffect(() => {
    const minAge = parseInt(minimumAge);
    const maxAge = parseInt(maximumAge);

    if (minAge <= 0 || maxAge <= 0 || minAge > maxAge) {
      setAgeError(true);
    } else {
      setAgeError(false);
    }
  }, [minimumAge, maximumAge]);

  return (
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <DefaultPageLayout
              disableContinue={!isValid || ageError || adHeadlineValueError || newsfeedLinkDescriptionValueError}
              onBack={onBack}
              header={t('pages.xmo.leadAds.title')}
              onContinue={handleSubmit(handleFormSubmit)}
          >
            <Box>
              <GridContainer fullWidth>
                <GridItem sizes={[12]}>
                  <Typography variant="h6">{t('pages.xmo.leadAds.audience')}</Typography>
                </GridItem>

                <GridItem sizes={[6, 6]}>
                  <IQInputNumericField
                      theme={IQTheme}
                      schema={schema}
                      label={t('pages.xmo.leadAds.minimumAge')}
                      name="minimumAge"
                      disallowNegative
                      required
                      defaultValue={product?.minimumAge || ""}
                      fullWidth
                  />
                  <IQInputNumericField
                      theme={IQTheme}
                      schema={schema}
                      label={t('pages.xmo.leadAds.maximumAge')}
                      name="maximumAge"
                      disallowNegative
                      required
                      defaultValue={product?.maximumAge || ""}
                      fullWidth
                  />
                  {ageError &&
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                              sx={{
                                color: '#C20F1E',
                                fontSize: '14px',
                                marginTop: '-15px',
                                marginBottom: '15px',
                                display: 'flex',
                                alignItems: 'center'
                              }}
                              variant="subtitle1"
                          >
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                              <ErrorIcon
                                  sx={{
                                    color: '#C20F1E',
                                    fontSize: '16px',
                                    marginRight: '5px'
                                  }}
                              />
                              {t('pages.xmo.leadAds.maximumAgeError')}
                            </Box>
                          </Typography>
                        </Grid>
                      </Grid>
                  }
                </GridItem>

                <GridItem sizes={[12]}>
                  <IQButtonRadioContainer
                      label={t('pages.xmo.leadAds.gender')}
                      name="gender"
                      optionsList={genderOptionRadioItems}
                      required
                      inline
                  />
                </GridItem>

                <GridItem sizes={[12]}>
                  <Typography variant="h6">{t('pages.xmo.leadAds.strategy')}</Typography>
                </GridItem>

                <GridItem sizes={[12]}>
                  <IQFormSelect
                      id="callToActionButton"
                      name="callToActionButton"
                      labelText={t('pages.xmo.leadAds.callToActionButton')}
                      items={callToActionDropDown}
                      defaultValue=""
                      theme={IQTheme}
                      fontLabelWeight="600"
                      fullWidth
                      schema={schema}
                      placeholder={t('pages.xmo.leadAds.callToActionButtonPlaceholder')}
                  />
                </GridItem>

                <GridItem sizes={[12]}>
                  <Typography
                      sx={{fontWeight: 'bold'}}>{t('pages.xmo.leadAds.useAutomaticOrOptimizedPlacement')}
                    <RadioGroup
                        id='useAutomaticOrOptimizedPlacement'
                        name='useAutomaticOrOptimizedPlacement'
                        defaultValue={product?.useAutomaticOrOptimizedPlacement || ''}
                        row
                    >
                      {yesNoList.map(opt => (
                          <FormControlLabel
                              key={opt.value}
                              control={<Radio value={opt.value}/>}
                              label={opt.description}
                              {...register('useAutomaticOrOptimizedPlacement', {
                                validate: (value) => schemaValidate(value, 'useAutomaticOrOptimizedPlacement', schema),
                              })}
                          />
                      ))}
                    </RadioGroup>
                  </Typography>
                </GridItem>

                {useAutomaticOrOptimizedPlacementNo && (
                    <GridItem sizes={[12]}>
                      <Grid>
                        <Grid>
                          <Typography sx={{fontWeight: 'bold'}}>
                            {t('pages.xmo.leadAds.clientWantTheirAdsToAppear')}
                          </Typography>
                        </Grid>
                        <Grid container>
                          {clientWantTheirAdsToAppearList.map((option) => (
                              <Grid item xs={12} sm={6} key={option.label}>
                                <FormControlLabel
                                    control={
                                      <Controller
                                          name="clientWantTheirAdsToAppear"
                                          control={control}
                                          {...register('clientWantTheirAdsToAppear', {
                                            validate: (value) => schemaValidate(value, 'clientWantTheirAdsToAppear', schema),
                                          })}
                                          render={({field}) => (
                                              <Checkbox
                                                  {...field}
                                                  value={option.label}
                                                  checked={field.value.includes(option.label)}
                                                  onChange={() => {
                                                    const newValue = field.value.includes(option.label)
                                                        ? field.value.filter(e => e !== option.label)
                                                        : [...field.value, option.label];
                                                    field.onChange(newValue);
                                                  }}
                                              />
                                          )}
                                      />
                                    }
                                    label={<>
                                      {option.label}
                                      {option.label === 'MarketPlace' && (
                                          <Tooltip
                                              title={t('pages.xmo.leadAds.marketPlaceTooltipMessage')}
                                              placement={"top"}>
                                            <InfoOutlinedIcon color="primary" sx={{
                                              marginLeft: 1,
                                              fontSize: '18px'
                                            }}/>
                                          </Tooltip>
                                      )}
                                    </>}
                                />
                              </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </GridItem>
                )}

                <GridItem sizes={[12]}>
                  <Grid>
                    <Grid>
                      <Typography sx={{fontWeight: 'bold'}}>
                        {t('pages.xmo.leadAds.selectContactFieldsThatApply')}
                      </Typography>
                    </Grid>
                    <Grid container>
                      {leadAdsFormContactFieldsList.map((option) => (
                          <Grid item xs={12} sm={6} key={option.label}>
                            <FormControlLabel
                                control={
                                  <Controller
                                      name="selectContactFieldsThatApply"
                                      control={control}
                                      {...register('selectContactFieldsThatApply', {
                                        validate: (value) => schemaValidate(value, 'selectContactFieldsThatApply', schema),
                                      })}
                                      render={({field}) => (
                                          <Checkbox
                                              {...field}
                                              value={option.label}
                                              checked={field.value.includes(option.label)}
                                              onChange={() => {
                                                const newValue = field.value.includes(option.label)
                                                    ? field.value.filter(e => e !== option.label)
                                                    : [...field.value, option.label];
                                                field.onChange(newValue);
                                              }}
                                          />
                                      )}
                                  />
                                }
                                label={option.label}
                            />
                          </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </GridItem>

                <GridItem sizes={[12]}>
                  <Typography sx={{fontWeight: 'bold'}}>
                    {t('pages.xmo.leadAds.leadAdsQuestionOne')}
                    <RadioGroup
                        id="leadAdsQuestionOne"
                        name="leadAdsQuestionOne"
                        defaultValue={product?.leadAdsQuestionOne || ''}
                        row
                    >
                      {noneCustomList.map(opt => (
                          <FormControlLabel
                              key={opt.value}
                              control={<Radio value={opt.value}/>}
                              label={opt.description}
                              {...register('leadAdsQuestionOne')}
                          />
                      ))}
                    </RadioGroup>
                  </Typography>
                </GridItem>

                {(watch('leadAdsQuestionOne')) === "Custom" && (
                    <>
                      <GridItem sizes={[12]}>
                        <IQFormInput
                            id="customQuestionOne"
                            name="customQuestionOne"
                            labelText={t('pages.xmo.leadAds.customQuestionOne')}
                            theme={IQTheme}
                            fontLabelWeight="600"
                            fullWidth
                            schema={schema}
                        />
                      </GridItem>

                      <GridItem sizes={[12]}>
                        <IQFormInput
                            id="customAnswerOne"
                            name="customAnswerOne"
                            labelText={t('pages.xmo.leadAds.customAnswerOne')}
                            theme={IQTheme}
                            fontLabelWeight="600"
                            fullWidth
                            schema={schema}
                            subDescription={t('pages.xmo.leadAds.customQuestionHelperText')}
                        />
                      </GridItem>
                    </>)}

                <GridItem sizes={[12]}>
                  <Typography sx={{fontWeight: 'bold'}}>
                    {t('pages.xmo.leadAds.leadAdsQuestionTwo')}
                    <RadioGroup
                        id="leadAdsQuestionTwo"
                        name="leadAdsQuestionTwo"
                        defaultValue={product?.leadAdsQuestionTwo || ''}
                        row
                    >
                      {noneCustomList.map(opt => (
                          <FormControlLabel
                              key={opt.value}
                              control={<Radio value={opt.value}/>}
                              label={opt.description}
                              {...register('leadAdsQuestionTwo')}
                          />
                      ))}
                    </RadioGroup>
                  </Typography>
                </GridItem>

                {(watch('leadAdsQuestionTwo')) === "Custom" && (
                    <>
                      <GridItem sizes={[12]}>
                        <IQFormInput
                            id="customQuestionTwo"
                            name="customQuestionTwo"
                            labelText={t('pages.xmo.leadAds.customQuestionTwo')}
                            theme={IQTheme}
                            fontLabelWeight="600"
                            fullWidth
                            schema={schema}
                        />
                      </GridItem>

                      <GridItem sizes={[12]}>
                        <IQFormInput
                            id="customAnswerTwo"
                            name="customAnswerTwo"
                            labelText={t('pages.xmo.leadAds.customAnswerTwo')}
                            theme={IQTheme}
                            fontLabelWeight="600"
                            fullWidth
                            schema={schema}
                            subDescription={t('pages.xmo.leadAds.customQuestionHelperText')}
                        />
                      </GridItem>
                    </>)}

                <GridItem sizes={[12]}>
                  <Typography sx={{fontWeight: 'bold'}}>
                    {t('pages.xmo.leadAds.leadAdsQuestionThree')}
                    <RadioGroup
                        id="leadAdsQuestionThree"
                        name="leadAdsQuestionThree"
                        defaultValue={product?.leadAdsQuestionThree || ''}
                        row
                    >
                      {noneCustomList.map(opt => (
                          <FormControlLabel
                              key={opt.value}
                              control={<Radio value={opt.value}/>}
                              label={opt.description}
                              {...register('leadAdsQuestionThree')}
                          />
                      ))}
                    </RadioGroup>
                  </Typography>
                </GridItem>

                {(watch('leadAdsQuestionThree')) === "Custom" && (
                    <>
                      <GridItem sizes={[12]}>
                        <IQFormInput
                            id="customQuestionThree"
                            name="customQuestionThree"
                            labelText={t('pages.xmo.leadAds.customQuestionThree')}
                            theme={IQTheme}
                            fontLabelWeight="600"
                            fullWidth
                            schema={schema}
                        />
                      </GridItem>

                      <GridItem sizes={[12]}>
                        <IQFormInput
                            id="customAnswerThree"
                            name="customAnswerThree"
                            labelText={t('pages.xmo.leadAds.customAnswerThree')}
                            theme={IQTheme}
                            fontLabelWeight="600"
                            fullWidth
                            schema={schema}
                            subDescription={t('pages.xmo.leadAds.customQuestionHelperText')}
                        />
                      </GridItem>
                    </>)}

                <GridItem sx={{marginBottom: 1}}>
                  <Typography variant="h6">{t('pages.xmo.leadAds.detailedTargeting')}</Typography>
                </GridItem>
                <GridItem>
                  <Typography
                      variant="subtitle1">{t('pages.xmo.leadAds.detailedTargetingDescription')}</Typography>
                </GridItem>
                <GridItem sizes={[12]}>
                  <Grid>
                    <Grid>
                      <Typography sx={{fontWeight: 'bold'}}>
                        {t('pages.xmo.leadAds.detailedTargetingPreferencesThatApply')}
                      </Typography>
                    </Grid>
                    <Grid container>
                      {detailTargetingPreferencesList.map((option) => (
                          <Grid item xs={12} sm={6} key={option.label}>

                            <FormControlLabel
                                control={
                                  <Controller
                                      name="detailedTargetingPreferencesThatApply"
                                      control={control}
                                      {...register('detailedTargetingPreferencesThatApply', {
                                        validate: (value) => schemaValidate(value, 'detailedTargetingPreferencesThatApply', schema),
                                      })}
                                      render={({field}) => (
                                          <Checkbox
                                              {...field}
                                              value={option.label}
                                              checked={field.value.includes(option.label)}
                                              onChange={() => {
                                                const newValue = field.value.includes(option.label)
                                                    ? field.value.filter(e => e !== option.label)
                                                    : [...field.value, option.label];
                                                field.onChange(newValue);
                                              }}
                                          />
                                      )}
                                  />
                                }
                                label={<Box style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center"
                                }}>
                                  {option.label}
                                  <Tooltip
                                      title={option.tooltipMessage}
                                      placement={"top"}>
                                    <InfoOutlinedIcon color="primary" sx={{
                                      marginLeft: 1,
                                      fontSize: '18px'
                                    }}/>
                                  </Tooltip>
                                </Box>}
                            />
                          </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  {detailedTargetingPreferencesThatApplyDemographicTargeting && (
                      <Grid container>
                        <Grid item xs={12}>
                          <Grid item xs={12}>
                            <Typography variant="h6">{t('pages.xmo.leadAds.demographicTargeting')}
                            </Typography>
                            <Typography variant="body1" mt={1}>
                              {t('pages.xmo.leadAds.demographicTargetingDescription')}
                            </Typography>

                          </Grid>
                          <Grid item xs={12} mt={1}>
                            <Typography variant="body1" component="ul">
                              {demographicTargetingList.map((item, index) => (
                                  <Typography key={index} variant="body1"
                                              component="li">
                                    {item}
                                  </Typography>
                              ))}
                            </Typography>
                          </Grid>
                          <Box mb={1}>
                            <Typography sx={{
                              fontWeight: 'bold',
                              display: "inline-block",
                              marginTop: "15px"
                            }}>
                              {t('pages.xmo.leadAds.demographicTargetingDetails')}
                            </Typography>
                            <Tooltip
                                title={t('pages.xmo.leadAds.detailedTargetingDescription')}
                                placement="top">
                              <InfoOutlinedIcon color="primary"
                                                sx={{
                                                  marginLeft: 1,
                                                  fontSize: '18px',
                                                  cursor: "pointer"
                                                }}/>
                            </Tooltip>
                          </Box>
                          <IQFormTextArea
                              id="demographicTargetDetail"
                              name="demographicTargetDetail"
                              fontLabelWeight="600"
                              rowCount={5}
                              fullWidth
                              showError
                          />
                        </Grid>
                      </Grid>
                  )}
                  {detailedTargetingPreferencesThatApplyBehavioralTargeting && (
                      <Grid container>
                        <Grid item xs={12} mt={2}>
                          <Grid item xs={12}>
                            <Typography variant="h6">{t('pages.xmo.leadAds.behavioralTargeting')}
                            </Typography>
                            <Typography variant="body1" mt={1}>
                              {t('pages.xmo.leadAds.behavioralTargetingDescription')}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} mt={1}>
                            <Typography variant="body1" component="ul">
                              {behavioralTargetingList.map((item, index) => (
                                  <Typography key={index} variant="body1"
                                              component="li">
                                    {item}
                                  </Typography>
                              ))}
                            </Typography>
                          </Grid>
                          <Box mb={1}>
                            <Typography sx={{
                              fontWeight: 'bold',
                              display: "inline-block",
                              marginTop: "15px"
                            }}>
                              {t('pages.xmo.leadAds.behavioralTargetingDetails')}
                            </Typography>
                            <Tooltip
                                title={t('pages.xmo.leadAds.behavioralTargetingDescription')}
                                placement="top">
                              <InfoOutlinedIcon color="primary"
                                                sx={{
                                                  marginLeft: 1,
                                                  fontSize: '18px',
                                                  cursor: "pointer"
                                                }}/>
                            </Tooltip>
                          </Box>
                          <IQFormTextArea
                              id="behavioralTargetDetail"
                              name="behavioralTargetDetail"
                              fontLabelWeight="600"
                              rowCount={5}
                              fullWidth
                              showError
                          />
                        </Grid>
                      </Grid>
                  )}
                  {detailedTargetingPreferencesThatApplyInterestTargeting && (
                      <Grid container>
                        <Grid item xs={12} mt={2}>
                          <Grid item xs={12}>
                            <Typography variant="h6">{t('pages.xmo.leadAds.interestTargeting')}
                            </Typography>
                            <Typography variant="body1">
                              {t('pages.xmo.leadAds.interestTargetingDescription')}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} mt={1}>
                            <Typography variant="body1" component="ul">
                              {interestTargetingList.map((item, index) => (
                                  <Typography key={index} variant="body1"
                                              component="li">
                                    {item}
                                  </Typography>
                              ))}
                            </Typography>
                          </Grid>
                          <Box mb={1}>
                            <Typography sx={{
                              fontWeight: 'bold',
                              display: "inline-block",
                              marginTop: "15px"
                            }}>
                              {t('pages.xmo.leadAds.interestTargetingDetails')}
                            </Typography>
                            <Tooltip
                                title={t('pages.xmo.leadAds.interestTargetingDescription')}
                                placement="top">
                              <InfoOutlinedIcon color="primary"
                                                sx={{
                                                  marginLeft: 1,
                                                  fontSize: '15px',
                                                  cursor: "pointer"
                                                }}/>
                            </Tooltip>
                          </Box>
                          <IQFormTextArea
                              id="interestTargetDetail"
                              name="interestTargetDetail"
                              fontLabelWeight="600"
                              rowCount={5}
                              fullWidth
                              showError
                          />
                        </Grid>
                      </Grid>
                  )}
                </GridItem>
                <GridItem sx={{marginBottom: 1}}>
                  <Typography variant="h6">{t('pages.xmo.leadAds.privacyPolicy')}</Typography>
                </GridItem>
                <GridItem>
                  <Typography
                      variant="subtitle1">{t('pages.xmo.leadAds.privacyPolicyDescription')}
                    <Link href="https://www.facebook.com/privacy/explanation" target="_blank"
                          rel="noopener noreferrer">
                      {t('pages.xmo.leadAds.here')}
                    </Link>.
                  </Typography>
                </GridItem>
                <GridItem sizes={[12]}>
                  <IQFormInput
                      id="privacyPolicyUrl"
                      theme={IQTheme}
                      labelText={t('pages.xmo.leadAds.privacyPolicyUrl')}
                      fontLabelWeight="600"
                      name="privacyPolicyUrl"
                      fullWidth
                      showError
                      tooltipText={t('pages.xmo.leadAds.privacyPolicyTooltipMessage')}
                      tooltipPlacement={"top"}
                      tooltipPaddingBottom={18}
                      schema={schema}
                      adornmentIcon={<InsertLinkIcon/>}
                      maxLength={35}
                  />
                </GridItem>
                <GridItem sizes={[12]}>
                  <IQFormInput
                      id="advertiserDisclaimerUrl"
                      theme={IQTheme}
                      labelText={t('pages.xmo.leadAds.advertiserDisclaimerUrl')}
                      fontLabelWeight="600"
                      name="advertiserDisclaimerUrl"
                      fullWidth
                      showError
                      schema={schema}
                      tooltipText={t('pages.xmo.leadAds.advertiserDisclaimerTooltipMessage')}
                      tooltipPlacement={"top"}
                      tooltipPaddingBottom={18}
                      adornmentIcon={<InsertLinkIcon/>}
                      maxLength={35}
                  />
                </GridItem>
                <GridItem sx={{marginBottom: 1}}>
                  <Typography variant="h6">{t('pages.xmo.leadAds.contentAndCreative')}</Typography>
                </GridItem>
                <GridItem sizes={[12]}>
                  <Typography sx={{fontWeight: 'bold'}}>
                    {t('pages.xmo.leadAds.visualStrategyClientWantToEmploy')}
                    <RadioGroup
                        id="visualStrategyClientWantToEmploy"
                        name="visualStrategyClientWantToEmploy"
                        defaultValue={product?.visualStrategyClientWantToEmploy || ''}
                        row
                    >
                      {visualStrategyClientWantToEmployList.map(opt => (
                          <FormControlLabel
                              key={opt.value}
                              control={<Radio value={opt.value}/>}
                              label={
                                <React.Fragment>
                                  {opt.description}
                                  {(opt.description === 'Single Video' || opt.description === 'Multiple Videos') && (
                                      <Tooltip
                                          title={t('pages.xmo.leadAds.visualStrategyTooltip')}
                                          placement="top">
                                        <InfoOutlinedIcon color="primary"
                                                          sx={{marginLeft: 1, fontSize: '15px'}}/>
                                      </Tooltip>
                                  )}
                                </React.Fragment>
                              }
                              {...register('visualStrategyClientWantToEmploy', {
                                validate: (value) => schemaValidate(value, 'visualStrategyClientWantToEmploy', schema),
                              })}
                          />
                      ))}
                    </RadioGroup>
                  </Typography>
                </GridItem>

                <GridItem sizes={[12]}>
                  <Typography sx={{display: "inline-block", fontWeight: 'bold'}}>
                    {t('pages.xmo.leadAds.clientBeProvidingImagesToUse')}
                    <RadioGroup
                        id="clientBeProvidingImagesToUse"
                        name="clientBeProvidingImagesToUse"
                        defaultValue={product?.clientBeProvidingImagesToUse || ''}
                        row
                    >
                      {clientBeProvidingImagesToUseList.map(opt => (
                          <FormControlLabel
                              key={opt.value}
                              control={<Radio value={opt.value}/>}
                              label={opt.description}
                              {...register('clientBeProvidingImagesToUse', {
                                validate: (value) => schemaValidate(value, 'clientBeProvidingImagesToUse', schema),
                              })}
                          />
                      ))}
                    </RadioGroup>
                  </Typography>
                </GridItem>

                <GridItem sizes={[12]}>
                  <Typography sx={{fontWeight: 'bold', display: "inline-block"}}>
                    {t('pages.xmo.leadAds.campaignUtilizeTheCarouselFormat')}
                    <Tooltip
                        title={t('pages.xmo.leadAds.campaignUtilizeTheCarouselFormatTooltipMessage')}
                        placement="top">
                      <InfoOutlinedIcon color="primary"
                                        sx={{marginLeft: 1, fontSize: '18px', cursor: "pointer"}}/>
                    </Tooltip>
                    <RadioGroup
                        id="campaignUtilizeTheCarouselFormat"
                        name="campaignUtilizeTheCarouselFormat"
                        defaultValue={product?.campaignUtilizeTheCarouselFormat || ''}
                        row
                    >
                      {yesNoList.map(opt => (
                          <FormControlLabel
                              key={opt.value}
                              control={<Radio value={opt.value}/>}
                              label={opt.description}
                              {...register('campaignUtilizeTheCarouselFormat', {
                                validate: (value) => schemaValidate(value, 'campaignUtilizeTheCarouselFormat', schema),
                              })}
                          />
                      ))}
                    </RadioGroup>
                  </Typography>
                </GridItem>

                <GridItem sizes={[12]}>
                  <Typography sx={{marginBottom: '8px', fontWeight: 'bold'}}>
                    {t('Will the client be providing their own text creative? *')}
                    <RadioGroup
                        id="clientBeProvidingTextCreative"
                        name="clientBeProvidingTextCreative"
                        defaultValue={product?.clientBeProvidingTextCreative || ''}
                        row
                    >
                      {yesNoList.map(opt => (
                          <FormControlLabel
                              key={opt.value}
                              control={<Radio value={opt.value}/>}
                              label={opt.description}
                              {...register('clientBeProvidingTextCreative', {
                                validate: (value) => schemaValidate(value, 'clientBeProvidingTextCreative', schema),
                              })}
                          />
                      ))}
                    </RadioGroup>
                  </Typography>
                </GridItem>

                {((campaignUtilizeTheCarouselFormatNo && clientBeProvidingTextCreativeYes) ||
                    (campaignUtilizeTheCarouselFormatYes && clientBeProvidingTextCreativeYes)) && (
                    <>
                      <GridItem sizes={[12]}>
                        <IQFormInput
                            id="adHeadline"
                            name="adHeadline"
                            labelText={t('pages.xmo.leadAds.adHeadline')}
                            theme={IQTheme}
                            fontLabelWeight="600"
                            fullWidth
                            schema={schema}
                            maxLength={campaignUtilizeTheCarouselFormatNo ? 75 : 60}
                            subDescription={campaignUtilizeTheCarouselFormatNo ? `${watch('adHeadline').length}/75` : `${watch('adHeadline').length}/60`}
                        />
                      </GridItem>
                      {adHeadlineValueError &&
                          <Grid container>
                            <Grid item xs={12}>
                              <Typography
                                  sx={{
                                    color: '#C20F1E',
                                    fontSize: '14px',
                                    marginTop: '-15px',
                                    marginBottom: '15px',
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                  variant="subtitle1"
                              >
                                <Box sx={{display: 'flex', alignItems: 'center'}}>
                                  <ErrorIcon
                                      sx={{
                                        color: '#C20F1E',
                                        fontSize: '16px',
                                        marginRight: '5px'
                                      }}
                                  />
                                  {t('pages.xmo.leadAds.maximumCharacterLimitExceedError')}
                                </Box>
                              </Typography>
                            </Grid>
                          </Grid>
                      }
                      <GridItem sizes={[12]}>
                        <IQFormInput
                            id="adBodyText"
                            name="adBodyText"
                            labelText={t('pages.xmo.leadAds.adBodyText')}
                            theme={IQTheme}
                            fontLabelWeight="600"
                            fullWidth
                            schema={schema}
                            maxLength={255}
                            subDescription={`${watch('adBodyText').length}/255`}
                        />
                      </GridItem>

                      <GridItem sizes={[12]}>
                        <IQFormInput
                            id="newsfeedLinkDescription"
                            name="newsfeedLinkDescription"
                            labelText={t('pages.xmo.leadAds.newsfeedLinkDescription')}
                            theme={IQTheme}
                            fontLabelWeight="600"
                            fullWidth
                            schema={schema}
                            maxLength={campaignUtilizeTheCarouselFormatNo ? 250 : 60}
                            subDescription={campaignUtilizeTheCarouselFormatNo ? `${watch('newsfeedLinkDescription').length}/250` : `${watch('newsfeedLinkDescription').length}/60`}
                        />
                      </GridItem>
                      {newsfeedLinkDescriptionValueError &&
                          <Grid container>
                            <Grid item xs={12}>
                              <Typography
                                  sx={{
                                    color: '#C20F1E',
                                    fontSize: '14px',
                                    marginTop: '-15px',
                                    marginBottom: '15px',
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                  variant="subtitle1"
                              >
                                <Box sx={{display: 'flex', alignItems: 'center'}}>
                                  <ErrorIcon
                                      sx={{
                                        color: '#C20F1E',
                                        fontSize: '16px',
                                        marginRight: '5px'
                                      }}
                                  />
                                  {t('pages.xmo.leadAds.maximumCharacterLimitExceedError')}
                                </Box>
                              </Typography>
                            </Grid>
                          </Grid>
                      }
                    </>
                )}
                <GridItem>
                  <NeSelectChip label
                                labelText={t('pages.xmo.leadAds.services')}
                                items={[]}
                                name="services"
                                placeholder={t('pages.xmo.leadAds.servicesPlaceholder')}
                                values={product?.services}
                                maxItems={4}
                                freeSolo
                                required
                                disableClear
                                schema={schema}
                                theme={IQTheme}
                  />
                </GridItem>
              </GridContainer>
            </Box>
          </DefaultPageLayout>
        </FormProvider>
      </ValidationProvider>
  );
}