import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";

const {t} = i18n;

export const WebsitePrivacyPolicySchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.websitePrivacyPolicy.title'),
  type: 'object',
  required: [
    'websitePrivacyPolicy',
  ],
  properties: {
    websitePrivacyPolicy: {
      type: 'string',
      title: t('pages.ausProduct.websitePrivacyPolicy.websitePrivacyPolicyLabel'),
      description: t('pages.ausProduct.websitePrivacyPolicy.websitePrivacyPolicyDescription'),
      default: data?.websitePrivacyPolicy || '',
    },
  },
});

const inputFieldKeys = [
  'websitePrivacyPolicy'
];

const inputFields = inputFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textInputField'},
}), {});
export const WebsitePrivacyPolicyUISchema = {
  'ui:order': [
    'websitePrivacyPolicy'
  ],
  ...inputFields,
};

export const WebsitePrivacyPolicyCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.websitePrivacyPolicy === '') {
    errors.websitePrivacyPolicy.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
