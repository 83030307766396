import { Urls } from 'navigation/Urls';
import { MenuType } from 'services/navigationSlice';

export const standaloneAddonsMenu: MenuType = ({
  label: 'Addons',
  url: '',
  status: 'incomplete',
  offeringType: 'MasterAddon',
  subSteps: [
    {
      label: 'Details',
      url: Urls.WebsiteUrl,
      status: 'incomplete',
      offeringType: Urls.WebsiteUrl,
    },
    {
      url: Urls.HeroVideo,
      label: 'Video Background',
      status: 'incomplete',
      offeringType: 'VideoBackground',
    },
    {
      url: Urls.EmailHosting,
      label: 'Email Hosting',
      status: 'incomplete',
      offeringType: 'EmailHosting',
    },
    {
      url: Urls.ExpandableMenus,
      label: 'Expandable Menu',
      status: 'incomplete',
      offeringType: 'ExpandableMenus',
    },
    {
      url: Urls.OnlineBooking,
      label: 'Online Booking',
      status: 'incomplete',
      offeringType: 'OnlineBooking',
    },
    {
      url: Urls.PaymentForms,
      label: 'Payment Forms',
      status: 'incomplete',
      offeringType: 'PaymentForms',
    },
    {
      url: Urls.HipaaForms,
      label: 'HIPAA Forms',
      status: 'incomplete',
      offeringType: 'HipaaForms',
    },
    {
      url: Urls.Popups,
      label: 'Pop-up',
      status: 'incomplete',
      offeringType: 'Popups',
    },
  ],
});
