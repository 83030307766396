import CheckCircleIcon from '@mui/icons-material/Check';
import DirectionsIcon from '@mui/icons-material/Close';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Container, FormControlLabel, Pagination, Switch, Typography,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/system/Box';
import Grid from '@mui/system/Unstable_Grid';
import debounce from 'lodash.debounce';
import * as React from 'react';
import AxiosAPI from 'services/common/axios';
import TimeAgo from 'timeago-react';

export default function ActivityLog() {
  const debounceDuration = 1000;

  const [rows, setRows] = React.useState([]);
  const [search, setSearch] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [tab, setTab] = React.useState(0);
  const [live, setLive] = React.useState(false);
  const [lastDate, setLastDate] = React.useState(new Date());

  React.useEffect(() => {
    function getAlerts() {
      getData(search, page, tab);
    }
    if (live === true) {
      getAlerts();
      const interval = setInterval(() => getAlerts(), 20000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [live]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  function getData(searchString, page, tab) {
    const tempPlatform = [tab];
    if (tab === 1) {
      tempPlatform.push([2, 3, 99]);
    } else if (tab === 0) {
      tempPlatform.push([0, 1, 2, 3, 99]);
    }
    setLastDate(new Date());
    AxiosAPI.get(
      `/activitylog?page=${page}&platform=${tempPlatform}&query=${encodeURI(searchString)}`,
    ).then((results: any) => {
      setRows(results.data || []);
    });
  }

  const debouncedChangeHandler = React.useCallback(
    debounce(getData, debounceDuration),
    [],
  );

  React.useEffect(() => {
    // fetch data from server
    debouncedChangeHandler(search, page, tab);
  }, [page, search, tab]);

  const getIcon = (messageType: any) => {
    if (messageType === 99) {
      return <HighlightOffIcon color="info" />;
    } if (messageType === 1) {
      return <CheckCircleIcon color="success" />;
    }
    return <InfoOutlinedIcon color="info" />;
  };

  const onPageChange = (event: React.ChangeEvent<any>, page: number) => {
    setPage(page);
  };
  const onSearch = (event: React.ChangeEvent<any>) => {
    setSearch(event.target.value.toString());
  };
  const onClearSearchClick = () => {
    setSearch('');
    setPage(1);
  };
  const onRefresh = () => {
    getData(search, page, tab);
  };

  const onLiveClicked = (event: any) => {
    console.log(event);
    setLive(event.target.checked);
  };

  return (
    <Container sx={{ marginTop: '50px' }}>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Logs" />
              <Tab label="API Errors" />
            </Tabs>
          </Box>
        </Grid>
        <Grid xs={7}>
          <Box>
            <Paper
              component="form"
              sx={{
                p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%',
              }}
            >
              <InputBase
                value={search}
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search logs"
                inputProps={{ 'aria-label': 'search logs' }}
                onChange={onSearch}
              />
              <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
              <IconButton color="primary" sx={{ p: '10px' }} onClick={onClearSearchClick}>
                <DirectionsIcon />
              </IconButton>
              <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
              <IconButton disabled={live} color="primary" sx={{ p: '10px' }}
                onClick={onRefresh}
              >
                <RefreshIcon />
              </IconButton>
              <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
              <FormControlLabel control={<Switch checked={live} onChange={onLiveClicked} />} label="Live" />
            </Paper>
          </Box>
        </Grid>
        <Grid xs={5} justifyContent="flex-end">
          <Pagination sx={{ m: 1 }} count={50} shape="rounded"
            onChange={onPageChange}
          />
        </Grid>
        <Grid xs={12}>
          <Typography variant="caption">
            Last updated:
            {lastDate.toString()}
            {' '}
            -
            <TimeAgo datetime={new Date(lastDate)} />
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Paper style={{ maxHeight: 800, overflow: 'auto' }}>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {React.Children.toArray(rows.map((c) => (
                <>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      {getIcon(c.messageType)}
                    </ListItemAvatar>
                    <ListItemText
                      primary={(
                        <Typography variant="subtitle2" display="block" gutterBottom>
                          {`${c.subject} | ${c.orderId} | ${c.orderItemId}`}
                        </Typography>
                      )}
                      secondary={(
                        <>
                          <Typography variant="caption" display="block" gutterBottom>
                            {c.message}
                          </Typography>
                          <Typography variant="caption" display="block" gutterBottom>
                            <TimeAgo datetime={new Date(c.loggedAtString)} />
                            {' '}
                            {`   |  ${c.loggedAtString}`}
                          </Typography>
                        </>
                      )}
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </>
              )))}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
