import { IQThemeV2 } from '@gannettdigital/shared-react-components';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {
  Button,
  Collapse,
  Grid,
  IconButton,
  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography,
} from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { ReactFragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CoModalConfirm from 'components/modals/CoModalConfirm';
import {
  Order, deleteOrder, getOrderDetails, selectOrderDetails, selectOrders,
} from 'services/myOrdersSlice';
import {
  selectUserAccess, selectUserId,
} from 'services/userSlice';
import { getOrder } from 'services/ordersSlice';
import OrderDetailsRow from './OrderDetailsRow';
import { addonsList } from './OrderDetailsConstants';

const HeaderCell = ({ children, icon, onClick }: { children: any, icon?: ReactFragment, onClick?: () => void }) => (
  <TableCell sx={{
    borderBottom: 0, cursor: onClick ? 'pointer' : 'auto', paddingY: 2, background: IQThemeV2.palette.info.light,
  }}
    onClick={onClick}
  >
    <Box sx={{
      display: 'flex', flexDirection: 'row', alignItems: 'center',
    }}
    >
      <Typography fontWeight="bold" marginRight={1}>
        {children}
      </Typography>
      {icon}
    </Box>
  </TableCell>
);

const BodyCell = ({ children, expanded, styles }:
{ children: any, expanded?: boolean, styles?: any }) => {
  const defaultBorder = '1px solid rgba(224, 224, 224, 1)';

  return (
    <TableCell sx={{
      borderBottom: expanded ? 0 : defaultBorder,
      '&:first-of-type': {
        borderLeft: defaultBorder,
      },
      '&:last-of-type': {
        borderRight: defaultBorder,
      },
      ...styles,
    }}
    >
      {children}
    </TableCell>
  );
};

export const formatDate = (value: Date) => {
  const dateValue = new Date(value);
  // eslint-disable-next-line max-len
  return `${dateValue.getMonth() + 1}/${dateValue.getDate()}/${dateValue.getFullYear()}`;
};

export const colorMap = {
  'Partial Payment': '#f1dcd0',
  'Payment Pending': '#f1dcd0',
  Pending: '#f1dcd0',
  'Payment Verified': '#cce3f3',
  'Assignments Pending': '#f1dcd0',
  'Assignments Made': '#cce3f3',
  Incomplete: '#f1dcd0',
  'On Track': '#cce3f3',
  Completed: '#cce6de',
  COMPLETED: '#cce6de',
  'Awaiting Review': '#cce3f3',
  'Off Track': '#f3cfd2',
  Fulfilled: '#cce6de',
  default: '#cce3f3',
};

export const renderStatusPill = (status) => (
  <Box sx={{
    background: colorMap[status as any],
    padding: '3px 8px',
    borderRadius: '14px',
    textAlign: 'center',
    width: 'fit-content',
  }}
  >
    <Typography sx={{ fontWeight: '600', fontSize: '12px', textTransform: 'uppercase' }}>
      {status}
    </Typography>
  </Box>
);

const OrderRow = ({ order, expanded, setExpanded }: { order: Order, expanded: string | boolean, setExpanded: any }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const unfilteredOrderDetails = useSelector(selectOrderDetails);
  const navigate = useNavigate();

  const orderId = order.id.toString();

  const orderDetails = unfilteredOrderDetails.filter(e => !addonsList.includes(e.productName));

  const handleNavigationForOrder = async (order) => {
    await dispatch(getOrder(order.id));
    if (order.gmaid !== null) {
      navigate(`/order/${order.id}/businesses/details`);
    } else {
      navigate(`/order/${order.id}/businesses/search`);
    }
  };

  const getOrderStatus = (order) => order.status;
  const getOrderUserId = (order) => order.userId;
  const removeOrder = (orderId) => dispatch(deleteOrder(orderId));

  const access = useSelector(selectUserAccess);
  const userId = useSelector(selectUserId);

  const expandRow = (orderId) => {
    if (expanded === orderId) {
      setExpanded(false);
    } else {
      dispatch(getOrderDetails(order.id));
      setExpanded(orderId);
    }
  };

  const canEditOrDeleteOrder = (order) => {
    if (access.isAdmin || (getOrderUserId(order) === userId)) {
      return true;
    }
    return false;
  };

  const renderEditAndDelete = (orderId) => (
    <>
      <BodyCell expanded={expanded === orderId} styles={{ paddingRight: '0', width: '30px' }}>
        {getOrderStatus(order) === 'Incomplete' && canEditOrDeleteOrder(order) && (
          <Button
            onClick={() => handleNavigationForOrder(order)}
          >
            <ModeEditIcon />
          </Button>
        )}
      </BodyCell>
      <BodyCell expanded={expanded === orderId} styles={{ paddingLeft: '0', width: '30px' }}>
        {getOrderStatus(order) === 'Incomplete' && canEditOrDeleteOrder(order) && (
          <IconButton onClick={() => setShowDeleteModal(true)}>
            <DeleteOutlineIcon sx={{ color: `${theme.palette.text.primary}` }} />
          </IconButton>
        )}
      </BodyCell>
    </>
  );

  const mapProductStatus = {
    Green: 'On Track',
    'On Track': 'On Track',
    Red: 'Off Track',
    Completed: 'Fulfilled',
    Cancelled: 'Off Track',
    'Payment Pending': 'Payment Pending',
    'Payment Verified': 'Payment Verified',
    'Assignments Pending': 'Assignments Pending',
    'Assignments Made': 'Assignments Made',
    'NULL': 'Incomplete'
  };

  const getProductStatus = (index, neonStatuses, status) => {
    if (getOrderStatus(order) === 'Incomplete') {
      return null;
    }
    const statusArray = neonStatuses.split(',').map(status => status.split('#')[1]);
    const productStatus = mapProductStatus[statusArray[index]] || mapProductStatus[status] || 'Off Track';
    return productStatus && renderStatusPill(productStatus);
  };

  return (
    <>
      <TableRow
        sx={{
          background: 'white',
          '& .MuiTableCell-root': {
            paddingY: 3,
          },
        }}
      >
        <BodyCell expanded={expanded === orderId} styles={{ paddingRight: '0' }}>
          {getOrderStatus(order) !== 'Incomplete' && (
            <IconButton onClick={() => expandRow(orderId)}>
              <KeyboardArrowDownIcon sx={{ color: `${theme.palette.text.primary}` }} fontSize="medium" />
            </IconButton>
          )}
        </BodyCell>
        <BodyCell expanded={expanded === orderId} styles={{ paddingRight: '0' }}>
          <Typography>{formatDate(order.createdAt)}</Typography>
        </BodyCell>
        <BodyCell expanded={expanded === orderId} styles={{ paddingRight: '0' }}>
          <Typography>{order.businessName}</Typography>
        </BodyCell>
        <BodyCell expanded={expanded === orderId} styles={{ paddingRight: '0' }}>
          <Typography>{order.id}</Typography>
        </BodyCell>
        <BodyCell expanded={expanded === orderId} styles={{ paddingRight: '0' }}>
          <Typography>{order.gmaid}</Typography>
        </BodyCell>
        <BodyCell expanded={expanded === orderId}>
          {order.products.map((item, index) => (
            <Box display="flex" gap="10px" marginBottom={1}>
              <Typography>
                {(order.healthcare && item === 'Premium Listings')
                  ? 'Healthcare'
                  : item}
              </Typography>
              {getProductStatus(index, order.neonStatus, order.status)}
            </Box>
          ))}
        </BodyCell>
        {renderEditAndDelete(orderId)}
      </TableRow>
      <TableRow
        className={expanded ? 'row-expanded' : 'row-hidden'}
        sx={{ height: '0' }}
      >
        <TableCell colSpan={9} sx={{ borderBottom: 0, background: 'transparent', padding: 0 }}>
          <Collapse in={expanded === orderId} timeout="auto" unmountOnExit>
            <OrderDetailsRow orderDetails={orderDetails} />
          </Collapse>
        </TableCell>
      </TableRow>
      <CoModalConfirm
        open={showDeleteModal}
        title="Archive Order"
        description={`Are you sure you want to archive ${order.businessName || 'this order'}?`}
        cancelText="Cancel"
        confirmText="Yes, archive"
        handleClose={() => setShowDeleteModal(false)}
        handleConfirm={() => removeOrder(order.id)}
      />
    </>
  );
};

export default function OrdersTable() {
  const theme = useTheme();
  const orders = useSelector(selectOrders);
  const [searchParams, setSearchParams] = useSearchParams();
  const [expanded, setExpanded] = useState<string | false>(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const orderIdParam = searchParams.get('orderId');
    setExpanded(orderIdParam);
  }, []);

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    searchParams.set('page', `${page}`);
    setSearchParams(searchParams);
  };

  const toggleSort = (key: string) => {
    const cronValue = searchParams.get('cron');
    const bizValue = searchParams.get('biz');
    if (key === 'cron') {
      const newValue = cronValue === 'asc' ? 'desc' : 'asc';
      searchParams.set('cron', newValue);
    } else {
      const newValue = bizValue === 'asc' ? 'desc' : 'asc';
      searchParams.set('biz', newValue);
    }
    setSearchParams(searchParams);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    searchParams.set('page', '0');
    searchParams.set('size', event.target.value);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const rowsQueryValue = searchParams.get('size');
    if (rowsQueryValue) setRowsPerPage(+rowsQueryValue);
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer component={Paper}
          sx={{
            '&.MuiPaper-root': {
              backgroundColor: 'transparent',
              boxShadow: '0 2px 6px rgba(0, 0, 0, .2)',
              borderRadius: '8px',
              overflow: 'hidden',
            },
            '& .MuiTable-root': { borderCollapse: 'separate' },
          }}
        >
          <Table size="small">
            <TableHead>
              <TableRow sx={{ border: 0, background: 'transparent' }}>
                <HeaderCell>&nbsp;</HeaderCell>
                <HeaderCell icon={
                  searchParams.get('cron') === 'asc'
                    ? <ArrowDownwardIcon fontSize="small" color="primary" />
                    : <ArrowUpwardIcon fontSize="small" color="primary" />
                }
                  onClick={() => toggleSort('cron')}
                >
                  Date Created
                </HeaderCell>
                <HeaderCell icon={
                  searchParams.get('biz') === 'asc'
                    ? <ArrowDownwardIcon fontSize="small" color="primary" />
                    : <ArrowUpwardIcon fontSize="small" color="primary" />
                }
                  onClick={() => toggleSort('biz')}
                >
                  Business Name
                </HeaderCell>
                <HeaderCell>ID</HeaderCell>
                <HeaderCell>GMAID</HeaderCell>
                <HeaderCell>Items/Status</HeaderCell>
                <HeaderCell>&nbsp;</HeaderCell>
                <HeaderCell>&nbsp;</HeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.content.map(order => (
                <OrderRow order={order} expanded={expanded} setExpanded={setExpanded}
                  key={order.id}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}
        display="flex"
        justifyContent="flex-end"
        marginTop={3}
      >
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={orders.totalElements}
          rowsPerPage={rowsPerPage}
          page={orders.pageable.pageNumber}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
          sx={{
            '.MuiTablePagination-select, .MuiSvgIcon-root': { color: theme.palette.primary.main },
            '.Mui-disabled .MuiSvgIcon-root': { color: theme.palette.primary.main, opacity: '0.38' },
          }}
        />
      </Grid>
    </Grid>
  );
}
