/* eslint-disable import/no-extraneous-dependencies */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';
import Dropdown from 'pages/generic-product/details/custom-fields/Dropdown';
import { validateEmail } from 'pages/generic-product/details/custom-fields/EmailTextField';
import { validateUrl } from 'pages/generic-product/details/custom-fields/UrlTextField';

const { t } = i18n;
const urlValidationParameter = ['https://facebook.com', 'https://fb.me', 'https://fb.com'];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const VideoCreativeServiceSchema: RJSFSchema | any = (data) => ({
  // eslint-disable-next-line max-len
  type: 'object',
  required: ['productTypeSelection', 'contactWithTheClientDirectly',
    'clientContactFirstName', 'clientContactLastName', 'clientContactNumber', 'clientContactEmail'],
  properties: {
    solutionStrategyTitle: {
      type: 'string',
      title: t('pages.smm.solutionStrategy.title'),
    },
    productTypeSelection: {
      type: 'string',
      title: t('pages.videoCreativeService.productTypeSelection'),
      default: '',
      enum: [
        'Advanced Montage',
        'Full Motion Video Plus',
        'Three Video Bundle',
        'Audio Ad :15',
        'Audio Ad :30',
      ],
    },
    contactWithTheClientDirectly: {
      type: 'string',
      title: t('pages.videoCreativeService.contactWithTheClientDirectly'),
      default: '',
      enum: [
        'Yes - Contact the client directly',
        'No - Gather all client requests from the Sales/Services rep',
      ],
    },
    clientContactFirstName: {
      type: 'string',
      title: t('pages.videoCreativeService.clientContactFirstName'),
      default: '',
    },
    clientContactLastName: {
      type: 'string',
      title: t('pages.videoCreativeService.clientContactLastName'),
      default: '',
    },
    clientContactNumber: {
      type: 'string',
      title: t('pages.videoCreativeService.clientContactNumber'),
      default: '',
    },
    clientContactEmail: {
      type: 'string',
      title: t('pages.videoCreativeService.clientContactEmail'),
      default: '',
    },
    maid: {
      type: 'string',
      title: t('pages.videoCreativeService.maId'),
      default: '',
    },
    businessName: {
      type: 'string',
      title: t('pages.videoCreativeService.businessName'),
      default: '',
    },
    businessPhoneNumber: {
      type: 'string',
      title: t('pages.videoCreativeService.businessPhoneNumber'),
      default: '',
    },
    businessPhysicalAddress: {
      type: 'string',
      title: t('pages.videoCreativeService.businessPhysicalAddress'),
      default: '',
    },
    city: {
      type: 'string',
      title: t('pages.videoCreativeService.city'),
      default: '',
    },
    state: {
      type: 'string',
      title: t('pages.videoCreativeService.state'),
      default: '',
    },
    zipcode: {
      type: 'string',
      title: t('pages.videoCreativeService.zipcode'),
      default: '',
    },
    websiteUrl: {
      type: 'string',
      title: t('pages.videoCreativeService.websiteUrl'),
      default: '',
    },
    facebookPageUrl: {
      type: 'string',
      title: t('pages.videoCreativeService.facebookPageUrl'),
      default: '',
      customUrlValidation: true,
      urlValidationParameter,
      urlValidationErrorText: t('pages.smm.details.facebookUrlErrorMessage'),
    },
    callToAction: {
      type: 'string',
      title: t('pages.videoCreativeService.callToAction'),
      default: '',
    },
    videoFocusType: {
      type: 'string',
      title: t('pages.videoCreativeService.videoFocusType'),
      default: '',
      enum: [
        '',
        'Company Information',
        'Explainer / How-To',
        'Seasonal / Promotion',
        'Other',
      ],
    },
    videoFocusInfo: {
      type: 'string',
      title: t('pages.videoCreativeService.videoFocusInfo'),
      default: '',
      description: t('pages.videoCreativeService.videoFocusInfoHelpText'),
    },
    scriptRequirements: {
      type: 'string',
      title: t('pages.videoCreativeService.scriptRequirements'),
      default: '',
      enum: [
        '',
        'Client Provided Script',
        'Write Script for Me',
      ],
    },
    scriptBulletPoints: {
      type: 'string',
      title: t('pages.videoCreativeService.scriptBulletPoints'),
      default: '',
      description: t('pages.videoCreativeService.scriptBulletPointsHelpText'),
    },
    voiceTalentPreference: {
      type: 'string',
      title: t('pages.videoCreativeService.voiceTalentPreference'),
      default: '',
      enum: [
        '',
        'Male',
        'Female',
        'No Preference',
      ],
    },
    musicSelection: {
      type: 'string',
      title: t('pages.videoCreativeService.musicSelection'),
      default: '',
      enum: [
        '',
        'Easy',
        'Jazzy',
        'High Energy',
        'New Age',
        'Positive',
        'Rock',
        'No Preference',
      ],
    },
    assetsUploaded: {
      type: 'string',
      title: t('pages.videoCreativeService.assetsUploaded'),
      default: '',
      enum: [
        '',
        'Yes',
        'No',
      ],
      description: t('pages.videoCreativeService.assetsUploadedHelpText'),
    },
    productionNotes: {
      type: 'string',
      title: t('pages.videoCreativeService.productionNotes'),
      default: '',
      description: t('pages.videoCreativeService.productionNotesHelpText'),
    },
  },
  dependencies: {
    contactWithTheClientDirectly: {
      oneOf: [
        {
          properties: {
            contactWithTheClientDirectly: {
              enum: ['No - Gather all client requests from the Sales/Services rep'],
            },
            emailForCreativeApprovalNotifications: {
              type: 'string',
              title: t('pages.videoCreativeService.emailForCreativeApprovalNotifications'),
              default: '',
              description: t('pages.videoCreativeService.emailForApprovalNotificationsDescription'),
            },
          },
          required: ['emailForCreativeApprovalNotifications'],
        },
      ],
    },
    scriptRequirements: {
      oneOf: [
        {
          properties: {
            scriptRequirements: {
              enum: ['Client Provided Script'],
            },
            scriptUploaded: {
              type: 'string',
              title: t('pages.videoCreativeService.scriptUploaded'),
              default: '',
              enum: [
                '',
                'Yes',
                'No',
              ],
            },
          },
        },
      ],
    },
    assetsUploaded: {
      oneOf: [
        {
          properties: {
            assetsUploaded: {
              enum: ['No'],
            },
            assetUploadedDescriptiveMessage: {
              type: 'string',
              title: t('pages.videoCreativeService.noAssetUploadedDescriptiveMessage'),
            },
          },
        },
      ],
    },
  },
});

const textAreaKeys = [
  'videoFocusInfo',
  'scriptBulletPoints',
  'productionNotes',
  'emailForCreativeApprovalNotifications',
];

const dropdownFieldKeys = [
  'videoFocusType',
  'scriptRequirements',
  'scriptUploaded',
  'voiceTalentPreference',
  'musicSelection',
  'assetsUploaded',
];

const textFieldKeys = [
  'city',
  'state',
  'businessName',
  'maid',
  'clientContactFirstName',
  'clientContactLastName',
  'zipcode',
  'callToAction',
  'businessPhysicalAddress',
];

const textLabelKeys = [
  'assetUploadedDescriptiveMessage',
];

const phoneNumberTextKeys = [
  'clientContactNumber',
  'businessPhoneNumber',
];

const urlTextKeys = [
  'facebookPageUrl',
  'websiteUrl',
];

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'dropdown' },
}), {});

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textInputField' },
}), {});

const textAreaFields = textAreaKeys.reduce((o, key) => ({ ...o, [key]: { 'ui:widget': 'TextareaWidget' } }), {});

const textLabelFields = textLabelKeys.reduce((o, key) => ({ ...o, [key]: { 'ui:widget': 'textLabel' } }), {});

const phoneNumberTextFields = phoneNumberTextKeys
  .reduce((o, key) => ({ ...o, [key]: { 'ui:widget': 'phoneNumberTextField' } }), {});

const urlTextFields = urlTextKeys.reduce((o, key) => ({ ...o, [key]: { 'ui:widget': 'UrlTextWidget' } }), {});

export const VideoCreativeServiceUISchema = {
  'ui:order': ['solutionStrategyTitle', 'productTypeSelection', 'contactWithTheClientDirectly',
    'emailForCreativeApprovalNotifications', 'clientContactFirstName', 'clientContactLastName',
    'clientContactNumber', 'clientContactEmail', 'maid', 'businessName', 'businessPhoneNumber',
    'businessPhysicalAddress', 'city', 'state', 'zipcode', 'websiteUrl', 'facebookPageUrl', 'callToAction',
    'videoFocusType', 'videoFocusInfo', 'scriptRequirements', 'scriptUploaded', 'scriptBulletPoints',
    'voiceTalentPreference', 'musicSelection', 'assetsUploaded', 'assetUploadedDescriptiveMessage', 'productionNotes'],

  clientContactEmail: {
    'ui:widget': 'EmailTextWidget',
    'ui:placeholder': t('pages.videoCreativeService.emailHelpContext'),
  },
  productTypeSelection: {
    'ui:widget': (props) => <Dropdown {...props} errorMessage="Please select the product type" />,
  },
  contactWithTheClientDirectly: {
    'ui:widget': (props) => (
      <Dropdown {...props}
        errorMessage="Please select if the video production team should work directly with the client"
      />
    ),
  },
  solutionStrategyTitle: {
    'ui:widget': 'headingTextLabel',
  },

  ...textAreaFields,
  ...textFields,
  ...dropdownFields,
  ...textLabelFields,
  ...phoneNumberTextFields,
  ...urlTextFields,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const VideoCreativeServiceCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.clientContactFirstName === '') {
    errors.clientContactFirstName.addError(t('pages.videoCreativeService.firstNameErrorMessage'));
    errors.addError(true);
  }
  if (formData?.clientContactLastName === '') {
    errors.clientContactLastName.addError(t('pages.videoCreativeService.lastNameErrorMessage'));
    errors.addError(true);
  }
  if (formData?.clientContactEmail === '') {
    errors.clientContactEmail.addError(t('pages.videoCreativeService.clientContactEmailErrorMessage'));
    errors.addError(true);
  }
  if (formData?.clientContactNumber === '') {
    errors.clientContactNumber.addError(t('pages.videoCreativeService.clientContactNumberErrorMessage'));
    errors.addError(true);
  }
  if (formData?.emailForCreativeApprovalNotifications === '') {
    errors.emailForCreativeApprovalNotifications
      .addError(t('pages.videoCreativeService.emailForApprovalNotificationsErrorMessage'));
    errors.addError(true);
  }
  if (formData?.maid === '') {
    errors.maid.addError(t('pages.videoCreativeService.maIdErrorMessage'));
    errors.addError(true);
  }
  if (formData?.businessName === '') {
    errors.businessName.addError(t('pages.videoCreativeService.businessNameErrorMessage'));
    errors.addError(true);
  }
  if (formData?.businessPhoneNumber === '') {
    errors.businessPhoneNumber.addError(t('pages.videoCreativeService.businessPhoneNumberErrorMessage'));
    errors.addError(true);
  }
  if (formData?.videoFocusInfo === '') {
    errors.videoFocusInfo.addError(t('pages.videoCreativeService.videoFocusInfoErrorMessage'));
    errors.addError(true);
  }
  if (formData?.callToAction === '') {
    errors.callToAction.addError(t('pages.videoCreativeService.callToActionErrorMessage'));
    errors.addError(true);
  }
  if (formData?.productionNotes === '') {
    errors.productionNotes.addError(t('pages.videoCreativeService.productionNotesErrorMessage'));
    errors.addError(true);
  }
  if (formData?.clientContactEmail !== '' && !validateEmail(formData?.clientContactEmail)) {
    errors.clientContactEmail.addError(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'));
    errors.addError(true);
  }
  if (formData?.facebookPageUrl !== ''
    && !urlValidationParameter.some(url => formData?.facebookPageUrl?.startsWith(url))) {
    errors.facebookPageUrl.addError(t('pages.socialAds.socialAdsWebClicksSection.inputError.facebookUrlErrorMessage'));
    errors.addError(true);
  }
  if (formData?.websiteUrl !== '' && !validateUrl(formData?.websiteUrl)) {
    errors.websiteUrl.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
