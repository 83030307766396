import {
  Adornment, IQFormInput, IQFormSelect, ValidationProvider,
} from '@gannettdigital/shared-react-components';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export interface NameStatusFilterValue {
  searchTerm?: string
  status?: string
}

interface Props {
  value: NameStatusFilterValue
  onFilterUpdated: (value: NameStatusFilterValue) => void
}

const statuses = [
  {
    value: 'All',
    description: 'All',
  },
  {
    value: 'On+Track',
    description: 'On Track',
  },
  {
    value: 'Off+Track',
    description: 'Off Track',
  },
  {
    value: 'Incomplete',
    description: 'Incomplete',
  },
  {
    value: 'Fulfilled',
    description: 'Fulfilled',
  },
  {
    value: 'Payment+Pending',
    description: 'Payment Pending',
  },
];

export default function NameAndStatusFilter({ value, onFilterUpdated }: Props) {
  const theme = useTheme();

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      name: '',
      status: 'All',
    },
  });

  const { setValue } = methods;

  const schema = {
    yupValidations: {},
    customValidations: {},
  };

  const handleNameChange = (searchTerm: string) => {
    onFilterUpdated({ searchTerm });
  };

  const handleStatusChange = (status) => {
    onFilterUpdated({ status });
  };

  useEffect(() => {
    if (Object.keys(value).length > 0) {
      if (value.searchTerm) {
        setValue('name', value.searchTerm);
      } else if (value.status) {
        setValue('status', value.status);
      }
    }
  }, [value]);

  return (
    <FormProvider {...methods}>
      <ValidationProvider schema={schema}>
        <Grid container sx={{ alignItems: 'flex-end' }}>
          <Grid item xs={7}>
            <IQFormInput
              id="name"
              name="name"
              labelText=""
              theme={theme}
              adornmentIcon={<SearchIcon />}
              adornmentPosition={Adornment.START_ADORNMENT}
              fullWidth
              onChange={(event) => handleNameChange(event.target.value)}
            />
          </Grid>
          <Grid item xs={3} marginLeft={3}>
            <IQFormSelect
              name="status"
              labelText="Status"
              items={statuses}
              fullWidth
              onChange={(event) => handleStatusChange(event.target.value)}
              sx={{ '&.MuiOutlinedInput-root': { backgroundColor: 'white' } }}
            />
          </Grid>
        </Grid>
      </ValidationProvider>
    </FormProvider>
  );
}
