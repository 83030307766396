import * as yup from "yup";
import i18n from 'i18n/i18n';
import {urlValidationRegex} from "shared/constants";

const {t} = i18n;

const schema = {
  primaryProvisioningNumber: yup.string().required(t('pages.xmo.solutionsStrategy.proxy.primaryProvisioningNumberErrorMessage')),
  forwardingNumber: yup.string().required(t('pages.xmo.solutionsStrategy.proxy.forwardingNumberErrorMessage')),
  businessNameForCallVerification: yup.string().required(t('pages.xmo.solutionsStrategy.proxy.businessNameForCallVerificationErrorMessage')),
  clientWantPhoneTracking: yup.string().required(t('pages.xmo.solutionsStrategy.proxy.clientWantPhoneTrackingErrorMessage')),
  shouldWeImplementUtmString: yup.string().required(t('pages.xmo.solutionsStrategy.proxy.shouldWeImplementUtmStringErrorMessage')),
  isCaptchaInstalled: yup.string().required(t('pages.xmo.solutionsStrategy.proxy.isCaptchaInstalledErrorMessage')),
  trackingForTheCaptchaPiece: yup.string().required(t('pages.xmo.solutionsStrategy.proxy.trackingForTheCaptchaPieceErrorMessage')),
  clientHaveAnotherMaidAccount: yup.string().required(t('pages.xmo.solutionsStrategy.proxy.clientHaveAnotherMaidAccountErrorMessage')),
  webEventType: yup.string().required(t('pages.xmo.solutionsStrategy.proxy.webEventTypeErrorMessage')),
  advertiserYelpProfileRatings:yup.string().required(t('pages.xmo.solutionsStrategy.advertiserYelpProfileStarRatingErrorMessage')),
  yelpBusinessProfileUrl: yup.string().required(t('pages.socialAds.socialAdsWebClicksSection.inputError.landingPageRequired'))
  .matches(urlValidationRegex, t('pages.socialAds.socialAdsWebClicksSection.inputError.landingPageRequired')),
}

export default {
  yupValidations: schema,
  customValidations: {},
};